import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import {userProperties} from 'Util/Property/UserProperties'

export async  function getAllUsers(){
    try{
      
        return axios.get(urlProperties.users.getUsers)
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    }catch (error ) {
        console.log(userProperties.messages.error.loadAll);
    }


}

export async  function getUser(userId){
    try{
        return axios.get(urlProperties.user.getUser+"/"+userId)
    }catch (error ) {
        console.log("Error loading user");
    }

}

export  function getAllChildRoles(userRole){
    return  axios.get(urlProperties.user.getAllChildRoles + "/" + userRole)     
}

export function getAllRoles(){
    return axios.get("getRoles");
}

export function getUsersByRoles(rolesList){
    return axios.get("getUsersByRoleIds?roleIds="+rolesList);
}
