import React, { useEffect, useRef } from "react";
import CustomMarker from "./CustomMarker";
import { gpsMarker } from "./SvgIcons";

const RouteMarker = ({
  route,
  origin,
  destination,
  markerChangeLatency,
  routeColor,
}) => {
  const currentRouteIndexRef = useRef(0);
  const markerRef = useRef();

  useEffect(() => {
    if (!route) return;

    const interval = setInterval(() => {
      if (route.length === currentRouteIndexRef.current)
        clearInterval(interval);
      if (markerRef.current)
        markerRef.current.setPosition(route[currentRouteIndexRef.current++]);
    }, markerChangeLatency);

    return () => {
      clearInterval(interval);
    };
  }, [route]);

  const gpsMarkerIcon = gpsMarker(routeColor);

  return (
    <CustomMarker
      position={origin}
      markerRef={markerRef}
      icon={gpsMarkerIcon}
      iconSize={24}
      anchorPoint={12}
    />
  );
};

export default RouteMarker;
