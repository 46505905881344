import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { handleErrorMessage } from 'services/CommonService';
import {
  getWorkflows,
  deleteWorkflow,
} from 'services/Workflow/WorkflowService';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';
import Button from 'components/CustomButtons/Button.js';
import { workflowProperties } from 'Util/Property/WorkflowProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import LoadingOverlay from 'react-loading-overlay';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import styles from './Workflows.module.css';
import { Typography } from '@material-ui/core';
import CategoryIcon from '@material-ui/icons/Category';
import Stage from 'views/Settings/Workflows/Workflow/Stages/Stages';
import Spinner from 'components/Spinner/Spinner.js';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));

const Workflows = (props) => {
  const classes = useStyles();

  const [permissions, setPermissions] = useState({});
  const [Workflows, setWorkflows] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const tableRef = useRef(null);

  let workflowTab = 'workflowTab';
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(workflowTab, newValue);
  };

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addWorkflow,
      PermissionProperties.viewWorkflow,
      PermissionProperties.editWorkflow,
      PermissionProperties.deleteWorkflow,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let custId = 0;
    if (props.id && props.id > 0) {
      custId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        custId = queryParam['id'];
      }
    }
    getWorkflowsFilterTypes();
    let tab = localStorage.getItem(workflowTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  }, [props.id]);

  const getWorkflowsFilterTypes = () => {
    setIsLoading(true);
    getWorkflows()
      .then((result) => {
        if (result.status === 200) {
          setWorkflows(result.data);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.loadData
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.loadData);
      });
  };
  const onWorkflowViewHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/workflow',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const deleteWorkflowHandler = (rowData) => {
    setRowData(rowData);
    setIsDeleteDialogOpen(true);
  };

  const deleteStageCloseHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteConfirmHandler = async () => {
    let data = { ...rowData };
    setIsLoading(true);
    await deleteWorkflow(data.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: workflowProperties.messages.success.delete,
          });
          getWorkflowsFilterTypes();
        } else {
          // could not delete succesfully
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.delete
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.delete);
      });
    deleteStageCloseHandler();
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <AppBar
                  position="static"
                  color="inherit"
                  className={styles.appBarStyle}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    className={classes.tabsWrapper}
                  >
                    <Tab label="Workflows" {...a11yProps(0)} />

                    <Tab label="Stages" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Dialog
                    open={isDeleteDialogOpen}
                    onClose={deleteStageCloseHandler}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Do you want to delete this Workflow
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        size="sm"
                        onClick={deleteStageCloseHandler}
                        color="primary"
                      >
                        {generalProperties.cancel}
                      </Button>
                      <Button
                        size="sm"
                        onClick={deleteConfirmHandler}
                        color="primary"
                        autoFocus
                      >
                        {generalProperties.confirm}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {/* customer table content */}
                  <div className={'material-grid-wrapper'}>
                    <MaterialTable
                      title={
                        <div>
                          <span className={'grid-title-icon'}>
                            <CategoryIcon style={{ fontSize: '1.2rem' }} />
                          </span>
                          <Typography
                            variant="h6"
                            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                          >
                            Workflows
                          </Typography>
                          {/* <Typography variant="body1">Manage products here</Typography> */}
                        </div>
                      }
                      tableRef={tableRef}
                      columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Workflow Type', field: 'form_type' },
                        { title: 'Sub Type', field: 'sub_type_name' },
                        { title: 'Created By', field: 'created_by' },
                      ]}
                      data={Workflows}
                      components={{
                        OverlayLoading: () => (
                          <div className={styles.loadingOverlay}>
                            <Spinner />
                          </div>
                        ),
                      }}
                      actions={[
                        {
                          icon: 'visibility',
                          iconProps: {
                            className: 'action-buttons view-button',
                          },
                          tooltip: 'View More Details',
                          onClick: (event, rowData) =>
                            onWorkflowViewHandler(rowData.id, false),
                          hidden: !permissions[
                            PermissionProperties.viewWorkflow
                          ],
                        },
                        {
                          icon: 'edit',
                          tooltip: 'Edit workflow',
                          iconProps: {
                            className: 'action-buttons edit-button',
                          },
                          onClick: (event, rowData) =>
                            onWorkflowViewHandler(rowData.id, true),
                          position: 'row',
                          hidden: !permissions[
                            PermissionProperties.editWorkflow
                          ],
                        },
                        {
                          icon: 'delete',
                          tooltip: 'Delete workflow',
                          iconProps: {
                            className: 'action-buttons delete-button',
                          },
                          onClick: (event, rowData) =>
                            deleteWorkflowHandler(rowData),
                          position: 'row',
                          hidden: !permissions[
                            PermissionProperties.deleteWorkflow
                          ],
                        },
                        {
                          icon: () => (
                            <Link to={props.match.url + '/workflow'}>
                              <div
                                className={
                                  'generic-button-class div-button-wrapper'
                                }
                                variant="contained"
                                color="primary"
                              >
                                Add Workflow
                              </div>
                            </Link>
                          ),
                          tooltip: 'Create new workflow',
                          isFreeAction: true,
                          hidden: !permissions[
                            PermissionProperties.addWorkflow
                          ],
                        },
                      ]}
                      options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        sorting: false,
                        search: false,
                        maxBodyHeight: '70vh',
                        headerStyle: {
                          fontSize: '0.7rem',
                          textAlignL: 'center',
                        },
                        rowStyle: {
                          fontSize: '0.6rem',
                          fontWeight: 450,
                          padding: '5rem',
                        },
                      }}
                      onRowClick={(event, rowData, toggleDetailPanel) =>
                        onWorkflowViewHandler(rowData.id, false)
                      }
                    />
                  </div>
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <Stage {...props} />
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Workflows;
