import * as ActionTypes from './claimsExpenseCategoriesActionTypes'
import ClaimsExpensesCategoriesService from "../../services/ClaimsSettings/ClaimsExpensesCategoriesService";

export const geAllExpenseTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_EXPENSES_LIST_START });

            const response = await ClaimsExpensesCategoriesService.getAllClaimsExpenseCategories();
            dispatch({
                type: ActionTypes.GET_CLAIMS_EXPENSES_LIST_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_EXPENSES_LIST_FAILED,
                payload: error
            });
        }
    };
};

export const geExpenseCategoryById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_EXPENSE_BY_ID_START });

            const response = await ClaimsExpensesCategoriesService.getClaimsExpenseCategoryById(id);
            dispatch({
                type: ActionTypes.GET_CLAIMS_EXPENSES_BY_ID_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_EXPENSES_BY_ID_SUCCESS,
                payload: error
            });
        }
    };
};
export const createExpenseCategory = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.CREATE_CLAIMS_EXPENSE_START });
            const response = await ClaimsExpensesCategoriesService.createClaimsExpenseCategory(data);
            dispatch({
                type: ActionTypes.CREATE_CLAIMS_EXPENSE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.CREATE_CLAIMS_EXPENSE_FAILED,
                payload: error
            });
        }
    };
};
export const updateExpenseCategory = (data,id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.UPDATE_CLAIMS_EXPENSE_START });
            const response = await ClaimsExpensesCategoriesService.updateClaimsExpenseCategory(data,id);
            dispatch({
                type: ActionTypes.UPDATE_CLAIMS_EXPENSE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.UPDATE_CLAIMS_EXPENSE_FAILED,
                payload: error
            });
        }
    };
};
export const deleteExpenseCategory = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.DELETE_CLAIMS_EXPENSE_START });
            const response = await ClaimsExpensesCategoriesService.deleteClaimsExpenseCategoryById(id);
            dispatch({
                type: ActionTypes.DELETE_CLAIMS_EXPENSE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.DELETE_CLAIMS_EXPENSE_FAILED,
                payload: error
            });
        }
    };
};