import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
var averageColor = '#53315E';

export default function LineChartCommon(props) {
  const {
    classes,
    chartStartDate,
    onChartDateChange,
    chartEndDate,
    chartDataLoading,
  } = props;

  return (
    <div style={{ marginLeft: '0.5rem' }}>
      <DatePicker
        disabled={chartDataLoading}
        name={'Date Range'}
        className={classes.detailBox}
        selected={chartStartDate}
        onChange={onChartDateChange}
        startDate={chartStartDate}
        endDate={chartEndDate}
        selectsRange
      />
    </div>
  );
}
