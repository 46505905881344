import * as actionTypes from '../actions/searchActionType';

let initState = {
  isOpen: false,
};

const reducer = (state = initState, actions) => {
  let updatedState = { ...state };
  switch (actions.type) {
    case actionTypes.SEARCH_OPEN:
      updatedState.isOpen = true;
      return updatedState;
    case actionTypes.SEARCH_CLOSE:
      updatedState.isOpen = false;
      return updatedState;
    default:
      return state;
  }
};

export default reducer;
