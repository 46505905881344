import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import styles from 'assets/jss/material-dashboard-react/components/customInputStyle.js';

import CustomSearchableDropDown from 'components/CustomInput/CustomSearchableDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClearIcon from '@material-ui/icons/Clear';
import { Grid, IconButton } from '@material-ui/core';

import { DatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { CheckBoxOutlineBlank } from '@material-ui/icons';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    type,
    changed,
    adornmentText,
    adornmentIcon,
    adornmentPosition,
    multiple,
    inputRef,
    hideArrows,
    onSelectInputChange,
    onAdornmentClick,
    placeholder,
    maxLength,
    onKeyDown,
    freeSolo,
    onChangeTextfield,
  } = props;
  let isShowLabelText = true;

  // const labelClasses = classNames({
  //   [" " + classes.labelRootError]: error,
  //   [" "+classes.labelRoot+" " + classes.labelRootSuccess]: success && !error
  // });
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRoot + ' ' + classes.labelRootSuccess]: !error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
  });
  const root = classNames({
    [classes.marginTop]: labelText === undefined,
    [classes.hideArrows]: hideArrows === true,
  });

  let inputElement = null;
  switch (type) {
    case 'input':
      // eslint-disable-next-line no-case-declarations
      let adormentObj = '';
      if (adornmentText || adornmentIcon) {
        // append or prepend some text
        if (adornmentPosition === 'end') {
          adormentObj = {
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={onAdornmentClick}
                style={{ cursor: 'pointer' }}
              >
                {adornmentText
                  ? adornmentText
                  : adornmentIcon
                  ? adornmentIcon
                  : undefined}
              </InputAdornment>
            ),
          };
        } else {
          adormentObj = {
            startAdornment: (
              <InputAdornment
                position="start"
                onClick={onAdornmentClick}
                style={{ cursor: 'pointer' }}
              >
                {adornmentText
                  ? adornmentText
                  : adornmentIcon
                  ? adornmentIcon
                  : undefined}
              </InputAdornment>
            ),
          };
        }
      }
      if (changed) {
        inputElement = (
          <Input
            autoComplete="off"
            inputRef={inputRef}
            classes={{
              root: root,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            inputProps={{
              maxLength: maxLength && maxLength,
            }}
            id={id}
            {...adormentObj}
            {...inputProps}
            onKeyDown={onKeyDown}
            onChange={changed}
            placeholder={!labelText && placeholder && placeholder}
          />
        );
      } else {
        inputElement = (
          <Input
            inputRef={inputRef}
            autoComplete="off"
            inputProps={{
              maxLength: maxLength && maxLength,
            }}
            classes={{
              root: root,
              disabled: classes.disabled,
              underline: underlineClasses,
              label: labelClasses,
            }}
            {...adormentObj}
            id={id}
            {...inputProps}
            placeholder={!labelText && placeholder && placeholder}
          />
        );
      }
      break;
    case 'select': {
      // inputElement = (<Select id={id} value={inputProps.value} onChange={changed} readOnly={inputProps.readOnly} disabled={inputProps.disabled}>
      //   { inputProps.options.map((option) => {
      //     return <MenuItem key={option.value} value={option.value}>{option.displayValue}</MenuItem>
      //   })}
      // </Select>);

      inputElement = (
        <CustomSearchableDropDown
          labelText={labelText}
          inputProps={inputProps}
          id={id}
          freeSolo={freeSolo}
          inputRef={inputRef}
          labelProps={labelProps}
          autoComplete="off"
          changed={changed}
          onInputChange={onSelectInputChange}
          customSearchable={inputProps.customSearchable}
          multiple={multiple}
          onAdornmentClick={onAdornmentClick && onAdornmentClick}
          onChangeTextfield={onChangeTextfield && onChangeTextfield}
        />
      );
      isShowLabelText = false;
      break;
    }
    case 'date': {
      if (inputProps.clearableDate) {
        inputElement = (
          <Grid>
            <DatePicker
              {...inputProps}
              id={id}
              className={classes.root}
              onChange={changed}
              animateYearScrolling
              style={{ width: '100%' }}
            />
            <IconButton
              edge="end"
              style={{
                position: 'absolute',
                marginLeft: '-40px',
                marginTop: '20px',
              }}
              size="small"
              disabled={!inputProps.value}
              onClick={() => changed(null)}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        );
      } else {
        inputElement = (
          <DatePicker
            {...inputProps}
            id={id}
            className={classes.root}
            onChange={changed}
            animateYearScrolling
          />
        );
      }
      break;
    }
    case 'textarea': {
      inputElement = (
        <TextareaAutosize
          style={{
            // padding: '10px',
            lineHeight: '1.4',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: '14px',
            maxWidth: '100%',
          }}
          {...inputProps}
          autoComplete="off"
          onChange={changed}
        />
      );
      break;
    }
    case 'checkbox': {
      inputElement = (
        <Checkbox
          style={
            {
              //position: "absolute",
              // marginLeft: "40px",
              // marginTop: "20px",
            }
          }
          checked={inputProps.value}
          color="primary"
          readOnly={inputProps.readOnly}
          onChange={changed}
        />
      );
      break;
    }
    case 'radio': {
      inputElement = (
        <Radio
          checked={inputProps.value}
          color="primary"
          readOnly={inputProps.readOnly}
          onChange={changed}
        />
      );
      break;
    }
    case 'switch': {
      inputElement = (
        <Switch
          readOnly={inputProps.readOnly}
          checked={inputProps.value}
          onChange={changed}
          name="checkedB"
          color="primary"
        />
      );
      break;
    }
    default:
      inputElement = (
        <Input
          classes={{
            root: root,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          inputRef={inputRef}
          onChange={changed}
          {...inputProps}
        />
      );
  }
  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <div style={styles.gridStyle}>
        {labelText !== undefined && isShowLabelText ? (
          <FormControlLabel control={inputElement} label={labelText} />
        ) : null}
      </div>
    );
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      {labelText !== undefined && isShowLabelText ? (
        <InputLabel className={labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      {inputElement}

      {error ? (
        <div>
          <div>{labelProps.errormessage} </div>
          <Clear className={classes.feedback + ' ' + classes.labelRootError} />
        </div>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
};
