import Noty from 'noty';
import '../../../node_modules/noty/lib/noty.css';
//import "../../../node_modules/noty/lib/themes/bootstrap-v4.css";
import '../../../node_modules/noty/lib/themes/sunset.css';

export const notyDefault = (configObj) => {
  return new Noty({
    text: configObj.text,
    theme: 'sunset',
    type: configObj.type,
    timeout: configObj.isTimeoutCancel ? null : 4000,
  }).show();
};

export const notyTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  ALERT: 'alert',
  INFO: 'info',
  INFORMATION: 'information',
  WARNING: 'warning',
};
