import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
// import CustomBarChart from 'components/CustomCharts/BarChart';
// import CustomReBarChart from 'components/CustomCharts/CustomReBarChart';
import Grid from '@material-ui/core/Grid';
import { getAllUsers } from 'services/User/UserService';
import {
  getApprovedEstimations,
  getAttendingStatus,
  getCostPerJobByMachine,
  getCostPerJobByRequestType,
  getCostPerJobByTechnician,
  getCurrentUserLocations,
  getDelayedAttention,
  getDelayedTaskCompletion,
  getIdleEmpCount,
  getOnDutyEmpCount,
  getRepetitiveBreakdown,
  getSRCompletionStatus,
  getTaskCompletionStatus,
  getTileConfigsByRoleId,
} from 'services/DashBoard/DashBoardService';
import Map from 'views/Maps/Map';
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import { getServiceRequestDashboard } from 'services/DashboardService/DashboardService';
import { format } from 'date-fns';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../components/CustomButtons/Button';
import generalProperties from '../../Util/Property/GeneralProperties';
import Dialog from '@material-ui/core/Dialog';
import ChartDetails from './ChartDetails/ChartDetails';
import DashboardProperties from '../../Util/Property/DashboardProperties';
import { getServiceRequestCategoryCall } from '../../services/ServiceRequest/serviceRequestCategoryServices';
import _ from 'lodash';
import { getAllCustomers } from '../../services/Customer/CustomerService';
import { getActiveCategoryList } from '../../services/Product/ProductService';
import UrlProperties from 'Util/Property/UrlProperties';

import HighlightsTypeOne from '../../components/Highlights/HighlightsTypeOne';
import HighlightsTypeThree from '../../components/Highlights/HighlightsTypeThree';
import HighlightsTypeFive from '../../components/Highlights/HighlightsTypeFive';
import axios from '../../axios/axios-default';

import LineChart from '../../components/LIneCharts/LineChart';
import CustomBarChart from '../../components/CustomBarChart/CustomBarChart';
import HighlightsTypeFourMeter from 'components/Highlights/HighlightsTypeFourMeter';
import LoadingOverlay from 'react-loading-overlay';
import SampleSkeleton from 'components/Highlights/SampleSkeleton';
import DashboardMap from 'views/Maps/DashboardMap';

import HighlightsGrid from './HighlightsGrid/HighlightsGrid';
import {
  DelayedIcon,
  DelayedPendingIcon,
  IdlingIcon,
  IdlingPendingIcon,
  OnthewayIcon,
  OnthewayPendingIcon,
  OntimeIcon,
  OntimePendingIcon,
  OutletIcon,
  DistributorIcon,
} from 'assets/icons/MapMarkers.js';
import { tooltip } from 'leaflet';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const initialStartDate = new Date();
  const initialEndDate = new Date();
  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
  const sampleHighlight = [1, 2, 3, 4, 5, 6, 7, 8];
  initialStartDate.setMonth(initialStartDate.getMonth() - 1);
  const [getIdleEmp, setIdleEmp] = useState(null);
  const [getOnDutyEmp, setOnDutyEmp] = useState(null);
  const [userLocations, setUserLocations] = useState([]);
  console.log("🚀 ~ Dashboard ~ userLocations:", userLocations)

  const [serviceRequestCategories, setServiceRequestCategories] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [
    costPerJobCByProductCategoryChartFilters,
    setCostPerJobCByProductCategoryChartFilters,
  ] = useState({
    chartDates: [initialStartDate, initialEndDate],
    srType: '',
  });

  const [moreDetailsDialogOpen, setMoreDetailsDialogOpen] = useState(false);

  const [selectedChartType, setSelectedChartType] = useState();

  const classes = useStyles();
  const [roleId, setRoleId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //Higlights
  const [highlightsLoading, setHighlightsLoading] = React.useState(false);
  const [configpannelData, setConfigPanelData] = useState([]);
  const [configpannelDataLoadSt, setConfigpannelDataLoadSt] = useState(false);
  const [tilePanelLoading, setTilePanelLoading] = useState(true);

  useEffect(() => {
    let role = localStorage.getItem('role');
    setRoleId(role);
    setConfigpannelDataLoadSt(false);
  }, []);

  useEffect(() => {
    if (roleId.length !== 0) {
      getTileConfigs(roleId);
    }
  }, [roleId]);

  // useEffect(() => {
  //   if (!getIdleEmp) {
  //     getIdleEmpCount().then((result) => {
  //       setIdleEmp(result.data);

  //       //result.data;
  //     });

  //     if (!getOnDutyEmp) {
  //       getOnDutyEmpCount().then((result) => {
  //         setOnDutyEmp(result.data);
  //       });
  //     }
  //   }
  // }, [getIdleEmp, getOnDutyEmp]);
  const placeSelectHandler = () => {};

  const getTileConfigs = async (id) => {
    // setIsLoading(true);
    setTilePanelLoading(true);

    let highlightConfiguration = localStorage.getItem('highlightConfiguration');

    if (highlightConfiguration != null && highlightConfiguration != undefined) {
      setConfigPanelData(JSON.parse(highlightConfiguration));
      setTilePanelLoading(false);
    } else {
      await getTileConfigsByRoleId(id)
        .then((result) => {
          if (result.status == '200') {
            let configs = [];
            if (result.data.length > 0) {
              result.data.map((tile) => {
                let nameKey = 'config_' + tile.id;
                localStorage.removeItem(nameKey);
                let apiString;
                if (tile.tile_type === 'type1') {
                  configs.push({
                    id: tile.id,
                    category: tile.tile_type,
                    hasSub: true,
                    Title: tile.heading,
                    subTitle: 'Working Officers',
                    data: sampleData1,
                    action: tile.action,
                    colorCode: tile.color,
                    iconName: tile.icon_ref,
                    icon: tile.icon_ref,
                    tileColor: tile.color,
                  });
                } else if (tile.tile_type === 'type2') {
                  configs.push({
                    id: tile.id,
                    category: tile.tile_type,
                    hasSub: true,
                    Title: tile.heading,
                    subTitle: 'Working Officers',
                    data: 85,
                    action: tile.action,
                    colorCode: tile.color,
                    iconName: tile.icon_ref,
                    icon: tile.icon_ref,
                    tileColor: tile.color,
                  });
                } else {
                  configs.push({
                    id: tile.id,
                    category: tile.tile_type,
                    hasSub: true,
                    Title: tile.heading,
                    subTitle: 'Working Officers',
                    data: sampleData1,
                    action: tile.action,
                    colorCode: tile.color,
                    iconName: tile.icon_ref,
                    icon: tile.icon_ref,
                    tileColor: tile.color,
                  });
                }
              });
            }
            setConfigPanelData(configs);
            localStorage.setItem(
              'highlightConfiguration',
              JSON.stringify(configs)
            );
          }
          setTilePanelLoading(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setTilePanelLoading(false);
        });
    }
  };

  const getServiceRequestCategories = () => {
    getServiceRequestCategoryCall()
      .then((result) => {
        setServiceRequestCategories(result.data);
        if (!_.isEmpty(result.data)) {
          setCostPerJobCByProductCategoryChartFilters({
            chartDates: costPerJobCByProductCategoryChartFilters.chartDates,
            srType: result.data[0].name,
          });
        }
      })
      .catch((e) => {
        setServiceRequestCategories([]);
      });
  };
  const getCustomers = () => {
    getAllCustomers()
      .then((result) => {
        setCustomers(result.data);
      })
      .catch((e) => {
        setCustomers([]);
      });
  };

  let timer = null;

  const checkForChartUpdate = () => {
    if (
      typeof window.google != 'undefined' &&
      typeof window.google.charts != 'undefined'
    ) {
      clearInterval(timer);

      return;
    }
  };

  let mapTimer = null;
  useEffect(() => {
    getAllUsersRequest();
    getAllProductRequest();
    setTimeout(function() {
      setHighlightsLoading(false);
    }, 2000);
    getServiceRequestCategories();
    getCustomers();
    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/charts/loader.js';
    script.async = true;
    script.setAttribute('id', 'gcs-5645');
    document.body.appendChild(script);
    if (timer != null) {
      clearInterval(timer);
    }
    timer = setInterval(checkForChartUpdate, 50);

    if (mapTimer !== null) {
      clearInterval(mapTimer);
    }

    setTimeout(function() {
      getCurrentUserLocationForDashboard();
    }, 3000);

    // mapTimer = setInterval(getCurrentUserLocationForDashboard, 60 * 1000 * 5);
    // //getCurrentUserLocationForDashboard();
    // let mounted = true;
    // getServiceRequestDashboard().then((items) => {
    //   if (mounted) {
    //     console.log('=======>>>>>>>', items);
    //   }
    // });
    // return () => {
    //   clearInterval(timer);
    //   clearInterval(mapTimer);
    //   return (mounted = false);
    // };
  }, []);
  const computeCentroid = () => {
    var locations = [
      [8.226949371061773, 80.04612423348817],
      [8.130437412765746, 79.9596069072676],
      [8.02710310230976, 80.05161739705773],
      [8.071975196173208, 80.14980769586361],
      [8.161704406747738, 80.1491210504174],
    ];

    var latitude = 0;
    var longitude = 0;
    var n = locations.length;

    for (var i = 0; i < n; i++) {
      latitude += locations[i][0];
      longitude += locations[i][1];
    }
  };
  const getCurrentUserLocationForDashboard = () => {
    getCurrentUserLocations()
      .then((result) => {
        if (result.status === 200) {
          let locations = [];
          //set on the way locations
          let onTheWays = result.data.on_the_way;
          for (let i in onTheWays) {
            let onTheWay = onTheWays[i];
            let onTheWay_icon = '';
            onTheWay.hasPendingSr == true
              ? (onTheWay_icon = OnthewayPendingIcon)
              : (onTheWay_icon = OnthewayIcon);
            let locationObj = {
              icon: onTheWay_icon,
              markerPosition: {
                lat: onTheWay.latitude,
                lng: onTheWay.longitude,
              },
              fillColor: '#2314cd',
              label: '' + onTheWay.full_name.split(' ')[0],
              id: onTheWay.user_id,
              scale: 1.5,
            };
            locations.push(locationObj);
          }
          let delayed = result.data.delayed;
          for (let i in delayed) {
            let delay = delayed[i];
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
            let delay_icon = '';
            delay.hasPendingSr == true
              ? (delay_icon = DelayedPendingIcon)
              : (delay_icon = DelayedIcon);
            let locationObj = {
              icon: delay_icon,
              markerPosition: { lat: delay.latitude, lng: delay.longitude },
              fillColor: '#ffa500',
              label: '' + delay.full_name.split(' ')[0],
              id: delay.user_id,
              scale: 1.5,
            };
            locations.push(locationObj);
          }
          let notDelayed = result.data.not_delayed;
          for (let i in notDelayed) {
            let notDelay = notDelayed[i];
            let notDelay_icon = '';
            notDelay.hasPendingSr == true
              ? (notDelay_icon = OntimePendingIcon)
              : (notDelay_icon = OntimeIcon);
            let locationObj = {
              icon: notDelay_icon,
              markerPosition: {
                lat: notDelay.latitude,
                lng: notDelay.longitude,
              },
              fillColor: '#40da59',
              label: '' + notDelay.full_name.split(' ')[0],
              id: notDelay.user_id,
              scale: 1.5,
            };
            locations.push(locationObj);
          }
          let notStartedList = result.data.next_not_started;
          for (let i in notStartedList) {
            let notStarted = notStartedList[i];
            let notStarted_icon = '';
            notStarted.hasPendingSr == true
              ? (notStarted_icon = IdlingPendingIcon)
              : (notStarted_icon = IdlingIcon);
            let locationObj = {
              icon: notStarted_icon,
              markerPosition: {
                lat: notStarted.latitude,
                lng: notStarted.longitude,
              },
              fillColor: '#ee2c12',
              label: '' + notStarted.full_name.split(' ')[0],
              id: notStarted.user_id,
              scale: 1.5,
            };
            locations.push(locationObj);
          }
          // let outletList = result.data.outlets;
          // for (let i in outletList) {
          //   let outlet = outletList[i];
          //   let outlet_icon = OutletIcon;
          //   let locationObj = {
          //     icon: outlet_icon,
          //     markerPosition: {
          //       lat: outlet.latitude,
          //       lng: outlet.longitude,
          //     },
          //     fillColor: '#01045c',
          //     label: '' + outlet.full_name.split(' ')[0],
          //     id: outlet.customer_id,
          //     tooltipEnabled:true,
          //     tooltipProps:{
          //       name:outlet.full_name
          //     },
          //     scale: 2,
          //   };
          //   locations.push(locationObj);
          // }
          let distributorList = result.data.distributors;
          for (let i in distributorList) {
            let distributor = distributorList[i];
            let distributor_icon = OutletIcon;
            let locationObj = {
              icon: distributor_icon,
              markerPosition: {
                lat: distributor.latitude,
                lng: distributor.longitude,
              },
              fillColor: '#0411c2',
              label: '' + distributor.full_name.split(' ')[0],
              id: distributor.customer_id,
              tooltipEnabled:true,
              tooltipProps:{
                name:distributor.full_name
              },
              scale: 2,
            };
            locations.push(locationObj);
          }
          setUserLocations(locations);
        } else {
          // handleErrorMessage(result.data, "Could not load field engineer locations");
        }
        //result.data;
      })
      .catch((error) => {
        // handleErrorMessage(error, "Could not load field engineer locations");
      });
  };

  const closeMoreDetailPopUp = () => {
    setSelectedChartType(undefined);
    setMoreDetailsDialogOpen(false);
  };
  const openMoreDetailPopUp = (chartType) => {
    setSelectedChartType(chartType);
    setMoreDetailsDialogOpen(true);
  };

  const getAllUsersRequest = () => {
    getAllUsers()
      .then((result) => {
        if (result.status === 200) {
          var arr = [];

          result.data.map((val) => {
            arr.push({
              id: val.id,
              name: val.first_name + ' ' + val.last_name,
            });
          });
          setAllUsers(arr);
        }
      })
      .catch((error) => {});
  };
  const getAllProductRequest = () => {
    getActiveCategoryList()
      .then((result) => {
        if (result.status === 200) {
          var arr = [];
          result.data.map((val) => {
            arr.push({ id: val.id, name: val.name });
          });
          setAllProducts(arr);
        }
      })
      .catch((error) => {});
  };

  //StaffActivity
  const sampleData1 = {
    total: 30,
    type: [
      {
        title: 'On the Job',
        total: 18,
        percentage: 60,
      },
      {
        title: 'Travelling',
        total: 8,
        percentage: 27,
      },
      {
        title: 'idling',
        total: 4,
        percentage: 13,
      },
    ],
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        {/* <HighlightsGrid
          tilePanelLoading={tilePanelLoading}
          configpannelData={configpannelData}
          highlightsLoading={highlightsLoading}
        /> */}
        <Grid container spacing={3} style={{ paddingTop: '20px' }}>
          {tilePanelLoading
            ? sampleHighlight.map((val) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={val}>
                    <SampleSkeleton />
                  </Grid>
                );
              })
            : configpannelData.map((item) =>
                item.category === 'type1' ? (
                  <Grid item xs={12} sm={6} md={3}>
                    <HighlightsTypeOne
                      hasSub={item.hasSub}
                      loading={highlightsLoading}
                      title={item.Title}
                      subTitle={item.subTitle}
                      // data={item.data}
                      // data={item.newData}
                      dataRow={item}
                      colorCode={item.colorCode}
                      iconName={item.iconName}
                      icon={item.icon}
                      tileColor={item.tileColor}
                      categoryType={item.category}
                      action={item.action}
                    />
                  </Grid>
                ) : item.category === 'type2' ? (
                  <Grid item xs={12} sm={6} md={3}>
                    <HighlightsTypeThree
                      loading={highlightsLoading}
                      title={item.Title}
                      total={item.data}
                      dataRow={item}
                      colorCode={item.colorCode}
                      icon={item.icon}
                      tileColor={item.tileColor}
                      categoryType={item.category}
                      action={item.action}
                    />
                  </Grid>
                ) : item.category === 'type4' ? (
                  <Grid item xs={12} sm={6} md={3}>
                    <HighlightsTypeFourMeter
                      hasSub={item.hasSub}
                      loading={highlightsLoading}
                      title={item.Title}
                      data={item.data}
                      dataRow={item}
                      colorCode={item.colorCode}
                      icon={item.icon}
                      tileColor={item.tileColor}
                    />
                  </Grid>
                ) : item.category === 'type3' ? (
                  <Grid item xs={12} sm={6} md={3}>
                    <HighlightsTypeFive
                      loading={highlightsLoading}
                      title={item.Title}
                      data={item.data}
                      dataRow={item}
                      colorCode={item.colorCode}
                      icon={item.icon}
                      tileColor={item.tileColor}
                    />
                  </Grid>
                ) : (
                  ''
                )
              )}
        </Grid>
        <br />
        <GridContainer style={{ paddingLeft: 12, paddingRight: 12 }}>
          <Card style={{ marginTop: '4px' }}>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <Map
                  center={{ lat: 6.90343, lng: 79.85797 }}
                  height="600px"
                  zoom={8}
                  placeHandler={placeSelectHandler}
                  markers={userLocations}
                  disableInfo={true}
                />
                {/* <DashboardMap markers={userLocations} /> */}
              </GridItem>
            </CardBody>
          </Card>
        </GridContainer>
        <div>
          <GridContainer>
            <br />
            <Grid
              container
              spacing={3}
              style={{
                marginBottom: '12px',
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              {/* <Grid item xs={12} sm={12} md={6}>
                <LineChart
                  highlightsLoading={highlightsLoading}
                  endPoint={'/'}
                  // serviceRequestCategories={serviceRequestCategories}
                  // products={allProducts}
                  // users={allUsers}
                  selection={'SR Type'}
                  title={'Cost Per Job - Service Request Type'}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LineChart
                  highlightsLoading={highlightsLoading}
                  endPoint={'/'}
                  // serviceRequestCategories={serviceRequestCategories}
                  // products={allProducts}
                  // users={allUsers}
                  selection={'Field Agent'}
                  title={'Cost Per Job - Field Agent'}
                />
              </Grid> */}
            </Grid>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomBarChart
                title={'Average Days of Completion - Service Request Type'}
                // products={allProducts}
                // users={allUsers}
                // serviceRequestCategories={serviceRequestCategories}
                selection={'SR Type'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomBarChart
                title={'Average Days of Completion - Field Agent'}
                // products={allProducts}
                // users={allUsers}
                // serviceRequestCategories={serviceRequestCategories}
                selection={'Field Agent'}
              />
            </GridItem>
          </GridContainer>
          <br />
          <br />
          {/* <Dialog
            maxWidth="lg"
            fullWidth={true}
            open={moreDetailsDialogOpen}
            onClose={closeMoreDetailPopUp}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <ChartDetails
                chartTitle={DashboardProperties.chartTitles[selectedChartType]}
                method={getMoreChartDetails}
                chartType={selectedChartType}
              />
            </DialogContent>
            <DialogActions>
              <Button
                size="sm"
                onClick={closeMoreDetailPopUp}
                color="primary"
                autoFocus
              >
                {generalProperties.close}
              </Button>
            </DialogActions>
          </Dialog> */}
        </div>
      </LoadingOverlay>
    </div>
  );
}
