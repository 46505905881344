import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {
  responseProperties,
  getResponseForm,
} from 'Util/Property/ResponseProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import * as trainingService from 'services/Training/TrainingService';
import styles from './Leave.module.css';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { getAllUsers } from 'services/User/UserService';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';

const Response = (props) => {
  const [responseForm, setResponseForm] = useState(getResponseForm());
  const [responseHeading, setResponseHeading] = useState(
    responseProperties.Response
  );
  const [isLoading, setIsLoading] = useState(false);
  const [notifiactionId, setNotifiactionId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [allUserIds, setAllUserIds] = useState([]);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addTraining,
      PermissionProperties.editTraining,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    props.setResponse(responseForm);
  }, []);

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;

    let formData = { ...responseForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setResponseForm(formData);
    props.setResponse(formData);
  };

  const onMessageSend = async (values) => {};

  let formElementArray = [];
  for (let key in responseForm) {
    formElementArray.push({
      id: key,
      config: responseForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <div className="generic-form-container">
              {!isEdit ? (
                <div className={'generic-form-header-wrapper'}>
                  <br />
                </div>
              ) : (
                ''
              )}
              <Form
                onSubmit={onMessageSend}
                validate={(values, ss) => {
                  const errors = {};

                  return errors;
                }}
                render={({
                  handleSubmit,
                  reset,
                  submitting,
                  pristine,
                  valid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          if (!element.config.isHide) {
                            let mdVal = element.config.size;
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <Field name={element.id}>
                                  {({ input, meta, options, value }) => (
                                    <div style={{ position: 'relative' }}>
                                      <CustomInput
                                        labelText={element.config.label}
                                        id={element.id}
                                        inputProps={{
                                          ...input,
                                          ...element.config.inputProps,
                                          readOnly: isView ? true : false,
                                        }}
                                        type={
                                          element.id === isView
                                            ? 'input'
                                            : element.config.elType
                                        }
                                        formControlProps={
                                          element.config.formControlProps
                                        }
                                        adornmentText={
                                          element.config.adornmentText
                                        }
                                        adornmentPosition={
                                          element.config.adornmentPosition
                                        }
                                        multiple={element.config.multiple}
                                        changed={(event, value) => {
                                          input.onChange(event);
                                          onChangeHandeler(event, element.id);
                                        }}
                                        labelProps={{
                                          ...element.config.labelProps,
                                          error: meta.error && meta.touched,
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className={styles.formError}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                  </form>
                )}
              />
            </div>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Response;
