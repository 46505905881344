const urlProperties = {
  //baseUrl: "http://dev.cba:8089/api/v1/", // 'http://localhost:8081/cba/'    uat =138.201.99.190
  // baseUrl: process.env.REACT_APP_BASE_URL, //"http://dev.cba:8089/api/v1/", // 'http://localhost:8081/cba/'    uat =138.201.99.190
  // baseUrl: 'http://136.243.219.42:8089/api/v1/', //"http://dev.cba:8089/api/v1/", // 'http://localhost:8081/cba/'    uat =138.201.99.190
  baseUrl: process.env.REACT_APP_BASE_URL,
  getAllCustomers: 'searchCustomers',
  filterAllCustomers: 'filterCustomer',
  getCustomers: 'getCustomers',
  getCustomer: 'getCustomer',
  getProductCategories: 'searchProductCategory',
  filterProductCategory: 'filterProductCategory',
  getProductCategory: 'getProductCategory',
  getProductBrands: 'searchProductBrand',
  filterProductBrand: 'filterProductBrand',
  getProductModels: 'searchProductModel',
  filterProductSubStatus: 'filterProductSubStatus',
  updateProductSubStatus: 'updateProductSubStatus/',
  filterPartCategory: 'filterPartCategory',
  updatePartCategory: 'updatePartCategory/',
  filterProductModel: 'filterProductModel',
  getAllUsers: 'searchUsers',
  filterCustomerUsers: 'filterCustomerUsers',
  filterUsers: 'filterUsers',
  filterLeaves: 'filterLeaves',
  filterClaimReport: 'filterClaimReport',
  getCustomerHistory: 'getCustomerHistory',
  customerUpload: 'customerUpload/csv',
  user: {
    getAllChildRoles: 'getAllChildRoles',
    searchUsersArea: 'searchUsersArea',
    getAllRoles: 'getRoles',
    getAllLeaveScheme: 'getLeaveSchemes',
    getActiveVehicleTypes: 'getActiveVehicleTypes',
    getUser: 'getUser',
    getUserImage: 'getUserImageByUserId',
    createUserImage: 'createUserImage',
    updateUserImage: 'updateUserImageById',
    resetPassword: 'resetPassword',
    changePassword: 'changePassword',
  },
  serviceAgreement: {
    serviceAgreement: 'serviceAgreement',
    getAllServiceAgreement: 'searchServiceAgreement',
    filterAllServiceAgreement: 'filterServiceAgreement',
    getServiceAgreements: 'getServiceAgreements',
    getServiceAgreementsByCategory: 'getServiceAgreementsByCategory',
    getServiceAgreementTypes: 'getServiceAgreementCategories',
    // getServiceAgreementsByTypeAndModel: "getServiceAgreementsByTypeAndModel"
  },
  customer: {
    getCustomersByContactNumber: 'getCustomersByContactNumber',
    searchCustomersTid: 'searchCustomersTid',
    getCustomersByTid: 'getCustomersByTid',
  },
  serviceRequest: {
    getAllServiceRequests: 'searchServiceRequest',
    filterServiceRequest: 'filterServiceRequest',
    filterServiceRequestForExport: 'filterServiceRequestForExport',
    serviceRequest: 'serviceRequest',
    getAllCustomers: 'getCustomers',
    createServiceRequest: 'createServiceRequest',
    getServiceRequests: 'getServiceRequests',
    updateServiceRequest: 'updateServiceRequest',
    deleteServiceRequest: 'deleteServiceRequest',
    getServiceRequest: 'getServiceRequest',
    assignUserToServiceRequests: 'assignUserToServiceRequests',
    getEstimatedTimeForPlannedDate: 'getEstimatedTimeForPlannedDate',
    getServiceRequestByNotCompleted: 'getServiceRequestByNotCompleted',
    getServiceRequestByCustomer: 'getServiceRequestByCustomer',
    getServiceRequestHistory: 'getServiceRequestHistory',
    createBulkServiceRequests: 'createBulkServiceRequests',
    getSRReport: 'getServiceRequestReportPDF',
  },
  serviceRequestCategories: {
    getServiceRequestCategories: 'getServiceRequestCategories',
    deleteServiceRequestCategory: 'deleteServiceRequestCategory/',
    createServiceRequestCategory: 'createServiceRequestCategory',
    updateServiceRequestCategory: 'updateServiceRequestCategory/',
    filterServiceRequestCategory: 'filterServiceRequestCategory',
  },
  users: {
    getUsers: 'getUsers',
    getUsersByRole: 'getUsersByRole',
    searchCustomerUsers: 'searchCustomerUsers',
  },
  checklist: {
    checklist: 'checklist',
    getChecklists: 'getCheckLists',
    filterChecklists: 'filterCheckLists',
    getChecklist: 'getCheckList',
    addChecklist: 'createCheckList',
    updateChecklist: 'updateCheckList',
    deleteChecklist: 'deleteCheckList',
    addCheckLists: 'createCheckLists',
    searchCheckLists: 'searchCheckLists',
    // getCheckListsByModelIdAndSRTypeId: "getCheckListsByModelIdAndSRTypeId"
    getCheckListsByModelIdAndSRTypeId:
      'getCheckListByModelAndSRTypeAndCategory',
    AlreadyAvailableChecklist: 'isCheckListExistByModelAndSRTypeAndCategory',
  },
  priorityConfiguration: {
    priorityConfiguration: 'priorityConfiguration',
    getPriorityConfigurations: 'getPriorityConfigurations',
    getPriorityConfiguration: 'getPriorityConfiguration',
    addPriorityConfiguration: 'createPriorityConfiguration',
    updatePriorityConfiguration: 'updatePriorityConfiguration',
    deletePriorityConfiguration: 'deletePriorityConfiguration',
    searchPriorityConfigurations: 'searchPriorityConfigurations',
    filterPriorityConfigurations: 'filterPriorityConfig',
    getBestMatchPriorityConfiguration: 'getBestMatchPriorityConfiguration',
  },
  status: {
    getStatusByFormAndSRCategory: 'getStatusByFormAndSRCategory',
    getStatusByForm: 'getStatusByForm',
  },
  product: {
    getProducts: 'getProducts',
    getProduct: 'getProduct',
    searchProductsBySerial: 'searchProductsBySerial',
    searchProductsByTid: 'searchProductsByTID',
    getCustomerProducts: 'getCustomerProducts',
    filterCustomerProducts: 'filterProduct',
    createProduct: 'createProduct',
    updateProduct: 'updateProduct',
    deleteProduct: 'deleteProduct',
    searchProduct: 'searchProduct',
    filterProduct: 'filterProduct',
    getProductsByCustomerAndModel: 'getProductsByCustomerAndModel',
    getProductsForBulkSA: 'getProductsForBulkSa',
    getProductSubStatuses: 'getProductSubStatuses',
    getProductHistory: 'getProductHistory',
    getAccessoriesByProductCategory: 'getPartCategoriesByProductCategory',
  },
  estimation: {
    getEstimations: 'getAllEstimations',
    getEstimation: 'getEstimations',
    getEstimationByCustId: 'getEstimationByCustId',
    createEstimations: 'createEstimations',
    updateEstimations: 'updateEstimations',
    deleteEstimations: 'deleteEstimations',
    searchEstimations: 'searchEstimations',
    filterEstimations: 'filterEstimations',
    getEstimationHistory: 'getEstimationHistory',
    reportGenerate: 'getEstimationReportPDF',
  },
  dashboard: {
    getOnDutyEmployees: 'getOnDutyEmployeeCount',
    getIdleEmployees: 'getIdleEmployeeCount',
    getServiceRequestDashboard: 'getServiceRequestDashboard',
    getCurrentUserLocations: 'getCurrentUserLocations',
    search: 'search',
  },
  productServiceAgreement: {
    getProductServiceAgreements: 'getProductServiceAgreements',
    getProductServiceAgreement: 'getProductServiceAgreement',
    getProductServiceAgreementsOfProduct:
      'getProductServiceAgreementsOfProduct',
    createProductServiceAgreement: 'createProductServiceAgreement',
    updateProductServiceAgreement: 'updateProductServiceAgreement',
    deleteProductServiceAgreement: 'deleteProductServiceAgreement',
    searchProductServiceAgreement: 'searchProduct',
    searchProductServiceAgreements: 'searchProductServiceAgreements',
    filterProductServiceAgreement: 'filterProductServiceAgreement',
    filterUpcomingProductServiceAgreement:
      'filterUpcomingProductServiceAgreement',
    createBulkProductServiceAgreements: 'createPSAForBulkProducts',
    getExpiringProductServiceAgreements: 'getExpiringPSAUntil',
    createBulkUpcomingProductServiceAgreement: 'createBulkUpcomingPSA',
    getUpcomingProductServiceAgreement: 'getUpcomingProductServiceAgreement',
    updateUpcomingProductServiceAgreement:
      'updateUpcomingProductServiceAgreement',
    deleteUpcomingProductServiceAgreement:
      'deleteUpcomingProductServiceAgreement',
    createFutureSRsUntil: 'createFutureSRsUntil',
    getPSAReport: 'getServiceAgreementReportPDF',
    updatePSAWorkflowStage: 'updatePSAWorkflowStage',
  },
  training: {
    getAllTrainings: 'getAllTraingings',
    updateNotificationStatus: 'updateNotificationStatus',
    viewNotification: 'viewNotification',
    createNotification: 'createNotification',
    viewAllNotification: 'viewAllNotification',
    filterNotifications: 'filterNotifications',
    searchUserComment: 'searchUserComment',
    filterUserRequestTrainings: 'filterUserRequestTrainings',
    getUserComment: 'getUserComment',
  },
  feedback: {
    sendCustomerFeedbackEmail: 'sendCustomerFeedbackEmail',
    findByFeedbackToken: 'findByFeedbackToken',
    updateCustomerFeedBack: 'updateCustomerFeedBack',
    getAllFeedback: 'getAllFeedback',
    getFeedback: 'findByFeedbackToken',
    getFeedbackById: 'getFeedback',
    getAllCustomerFeedback: 'getAllCustomerFeedback',
  },
  reports: {
    generateReport: 'generateReportData',
    searchOperationalReport: 'searchOperationalReport',
    searchOnSiteTechDetailReport: 'searchOnSiteTechDetailReport',
  },
  stage: {
    getStageByFormAndSRCategory: 'getStageByFormAndSRCategory',
    getStageByForm: 'getStageByForm',
  },
  categorylist: {
    category3: 'getCategory3list',
  },
  reportsAndDashboards: {
    reportsAndDashboards: 'reportsAndDashboard',
  },
  leave: {
    updateLeaves: 'updateLeaves',
    notifyUserLeave: 'notifyUserLeave',
    getLeaves: 'getLeavesGroupedByCurrentSupervisor',
  },
};

export default urlProperties;
