import { GoogleMapsAPI } from "views/Maps/ClientConfig";

export const loadGoogleMapsScript = () => {
    return new Promise((resolve, reject) => {
      if (window.google && window.google.maps) {
        resolve();
        return;
      }
  
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=geometry`;
      script.async = true;
      script.defer = true;
  
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
  
      document.body.appendChild(script);
    });
  };
  