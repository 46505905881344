import React, { useEffect, useState } from 'react';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import axios from '../../axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';
import { userProperties, getUserForm } from 'Util/Property/UserProperties';
import Avatar from '@material-ui/core/Avatar';

export default function ProfileData(props) {
  const [ProfileImage, setProfileImage] = useState('');
  const [userName, setUserName] = useState('');

  let userId = localStorage.getItem('userId');
  useEffect(() => {
    userData(userId);
  }, []);

  const userData = (userId) => {
    const imageobj = JSON.parse(localStorage.getItem('userImage')) || null;

    if (imageobj != null) {
      const imageUserId = imageobj.userId;
      if (userId != null) {
        if (Number(userId) !== imageUserId) {
          axios
            .get(UrlProperties.user.getUserImage + '/' + userId)
            .then((result) => {
              const userImageObj = {
                imageId: result.data.id,
                userId: result.data.user_id,
                image: result.data.image,
              };
              localStorage.setItem('userImage', JSON.stringify(userImageObj));
              setProfileImage(userImageObj.image);
              let name = localStorage.getItem('userFullName');
              props.getLoggedUserName(name);
            })
            .catch((error) => {
              notyDefault({
                type: notyTypes.ERROR,
                text: userProperties.messages.error.loadData,
              });
            });
        } else {
          setProfileImage(imageobj.image);
        }
      }
    } else {
      if (userId !== null) {
        axios
          .get(UrlProperties.user.getUserImage + '/' + userId)
          .then((result) => {
            const userImage = {
              imageId: result.data.id,
              userId: result.data.user_id,
              image: result.data.image,
            };

            localStorage.setItem('userImage', JSON.stringify(userImage));
            setProfileImage(userImage.image);
            let name = localStorage.getItem('userFullName');
            setUserName(name);
            props.getLoggedUserName(name);

            // }
          })
          .catch((error) => {
            notyDefault({
              type: notyTypes.ERROR,
              text: userProperties.messages.error.loadData,
            });
          });
      }
    }
  };

  return (
    <div>
      <div>
        <Avatar
          src={
            ProfileImage == '' || typeof ProfileImage === 'undefined'
              ? ''
              : ProfileImage
            // : `data:image/jpeg;base64,${ProfileImage}`
          }
          // <Avatar src={ProfileImage == '' || typeof ProfileImage === 'undefined'? "https://www.linkpicture.com/q/def_user-removebg-preview.png" :ProfileImage}
          round={true}
          style={{
            width: '38px',
            height: '38px',
            border: '3px solid #d4cfcf ',
            marginTop: 0,
          }}
        />
        {/* round={true} style={{width:'50px',height:'50px' , border: '3px solid #716f6f ', marginTop:40, marginBottom:5,marginLeft:30}}/> */}
        {/* // round={true} style={{width:'50px',height:'50px' }}/> */}
        {/* <img src={ProfileImage == '' || typeof ProfileImage === 'undefined'? "https://www.linkpicture.com/q/def_user-removebg-preview.png" :ProfileImage} width={50} height={50} style={{borderRadius:'120px'}}/> */}
        {/* <h6>hi{userName}</h6> */}
        {/* <p style={{fontSize:10 }}>
               hi{userName}
               </p> */}
      </div>
    </div>
  );
}
