
export const GET_FUEL_RATES_LIST_START ="GET_FUEL_RATES_LIST_START";
export const GET_FUEL_RATES_LIST_SUCCESS ="GET_FUEL_RATES_LIST_SUCCESS";
export const GET_FUEL_RATES_LIST_FAILED ="GET_FUEL_RATES_LIST_FAILED";

export const GET_FUEL_RATE_BY_ID_START ="GET_FUEL_RATE_BY_ID_START";
export const GET_FUEL_RATES_BY_ID_SUCCESS ="GET_FUEL_RATES_BY_ID_SUCCESS";
export const GET_FUEL_RATES_BY_ID_FAILED ="GET_FUEL_RATES_BY_ID_FAILED";

export const GET_ACTIVE_VEHICLE_TYPES_START ="GET_ACTIVE_VEHICLE_TYPES_START";
export const GET_ACTIVE_VEHICLE_TYPES_SUCCESS ="GET_ACTIVE_VEHICLE_TYPES_SUCCESS";
export const GET_ACTIVE_VEHICLE_TYPES_FAILED ="GET_ACTIVE_VEHICLE_TYPES_FAILED";

export const CREATE_FUEL_RATE_START ="CREATE_FUEL_RATE_START";
export const CREATE_FUEL_RATE_SUCCESS ="CREATE_FUEL_RATE_SUCCESS";
export const CREATE_FUEL_RATE_FAILED ="CREATE_FUEL_RATE_FAILED";

export const DELETE_FUEL_RATE_START ="DELETE_FUEL_RATE_START";
export const DELETE_FUEL_RATE_SUCCESS ="DELETE_FUEL_RATE_SUCCESS";
export const DELETE_FUEL_RATE_FAILED ="DELETE_FUEL_RATE_FAILED";

export const UPDATE_FUEL_RATE_START ="UPDATE_FUEL_RATE_START";
export const UPDATE_FUEL_RATE_SUCCESS ="UPDATE_FUEL_RATE_SUCCESS";
export const UPDATE_FUEL_RATE_FAILED ="UPDATE_FUEL_RATE_FAILED";