import axios from '../../axios/axios-default';
import urlProperties from '../../Util/Property/UrlProperties';
import ax from 'axios';

const baseUrl = urlProperties.baseUrl;
export default {
  getAllClaimsReports() {
    return axios.get(`${baseUrl}getClaimReports`);
  },
  getAllUserForClaims() {
    return axios.get(`${baseUrl}getUsers`);
  },
  getAllClaimsReportById(id) {
    return axios.get(`${baseUrl}getClaimReports/${id}`);
    // return axios.get(`${baseUrl}getClaimReports/19`);
  },
  updateClaimReportById(data) {
    return axios.put(`${baseUrl}updateClaimReports/${data.id}`, data);
  },
  generateClaim(data) {
    return axios.post(
      `${baseUrl}createClaimReports?userId=${data.userId}&startDate=${data.startDate}&endDate=${data.endDate}`
    );
  },

  generateClaimReport(uuid) {
    // return axios.get(`${baseUrl}getClaimReportPDF/${uuid}`);
    return axios({
      url: `${baseUrl}getClaimReportPDF/${uuid}`,
      method: 'GET',
      responseType: 'blob',
    });
  },

  sendClaimReport(uid, uuid, reportName) {
    return axios.post(
      // `${baseUrl}notifyUserClaim/${uid}?claimUuid=${uuid}&claimReportName=${reportName}`
      `${baseUrl}notifyUserClaim/${42}?claimUuid=${uuid}&claimReportName=${reportName}`
    );
  },

  updateClaimReport(data) {
    return axios.patch(`${baseUrl}updateClaimReportStage/${data.id}`, data);
  },
  getRouteOptionsForCalim(id) {
    return axios.get('checkClaimReportRouteOptions/' + id);
  },
};
