const DateRangeStyle = {
    
    detailBox: {
        margin: '5px -10px',
        border: '1px solid #D2D2D2',
        borderRadius: '3px',
        padding: '10px 5px',
        display: 'inherit'
    },



};

export default DateRangeStyle;
