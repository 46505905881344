import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from 'views/Auth/LoginPage';
import Admin from 'layouts/Admin.js';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import RouterGuard from './components/HOC/RouterGuard';
import RouterProps from './Util/Property/RouterProperties';
//import "../node_modules/noty/lib/noty.css";
//import "../node_modules/noty/lib/themes/mint.css";
import './App.css';
import '../node_modules/rc-tree/assets/index.css';
import FeedBack from 'views/FeedBack/FeedBack';
import { isCustomerUser, isWareHouseUrl } from './Util/Util';
import TechnicianRouteReport from 'views/Reports/TechnicianRouteReport';
import OnSiteTecDetailReport from 'views/Reports/OnSiteTecDetailReport';
import OnSiteUserDetailReport from 'views/Reports/OnSiteUserDetailReport';
import { GlobalDebug } from './Util/remove-consoles';

class App extends Component {
  constructor(props) {
    super(props);
    GlobalDebug(true);
  }
  isComponentDidMountExecuted = false;
  componentDidMount() {
    this.isComponentDidMountExecuted = true;
    // this.props.checkAutoSignIn();  need to think about this
  }

  hist = createBrowserHistory();

  render() {
    let reactRedirectEl = null;
    let dashBoardPath = RouterProps.mainDomain + '/dashboard';
    let loginPath = RouterProps.mainDomain + '/login';
    let wareHouseLoginPath = RouterProps.mainDomain + '/warehouse/login';
    let wareHousePath = RouterProps.mainDomain + '/warehouse';
    let feedBackPath = RouterProps.mainDomain + '/feedback/:token';
    let technicianRoute =
      RouterProps.mainDomain + '/reports/technicianroute/:technicianId/:date';
    let onSiteTecDetail =
      RouterProps.mainDomain +
      '/reports/onSiteTecDetail/:technicianId/:assignedDate';
    let onSiteUserDetail =
      RouterProps.mainDomain +
      '/reports/onSiteUserDetail/:technicianId/:assignedDate';
    const loggedInUserCustomerId = localStorage.getItem('userCustomerId');
    //let customerPath = `${RouterProps.mainDomain}/customers/customerDetails?id=${loggedInUserCustomerId}`;
    let customerPath = `${RouterProps.mainDomain}/customer-dashboard`;
    let redirectPath;
    if (isCustomerUser()) {
      redirectPath = customerPath;
    } else if (isWareHouseUrl(window.location)) {
      redirectPath = wareHousePath;
    } else {
      redirectPath = dashBoardPath;
    }
    if (!this.props.isAuthenticated && this.isComponentDidMountExecuted) {
      reactRedirectEl = (
        <Switch>
          <Route path={loginPath} component={Login} />
          <Route path={wareHouseLoginPath} component={Login} />
          <Route path={feedBackPath} component={FeedBack} />
          {/* <Route path="/rtl" component={RTL} /> */}
          {<Redirect from="/" to={loginPath} />}
        </Switch>
      );
    } else {
      reactRedirectEl = (
        <Switch>
          <Route path={loginPath} component={Login} />
          <Route path={feedBackPath} component={FeedBack} />
          <Route path={wareHouseLoginPath} component={Login} />
          <Route path={technicianRoute} component={TechnicianRouteReport} />
          <Route path={onSiteTecDetail} component={OnSiteTecDetailReport} />
          <Route path={onSiteUserDetail} component={OnSiteUserDetailReport} />
          <Route path={RouterProps.mainDomain} component={RouterGuard(Admin)} />
          {/* <Route path="/rtl" component={RTL} /> */}
          {<Redirect from="/" to={redirectPath} />}
        </Switch>
      );
    }
    return <Router history={this.hist}>{reactRedirectEl}</Router>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token != null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAutoSignIn: () => dispatch(actions.checkAuthState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
