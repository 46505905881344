import moment from 'moment';
const emailPattern = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const datePickerFormatForSA = 'yyyy-MM-DD';
const statusArray = [
  { value: 'ACTIVE', displayValue: 'Active' },
  { value: 'INACTIVE', displayValue: 'Inactive' },
  { value: 'TERMINATED', displayValue: 'Terminated' },
];
const vehicleOwnerShipArray = [
  { value: 'OWN', displayValue: 'Own' },
  { value: 'COMPANY', displayValue: 'Company' },
  { value: 'RENTED', displayValue: 'Rented' },
];
const departmentArray = [
  { value: 'WAREHOUSE', displayValue: 'Warehouse' },
  { value: 'FINANCE', displayValue: 'Finance' },
  { value: 'IT', displayValue: 'IT' },
  { value: 'POS', displayValue: 'POS' },
  { value: 'SALES', displayValue: 'Sales' },
  { value: 'TECHNICAL', displayValue: 'Technical' },
];

export const typeArray = [
  { value: 'USER', displayValue: 'Admin' },
  { value: 'VEHICLE', displayValue: 'Vehicle' },
];

export const userProperties = {
  emailPatern: emailPattern,
  addUser: 'Add user',
  addCustomerUser: 'Add Customer user',
  editUser: 'Edit user',
  viewUser: 'View User',
  userDetails: 'User Details',
  changePassword: 'Change Password',
  passwordMismatch: 'Password mismatch',
  plsAddNewUser: 'Please add new user',
  plsAddNewCustomerUser: 'Please add new Customer user',
  manageUser: 'Manage user here',
  messages: {
    error: {
      loadData: 'Could not load user data please try again',
      saveData: 'Could not save user data please try again',
      deleteUser: 'Could not terminate user please try again',
      userNameTaken: 'Username is already taken',
      userRoles: 'Could not load user roles',
      activeVehicleTypes: 'Could not load active vehicle types',
      leaveSchemes: 'Could not load leave schemes',
      customers: 'Could not load Customers',
      area: 'Could not load Areas',
      changePassword: 'Could not change user password try again',
      loadAll: 'Could not load users please try again',
    },
    success: {
      saveData: 'User details saved successfully',
      deleteUser: 'User data terminated successfully',
      changePassword: 'User password changed successfully',
    },
    normal: {
      deleteCustomer: 'Do you want to terminate this user ?',
      changePassword: 'Do you want to change password',
    },
  },
  addNewUser: 'Add New User',
  addNewCustomerUser: 'Add New Customer User',
  users: 'Users',
};

export const getPasswordForm = () => {
  return {
    newPassword: {
      elType: 'input',
      label: 'New Password',
      inputProps: {
        type: 'password',
        placeholder: 'New Password',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    confirmNewPassword: {
      elType: 'input',
      label: 'Confirm Password',
      inputProps: {
        type: 'password',
        placeholder: 'Confirm Password',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
  };
};
export const getUserPasswordResetForm = () => {
  const pwdResetForm = getPasswordForm();
  return {
    oldPassword: {
      elType: 'input',
      label: 'Old Password',
      inputProps: {
        type: 'password',
        placeholder: 'Old Password',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    ...pwdResetForm,
  };
};

export const getUserForm = () => {
  return {
    first_name: {
      elType: 'input',
      label: 'First Name',
      inputProps: {
        type: 'text',
        placeholder: 'First Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    last_name: {
      elType: 'input',
      label: 'Last Name',
      inputProps: {
        type: 'text',
        placeholder: 'Laset Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    user_name: {
      elType: 'input',
      label: 'Username',
      inputProps: {
        type: 'text',
        placeholder: 'Username',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: ['validateUsername'],
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    password: {
      elType: 'input',
      label: 'Password',
      inputProps: {
        type: 'password',
        placeholder: 'Password',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      isHide: false,
    },
    address: {
      elType: 'input',
      label: 'Address',
      inputProps: {
        type: 'text',
        placeholder: 'Address',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: true,
    },
    email: {
      elType: 'input',
      label: 'Email',
      inputProps: {
        type: 'text',
        placeholder: 'Email',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    employee_number: {
      elType: 'input',
      label: 'Employee Number',
      inputProps: {
        type: 'text',
        placeholder: 'Employee Number',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: false,
    },
    join_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormatForSA,
        value: new Date(),
        label: 'Register Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,

      // elType: 'date',
      // label: 'Register Date',
      // inputProps: {
      //   type: 'text',
      //   value: '',
      //   // value: moment(),
      //   initvalue: '',
      //   // format: "YYYY/MM/DD"
      //   format: "DD/MM/YYYY"
      // },
      // formControlProps: {
      //   fullWidth: true
      // },
      // valid:true,
      // touched:false,
      // isFullWidth :false,
      // labelProps:{
      //   required : true,
      //   error:false,
      //   style:{position:"static",transform:"none"}
      // }
    },
    designation: {
      elType: 'input',
      label: 'Designation',
      inputProps: {
        type: 'text',
        placeholder: 'Designation',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: false,
        error: false,
      },
      isFullWidth: true,
    },
    // location: {
    //   elType: 'input',
    //   label: 'Location',
    //   inputProps: {
    //     type: 'text',
    //     placeholder: 'Location',
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   isDisabled: false,
    //   size: 6,
    // },
    area: {
      elType: 'select',
      label: 'Area',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        customSearchable: true,
      },
      freeSolo: true,
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      labelProps: {
        required: false,
        error: false,
        style: {
          fontSize: '14px',
        },
      },
      isAsyncDropDown: true,
    },
    // immediate_supervisor: {
    //   elType: 'input',
    //   label: 'Immediate Supervisor',
    //   inputProps: {
    //     type: 'text',
    //     placeholder: 'Immediate Supervisor',
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   labelProps: {
    //     required: true,
    //     error: false,
    //   },
    //   isFullWidth: true,
    // },
    // immediate_supervisor: {
    //   elType: 'select',
    //   label: 'Roles',
    //   inputProps: {
    //     options: [],
    //     value: [],
    //     initvalue: [],
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   size: 12,
    //   isAsyncDropDown: true,
    //   multiple: true,
    // },
    // userIdList: {
    //   elType: 'select',
    //   label: 'Immediate Supervisor',
    //   inputProps: {
    //     options: [],
    //     width: '100%',
    //     enableclearable: true,
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   validations: {
    //     required: false,
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   touched: false,
    // },
    // userIdList: {
    //   elType: 'select',
    //   label: 'Immediate Supervisors',
    //   inputProps: {
    //     options: [],
    //     value: [],
    //     initvalue: [],
    //   },

    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   size: 12,
    //   isAsyncDropDown: true,
    //   multiple: true,
    //   labelProps: {
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    // },
    // parent_user_id: {
    //   elType: 'select',
    //   label: 'Parent User',
    //   inputProps: {
    //     options: [],
    //     value: [],
    //     initvalue: [],
    //     enableclearable:true,
    //   },

    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   size: 12,
    //   isAsyncDropDown: true,
    //   multiple: false,
    //   labelProps: {
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    // },
    phone: {
      elType: 'input',
      label: 'Phone No',
      inputProps: {
        type: 'text',
        placeholder: 'Phone No',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      isHide: false,
    },
    // fax: {
    //   elType: 'input',
    //   label: 'Fax No',
    //   inputProps: {
    //     type: 'text',
    //     placeholder: 'Fax No',
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   labelProps: {
    //     required: false,
    //   },
    //   isFullWidth: false,
    // },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: statusArray,
        value: 'ACTIVE',
        initvalue: 'ACTIVE',
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      labelProps: {
        style: {
          fontSize: '14px',
        },
      },
    },
    role: {
      elType: 'select',
      label: 'Role',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      labelProps: {
        style: {
          fontSize: '14px',
        },
      },
    },
    // vehicle_type_id: {
    //   elType: 'select',
    //   label: 'Vehicle Type',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   labelProps: {
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    // },
    // ownership_type: {
    //   elType: 'select',
    //   label: 'Vehicle Ownership',
    //   inputProps: {
    //     options: vehicleOwnerShipArray,
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   labelProps: {
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    // },
    customer_id: {
      elType: 'select',
      label: 'Customer',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      labelProps: {
        required: true,
        style: {
          fontSize: '14px',
        },
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
    },
    
    // department: {
    //   elType: 'select',
    //   label: 'Department',
    //   inputProps: {
    //     options: departmentArray,
    //     value: departmentArray[0].value,
    //     initvalue: '',
    //   },
    //   labelProps: {
    //     required: true,
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    // },
    type: {
      elType: 'select',
      label: 'User Type',
      inputProps: {
        options: typeArray,
        value: 'VEHICLE',
        initvalue: "VEHICLE",
      },
      labelProps: {
        required: true,
        style: {
          fontSize: '14px',
        },
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
    },
    // customer_distributor_id: {
    //   elType: 'select',
    //   label: 'Distributor Customer',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //     enableclearable: true,
    //   },
    //   labelProps: {
    //     required: false,
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    // },
    // leave_scheme_id: {
    //   elType: 'select',
    //   label: 'Leave Schema',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //   },
    //   labelProps: {
    //     required: true,
    //     style: {
    //       fontSize: '14px',
    //     },
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    // },
    field_user_type: {
      elType: 'checkbox',
      label: 'Field User',
      inputProps: {
        value: false,
        initvalue: false,
      },
      labelProps: {
        required: false,
        style: {
          fontSize: '67px',
        },
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
    },
  };
};
