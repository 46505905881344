import { customerProperties } from "./CustomerPorerties";

const statusArray = [
  { value: "ACTIVE", displayValue: 'Active' },
  { value: "INACTIVE", displayValue: 'Inactive' }
]

const BusinessType = [
  { value: "SOLE PROPRIETOR", displayValue: 'SOLE PROPRIETOR' },
  { value: "PRIVATE LIMITED", displayValue: 'PRIVATE LIMITED' },
  { value: "PARTNERSHIP", displayValue: 'PARTNERSHIP' },
  { value: "INDIVIDUAL", displayValue: 'INDIVIDUAL' },
  
];

export const CheckListDisabledFields = {
  CHECKLIST: [
    "productCategoryId",
    "productBrandId",
    "productModelId",
    "category",
  ]
};

const checklist = {
    checklist : "Checklist",
    addChecklist : "Add Checklist",
    checklists : "Checklists",
    viewChecklist : "View Checklist",
    editChecklist : "Edit Checklist",
    addCheckItem : "Add Check Item",
    messages: {
        error: {
          loadData: "Could not load Checklist data. Please try again",
          saveData: "Could not save Checklist data. Please try again",
          delete: "Could not delete Checklist. Please try again",
          loadAll: "Could not load Checklist. Please try again",
          loadProductCategories : "Could not load product categories",
          loadProductBrands : "Could not load product brands",
          loadProductModels : "Could not load product models",
          loadcategory3 : "Could not load category 3",
          alreadyAvailable:"Checklist Already available please update...",
          availabilitycheckError: "Checklist check Availability Error"
        },
        success: {
          saveData: "Checklist details saved successfully",
          delete: "Checklist deleted successfully"
        },
        normal: {
          deleteConfirm: "Do you want to delete this Checklist?",
        }
    
      },
      checklistForm :{
        name: {
            elType: 'input',
            label: 'Name',
            inputProps: {
              type: 'text',
              placeholder: 'Name',
              value: '',
              initvalue: ''
            },
            formControlProps: {
              fullWidth: true
            },
            labelProps: {
              required: true,
              error:false
            },
            size: 12,
          },
          serviceRequestCategoryId: {
            elType: 'select',
            label: 'Request Type',
            inputProps: {
              options: [],
              value: '',
              initvalue: ''
            },
            formControlProps: {
              fullWidth: true
            },
            size: 12,
            isAsyncDropDown : true 
          },
          productCategoryId: {
            elType: 'select',
            label: 'Product Category',
            inputProps: {
              options: [],
              value: '',
              initvalue: ''
            },
            formControlProps: {
              fullWidth: true
            },
            size: 6,
            isAsyncDropDown : true
          },
          productBrandId: {
            elType: 'select',
            label: 'Product Brand',
            inputProps: {
              options: [],
              value: '',
              initvalue: ''
            },
            formControlProps: {
              fullWidth: true
            },
            size: 6,
            isAsyncDropDown : true
          },
          productModelId: {
            elType: 'select',
            label: 'Product Model',
            inputProps: {
              options: [],
              value: '',
              initvalue: ''
            },
            formControlProps: {
              fullWidth: true
            },
            size: 6,
            isAsyncDropDown : true
          },
          status: {
            elType: 'select',
            label: 'Status',
            inputProps: {
              options: statusArray,
              value: 'ACTIVE',
              initvalue: 'ACTIVE'
            },
            formControlProps: {
              fullWidth: true
            },
            size: 6,
          },
          category: {
            elType: 'select',
            label: customerProperties.category3,
            inputProps: {
              options: BusinessType,
              placeholder: customerProperties.category3,
              value: '',
              initvalue: ' '
            },
            formControlProps: {
              fullWidth: true
            },
            size: 6,
            isAsyncDropDown : true
          },
        }   

}



export default checklist;