import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone'
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from '../Noty/NotyCustom';
import readXlsxFile from 'read-excel-file'
import { Typography } from '@material-ui/core';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import * as moment from 'moment';
import writeXlsxFile from 'write-excel-file'
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from "material-table";
import Lottie from 'react-lottie';
import animationData from '../../assets/onsiteAnimation.json';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function ReportView4(props) {

    const { open, fileUploadClose, getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject, url , uploadLoacation, acceptFileType,type} = props;
    const [file, setFile] = React.useState([]);

    const [error, setError] = React.useState("");
    const [hasError, setHasError] = React.useState(false);
    const [dataArray, setDataArray] = React.useState([]);
    const [openSecondPreview, setOpenSecondPreview] = useState(false);
    const [newFile, setNewFile] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
   // const [handleClose, setHandleClose] = useState(false);
    

    const baseUrl = UrlProperties.baseUrl.split(":8089")[0];
    
    const handleClose = () => {
        // setFile([]);
         fileUploadClose();
    }

    useEffect(() => {

    }, []);
   
    useEffect(() => {
   
    }, [file]);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '140px',
        width: '400px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#B22900',
        borderStyle: 'dashed',
        backgroundColor: '#ffcec7',
        color: '#f73123',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const style = useMemo(() => ({
        ...baseStyle,
    }), [

    ]);

    return (
        <div>
            <Dialog
            maxWidth="xl"
            fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'>
                    <DialogContent>
                    <b><center>
                    From:2022-06-11   To:2022-07-02
                        </center></b>
                    {
                      type =="Average_field_Cost"? 
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Average field Cost</Typography>
                            </div>
                          }
                          columns={[
                            { title: "#", width:'9%', field: "num" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "SR No", field: "srno" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Priority", field: "priority" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Customer/Address",width:'15%', field: "customer" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Province", field: "province", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "District", field: "district", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Product", field: "product", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Mode of transport", field: "modeoftransport", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Travelled (KM)", field: "travelled", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of Travelling", field: "costoftravelling", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of BA/TA", field: "ba", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of Meal", field: "meal", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of Allowances", field: "allowances", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Fixed Cost", field: "fixedcost", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            

                          ]}
                          data={[
                            { num: '1', srno: '001', priority: 'High', customer: 'HNB-Dehiwala', province:'Western', district:'Colombo', product: 'Finger Scanner', modeoftransport: 'Bike',costoftravelling:625, travelled: 25 , ba: 50.00, meal: 83.33, allowances:66.77, fixedcost:333.33}, 
                            { num: '2', srno: '002', priority: 'Low', customer: 'COM Bank-Kottawa',province:'Western', district:'Colombo',  product: 'Note Counter', modeoftransport: 'Bike', travelled: 5 ,costoftravelling: 125, ba: 50.00, meal: 83.33, allowances:66.77, fixedcost:333.33},
                            { num: '3', srno: '003', priority: 'Mid', customer: 'SMIB-Gampaha',province:'Western', district:'Gampaha',  product: 'Photocopier', modeoftransport: 'Bike', travelled: 10 , costoftravelling:250, ba: 50.00, meal: 83.33, allowances:66.77, fixedcost:333.33}
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                           // setOpenFileUpload4(true)
                          }}
                        /></div></GridItem></GridContainer>
                    
                    : null

                    }
                    </DialogContent>
                    <DialogActions>
                        {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

                        <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        </div>
    );
}
