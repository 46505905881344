import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import styles from './ClaimsSetup.module.css';
import './ClaimSetup.css';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from '../../../components/Noty/NotyCustom';
import generalProperties from 'Util/Property/GeneralProperties';
import ClaimsSettingsProperty, {
  formInitialData,
  ruleTypes,
} from 'Util/Property/ClaimsSettingsProperty';
import './ClaimsSetup.module.css';
import MaterialTable from 'material-table';
import { AccountBalanceRounded } from '@material-ui/icons';
import {
  Checkbox,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from '../../../components/TabPanels/FullWidthTabPanel';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { currencyFormatter } from '../../../Util/Util';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PermissionProperties from '../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../Util/Permission/CheckPermission';
import WorkflowRoute from 'views/WorkflowRoute/WorkflowRoute';
import Spinner from 'components/Spinner/Spinner';
import SendIcon from 'assets/img/icons/Send.svg';

class ClaimSetup extends Component {
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    this.commentRef = React.createRef();
  }
  state = {
    claimSetupForm: { ...ClaimsSettingsProperty.claimSetupForm },
    id: null,
    formIsValid: false,
    isLoading: true,
    data: [{ distanceFrom: '', distanceTo: '', allowance: '', count: 1 }],
    lateNightAllowance: '',
    tabValue: 0,
    claimsReportData: {},
    permissions: {},
    isWfRouteDialogOpen: false,
    isReportOpen: false,
    claimTableData: [],
  };

  checkValidity(value, rules) {
    let returnObj = {
      isValid: true,
      errormessage: '',
    };
    if (typeof rules == 'undefined') {
      return returnObj;
    }

    if (rules.required) {
      if (!value) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.emptyField;
      }
    }
    return returnObj;
  }

  saveDetails = (event, routeData) => {
    const validData = this.validateData();
    if (validData) {
      const { claimsReportData, claimSetupForm } = this.state;
      const { updateClaimReport } = this.props;
      if (
        typeof routeData !== 'undefined' &&
        typeof routeData.currentStage !== 'undefined'
      ) {
        for (let key in routeData) {
          claimsReportData[key] = routeData[key];
        }
      }
      updateClaimReport(claimsReportData);
    }
  };

  componentDidMount() {
    const { getUsers } = this.props;
    getUsers();
    let permissionArray = [
      PermissionProperties.addClaims,
      PermissionProperties.viewClaims,
      PermissionProperties.deleteClaims,
      PermissionProperties.editClaims,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      userList,
      claimsReportData,
      claimsReportDataLoading,
      claimUpdateSuccess,
      match,
      claimUpdateInProgress,
      usersLoading,
    } = this.props;
    const { params } = match;
    const { claimSetupForm, permissions } = this.state;
    const { id } = params;
    if (
      usersLoading !== prevProps.usersLoading &&
      userList.length > 0 &&
      !usersLoading
    ) {
      this.setState(
        {
          claimSetupForm: {
            ...prevState.claimSetupForm,
            employee_id: {
              ...prevState.claimSetupForm.employee_id,
              inputProps: {
                ...prevState.claimSetupForm.employee_id.inputProps,
                options: this.buildUserOptions(),
              },
            },
          },
        },
        () => {
          if (id) {
            this.props.geClaimReportById(id);
            this.props.getRouteOptionsByClaimId(id);
          }
        }
      );
    }

    if (
      claimsReportDataLoading !== prevProps.claimsReportDataLoading &&
      !claimsReportDataLoading
    ) {
      let claimSetupFormClone = { ...claimSetupForm };
      const isClaimAddPermAvailable =
        permissions[PermissionProperties.addClaims];
      if (
        !isClaimAddPermAvailable &&
        claimsReportData.employee_id !== localStorage.getItem('userId')
      ) {
        notyDefault({
          type: notyTypes.ERROR,
          text: "You don't have access to this information",
        });
        return;
      }
      Object.keys(claimSetupForm).forEach((claimInputItem) => {
        if (claimInputItem === 'assign_user_id') {
          claimSetupFormClone[claimInputItem].inputProps.value =
            claimsReportData[claimInputItem];
          claimSetupFormClone[
            claimInputItem
          ].inputProps.options = this.buildAssingUserOption(
            claimsReportData.assign_user_id,
            claimsReportData.assignUserName
          );
        } else {
          claimSetupFormClone[claimInputItem].inputProps.value =
            claimsReportData[claimInputItem];
        }
      });
      const cloneClaimData = Object.assign({}, claimsReportData);
      this.setState({
        claimSetupForm: claimSetupFormClone,
        claimsReportData: cloneClaimData,
      });
    }
    if (
      !claimUpdateInProgress &&
      claimUpdateInProgress !== prevProps.claimUpdateInProgress
    ) {
      if (claimUpdateSuccess) {
        let claimSetupFormClone = { ...claimSetupForm };
        Object.keys(claimSetupForm).forEach((claimInputItem) => {
          claimSetupFormClone[claimInputItem].inputProps.value =
            claimsReportData[claimInputItem];
        });
        const cloneClaimData = Object.assign({}, claimsReportData);
        this.setState({
          claimSetupForm: claimSetupFormClone,
          claimsReportData: cloneClaimData,
        });
        notyDefault({
          type: notyTypes.SUCCESS,
          text: ClaimsSettingsProperty.messages.success.updateClaimReport,
        });
      } else {
        notyDefault({
          type: notyTypes.ERROR,
          text: ClaimsSettingsProperty.messages.error.updateClaimReport,
        });
      }
    }
  }
  buildUserOptions = () => {
    const { userList } = this.props;
    if (userList.length > 0) {
      return userList.map((user) => {
        return {
          value: user.id,
          displayValue: user.first_name + ' ' + user.last_name,
        };
      });
    }
    return [];
  };

  buildAssingUserOption = (userId, userName) => {
    let userOpt = [];
    userOpt.push({ value: userId, displayValue: userName });
    return userOpt;
  };
  handleTabChange = (event, newVal) => {
    this.setState({
      tabValue: newVal,
    });
  };

  routeHandler = () => {
    this.setState({
      isWfRouteDialogOpen: true,
    });
  };

  routeSubmitHandler = (data) => {
    this.saveDetails('', data);
    this.setState({
      isWfRouteDialogOpen: false,
    });
  };

  routeCloseHandler = () => {
    this.setState({
      isWfRouteDialogOpen: false,
    });
  };

  onChangeSummaryTableInput = (e, summaryId) => {
    const { claimsReportData } = this.state;
    const claimsReportDataClone = { ...claimsReportData };
    const { claimsReportData: initialClaimsReportData } = this.props;
    const { name, value, checked } = e.target;
    const { claimSummaries = [] } = claimsReportDataClone;
    const {
      claimSummaries: initialClaimSummaries = [],
    } = initialClaimsReportData;
    let summaryClone = [...claimSummaries];
    const summaryObjIndex = _.findIndex(
      summaryClone,
      ({ id }) => id === summaryId
    );
    if (summaryObjIndex !== -1 && name) {
      const summaryData = { ...summaryClone[summaryObjIndex] };
      // if (name === 'adjustment') {
      //   const summary = initialClaimSummaries[summaryObjIndex];
      //   let summaryTotal = value ? parseFloat(value) : 0;
      //   summaryData[name] = summaryTotal;
      //   summaryData['total'] = summaryTotal + summary.total;
      // } else {
      //   summaryData[name] = checked;
      // }
      const summary = initialClaimSummaries[summaryObjIndex];
      let summaryTotal = value ? parseFloat(value) : 0;
      summaryData[name] = summaryTotal;
      summaryData['total'] = summaryTotal + summary.total;
      summaryClone[summaryObjIndex] = summaryData;

      claimsReportDataClone.claimSummaries = summaryClone;
    }
    this.setState((prevState) => ({
      claimsReportData: claimsReportDataClone,
    }));
  };

  onChangeTableInput = (rowData) => {
    const { claimsReportData } = this.state;

    let claimsReportDataClone = JSON.parse(JSON.stringify(claimsReportData));

    claimsReportDataClone.claim_sr_details
      .find((srDetails) => srDetails.id === rowData.srDetailsId)
      .claimAllowances.find(
        (claimAllowances) => claimAllowances.id === rowData.claimAllowancesId
      ).allocated_amount = rowData.allocated_amount * 1;

    let claimSrTotal = 0;

    claimsReportDataClone.claim_sr_details
      .find((srDetails) => srDetails.id === rowData.srDetailsId)
      .claimAllowances.map((el) => {
        claimSrTotal = claimSrTotal + el['allocated_amount'] * 1;
      });

    claimsReportDataClone.claim_sr_details.find(
      (srDetails) => srDetails.id === rowData.srDetailsId
    ).total = claimSrTotal;

    this.setState((prevState) => {
      return { claimsReportData: claimsReportDataClone };
    });

    this.props.updateClaimReportById(claimsReportDataClone);
  };

  onChangeCommentInput = (e) => {
    const { claimsReportData } = this.state;
    let claimsReportDataClone = JSON.parse(JSON.stringify(claimsReportData));

    const { name, value } = e.target;
    if (name) {
      claimsReportDataClone[name] = value;
      this.setState((prevState) => ({
        claimsReportData: claimsReportDataClone,
      }));
    }
  };

  onMessageSend = () => {
    const { claimsReportData } = this.state;

    this.props.sendClaimReport(
      claimsReportData.employee_id,
      claimsReportData.uuid,
      claimsReportData.name
    );
  };

  sendComment = () => {
    if (this.commentRef.current.value.length > 0) {
      const claimsReportDataClone = JSON.parse(
        JSON.stringify(this.state.claimsReportData)
      );

      claimsReportDataClone.new_comment = this.commentRef.current.value;

      this.props.updateClaimReportById(claimsReportDataClone);
      this.commentRef.current.value = '';
    }
  };

  previewReportHandler = async () => {
    const { claimsReportData } = this.state;
    await this.props.generateClaimReport(claimsReportData.uuid);
  };

  getClaimTableContent = () => {
    const {
      claimsReportDataLoading,
      usersLoading,
      claimUpdateInProgress,
      claimReportGenerateInProgress,
    } = this.props;
    const { claimsReportData, permissions } = this.state;

    let claimsReportDataClone = JSON.parse(JSON.stringify(claimsReportData));

    let claimTableDataClone = [];
    let requestedTotal = 0;
    let allocatedTotal = 0;

    if (!_.isEmpty(claimsReportDataClone)) {
      claimsReportDataClone['claim_sr_details'].map((srDetails) => {
        srDetails.claimAllowances.map((claimAllowances) => {
          requestedTotal =
            requestedTotal + claimAllowances.requested_amount === '-'
              ? 0
              : claimAllowances.requested_amount;
          allocatedTotal = allocatedTotal + claimAllowances.allocated_amount;
          claimTableDataClone.push({
            srDetailsId: srDetails.id,
            claimAllowancesId: claimAllowances.id,
            date: srDetails.date,
            sr_id: srDetails.sr_id,
            sr_category: srDetails.sr_category,
            customer_name: srDetails.customer_name,
            product_category: srDetails.product_category,

            start_meter: claimAllowances.start_odometer,
            end_meter: claimAllowances.end_odometer,
            distance: claimAllowances.distance,
            expense_category: claimAllowances.expense_category,
            ref_no: srDetails.tr_number,
            requested_amount: claimAllowances.requested_amount,
            allocated_amount: claimAllowances.allocated_amount,
          });
        });
      });
      claimTableDataClone.push({
        expense_category: 'Total',
        requested_amount: requestedTotal,
        allocated_amount: allocatedTotal,
      });
    }

    return (
      <div className={'material-grid-wrapper'} style={{ width: '100%' }}>
        <MaterialTable
          title={''}
          tableRef={this.tableRef}
          isLoading={
            claimsReportDataLoading ||
            usersLoading ||
            claimUpdateInProgress ||
            claimReportGenerateInProgress
          }
          components={{
            OverlayLoading: () => (
              <div className={styles.loadingOverlay}>
                <Spinner />
              </div>
            ),
          }}
          columns={[
            {
              title: 'Date',
              field: 'date',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.date
                  ? moment(data.date.join(',')).format('MM/DD/yyyy')
                  : '',
            },
            {
              title: 'SR ID',
              field: 'sr_id',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.sr_id == null
                  ? '-'
                  : data.sr_id,
            },
            {
              title: 'SR Category',
              field: 'sr_category',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.sr_category == null
                  ? '-'
                  : data.sr_category,
            },
            {
              title: 'Customer Name',
              field: 'customer_name',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.customer_name == null
                  ? '-'
                  : data.customer_name,
            },
            {
              title: 'Product Category',
              field: 'product_category',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.product_category == null
                  ? '-'
                  : data.product_category,
            },
            {
              title: 'Start Meter',
              field: 'start_meter',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.start_meter == null
                  ? '-'
                  : data.start_meter,
            },
            {
              title: 'End Meter',
              field: 'end_meter',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.end_meter == null
                  ? '-'
                  : data.end_meter,
            },
            // { title: 'Arrival', field: 'start_location' },
            // { title: 'Departure', field: 'end_location' },
            {
              title: 'Distance',
              field: 'distance',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined
                  ? data.distance == null
                    ? '-'
                    : `${data.distance}Km`
                  : '',
            },
            {
              title: 'Expense Category',
              field: 'expense_category',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.expense_category == null
                  ? '-'
                  : data.expense_category,
            },
            {
              title: 'Ref No/ TR No',
              field: 'ref_no',
              editable: 'never',
              cellStyle: {
                fontSize: '12px',
                fontWeight: '450',
                color: '#555',
              },
              render: (data) =>
                data.sr_id !== undefined && data.ref_no == null
                  ? '-'
                  : data.ref_no,
            },
            {
              title: 'Requested Amount',
              field: 'requested_amount',
              editable: 'never',

              render: (data) =>
                data.sr_id !== undefined && data.requested_amount == null
                  ? '-'
                  : currencyFormatter(data.requested_amount),
              cellStyle: {
                textAlign: 'right',
                fontSize: '12px',
                color: '#555',
                fontWeight: '450',
              },
            },
            {
              title: 'Allocated Amount',
              field: 'allocated_amount',
              editComponent: (props, index) => (
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  value={props.value}
                  // value={currencyFormatter(data.morning_tea)}
                  name="allocated_amount"
                  InputProps={{
                    className: styles.adjustmentInput,
                    // startAdornment: (
                    //   <InputAdornment
                    //     position="start"
                    //     className={styles.adornment}
                    //   >
                    //     <span>LKR</span>
                    //   </InputAdornment>
                    // ),
                  }}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    // this.onChangeTableInput(e, props.rowData);
                  }}
                />
              ),
              render: (data) =>
                data.sr_id !== undefined && data.allocated_amount == null
                  ? '-'
                  : currencyFormatter(data.allocated_amount),
              cellStyle: {
                textAlign: 'right',
                fontSize: '12px',
                color: '#555',
                fontWeight: '450',
              },
            },
          ]}
          data={claimTableDataClone}
          // data={machineData}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                // setTimeout(() => {
                //   resolve();
                // }, 1000);
                this.onChangeTableInput(newData);
                resolve();
              }),
            isEditHidden: (rowData) =>
              !permissions[PermissionProperties.editClaims] ||
              rowData.sr_id === undefined,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
            sorting: false,
            paging: false,
            filtering: true,
            tableLayout: 'auto',
            maxBodyHeight: '70vh',
            headerStyle: {
              fontSize: '12px',
              textAlignL: 'center',
              backgroundColor: '#e6e4df',
            },
            rowStyle: (rowData, index) => ({
              fontSize: '12px',
              color: rowData.sr_id === undefined ? '#000' : '#555',
              // padding: '5rem',
              backgroundColor: rowData.sr_id === undefined ? '#E0FAE1' : '',
              fontWeight: rowData.sr_id === undefined ? '500' : ' 450',
            }),
          }}
          // onRowClick={(event, rowData, toggleDetailPanel) => {}}
          onRowClick={null}
        />
      </div>
    );
  };

  fileUploadClose = () => {
    this.setState({
      isReportOpen: false,
    });
  };

  render() {
    const { match, claimsReportData = {} } = this.props;
    const { comments = '' } = claimsReportData;
    const {
      claimSetupForm = {},
      id,
      tabValue,
      claimsReportData: stateClaimData,
      permissions = {},
    } = this.state;

    const { new_comment = '' } = stateClaimData;
    const a11yProps = (index) => {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    };

    const { params } = match;
    const { type = '' } = params || {};
    let formElementArray = [];
    for (let key in this.state.claimSetupForm) {
      formElementArray.push({
        id: key,
        config: claimSetupForm[key],
      });
    }
    let commentStr = '';
    if (comments) {
      let commentsArr = comments.split(ClaimsSettingsProperty.COMMENT_END);
      for (let key in commentsArr) {
        commentStr += commentsArr[key] + '\n';
      }
    }
    let submitButton = (
      <Button
        disabled={!this.state.formIsValid}
        onClick={this.saveDetails}
        type="submit"
        color="primary"
      >
        Save
      </Button>
    );
    if (type === ClaimsSettingsProperty.actionType.view) {
      submitButton = null;
    }

    return (
      <div className="claims-setup-container">
        <LoadingOverlay spinner text="Loading ...">
          <WorkflowRoute
            isWfRouteDialogOpen={this.state.isWfRouteDialogOpen}
            routeOptions={this.props.routeOptions}
            routeSubmitHandler={this.routeSubmitHandler}
            routeCloseHandler={this.routeCloseHandler}
          />

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ minHeight: '400px' }}>
                <CardBody style={{ padding: '40px' }}>
                  <div className={'material-grid-wrapper '}>
                    <GridContainer>
                      <Grid spacing={3} md={6}>
                        <span className={'grid-title-icon'}>
                          <AccountBalanceRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Claim Details
                        </Typography>
                        <Typography variant="body1">
                          {id
                            ? 'Update your claims'
                            : 'Please create your claims.'}
                        </Typography>
                      </Grid>
                      <Grid spacing={3} md={6}>
                        {/*<Button*/}
                        {/*  onClick={() => {*/}
                        {/*    this.printRef.current.click();*/}
                        {/*  }}*/}
                        {/*  variant="contained"*/}
                        {/*  color="primary"*/}
                        {/*  style={{ float: "right" }}*/}
                        {/*>*/}
                        {/*  Print Claim*/}
                        {/*</Button>*/}
                        <Button
                          class="generic-button-class div-button-wrapper"
                          variant="contained"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                          color="primary"
                          style={{ float: 'right' }}
                        >
                          Go Back
                        </Button>
                        {permissions[PermissionProperties.editClaims] && (
                          <Button
                            class="generic-button-class div-button-wrapper"
                            onClick={this.routeHandler}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right' }}
                          >
                            Route
                          </Button>
                        )}

                        {!this.props.claimsReportDataLoading &&
                          !this.props.usersLoading &&
                          !this.props.claimUpdateInProgress && (
                            <Button
                              class="generic-button-class div-button-wrapper"
                              variant="contained"
                              onClick={
                                !this.props.claimReportGenerateInProgress
                                  ? this.previewReportHandler
                                  : null
                              }
                              color="primary"
                              style={{ float: 'right', width: '130px' }}
                            >
                              {this.props.claimReportGenerateInProgress ? (
                                <CircularProgress
                                  style={{ color: 'white' }}
                                  size={10}
                                />
                              ) : (
                                'Preview Claim'
                              )}
                            </Button>
                          )}
                        {!this.props.claimsReportDataLoading &&
                          !this.props.usersLoading &&
                          !this.props.claimUpdateInProgress && (
                            <Button
                              class="generic-button-class div-button-wrapper"
                              variant="contained"
                              onClick={
                                !this.props.claimReportSendInProgress
                                  ? this.onMessageSend
                                  : null
                              }
                              color="primary"
                              style={{ float: 'right', width: '100px' }}
                            >
                              {this.props.claimReportSendInProgress ? (
                                <CircularProgress
                                  style={{ color: 'white' }}
                                  size={10}
                                />
                              ) : (
                                'Send Mail'
                              )}
                            </Button>
                          )}
                      </Grid>
                    </GridContainer>
                  </div>
                  <div style={{ marginTop: '30px' }}>
                    <GridContainer>
                      {formElementArray.map((element) => {
                        if (!element.config.isHide) {
                          let mdVal = 12;
                          if (!element.config.isFullWidth) {
                            mdVal = 6;
                          }
                          if (element.config.elType === 'date') {
                            const dateStr = element.config.inputProps.value
                              .toString()
                              .split(',')
                              .join('-');
                            moment(new Date(date)).format('MM-DD-YYYY');

                            const date = moment(new Date(dateStr)).format(
                              'MM-DD-YYYY'
                            );

                            element.config.inputProps.value = date;
                          }
                          return (
                            <GridItem key={element.id} md={2}>
                              <CustomInput
                                labelText={element.config.label}
                                id={element.id}
                                formControlProps={
                                  element.config.formControlProps
                                }
                                inputProps={{
                                  ...element.config.inputProps,
                                  className: styles.width250,
                                }}
                                type={element.config.elType}
                                value={element.config.value}
                                changed={(event) =>
                                  this.onChangeHandler(event, element.id)
                                }
                                error={
                                  !element.config.valid &&
                                  element.config.touched
                                }
                                labelProps={element.config.labelProps}
                                gridStyle={element.gridStyle}
                              />
                            </GridItem>
                          );
                        }
                      })}
                    </GridContainer>
                  </div>

                  {/* <div style={{ marginTop: '30px' }}>
                    <GridContainer style={{ marginTop: '30px !important' }}>
                      <AppBar
                        position="static"
                        color="inherit"
                        className={styles.appBarStyle}
                      >
                        <Tabs
                          value={tabValue}
                          onChange={this.handleTabChange}
                          inkBarStyle={{ background: '#F15642' }}
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                          style={{
                            backgroundColor: '#FED3CD',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                          }}
                        >
                          <Tab
                            label={ClaimsSettingsProperty.travelAllowances}
                            style={tabValue === 0 ? { color: '#F15642' } : {}}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={ClaimsSettingsProperty.machineAllowances}
                            style={tabValue === 1 ? { color: '#F15642' } : {}}
                            {...a11yProps(1)}
                          />
                          <Tab
                            label={ClaimsSettingsProperty.summary}
                            style={tabValue === 2 ? { color: '#F15642' } : {}}
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </AppBar>
                      <FullWidthTabPanel
                        value={tabValue}
                        index={0}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {this.getTravelAllowanceTabContent()}
                      </FullWidthTabPanel>
                      <FullWidthTabPanel
                        value={tabValue}
                        index={1}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {this.getMachineAllowanceTabContent()}
                      </FullWidthTabPanel>
                      <FullWidthTabPanel
                        value={tabValue}
                        index={2}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {this.getSummaryTabContent()}
                      </FullWidthTabPanel>
                    </GridContainer>
                  </div> */}
                  <div style={{ marginTop: '30px' }}>
                    {this.getClaimTableContent()}
                  </div>

                  {!_.isEmpty(claimsReportData) && (
                    <>
                      <div
                        style={{
                          marginTop: '40px',
                          marginBottom: '10px',
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color: '#555',
                        }}
                      >
                        Comments
                      </div>
                      <div
                        style={{
                          marginBottom: '10px',
                        }}
                      >
                        <TextareaAutosize
                          placeholder="Comments"
                          fullWidth
                          style={{
                            marginTop: '10px',
                            lineHeight: '1.4',
                            width: '100%',
                            marginLeft: '0',
                          }}
                          rowsMin={3}
                          value={commentStr}
                          className="claim-report-comment-box"
                        />
                      </div>

                      <TextField
                        id="standard-full-width"
                        label="Supervisor/Manager Comment"
                        placeholder="Type your comment here"
                        inputRef={this.commentRef}
                        fullWidth
                        margin="normal"
                        className="claim-report-comment-box"
                        name="new_comment"
                        // onChange={this.onChangeCommentInput}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={
                                  this.props.claimUpdateInProgress
                                    ? null
                                    : () => {
                                        this.sendComment();
                                      }
                                }
                              >
                                <img
                                  src={SendIcon}
                                  style={{ width: '20px', height: '20px' }}
                                  alt="test"
                                />
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          {/* <div hidden>
            <ReactToPrint
              trigger={() => (
                <button ref={this.printRef} id="print-btn">
                  Print this out!
                </button>
              )}
              content={() => this.componentRef}
            />
            <ClaimsToPrint
              ref={(el) => (this.componentRef = el)}
              a11yProps={a11yProps()}
              tabValue={tabValue}
              formElementArray={formElementArray}
              getSummaryTabContent={this.getSummaryTabContent}
              getTravelAllowanceTabContent={this.getTravelAllowanceTabContent}
              getMachineAllowanceTabContent={this.getMachineAllowanceTabContent}
            />
          </div> */}
        </LoadingOverlay>
      </div>
    );
  }

  validateData = () => {
    const { claimSetupForm } = this.state;
    let isValid = true;
    _.forEach(Object.values(claimSetupForm), ({ inputProps }) => {
      if (!inputProps.value) {
        isValid = false;
      }
    });

    if (!isValid) {
      notyDefault({
        type: notyTypes.ERROR,
        text: ClaimsSettingsProperty.messages.error.invalidSetupData,
      });
      return false;
    }
    return true;
  };
}

const mapStateToProps = (state) => {
  const { claimsManagement } = state;
  const {
    claimsReportData,
    users,
    claimsReportUpdate,
    claimReportGenerate,
    claimReportSend,
    routeOptionsData,
  } = claimsManagement;
  return {
    claimsReportData: claimsReportData.data,
    claimsReportDataLoading: claimsReportData.isFetching,
    claimsReportDataError: claimsReportData.error,
    userList: users.data,
    usersLoading: users.isFetching,
    claimUpdateSuccess: claimsReportUpdate.success,
    claimUpdateError: claimsReportUpdate.error,
    claimUpdateInProgress: claimsReportUpdate.isLoading,
    claimReportGenerateSuccess: claimReportGenerate.success,
    claimReportGenerateError: claimReportGenerate.error,
    claimReportGenerateInProgress: claimReportGenerate.isLoading,
    claimReportSendSuccess: claimReportSend.success,
    claimReportSendError: claimReportSend.error,
    claimReportSendInProgress: claimReportSend.isLoading,
    routeOptions: routeOptionsData.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geClaimReportById: (id) => dispatch(actions.geClaimReportById(id)),
    updateClaimReportById: (data) =>
      dispatch(actions.updateClaimReportById(data)),
    generateClaimReport: (uuid) => dispatch(actions.generateClaimReport(uuid)),
    sendClaimReport: (uid, uuid, reportName) =>
      dispatch(actions.sendClaimMessage(uid, uuid, reportName)),
    updateClaimReport: (data) => dispatch(actions.updateClaimReport(data)),
    getUsers: () => dispatch(actions.getUsers()),
    getMealTypes: () => dispatch(actions.getMealTypes()),
    getProductCategories: () => dispatch(actions.getProductCategories()),
    getServiceRequestTypes: () => dispatch(actions.getServiceRequestTypes()),
    updateExpenseRule: (data, id) =>
      dispatch(actions.updateExpenseRule(data, id)),
    getRouteOptionsByClaimId: (id) =>
      dispatch(actions.getRouteOptionsByClaimId(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ClaimSetup));

class ClaimsToPrint extends React.Component {
  render() {
    const {
      formElementArray,
      tabValue,
      getTravelAllowanceTabContent,
      getMachineAllowanceTabContent,
      getSummaryTabContent,
    } = this.props;
    const a11yProps = (index) => {
      return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
      };
    };
    return (
      <div className="claims-setup-container">
        <LoadingOverlay spinner text="Loading ...">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ minHeight: '400px' }}>
                <CardBody style={{ padding: '40px' }}>
                  <div style={{ marginTop: '30px' }}>
                    <GridContainer>
                      {formElementArray.map((element) => {
                        if (!element.config.isHide) {
                          let mdVal = 12;
                          if (!element.config.isFullWidth) {
                            mdVal = 6;
                          }
                          return (
                            <GridItem key={element.id} md={2}>
                              <CustomInput
                                labelText={element.config.label}
                                id={element.id}
                                formControlProps={
                                  element.config.formControlProps
                                }
                                inputProps={{
                                  ...element.config.inputProps,
                                  readOnly: element.id === 'id',
                                  className: styles.width250,
                                }}
                                type={element.config.elType}
                                value={element.config.value}
                                changed={(event) =>
                                  this.onChangeHandler(event, element.id)
                                }
                                error={
                                  !element.config.valid &&
                                  element.config.touched
                                }
                                labelProps={element.config.labelProps}
                                gridStyle={element.gridStyle}
                              />
                            </GridItem>
                          );
                        }
                      })}
                    </GridContainer>
                  </div>

                  <div style={{ marginTop: '30px' }}>
                    <GridContainer style={{ marginTop: '30px !important' }}>
                      {/* <AppBar
                        position="static"
                        color="inherit"
                        className={styles.appBarStyle}
                      >
                        <Tabs
                          value={tabValue}
                          onChange={this.handleTabChange}
                          inkBarStyle={{ background: '#F15642' }}
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                          style={{
                            backgroundColor: '#F5EDED',
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                          }}
                        >
                          <Tab
                            label={ClaimsSettingsProperty.travelAllowances}
                            style={tabValue === 0 ? { color: '#F15642' } : {}}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={ClaimsSettingsProperty.machineAllowances}
                            style={tabValue === 1 ? { color: '#F15642' } : {}}
                            {...a11yProps(1)}
                          />
                          <Tab
                            label={ClaimsSettingsProperty.summary}
                            style={tabValue === 2 ? { color: '#F15642' } : {}}
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </AppBar> */}
                      {/* <FullWidthTabPanel
                        value={tabValue}
                        index={0}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {getTravelAllowanceTabContent()}
                      </FullWidthTabPanel>
                      <FullWidthTabPanel
                        value={tabValue}
                        index={1}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {getMachineAllowanceTabContent()}
                      </FullWidthTabPanel>
                      <FullWidthTabPanel
                        value={tabValue}
                        index={2}
                        dir={'ltr'}
                        style={{ width: '100%' }}
                      >
                        {getSummaryTabContent()}
                      </FullWidthTabPanel> */}
                    </GridContainer>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}
