import React from 'react';
import { connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import RouterProps from '../../Util/Property/RouterProperties';
import {isWareHouseUrl} from "../../Util/Util";
/**
 * 
 * @param {
 * 
 * } Component 
 * @author chamil
 * @description This HOC component use to check token availability before visiting a rout
 */

const routerGuard = (Component) =>{

        

        const checkCompoent =  (props) => { 
                const getToken = localStorage.getItem('token'); 
                if(!getToken) {
                   props.logOutHandler(); // to clear the state
                    if(isWareHouseUrl(window.location)){
                        props.history.replace({pathname: RouterProps.mainDomain+'/warehouse/login'});
                    } else {
                        props.history.replace({pathname: RouterProps.mainDomain+'/login'});
                    }
                }
                props.checkAuthStateForLogin(props);
                return <Component {...props} /> 
        } 

         const mapDispathToProps = dispatch => {
                 return {
                     logOutHandler : ()=> dispatch(actions.logOut()),
                     checkAuthStateForLogin :(props)  => dispatch(actions.checkAuthState(props))
                 }
         }
            
         return connect(null, mapDispathToProps)(checkCompoent);

} 

export default routerGuard;