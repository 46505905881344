import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { priorityConfigurationProperties } from 'Util/Property/PriorityConfigurationProperties'

export async function getAllPriorityConfigurations() {
    try {

        return axios.get(urlProperties.priorityConfiguration.getPriorityConfigurations)
        // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    } catch (error) {
        console.log(priorityConfigurationProperties.messages.error.loadAll);
    }

}

/**
 * service request save and update 
 */

export async function savePriorityConfiguration(method, url, data) {
    try {

        return await axios({
            method: method,
            url: url,
            data: data
        });
        // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    } catch (error) {
        console.log(priorityConfigurationProperties.messages.error.saveData);
    }

}


export async function getPriorityConfiguration(id) {
    try {

        return axios.get(urlProperties.priorityConfiguration.getPriorityConfiguration + "/" + id)
        // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    } catch (error) {
        console.log(priorityConfigurationProperties.messages.error.loadData);
    }

}


export async function deletePriorityConfiguration(id) {
    try {

        return axios.delete(urlProperties.priorityConfiguration.deletePriorityConfiguration + "/" + id)
        // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    } catch (error) {
        console.log(priorityConfigurationProperties.messages.error.delete);
    }

}


export async function getBestMatchPriorityConfiguration(customerId, categoryId, brandId, modelId, requestTypeId) {
    try {

        return axios.get(urlProperties.priorityConfiguration.getBestMatchPriorityConfiguration + "?customerId=" + customerId + 
        "&categoryId=" + categoryId+"&brandId="+brandId+"&modelId="+modelId+"&requestTypeId="+requestTypeId);
        // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
    } catch (error) {
        console.log(priorityConfigurationProperties.messages.error.loadData);
    }

}