import moment from 'moment';
export const ruleTypes = [
  { value: 'DISTANCE', displayValue: 'Distance Based' },
  { value: 'JOB_COUNT', displayValue: 'Job Count Based' },
  { value: 'MEAL_ALLOWANCE', displayValue: 'Meal Allowance' },
  { value: 'LATE_NIGHT', displayValue: 'Time Based' },
];
export const formInitialData = {
  'Distance Based': [{ distanceFrom: '', distanceTo: '', allowance: '' }],
  'Job Count Based': [
    {
      productCategoryId: '',
      serviceRequestCategoryId: '',
      allowance: '',
      count: 1,
    },
  ],
  'Meal Allowance': [{ fromKm: '', allowance: '' }],
  'Time Based': [{ fromKm: '', allowance: '' }],
};
export const claimStatus = [
  { value: 'DRAFT', displayValue: 'Draft' },
  { value: 'ASSIGNED', displayValue: 'Assigned' },
  { value: 'DISPUTE', displayValue: 'Dispute' },
  { value: 'AGREED', displayValue: 'Agreed' },
  { value: 'APPROVED', displayValue: 'Approved' },
];
const claimsSettingsProperties = {
  messages: {
    error: {
      loadData: 'Could not load expense category data please try again!',
      saveData: 'Could not save expense category data please try again!',
      updateClaimReport: 'Could not update claim report data please try again!',
      createClaimReport: 'Could not create claim report data please try again!',
      invalidClaimReport: 'Please fill out the missing fields!',
      invalidClaimReportDates:
        'Invalid date range selected for start & end dates!',
      deleteExpenseCategory:
        'Could not deactivate expense category please try again!',
      loadExpenseCategory: 'Could not load expense category please try again!',
      invalidRulesData: 'Data entered for rules are invalid!',
      invalidSetupData: 'Invalid Data!',
      deleteExpenseRule: 'Could not deactivate expense rule please try again!',
    },
    success: {
      saveData: 'Expense category details saved successfully!',
      saveExpenseRule: 'Expense rule saved successfully!',
      deleteExpenseCategory: 'Expense category deactivated successfully!',
      deleteExpenseRule: 'Expense Rule deactivated successfully!',
      updateClaimReport: 'Claim report updated successfully!',
    },
    normal: {
      deleteExpenseCategory: 'Do you want to deactivate this expense category?',
      deleteExpenseRule: 'Do you want to deactivate this expense rule?',
      generateClaim: 'Generate Claim',
    },
  },
  travelAllowances: 'Travel Allowances',
  machineAllowances: 'Machine Allowances',
  summary: 'Summary',
  COMMENT_END: 'CBA_COM_END',
  expenseCategoryForm: {
    name: {
      elType: 'input',
      label: 'Expense Category Name',
      inputProps: {
        type: 'text',
        placeholder: 'Expense Category name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    is_active: {
      elType: 'switch',
      label: 'Active',
      inputProps: {
        type: 'switch',
        placeholder: 'Active',
        value: true,
        initvalue: false,
      },
      formControlProps: {
        fullWidth: false,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
        style: { display: 'flex', alignItems: 'center' },
      },
      gridStyle: { display: 'flex' },
    },
  },
  expenseRuleForm: {
    id: {
      elType: 'input',
      label: 'ID',
      inputProps: {
        type: 'text',
        placeholder: 'Expense Category ID',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      isHide: true,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    name: {
      elType: 'input',
      label: 'Rule Name',
      inputProps: {
        type: 'text',
        placeholder: 'Expense Category name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    type: {
      elType: 'select',
      label: 'Rule Type',
      inputProps: {
        options: [],
        width: '100%',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
    },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: [
          { value: true, displayValue: 'Active' },
          { value: false, displayValue: 'Inactive' },
        ],
        width: '250px',
        value: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      size: 6,
    },
  },
  claimGenerateForm: {
    startDate: {
      elType: 'date',
      label: 'Start Date',
      inputProps: {
        type: 'text',
        placeholder: 'Start Date',
        value: moment(),
        initvalue: '',
        format: 'MM/DD/yyyy',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { position: 'static', transform: 'none' },
      },
    },
    endDate: {
      elType: 'date',
      label: 'End Date',
      inputProps: {
        type: 'text',
        placeholder: 'End Date',
        value: moment(),
        initvalue: '',
        format: 'MM/DD/yyyy',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { position: 'static', transform: 'none' },
      },
    },
    employeeName: {
      elType: 'select',
      label: 'Employee',
      inputProps: {
        options: [],
        width: '100%',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
    },
  },
  claimSetupForm: {
    id: {
      elType: 'input',
      label: 'Claim ID',
      inputProps: {
        type: 'text',
        placeholder: 'Claim ID',
        value: '',
        initvalue: '',
        disabled: true,
        style: {
          transform: 'none',
          fontSize: '14px',
          paddingTop: '3px',
          fontWeight: 500,
        },
      },
      formControlProps: {
        fullWidth: true,
        style: {
          paddingTop: '3px',
        },
      },
      validations: {
        required: true,
      },
      isHide: false,
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { transform: 'none', fontSize: '12px', fontWeight: 500 },
      },
    },
    employee_id: {
      elType: 'select',
      label: 'Employee',
      inputProps: {
        options: [],
        width: '100%',
        readOnly: true,
        disabled: true,
        className: 'claim-employee-select',
      },
      formControlProps: {
        fullWidth: true,
        style: {
          paddingTop: '6px',
        },
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      config: {
        formControlProps: {
          disabled: true,
        },
      },
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { transform: 'none', fontSize: '12px', fontWeight: 500 },
      },
    },
    stage_name: {
      elType: 'input',
      label: 'Stage',
      inputProps: {
        type: 'text',
        placeholder: 'Stage',
        value: '',
        initvalue: '',
        readOnly: true,
        disabled: true,
        style: {
          transform: 'none',
          fontSize: '14px',
          paddingTop: '3px',
          fontWeight: 500,
        },
      },
      formControlProps: {
        fullWidth: true,
        style: {
          paddingTop: '3px',
        },
      },
      size: 6,
      isFullWidth: false,
      isHide: false,
      isDisabled: true,
      doDisable: true,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { transform: 'none', fontSize: '12px' },
      },
    },
    status_name: {
      elType: 'input',
      label: 'Status',
      inputProps: {
        type: 'text',
        placeholder: 'status',
        value: '',
        initvalue: '',
        readOnly: true,
      },
      formControlProps: {
        fullWidth: true,
        style: {
          paddingTop: '6px',
        },
      },
      size: 6,
      isFullWidth: false,
      isHide: true,
      isDisabled: true,
      doDisable: true,
    },
    assign_user_id: {
      elType: 'select',
      label: 'Assign User',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
        readOnly: true,
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
        style: {
          paddingTop: '6px',
        },
      },
      size: 12,
      isDisabled: true,
      doDisable: true,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: {
          transform: 'none',
          fontSize: '12px',
          fontWeight: 500,
        },
      },
    },
    start_date: {
      elType: 'date',
      label: 'Start Date',
      inputProps: {
        type: 'text',
        placeholder: 'Start Date',
        value: moment(),
        initvalue: '',
        format: 'MM/DD/yyyy',
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
        style: { marginTop: '23px' },
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { position: 'static', transform: 'none', fontSize: '12px' },
      },
    },
    end_date: {
      elType: 'date',
      label: 'End Date',
      inputProps: {
        type: 'text',
        placeholder: 'End Date',
        value: moment(),
        initvalue: '',
        format: 'MM/DD/yyyy',
        disabled: true,
      },
      formControlProps: {
        fullWidth: true,
        style: { marginTop: '23px' },
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
        style: { position: 'static', transform: 'none', fontSize: '12px' },
      },
    },
  },
  actionText: {
    view: 'View',
    edit: 'Edit',
    add: 'Add',
  },
  actionType: {
    view: 'view',
    edit: 'edit',
    add: 'add',
  },
  urlRedirectTab: {
    formConfig: 0,
    expense: 1,
    rules: 2,
  },
  urlTab: ['formConfig', 'expense', 'rules'],
};
export default claimsSettingsProperties;
