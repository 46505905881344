import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { notyDefault } from 'components/Noty/NotyCustom';
import { serviceRequestProperties } from 'Util/Property/ServiceRequestProperties';
import { notyTypes } from 'components/Noty/NotyCustom';

export async function getAllServiceRequests() {
  return axios.get(urlProperties.serviceRequest.getServiceRequests);
}

/**
 * service request save and update
 */

export async function saveServiceRequest(method, url, data) {
  return await axios({
    method: method,
    url: url,
    data: data,
  });
  // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true"
}

export async function getServiceRequest(id) {
  return axios.get(urlProperties.serviceRequest.getServiceRequest + '/' + id);
}

export async function deleteServiceRequest(id) {
  return axios.delete(
    urlProperties.serviceRequest.deleteServiceRequest + '/' + id
  );
}

export async function assignUsersForServiceRequests(data) {
  return axios.patch(
    urlProperties.serviceRequest.assignUserToServiceRequests,
    data
  );
}
export async function getEstimatedTimeForPlannedDate(
  assignedUserId,
  plannedDate
) {
  return axios.get(
    `${urlProperties.serviceRequest.getEstimatedTimeForPlannedDate}?assignUserId=${assignedUserId}&plannedDate=${plannedDate}`
  );
}

export async function getServiceRequestByNotCompleted(customerId) {
  // return axios.get(urlProperties.serviceRequest.getServiceRequestByNotCompleted+"/"+customerId)
  return axios.get(
    urlProperties.serviceRequest.getServiceRequestByNotCompleted +
      '?customerId=' +
      customerId
  );
}

export async function getServiceRequestByCustomer(customerId) {
  return axios.get(
    urlProperties.serviceRequest.getServiceRequestByCustomer +
      '?customerId=' +
      customerId
  );
}

export async function getServiceRequestHistory(id) {
  return axios.get(
    urlProperties.serviceRequest.getServiceRequestHistory + '/' + id
  );
}

export function getRouteOptions(id) {
  return axios.get('checkRouteOptions/' + id);
}

export function getRouteOptionsByWorkflowStageTypeAndName(data) {
  try {
    return axios({
      method: 'POST',
      url: 'getRouteOptionsByWorkflowStageTypeAndName',
      data: data,
    });
  } catch (error) {
    let details = error.response.data;
    if (typeof details !== 'undefined') {
      notyDefault({
        type: notyTypes.ERROR,
        text:
          serviceRequestProperties.messages.error
            .getRouteOptionsByWorkflowStageTypeAndName,
      });
    }
  }
}

export async function getRouteOptionsBySrId(idList) {
  return axios.get('checkSrAssigneeRoutes?Idlist=' + idList);
}

export async function searchSrDescription(inputVal) {
  return axios.get('/searchSrDescription?search-text=' + inputVal);
}

/**
 * service request bulk create
 */
export async function createBulkSR(method, url, data) {
  try {
    return await axios({
      method: method,
      url: url,
      data: data,
    });
  } catch (error) {
    console.log(error.response);
    let details = error.response.data;
    if (typeof details !== 'undefined') {
      notyDefault({
        type: notyTypes.ERROR,
        text: serviceRequestProperties.messages.error.saveData,
      });
    }
  }
}

export async function generateSRReport(id) {
  try {
    return axios({
      url: `${urlProperties.serviceRequest.getSRReport}/${id}`,
      method: 'GET',
      responseType: 'blob',
    });
  } catch (error) {
    console.log(serviceRequestProperties.messages.error.reportGenerate);
  }
}
