import React, { useEffect, useState, useRef } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import StandardReports from 'views/Reports/StandardReports';
import styles from './Reports.module.css';
import OperationalReports from 'views/Reports/OperationalReports';


const Reports = (props) => {

    const [permissions, setPermissions] = useState({});

    let trainingTab = "reportsTab";
    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const theme = useTheme();
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem(trainingTab, newValue);

    };

    const tableRef = useRef(null);
    useEffect(() => {
        let tab = localStorage.getItem(trainingTab);
        // let permissionArray = [PermissionProperties.generateReports];
        // setPermissions(checkPermissoinListAvailable(permissionArray));
        if 
        (typeof tab !== 'undefined' && tab !== null) {
            setValue(parseInt(tab));
        }
    },
     []);


    return (<div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

                <Card>
                    <CardBody>
                        {/* <AppBar position="static" color="inherit" className={styles.appBarStyle}> */}
                            {/* <Tabs
                                value={value}
                                onChange={handleChange}
                                // indicatorColor="primary"
                                // textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            > */}
                                {/* <Tab label="Standard Reports" {...a11yProps(0)} /> */}

                                {/* <Tab label="Customized Reports" {...a11yProps(1)} /> */}

                            {/* </Tabs> */}
                        {/* </AppBar> */}
                        {/* customer table content */}
                        {/* <FullWidthTabPanel value={value} index={0} dir={theme.direction}>
                            <StandardReports  {...props}/>
                        </FullWidthTabPanel> */}
                        <FullWidthTabPanel value={value} index={0} dir={theme.direction}>
                          <OperationalReports  {...props}/>
                        </FullWidthTabPanel>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    </div>);
}

export default Reports;
