const pieChartTitles = [
  {
    value: 'notCompleted',
    displayValue: 'Not Completed',
  },
  {
    value: 'compliance',
    displayValue: 'Completed',
  },
  {
    value: 'notCompliance',
    displayValue: 'Completed Delayed',
  },
];

const tableFields = {
  table1: [{ id: '2', field: 'title' }, { id: '3', field: 'count' }],
  table2: [
    { id: '2', field: 'srId' },
    { id: '3', field: 'priority' },
    { id: '4', field: 'customerName' },
    { id: '5', field: 'province' },
    { id: '6', field: 'productName' },
    { id: '7', field: 'transportMedium' },
    { id: '8', field: 'stage' },
    { id: '9', field: 'startedDate' },
    { id: '10', field: 'completedDate' },
    { id: '11', field: 'updatedDate' },
    { id: '12', field: 'attendedBy' },
    { id: '13', field: 'age' },
    { id: '14', field: 'kpi' },
  ],
  table3: [
    { id: '2', field: 'srName' },
    { id: '3', field: 'customerName' },
    { id: '4', field: 'stage' },
    { id: '5', field: 'attendedBy' },
    { id: '6', field: 'attendedDate' },
    { id: '7', field: 'attendedTime' },
    { id: '8', field: 'gap' },
    { id: '9', field: 'kpi' },
  ],
};

export const highlight5Properties = {
  pieChartTitles: pieChartTitles,
  tableFields: tableFields,
};
