export const trainingProperties = {
  trainingAndDevelopment: 'Training and Development',
  viewMessage: 'View Message',
  composeMessage: 'compose new message',
  viewRequest: 'View Request',
  messages: {
    error: {
      loadData: 'Could not load  message please try again',
      sent: 'Could not sent message. please try again',
      delete: 'Could not delete message please try again',
      loadAll: 'Could not load messages please try again',
      loadEmails: 'Coud not load user emails',
      loadUsers: 'Could not load Users',
    },
    success: {
      sent: 'Message sent successfully',
      delete: 'Message deleted successfully',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this Message',
    },
  },
};

const datePickerFormat = 'MM/DD/yyyy';

const RequestStatus = [
  { value: 'PENDING', displayValue: 'PENDING' },
  { value: 'CLOSED', displayValue: 'CLOSED' },
];

export const getTrainingForm = () => {
  return {
    userIdList: {
      elType: 'select',
      label: 'User',
      inputProps: {
        options: [],
        value: [],
        initvalue: [],
      },

      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      multiple: true,
    },
    title: {
      elType: 'input',
      label: 'Title',
      inputProps: {
        type: 'text',
        placeholder: 'Title',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 12,
    },
    message: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Type your message here',
        value: '',
        initvalue: '',
        rowsMin: 7,
        rowsMax: 20,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};

export const getUserCommentForm = () => {
  return {
    requestedUserName: {
      elType: 'input',
      label: 'Requested User',
      inputProps: {
        type: 'text',
        placeholder: 'Requested User',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
    requestTypeName: {
      elType: 'input',
      label: 'Request Type',
      inputProps: {
        type: 'text',
        placeholder: 'Request Type',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
    requestStatus: {
      elType: 'select',
      label: 'Request Status',
      inputProps: {
        options: RequestStatus,
        value: 'PENDING',
        initvalue: 'PENDING',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
    },
    userCommentTitle: {
      elType: 'input',
      label: 'Title',
      inputProps: {
        type: 'text',
        placeholder: 'Title',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
    },
    userComments: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Comment',
        value: '',
        initvalue: '',
        rowsMin: 3,
        rowsMax: 20,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },

    responseComments: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Response',
        value: '',
        initvalue: '',
        rowsMin: 3,
        rowsMax: 20,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};
