/* eslint-disable no-empty */
import React, { useState, useEffect, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {
  productProperties,
  getProductForm,
} from 'Util/Property/ProductProperties';
import generalProperties from 'Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import {
  getActiveCategoryList,
  getProductHistory,
} from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import * as productService from 'services/ProductAndServices/ProductService';
import { getCustomer } from 'services/Customer/CustomerService';
import styles from './Product.module.css';
import { getServiceAgreementsByCategory } from 'services/serviceAgreeement';
import ListAltOutlinedIcon from '@material-ui/icons/ShoppingBasket';
import { Typography } from '@material-ui/core';
import { handleErrorMessage } from 'services/CommonService';
import RouterProps from '../../../Util/Property/RouterProperties';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import History from '../../History/History';
import { isCustomerUser } from '../../../Util/Util';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import Spinner from 'components/Spinner/Spinner';

const Product = (props) => {
  const [productForm, setProductForm] = useState(getProductForm());
  const [productHeading, setProductHeading] = useState(
    productProperties.addProduct
  );
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [productId, setProductId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [duplicateSerialDialogOpen, setDuplicateSerialDialogOpen] = useState(
    false
  );
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [existingCustomerData, setExistingCustomerData] = useState('');
  const [permissions, setPermissions] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState(0);
  const [productSubStatus, setProductSubStatus] = useState({});

  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });

  const tableRef = useRef(null);

  const [asyncDataState, setAsyncDataState] = useState({
    customerId: false,
    serviceAgreementId: false,
    productCategoryId: false,
    productBrandId: false,
    productModelId: false,
    productSubStatusId: false,
    department: false,
  });

  const [initialStatus, setInitilaStatus] = useState({
    customerId: '',
    productCategoryId: '',
    productBrandId: '',
    productModelId: '',
    serviceAgreementId: '',
    active: true,
    productSubStatusId: '',
  });

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addProductAndServices,
      PermissionProperties.viewProductAndServices,
      PermissionProperties.deleteProductAndServices,
      PermissionProperties.editProductAndServices,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  useEffect(() => {
    let productId = 0;
    let customerId = 0;

    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    customerId = queryParam['customerId'];

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      productId = props.id;
      customerId = props.customerId;
      setProductHeading(productProperties.viewProduct);
    } else if (props.location) {
      if (typeof queryParam['id'] != 'undefined') {
        productId = queryParam['id'];
        setProductHeading(productProperties.viewProduct);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          setProductHeading(productProperties.editProduct);
        } else {
          setIsView(true);
        }
      }
    }
    if (productId > 0) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      //setServiceRequestId(serviceRequestId);
      setProductId(productId);
      getProductData(productId, customerId);
      hideSalesCode(false);
    } else {
      // this is loading for add new Service Request
      getBackGroundData(customerId);
      hideSalesCode(true);
    }
  }, [props.id]);

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...productForm };
      setProductForm(formData);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const hideSalesCode = (isHide) => {
    productForm['saleCode'].isHide = isHide;
  };

  const getProductData = async (productId, customerId) => {
    setIsLoading(true);
    await productService
      .getProduct(productId)
      .then((result) => {
        setProductHeading(result.data.name);
        let initStat = { ...initialStatus };
        for (let key in productForm) {
          let value = result.data[key];
          if (!productForm[key].isAsyncDropDown) {
            productForm[key].inputProps.value = value;
            initStat[key] = value;
          } else {
            if (key === 'serviceAgreementId') {
              let saData = result.data['productServiceAgreements'];
              for (let index in saData) {
                if (saData[index].isActive) {
                  productForm[key].inputProps.initvalue =
                    saData[index].serviceAgreementId;
                  initStat[key] = saData[index].serviceAgreementId;
                  return false;
                }
              }
            } else {
              productForm[key].inputProps.initvalue = value;
              initStat[key] = value;
              console.log(
                '----------------> key',
                key,
                value,
                productForm[key].inputProps
              );
            }
          }
        }
        setInitilaStatus(initStat);
        setIsLoading(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    getBackGroundData(customerId);
  };

  const getBackGroundData = (customerId) => {
    setIsLoading(true);
    getCustomerForProduct(customerId);
    getProductSubStatuses();
    getActiveCategoryList()
      .then((result) => {
        asyncDataState.productCategoryId = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map((category) =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name,
            })
          );
          let defaultValue = setDataToProductForm(
            'productCategoryId',
            categoryOptions,
            true
          );
          if (defaultValue !== '') {
            getAllServiceAgreementsByCategory(defaultValue, true);
          }
          if (defaultValue !== '') {
            getActiveBrandListForProduct(defaultValue, true);
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductCategories,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductCategories,
        });
      });
  };

  const getProductSubStatuses = () => {
    setIsLoading(true);
    productService
      .getAllProductSubStatuses()
      .then((result) => {
        asyncDataState.productSubStatusId = true;
        if (result.status === 200) {
          let activeStatuses = [];
          let inactiveStatuses = [];
          result.data.map((subStatus) => {
            if (subStatus.active_product_sub_status) {
              activeStatuses.push({
                value: subStatus.id,
                displayValue: subStatus.name,
              });
            } else {
              inactiveStatuses.push({
                value: subStatus.id,
                displayValue: subStatus.name,
              });
            }
          });

          let prdtSubStatus = { ...productSubStatus };
          prdtSubStatus.activeStatuses = activeStatuses;
          prdtSubStatus.inactiveStatuses = inactiveStatuses;
          setProductSubStatus(prdtSubStatus);
          let options = [];
          if (productForm.active.inputProps.value) {
            options = activeStatuses;
          } else {
            options = inactiveStatuses;
          }
          let defaultValue = setDataToProductForm(
            'productSubStatusId',
            options,
            true
          );
        } else {
          handleErrorMessage(
            result.data,
            productProperties.messages.error.subStatuses
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, productProperties.messages.error.subStatuses);
      });
  };

  const getActiveBrandListForProduct = (catId, isFirstTime) => {
    setIsLoading(true);
    getActiveBrandList(catId)
      .then((result) => {
        asyncDataState.productBrandId = true;
        if (result.status === 200) {
          let brandOptions = [];
          result.data.map((brand) =>
            brandOptions.push({ value: brand.id, displayValue: brand.name })
          );
          let defaultValue = setDataToProductForm(
            'productBrandId',
            brandOptions,
            isFirstTime
          );
          if (defaultValue !== '') {
            getActiveModelListForProduct(defaultValue, isFirstTime);
          } else {
            // no brands for the seleted category
            getActiveModelListForProduct(0, isFirstTime);
            asyncDataState.productModelId = false;
          }
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductBrands,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductBrands,
        });
      });
  };

  const getActiveModelListForProduct = (brandId, isFirstTime) => {
    setIsLoading(true);
    getActiveModelList(brandId)
      .then((result) => {
        asyncDataState.productModelId = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map((model) =>
            modelOptions.push({ value: model.id, displayValue: model.name })
          );
          let defaultValue = setDataToProductForm(
            'productModelId',
            modelOptions,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadProductModels,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadProductModels,
        });
      });
  };

  const getAllServiceAgreementsByCategory = (categoryId, isFirstTime) => {
    setIsLoading(true);
    getServiceAgreementsByCategory(categoryId, 2)
      .then((result) => {
        asyncDataState.serviceAgreementId = true;
        if (result.status === 200) {
          let serviceAgreementOptions = result.data.reduce(function(
            filtered,
            option
          ) {
            var someNewValue = { value: option.id, displayValue: option.name };
            filtered.push(someNewValue);
            return filtered;
          },
          []);
          // result.data.map(serviceAgreement => serviceAgreementOptions.push({ "value": serviceAgreement.id, "displayValue": serviceAgreement.name }));
          setDataToProductForm(
            'serviceAgreementId',
            serviceAgreementOptions,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadServiceAgreements,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadServiceAgreements,
        });
      });
  };

  const getCustomerForProduct = (customerId) => {
    setIsLoading(true);
    getCustomer(customerId)
      .then((result) => {
        asyncDataState.customerId = true;
        if (result.status === 200) {
          let customerOptions = [];
          customerOptions.push({
            value: result.data.id,
            displayValue: result.data.name,
          });
          setDataToProductForm('customerId', customerOptions, true);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : productProperties.messages.error.loadCustomers,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: productProperties.messages.error.loadCustomers,
        });
      });
  };

  const onChangeHandeler = (event, elId) => {
    let value = '';
    let formData = { ...productForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elementData.elType == 'date') {
      inputProps.value = event.toDate();
      value = event.toDate();
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setProductForm(formData);
    if (elId === 'productCategoryId' && value !== '') {
      getActiveBrandListForProduct(value);
      getAllServiceAgreementsByCategory(value);
    }
    if (elId === 'productBrandId' && value !== '') {
      getActiveModelListForProduct(value);
    }

    if (elId === 'productModelId' && value !== '') {
    }

    if (elId === 'active') {
      statusChangeHandler(value);
    }
  };

  const statusChangeHandler = (value) => {
    let prdtSubStatus = { ...productSubStatus };
    let options = [];
    if (value) {
      options = prdtSubStatus.activeStatuses;
    } else {
      options = prdtSubStatus.inactiveStatuses;
    }

    productForm['productSubStatusId'].inputProps.initvalue = '';

    let defaultValue = setDataToProductForm(
      'productSubStatusId',
      options,
      true
    );
  };

  const onProductSubmit = async (
    values,
    formState,
    formErrors,
    ownershipChanged = false
  ) => {
    //TODO
    let method = '';
    let url = '';
    let data = {};
    let productFormData = { ...productForm };
    for (let key in productFormData) {
      let val = productFormData[key].inputProps.value;
      if (val !== '') {
        data[key] = val;
      } else {
        data[key] = 0;
      }
    }
    data['ownershipChanged'] = ownershipChanged;

    if (productId > 0) {
      data['id'] = productId;
      method = 'PATCH';
      url = UrlProperties.product.updateProduct + '/' + productId;
    } else {
      let productServiceAgreement = {
        serviceAgreementId: data['serviceAgreementId'],
      };
      let productServiceAgreements = [];
      productServiceAgreements.push(productServiceAgreement);
      data['productServiceAgreements'] = productServiceAgreements;
      data['id'] = 0;
      method = 'POST';
      url = UrlProperties.product.createProduct;
    }

    setIsLoading(true);
    await productService
      .saveProduct(method, url, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productProperties.messages.success.saveData,
          });
          if (productId == 0) {
            setDefaultData();
          }
          setIsLoading(false);
          props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: productProperties.messages.error.saveData,
          });
        }
      })
      .catch((error) => {
        const { response = {} } = error || {};
        const { data = {} } = response || {};
        const { details = '' } = data || {};
        setIsLoading(false);
        if (details.startsWith('Product is used by customer')) {
          setDuplicateSerialDialogOpen(true);
          setExistingCustomerData(details);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: details || productProperties.messages.error.saveData,
          });
        }
      });
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...productForm };
    for (let key in formData) {
      if (
        key !== 'productCategoryId' &&
        key !== 'productBrandId' &&
        key !== 'productModelId'
      ) {
        let elementData = formData[key]; // get data for form key
        let inputProps = { ...elementData.inputProps }; //  copy input data
        inputProps.value = formData[key].inputProps.initvalue;
        elementData.inputProps = inputProps;
        formData[key] = elementData;
      }
    }
    setProductForm(formData);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToProductForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = productForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    productForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    return defaultValue;
  };

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in productForm) {
    formElementArray.push({
      id: key,
      config: productForm[key],
    });
  }
  const duplicateSerialDialogCloseHandler = () => {
    setDuplicateSerialDialogOpen(false);
    setExistingCustomerData('');
  };
  const historyDialogCloseHandler = () => {
    setIsHistoryDialogOpen(false);
  };
  const historyDialogOpenHandler = () => {
    setIsHistoryDialogOpen(true);
  };
  const duplicateSerialDialogConfirmHandler = () => {
    setDuplicateSerialDialogOpen(false);
    setExistingCustomerData('');
    onProductSubmit(null, null, null, true);
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Dialog
                open={duplicateSerialDialogOpen}
                onClose={duplicateSerialDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {`${existingCustomerData}, do you want change the ownership ?`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={duplicateSerialDialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={duplicateSerialDialogConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={isHistoryDialogOpen}
                onClose={historyDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <History method={getProductHistory} id={productId} />
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={historyDialogCloseHandler}
                    color="primary"
                  >
                    {generalProperties.close}
                  </Button>
                </DialogActions>
              </Dialog>
              {!isEdit && !isView ? (
                <div className={'generic-form-header-wrapper'}>
                  <div className={'generic-page-title'}>
                    <span className={'generic-page-title-icon'}>
                      <ListAltOutlinedIcon />
                    </span>
                    <Typography
                      className={'generic-header-text'}
                      variant="h6"
                      style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                    >
                      {productHeading}
                    </Typography>
                    {/* <Typography variant="body1">Please add product.</Typography> */}
                  </div>
                </div>
              ) : (
                ''
              )}
              {productId && !isCustomerUser() ? (
                <div style={{ marginLeft: '15px', marginTop: '5px' }}>
                  <Button
                    onClick={historyDialogOpenHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.history}
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <Form
                onSubmit={onProductSubmit}
                initialValues={initialStatus}
                validate={(values, ss) => {
                  const errors = {};
                  if (
                    productForm.customerId.inputProps.value === '' ||
                    typeof productForm.customerId.inputProps.value ===
                      'undefined'
                  ) {
                    errors.customerId = generalProperties.emptyField;
                  }
                  if (
                    productForm.productModelId.inputProps.value === '' ||
                    typeof productForm.productModelId.inputProps.value ===
                      'undefined'
                  ) {
                    errors.productModelId = generalProperties.emptyField;
                  }
                  if (
                    productForm.serviceAgreementId.inputProps.value === '' ||
                    typeof productForm.serviceAgreementId.inputProps.value ===
                      'undefined'
                  ) {
                    errors.serviceAgreementId = generalProperties.emptyField;
                  }
                  // if (
                  //   productForm.productSubStatusId.inputProps.value === '' ||
                  //   typeof productForm.productSubStatusId.inputProps.value ===
                  //     'undefined'
                  // ) {
                  //   errors.productSubStatusId = generalProperties.emptyField;
                  // }
                  if (
                    productForm.serial.inputProps.value === '' ||
                    typeof productForm.serial.inputProps.value === 'undefined'
                  ) {
                    errors.customerId = generalProperties.emptyField;
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  reset,
                  submitting,
                  pristine,
                  valid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          if (!element.config.isHide) {
                            let mdVal = element.config.size;
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <Field name={element.id}>
                                  {({ input, meta, options, value }) => (
                                    <div style={{ position: 'relative' }}>
                                      <CustomInput
                                        labelText={element.config.label}
                                        id={element.id}
                                        inputProps={{
                                          ...input,
                                          ...element.config.inputProps,
                                          readOnly:
                                            isView ||
                                            (element.config.isDisabled &&
                                              isEdit) ||
                                            element.config.doDisable
                                              ? true
                                              : false,
                                        }}
                                        type={element.config.elType}
                                        formControlProps={
                                          element.config.formControlProps
                                        }
                                        adornmentText={
                                          element.config.adornmentText
                                        }
                                        adornmentPosition={
                                          element.config.adornmentPosition
                                        }
                                        changed={(event, value) => {
                                          input.onChange(event);
                                          onChangeHandeler(event, element.id);
                                        }}
                                        labelProps={{
                                          ...element.config.labelProps,
                                          error: meta.error && meta.touched,
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className={styles.formError}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      {!isView &&
                      (permissions[
                        PermissionProperties.addProductAndServices
                      ] ||
                        permissions[
                          PermissionProperties.editProductAndServices
                        ]) ? (
                        <Button
                          type="submit"
                          disabled={submitting || !valid || pristine}
                          color="primary"
                          autoFocus
                        >
                          {generalProperties.save}
                        </Button>
                      ) : (
                        ''
                      )}
                    </CardFooter>
                  </form>
                )}
              />
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Product;
