/* eslint-disable no-console */
import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { productProperties } from 'Util/Property/ProductProperties';
import { productServiceAgreementProperties } from 'Util/Property/ProductServiceAgreementProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
const baseUrl = urlProperties.baseUrl;

export async function getAllProductServiceAgreements() {
  try {
    return axios.get(
      urlProperties.productServiceAgreement.getProductServiceAgreements
    );
  } catch (error) {
    console.log(productProperties.messages.error.loadAll);
  }
}

/**
 * service request save and update
 */
export async function saveProductSeriveAgreement(method, url, data) {
  try {
    return await axios({
      method: method,
      url: url,
      data: data,
    });
  } catch (error) {
    console.log(error.response);
    let details = error.response.data;
    if (typeof details !== 'undefined') {
      notyDefault({
        type: notyTypes.ERROR,
        text:
          typeof details.details !== 'undefined'
            ? details.details
            : productProperties.messages.error.saveData,
      });
    }
  }
}

export async function saveBulkProductServiceAgreements(data) {
  try {
    return await axios.post(
      urlProperties.productServiceAgreement.createBulkProductServiceAgreements,
      data
    );
  } catch (error) {
    let details = error.response.data;
    if (typeof details !== 'undefined') {
      notyDefault({
        type: notyTypes.ERROR,
        text:
          typeof details.details !== 'undefined'
            ? details.details
            : productProperties.messages.error.saveData,
      });
    }
  }
}

export async function updateProductServiceAgreements(id, data, isUpcoming) {
  try {
    const api = isUpcoming
      ? urlProperties.productServiceAgreement
          .updateUpcomingProductServiceAgreement
      : urlProperties.productServiceAgreement.updateProductServiceAgreement;

    return await axios.patch(api + '/' + id, data);
  } catch (error) {
    let details = error.response.data;
    if (typeof details !== 'undefined') {
      notyDefault({
        type: notyTypes.ERROR,
        text:
          typeof details.details !== 'undefined'
            ? details.details
            : productProperties.messages.error.saveData,
      });
    }
  }
}

export async function getProductServiceAgreement(id, isUpcoming) {
  try {
    const api = isUpcoming
      ? urlProperties.productServiceAgreement.getUpcomingProductServiceAgreement
      : urlProperties.productServiceAgreement.getProductServiceAgreement;
    return axios.get(api + '/' + id);
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

export async function deleteProductServiceAgreement(id, isUpcoming) {
  try {
    const api = isUpcoming
      ? urlProperties.deleteUpcomingProductServiceAgreement
          .deleteUpcomingProductServiceAgreement
      : urlProperties.productServiceAgreement.deleteProductServiceAgreement;
    return axios.delete(api + '/' + id);
  } catch (error) {
    console.log(productProperties.messages.error.delete);
  }
}

export async function getProductServiceAgreementsOfProduct(productId) {
  try {
    return axios.get(
      urlProperties.productServiceAgreement
        .getProductServiceAgreementsOfProduct +
        '/' +
        productId
    );
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

export function getRouteOptions(id) {
  return axios.get('checkPSARouteOptions/' + id);
}

export async function getExpiringProductServiceAgreements(date) {
  try {
    return axios.get(
      urlProperties.productServiceAgreement
        .getExpiringProductServiceAgreements +
        '?dateUntil=' +
        date +
        '&isUpcoming=' +
        true
    );
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

export async function createBulkUpcomingPSA(date, body) {
  try {
    return axios.post(
      urlProperties.productServiceAgreement
        .createBulkUpcomingProductServiceAgreement +
        '?dateUntil=' +
        date,
      body
    );
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

export async function generateBulkPSR(date) {
  try {
    return axios.post(
      urlProperties.productServiceAgreement.createFutureSRsUntil +
        '?untilDate=' +
        date
    );
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

export async function generatePSAReport(id) {
  try {
    return axios({
      url: `${baseUrl}${urlProperties.productServiceAgreement.getPSAReport}/${id}`,
      method: 'GET',
      responseType: 'blob',
    });
  } catch (error) {
    console.log(
      productServiceAgreementProperties.messages.error.reportGenerate
    );
  }
}

export async function updatePSAWorkflowStage(id, data) {
  try {
    return await axios({
      method: 'PATCH',
      url: `${baseUrl}${urlProperties.productServiceAgreement.updatePSAWorkflowStage}/${id}`,
      data: data,
    });
  } catch (error) {
    console.log(productProperties.messages.error.loadData);
  }
}

// export async function getServiceAgreements() {
//   try {
//     return axios.get(
//       urlProperties.productServiceAgreement
//         .getProductServiceAgreementsOfProduct +
//         '/' +
//         productId
//     );
//     // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
//   } catch (error) {
//     console.log(productProperties.messages.error.loadData);
//   }
// }
