import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone'
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from '../Noty/NotyCustom';
import readXlsxFile from 'read-excel-file'
import { Typography } from '@material-ui/core';
import { customerProperties } from 'Util/Property/CustomerPorerties';
import * as moment from 'moment';
import writeXlsxFile from 'write-excel-file'
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from "material-table";
import Lottie from 'react-lottie';
import animationData from '../../assets/onsiteAnimation.json';
import ReportView4 from './ReportView4';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function ReportView3(props) {

    const { open, fileUploadClose, getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject, url , uploadLoacation, acceptFileType,type} = props;
    const [file, setFile] = React.useState([]);

    const [error, setError] = React.useState("");
    const [hasError, setHasError] = React.useState(false);
    const [dataArray, setDataArray] = React.useState([]);
    const [openSecondPreview, setOpenSecondPreview] = useState(false);
    const [newFile, setNewFile] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openFileUpload4, setOpenFileUpload4] = useState(false);
   // const [handleClose, setHandleClose] = useState(false);
    

    const baseUrl = UrlProperties.baseUrl.split(":8089")[0];
    
    const handleClose = () => {
        // setFile([]);
         fileUploadClose();
    }

    const fileUploadClose3= () => {
      setOpenFileUpload4(false);
  } 

 
    useEffect(() => {

    }, []);

    useEffect(() => {
   
    }, [file]);

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '140px',
        width: '400px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#B22900',
        borderStyle: 'dashed',
        backgroundColor: '#ffcec7',
        color: '#f73123',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const style = useMemo(() => ({
        ...baseStyle,
    }), [

    ]);

    return (
        <div>
           <ReportView4  open={openFileUpload4}  type={type} fileUploadClose={fileUploadClose3} />
            <Dialog
            maxWidth="xl"
            fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'>
                    <DialogContent>
                    <b><center>
                    From:2022-06-11   To:2022-07-02
                        </center></b>
                    {
                      type =="Efficiency_Summary_Time"? 
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Efficiency Summary - Time</Typography>
                            </div>
                          }    
                          columns={[
                            { title: "#", field: "num" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "SR Name",width:'12%', field: "srname" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Customer",width:'12%', field: "customer" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Stage",width:'12%', field: "stage" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Attended By",width:'12%', field: "attendedby", headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Attended Date",width:'12%', field: "attendeddate" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Attended Time",width:'12%', field: "attendedtime" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Milestone Gap (Days)",  width:'15%', field: "milestonegap", headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'},
                            render:(rowData) =><div style={{width:'20%',marginLeft:'40%', background:rowData["milestonegap"]<rowData["kpi"]?"#92D050":rowData["milestonegap"]>rowData["kpi"]?" #FF3300":"#FFC000"}}>{rowData["milestonegap"]}</div>},
                            { title: "KPI (Days)",width:'12%', field: "kpi" , headerStyle:{backgroundColor: '#e6e4df',padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          ]}
                          data={[
                            { num: '1', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Inquiries', attendedby: 'Shalomi' , attendeddate: '2021-01-26', attendedtime: '14:51:04', milestonegap: 0, kpi: 0}, 
                            { num: '2', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Assigned', attendedby: 'Shalomi' , attendeddate: '2021-01-27', attendedtime: '15:51:04', milestonegap: 1, kpi: 2}, 
                            { num: '3', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Customer Visit', attendedby: 'Layantha' , attendeddate: '2021-01-27', attendedtime: '16:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '4', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Demo', attendedby: 'Layantha' , attendeddate: '2021-01-27', attendedtime: '17:51:04', milestonegap: 0, kpi: 0}, 
                            { num: '5', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Assigned', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '18:51:04', milestonegap: 3, kpi: 2}, 
                            { num: '6', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Customer Visit', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '19:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '7', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Demo', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '20:51:04', milestonegap: 0, kpi: 0}, 
                            { num: '8', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Get Order', attendedby: 'Tharaka' , attendeddate: '2021-02-02', attendedtime: '21:51:04', milestonegap: 3, kpi: 2}, 
                            { num: '9', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Approval Process 1', attendedby: 'Enosh' , attendeddate: '2021-02-02', attendedtime: '22:51:04', milestonegap: 1, kpi: 1}, 
                            { num: '10', srname: 'SR-001/Sales', customer:'HNB-Dehiwala', stage: 'Approval Process 2', attendedby: 'Enosh' , attendeddate: '2021-02-03', attendedtime: '23:51:04', milestonegap: 0, kpi: 0}, 
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData) => {
                            // Get your id from rowData and use with link.
                            //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                            //event.stopPropagation();
                          }}
                        /> </div></GridItem></GridContainer>

                    : type =="Efficiency_Summary_Count"?
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Efficiency Summary - Count</Typography>
                            </div>
                          } 
                           columns={[
                            { title: "#",  width:'1%', field: "num" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "SR Name", field: "srname" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Customer", field: "customer" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Stage", field: "stage" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Attended By", field: "attendedby", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Attended Date", field: "attendeddate" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Attended Time", field: "attendedtime" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Age (Days)",   field: "milestonegap", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'},
                            render:(rowData) =><div style={{width:'100%',marginLeft:'25%',background:rowData["milestonegap"]<rowData["kpi"]?"#92D050":rowData["milestonegap"]>rowData["kpi"]?" #FF3300":"#FFC000"}}>{rowData["milestonegap"]}</div>},
                            { title: "KPI (Days)", field: "kpi" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                          ]}
                          data={[
                            { num: '1', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Inquiries', attendedby: 'Shalomi' , attendeddate: '2021-01-26', attendedtime: '14:51:04', milestonegap: 0, kpi: 0}, 
                            { num: '2', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Assigned', attendedby: 'Layantha' , attendeddate: '2021-01-27', attendedtime: '15:51:04', milestonegap: 1, kpi: 2}, 
                            { num: '3', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Customer Visit', attendedby: 'Layantha' , attendeddate: '2021-01-27', attendedtime: '16:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '4', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Demo', attendedby: 'Layantha' , attendeddate: '2021-01-27', attendedtime: '17:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '5', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Assigned', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '18:51:04', milestonegap: 3, kpi: 2}, 
                            { num: '6', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Customer Visit', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '19:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '7', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Demo', attendedby: 'Tharaka' , attendeddate: '2021-01-30', attendedtime: '20:51:04', milestonegap: 0, kpi: 1}, 
                            { num: '8', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Get Order', attendedby: 'Layantha' , attendeddate: '2021-02-02', attendedtime: '21:51:04', milestonegap: 3, kpi: 2}, 
                            { num: '9', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Approval Process 1', attendedby: 'Layantha' , attendeddate: '2021-02-02', attendedtime: '22:51:04', milestonegap: 1, kpi: 1}, 
                            { num: '10', srname: 'SR-001/New Sale', customer:'HNB-Dehiwala', stage: 'Approval Process 2', attendedby: 'Layantha' , attendeddate: '2021-02-03', attendedtime: '23:51:04', milestonegap: 0, kpi: 1}, 
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData) => {
                            // Get your id from rowData and use with link.
                           // window.open(this.props.match.url+`/onSiteUserDetail/${rowData.fieldAgent}/${rowData.assignDate}`, "_blank")
                            //event.stopPropagation();
                          }}
                        /> </div></GridItem></GridContainer>
                        : type =="Average_field_Cost"? 
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                        <div className={"material-grid-wrapper"}>
                        <MaterialTable
                          title={
                            <div>
                              <Typography variant="h6" style={{ fontSize: "1rem", marginTop: "-0.7rem" }}>Average field Cost</Typography>
                            </div>
                          }
                          columns={[
                            { title: "#", field: "num" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Date",field: "date" ,width:'12%', headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Total Field Visits Count", width:'14%', field: "totalfieldvisitscount" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Cost Of Travelling",width:'12%',field: "costoftravelling" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
                            { title: "Cost of BA/TA",width:'12%', field: "ba", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of Meal",width:'12%', field: "meal", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Cost of Allowances",width:'12%', field: "allowances", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Fixed Cost", width:'12%',field: "fixedcost", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},
                            { title: "Total Fixed Cost",width:'12%', field: "totalfixedcost", headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{textAlign:'center'}},


                          ]}
                          data={[
                            { num: '1', date: '1/1/2022', totalfieldvisitscount: 3, costoftravelling: 250.00 , ba: 150.00, meal: 250.00, allowances: 200.00 , fixedcost: 1000.00, totalfixedcost: 1850.00}, 
                            { num: '2', date: '1/2/2022', totalfieldvisitscount: 10, costoftravelling: 150.00, ba: '-', meal: 200.00, allowances: 120.00 , fixedcost: 1000.00, totalfixedcost: 1470.00},
                            { num: '3', date: '1/3/2022', totalfieldvisitscount: 7, costoftravelling: 300.00, ba: '-', meal: 250.00, allowances: 150.00 , fixedcost: 1000.00, totalfixedcost: 1700.00}
                          ]}
                          options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            pageSize: 5,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false,
                            padding: "dense",
                            maxBodyHeight: "70vh",
                        headerStyle: {
                            fontSize: '0.7rem',
                            textAlignL: "center"
                        },
                        rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: "5rem"
                        }
                          }}
                          // options={{
                          //   exportButton: true
                          // }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload4(true)
                          }}
                        /></div></GridItem></GridContainer>
                    
                    : null

                    }
                    </DialogContent>
                    <DialogActions>
                        {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

                        <Button className={'generic-button-class div-button-wrapper'} onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        </div>
    );
}
