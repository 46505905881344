import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import styles from './ServiceAgreement.module.css';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import {
  ServiceAgreementProperties,
  getServiceAgreementForm,
} from 'Util/Property/ServiceAgreementProperties';
import CardFooter from 'components/Card/CardFooter';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import LoadingOverlay from 'react-loading-overlay';
import Spinner from 'components/Spinner/Spinner.js';

import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import { getActiveCategoryList } from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import { postServiceAgreements } from 'services/serviceAgreeement';
import { getServiceAgrementbyId } from 'services/serviceAgreeement';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import RouterProps from '../../../../Util/Property/RouterProperties';
import { getCategoryByWorkflowType } from 'services/Workflow/WorkflowService';
import { workflowProperties } from 'Util/Property/WorkflowProperties';
import { handleErrorMessage } from 'services/CommonService';

const ServiceAgreement = (props) => {
  const [permissions, setPermissions] = useState({});
  const [serviceAgreementForm, setServiceAgreementForm] = useState(
    getServiceAgreementForm()
  );
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [previousServiceAgreement, setpreviousServiceAgreement] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [saHeading, setSAHeading] = useState(ServiceAgreementProperties.addSa);
  const [saId, setSAId] = useState(0);
  const [asyncDataState, setAsyncDataState] = useState({
    productCategory: false,
    type_id: false,
  });

  useEffect(() => {
    let srId = 0;
    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      srId = props.id;
      setSAHeading(ServiceAgreementProperties.viewSa);
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        srId = queryParam['id'];
        setSAHeading(ServiceAgreementProperties.viewSa);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          setSAHeading(ServiceAgreementProperties.editSa);
        } else {
          setIsView(true);
        }
      }
    }
    if (srId > 0) {
      getServiceAgreementData(srId);
      setSAId(srId);
    } else {
      serviceAgreementForm.status.isHide = true;
      getBackGroundData(true);
    }
    let permissionArray = [
      PermissionProperties.viewProductAttribute,
      PermissionProperties.viewRoleAndPermissions,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, [props.id]);

  const getServiceAgreementData = async (userId) => {
    setIsLoading(true);
    await getServiceAgrementbyId(userId)
      .then((result) => {
        if (result.status === 200) {
          let initData = {};
          setSAHeading(result.data.name);
          for (let key in serviceAgreementForm) {
            let value = result.data[key];

            if (key === 'productCategory') {
              serviceAgreementForm[key].inputProps.initvalue =
                result.data['product_category'].id;
              initData[key] = result.data['product_category'].id;
            } else if (key === 'type_id') {
              serviceAgreementForm[key].inputProps.initvalue = value;
              initData[key] = value;
            } else {
              initData[key] = value;
              serviceAgreementForm[key].inputProps.value = value;
            }
          }
          setInitialData(initData);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: ServiceAgreementProperties.messages.error.loadData,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: ServiceAgreementProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    getBackGroundData(true);
  };

  const getBackGroundData = (isFirstTime) => {
    getProductCategoriesByStatus();
    getSubCategoriesForWorkflow(isFirstTime);
  };

  const getSubCategoriesForWorkflow = (isFirstTime) => {
    setIsLoading(true);
    getCategoryByWorkflowType('SERVICE_AGREEMENTS')
      .then((result) => {
        if (result.status === 200) {
          let formOptions = [];
          result.data.map((form) =>
            formOptions.push({ value: form.id, displayValue: form.name })
          );
          let defaultValue = setDataToServiceRequestForm(
            'type_id',
            formOptions,
            isFirstTime
          );
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.workflowSubCategories
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.workflowSubCategories
        );
      });
  };

  const onChangeHandeler = (event, elId) => {
    let formData = { ...serviceAgreementForm };
    let elementData = formData[elId];
    let inputProps = { ...elementData.inputProps };
    elementData.inputProps = inputProps;
    inputProps.value = event.target.value;
    formData[elId] = elementData;

    // if (elId === 'productCategory') {
    //   getActiveBrandByCategories(event.target.value);
    // }
    // if (elId === 'productBrand') {
    //   getActiveModelByBrands(event.target.value);
    // }
  };

  const setDefaultData = () => {
    let saForm = { ...serviceAgreementForm };
    for (let key in saForm) {
      if (key !== 'productCategory') {
        let elementData = saForm[key]; // get data for form key
        let inputProps = { ...elementData.inputProps }; //  copy input data
        inputProps.value = inputProps.initvalue;
        elementData.inputProps = inputProps;
        saForm[key] = elementData;
      }
    }
    setServiceAgreementForm(saForm);
  };

  const getProductCategoriesByStatus = () => {
    setIsLoading(true);

    getActiveCategoryList()
      .then((result) => {
        asyncDataState.productCategory = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map((category) =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name,
            })
          );
          let defaultValue = setDataToServiceRequestForm(
            'productCategory',
            categoryOptions,
            true
          );
          // if (defaultValue !== '') {
          //   getActiveBrandByCategories(defaultValue, true);
          // } else {
          // }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : ServiceAgreementProperties.messages.error.loadProductCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ServiceAgreementProperties.messages.error.loadProductCategories,
        });
      });
  };

  // const getActiveBrandByCategories = (catId, isFirstTime) => {
  //   setIsLoading(true);
  //   getActiveBrandList(catId)
  //     .then((result) => {
  //       asyncDataState.productBrand = true;
  //       if (result.status === 200) {
  //         let brandOptions = [];
  //         result.data.map((brand) =>
  //           brandOptions.push({ value: brand.id, displayValue: brand.name })
  //         );
  //         let defaultValue = setDataToServiceRequestForm(
  //           'productBrand',
  //           brandOptions,
  //           isFirstTime
  //         );
  //         if (defaultValue !== '') {
  //           getActiveModelByBrands(defaultValue, isFirstTime);
  //         } else {
  //           // no brands for the seleted category
  //           getActiveModelByBrands(0, isFirstTime);
  //           asyncDataState.productModelId = false;
  //         }
  //       } else {
  //         setIsLoading(false);
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: result.data
  //             ? result.data.message
  //             : ServiceAgreementProperties.messages.error.loadProductBrands,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: ServiceAgreementProperties.messages.error.loadProductBrands,
  //       });
  //     });
  // };
  // const getActiveModelByBrands = (brandId, isFirstTime) => {
  //   setIsLoading(true);
  //   getActiveModelList(brandId)
  //     .then((result) => {
  //       asyncDataState.productBrand = true;
  //       if (result.status === 200) {
  //         let modelOptions = [];
  //         result.data.map((model) =>
  //           modelOptions.push({ value: model.id, displayValue: model.name })
  //         );
  //         setDataToServiceRequestForm(
  //           'productModel',
  //           modelOptions,
  //           isFirstTime
  //         );
  //       } else {
  //         notyDefault({
  //           type: notyTypes.ERROR,
  //           text: result.data
  //             ? result.data.message
  //             : ServiceAgreementProperties.messages.error.loadProductModels,
  //         });
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       notyDefault({
  //         type: notyTypes.ERROR,
  //         text: ServiceAgreementProperties.messages.error.loadProductModels,
  //       });
  //     });
  // };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToServiceRequestForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = serviceAgreementForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    serviceAgreementForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    checkForStateUpdate();
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
        return false;
      }
    }
    if (status) {
      let formData = { ...serviceAgreementForm };
      setServiceAgreementForm(formData);
    }
  };

  const success = (result) => {
    setIsLoading(false);
    notyDefault({
      type: notyTypes.SUCCESS,
      text: ServiceAgreementProperties.messages.success.saveData,
    });
    if (saId === 0) {
      setDefaultData();
    }
  };

  const fails = (result) => {
    setIsLoading(false);
    notyDefault({
      type: notyTypes.ERROR,
      text: ServiceAgreementProperties.messages.error.saveData,
    });
  };

  const onProductSubmit = async (values) => {
    let data = { ...values };

    for (let key in serviceAgreementForm) {
      if (key === 'productCategory') {
        data['product_category'] =
          serviceAgreementForm['productCategory'].inputProps.value;
      } else {
        data[key] = serviceAgreementForm[key].inputProps.value;
      }
    }

    data['id'] = saId;
    setIsLoading(true);
    await postServiceAgreements(data, success, fails);
    props.history.push(RouterProps.mainDomain + '/settings/serviceAgreements');
  };

  let formElementArray = [];
  for (let key in serviceAgreementForm) {
    formElementArray.push({
      id: key,
      config: serviceAgreementForm[key],
    });
  }

  return (
    <GridContainer>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridItem xs={12} sm={12} md={10}>
          <Card>
            <div className="generic-form-container">
              <div className={'generic-form-header-wrapper'}>
                <div className={'generic-page-title'}>
                  <Icon style={{ fontSize: '30px', marginBottom: '-2px' }}>
                    text_snippet
                  </Icon>
                  <Typography
                    className={'generic-header-text'}
                    variant="h6"
                    style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                  >
                    {saHeading}
                  </Typography>
                </div>
              </div>

              <Form
                onSubmit={onProductSubmit}
                initialValues={initialData}
                validate={(values, ss) => {
                  const errors = {};
                  if (!values.name) {
                    // setEror("first_name", true)
                    errors.name = GeneralProperties.emptyField;
                  }
                  if (!values.price) {
                    // setEror("first_name", true)
                    errors.price = GeneralProperties.emptyField;
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  reset,
                  submitting,
                  pristine,
                  valid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          if (!element.config.isHide) {
                            let mdVal = 12;
                            if (!element.config.isFullWidth) {
                              mdVal = 12;
                            }
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <Field name={element.id}>
                                  {({ input, meta, options, value }) => (
                                    <div style={{ position: 'relative' }}>
                                      <CustomInput
                                        labelText={element.config.label}
                                        id={element.id}
                                        inputProps={{
                                          ...input,
                                          ...element.config.inputProps,
                                          readOnly: isView ? true : false,
                                        }}
                                        type={element.config.elType}
                                        formControlProps={
                                          element.config.formControlProps
                                        }
                                        changed={(event, value) => {
                                          input.onChange(event);
                                          onChangeHandeler(event, element.id);
                                        }}
                                        labelProps={{
                                          ...element.config.labelProps,
                                          error: meta.error && meta.touched,
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className={styles.formError}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      <Button
                        type="submit"
                        disabled={submitting || !valid || pristine}
                        color="primary"
                        autoFocus
                      >
                        {GeneralProperties.save}
                      </Button>
                    </CardFooter>
                  </form>
                )}
              />
            </div>
          </Card>
        </GridItem>
      </LoadingOverlay>
    </GridContainer>
  );
};

export default ServiceAgreement;
