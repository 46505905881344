import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import LoadingOverlay from 'react-loading-overlay';
import GeneralProperties from 'Util/Property/GeneralProperties';
import Users from './Users';
import CustomerUsers from './CustomerUsers';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));
const UserTabs = (props) => {
  const { history, match, location } = props;
  const { search } = location;
  const classes = useStyles();

  let query = new URLSearchParams(search).get('to');
  if (!query) {
    query = 'formConfig';
  }
  const [isLoading, setIsLoading] = useState(false);
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    let tab = localStorage.getItem('userListTab');
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  });

  const handleChange = (event, newValue) => {
    localStorage.setItem('userListTab', newValue);
    setValue(newValue);
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text={GeneralProperties.pleaseWait}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <AppBar position="static" color="inherit">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    className={classes.tabsWrapper}
                  >
                    <Tab
                      label={GeneralProperties.userMangement}
                      {...a11yProps(0)}
                    />
                    {/* <Tab
                      label={GeneralProperties.customerUserMangement}
                      {...a11yProps(1)}
                    /> */}
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Users {...props} history={history} match={match} />
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <CustomerUsers {...props} history={history} match={match} />
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default UserTabs;
