import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Style/Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HourglassEmptyIcon from '../../assets/img/highlights/HourglassEmptyIcon.png';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SkeletonTypeOne from './Components/SkeletonTypeOne';
import CancelIcon from '@material-ui/icons/Cancel';
import CommuteIcon from '@material-ui/icons/Commute';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(styles);

export default function LastUpdate(props) {
  const { refresh, lastUpdate } = props;
  return (
    <Grid
      container
      justifyContent="space-between"
      alignContent=" flex-end"
      alignItems="center"
      style={{
        flexWrap: 'nowrap',
      }}
    >
      <Typography
        noWrap
        style={{
          marginTop: '0',
          paddingBottom: '5px',
          marginRight: '0',
          fontSize: '0.6rem',
          fontWeight: 400,
          textAlign: 'right',
          alignSelf: 'flex-end',
        }}
      >
        Last Update - {lastUpdate}
      </Typography>
      <Grid item xs={1} style={{ alignSelf: 'flex-end' }}>
        <IconButton
          onClick={refresh}
          color="primary"
          aria-label="upload picture"
          component="span"
          style={{
            height: '2rem',
            textAlign: 'right',
            marginTop: '0.3rem',
            marginRight: '-0.8rem',
          }}
        >
          <ReplayIcon style={{ color: '#00B838', fontSize: '1rem' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
  //   return (
  //     <div style={{ height: '3rem', display: 'inline' }}>
  //       <Grid container>
  //         <Grid item xs={11}>
  //           <p
  //             style={{
  //               fontSize: '0.6rem',
  //               fontWeight: 400,
  //               maxWidth: '100%',
  //               overflow: 'ellipsis',
  //             }}
  //           >
  //             Last Update - {lastUpdate}
  //           </p>
  //         </Grid>
  //         <Grid item xs={1}>
  //           <Grid container justifyContent="flex-end" alignContent="flex-end">
  //   <IconButton
  //     onClick={refresh}
  //     color="primary"
  //     aria-label="upload picture"
  //     component="span"
  //     style={{
  //       height: '2rem',
  //       textAlign: 'right',
  //       marginTop: '0.3rem',
  //       marginRight: '-0.8rem',
  //     }}
  //   >
  //     <ReplayIcon style={{ color: '#00B838', fontSize: '1rem' }} />
  //   </IconButton>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   );
}
