import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import styles from './ExpenseCategoryForm.module.css';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import axios from '../../../../../axios/axios-default';
import LoadingOverlay from 'react-loading-overlay';
import {
  notyDefault,
  notyTypes,
} from '../../../../../components/Noty/NotyCustom';
import generalProperties from 'Util/Property/GeneralProperties';
import ClaimsSettingsProperty from 'Util/Property/ClaimsSettingsProperty';
import InputLabel from '@material-ui/core/InputLabel';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { PermContactCalendarRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import PermissionProperties from '../../../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../../../Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';

class ExpenseCategoryForm extends Component {
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  state = {
    expenseCategoryForm: { ...ClaimsSettingsProperty.expenseCategoryForm },
    id: null,
    formIsValid: false,
    isLoading: true,
    permissions: {},
  };

  checkValidity(value, rules) {
    let returnObj = {
      isValid: true,
      errormessage: '',
    };
    if (typeof rules == 'undefined') {
      return returnObj;
    }

    if (rules.required) {
      if (!value) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.emptyField;
      }
    }
    return returnObj;
  }

  onChangeHandler = (event, elId) => {
    let formData = { ...this.state.expenseCategoryForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elId === 'name') {
      let validityObj = this.checkValidity(
        event.target.value,
        elementData.validations
      );
      formData[elId].inputProps.value = event.target.value;
      elementData.valid = validityObj.isValid;
      if (typeof elementData.labelProps !== 'undefined') {
        elementData.labelProps.error = !elementData.valid;
        elementData.labelProps.errormessage = validityObj.errormessage;
        elementData.touched = true;
      }
    } else {
      formData[elId].inputProps.value = !formData[elId].inputProps.value;
    }
    let isFormValid = true;
    for (let key in this.state.expenseCategoryForm) {
      isFormValid = this.state.expenseCategoryForm[key].valid && isFormValid;
    }
    this.setState({
      expenseCategoryForm: formData,
      formIsValid: isFormValid,
    });
  };

  saveDetails = () => {
    const { expenseCategoryForm, id } = this.state;
    const { createExpenseCategory, updateExpenseCategory } = this.props;
    const data = {
      name: expenseCategoryForm['name'].inputProps.value,
      is_active: expenseCategoryForm['is_active'].inputProps.value,
    };
    if (id) {
      updateExpenseCategory(data, id);
    } else {
      createExpenseCategory(data);
    }
  };

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { id, type } = params;
    if (id) {
      this.props.geExpenseCategoryById(id);
    } else {
      const { expenseCategoryForm } = this.state;
      expenseCategoryForm['name'].inputProps.value = '';
      expenseCategoryForm['is_active'].inputProps.value = true;
      expenseCategoryForm['is_active'].touched = false;
      expenseCategoryForm['is_active'].valid = true;
      expenseCategoryForm['name'].touched = false;
      expenseCategoryForm['name'].valid = false;
      this.setState({
        expenseCategoryForm: expenseCategoryForm,
        id: null,
      });
    }
    if (type === ClaimsSettingsProperty.actionType.edit) {
      this.setState({
        formIsValid: true,
      });
    }
    let permissionArray = [
      PermissionProperties.addExpenseCategories,
      PermissionProperties.viewExpenseCategories,
      PermissionProperties.deleteExpenseCategories,
      PermissionProperties.editExpenseCategories,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      claimExpenseCategoryObject,
      createInProgress,
      history,
      createFailed,
    } = this.props;
    if (
      prevProps.claimExpenseCategoryObject !== claimExpenseCategoryObject &&
      claimExpenseCategoryObject !== {}
    ) {
      const { expenseCategoryForm } = this.state;
      expenseCategoryForm['name'].inputProps.value =
        claimExpenseCategoryObject.name || '';
      expenseCategoryForm['is_active'].inputProps.value =
        claimExpenseCategoryObject.is_active;
      expenseCategoryForm['is_active'].touched = false;
      expenseCategoryForm['is_active'].valid = true;
      expenseCategoryForm['name'].touched = false;
      expenseCategoryForm['name'].valid = true;
      this.setState({
        expenseCategoryForm: expenseCategoryForm,
        id: claimExpenseCategoryObject.id,
      });
    }
    if (!createInProgress && prevProps.createInProgress !== createInProgress) {
      if (createFailed) {
        notyDefault({
          type: notyTypes.ERROR,
          text: ClaimsSettingsProperty.messages.error.saveData,
        });
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: ClaimsSettingsProperty.messages.success.saveData,
        });
      }
      history.push('/trackable/settings/claimsSettings?to=expense');
    }
  }

  render() {
    const { match } = this.props;
    const { params } = match;
    const { type = '', id } = params || {};
    let titleText = ClaimsSettingsProperty.actionText.add;
    if (type) {
      titleText =
        type === ClaimsSettingsProperty.actionType.view
          ? ClaimsSettingsProperty.actionText.view
          : ClaimsSettingsProperty.actionText.edit;
    }
    let formElementArray = [];
    for (let key in this.state.expenseCategoryForm) {
      formElementArray.push({
        id: key,
        config: this.state.expenseCategoryForm[key],
      });
    }
    let submitButton = (
      <Button
        disabled={!this.state.formIsValid}
        onClick={this.saveDetails}
        type="submit"
        color="primary"
      >
        Save
      </Button>
    );
    if (type === ClaimsSettingsProperty.actionType.view) {
      submitButton = null;
    }
    if (
      id &&
      !this.state.permissions[PermissionProperties.editExpenseCategories]
    ) {
      submitButton = null;
    }
    if (
      !id &&
      !this.state.permissions[PermissionProperties.addExpenseCategories]
    ) {
      submitButton = null;
    }

    return (
      <div>
        <LoadingOverlay active={false} spinner={<Spinner />} text="Loading ...">
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <div className="generic-form-container">
                  <CardBody>
                    <div className={`material-grid-wrapper`}>
                      <GridContainer>
                        <Grid spacing={3} md={6}>
                          <span className={'grid-title-icon'}>
                            <PermContactCalendarRounded />
                          </span>
                          <Typography
                            variant="h6"
                            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                          >
                            Claim Expense Category Management
                          </Typography>
                          {/* <Typography variant="body1">{"Please add new expense category."}</Typography> */}
                        </Grid>
                      </GridContainer>
                    </div>
                    <GridContainer>
                      {formElementArray.map((element) => {
                        if (!element.config.isHide) {
                          let mdVal = 12;
                          if (!element.config.isFullWidth) {
                            mdVal = 6;
                          }
                          return (
                            <GridItem
                              key={element.id}
                              xs={12}
                              sm={12}
                              md={mdVal}
                            >
                              <CustomInput
                                labelText={element.config.label}
                                id={element.id}
                                formControlProps={
                                  element.config.formControlProps
                                }
                                inputProps={{
                                  ...element.config.inputProps,
                                  readOnly:
                                    type ===
                                    ClaimsSettingsProperty.actionType.view,
                                }}
                                type={element.config.elType}
                                value={element.config.value}
                                changed={(event) =>
                                  this.onChangeHandler(event, element.id)
                                }
                                error={
                                  !element.config.valid &&
                                  element.config.touched
                                }
                                labelProps={element.config.labelProps}
                                gridStyle={element.gridStyle}
                              />
                            </GridItem>
                          );
                        }
                      })}
                    </GridContainer>
                  </CardBody>
                  <CardFooter>{submitButton}</CardFooter>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { claimExpenseSettings } = state;
  const {
    claimExpenseCategoryObject,
    createExpenseCategory,
  } = claimExpenseSettings;
  return {
    claimExpenseCategoryObject: claimExpenseCategoryObject.data,
    claimExpenseCategoryObjectLoading: claimExpenseCategoryObject.isFetching,
    createInProgress: createExpenseCategory.inProgress,
    createFailed: createExpenseCategory.error,
  };
};

const mapDispatchToProps = (dispath) => {
  return {
    geExpenseCategoryById: (id) => dispath(actions.geExpenseCategoryById(id)),
    createExpenseCategory: (data) =>
      dispath(actions.createExpenseCategory(data)),
    updateExpenseCategory: (data, id) =>
      dispath(actions.updateExpenseCategory(data, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseCategoryForm);
