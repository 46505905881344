import * as _ from 'lodash';
import dot from 'dot-object'
import React from "react";
export const currencyFormatter = (
    value,
    format = 'en-US',
    currency = 'LKR',
) => {
    const formatter = new Intl.NumberFormat(format, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    });
    return _.isFinite(value) ? formatter.format(value) : '';
};
export const isCustomerUser = () => {
    const loggedInUserCustomerId = localStorage.getItem("userCustomerId");
    return loggedInUserCustomerId !== 'undefined' && loggedInUserCustomerId !== 'null'
}
export const isWareHouseUrl = (location) => {
    return location.pathname.includes("/warehouse");
}
export const buildFilterObject = (query = {}) => {
  const { filters = [] } = query || {};
  const searchDto = {};
  filters.forEach(({ column, value = "" }) => {
    if (column.isNumericFilter) {
        if (isNaN(parseFloat(value))) {
            value = "";
        } else {
            value = parseFloat(value)
        }
    }
    if (value) {
      if (column.field === "is_active") {
        if (value.toLowerCase() === "active") {
          searchDto[column.field] = true;
        } else if(value.toLowerCase() === "inactive") {
          searchDto[column.field] = false;
        }
      } else {
          if(column.filterField){
              searchDto[column.filterField] = value;
          } else {
              searchDto[column.field] = value;
          }
      }
    }
  });
  return dot.object(searchDto);
};
export const appendPagination = (url , query) => {
    url = url + '?';
    url += 'perPage=' + query.pageSize
    url += '&page=' + (query.page)
    url += '&sortColumn=' + (typeof query.orderBy === 'undefined' ? 'name' : query.orderBy.field)
    url += '&sortOrder=' + (query.orderDirection)
    url += '&search=' + (query.search)
    return url;
}
export const convertToHoursAndMinutes = (minutesToConvert = 0) => {
    const hours = Math.floor(minutesToConvert / 60);
    const minutes = minutesToConvert % 60;
    return <>{hours} <b>Hrs</b> {minutes} <b>Min</b></>
}
export const getStatusChartLabel = key => {
  switch (key) {
    case "onTime":
      return "On Time";
    case "delayedNotCompleted":
      return "Not Resolved (Delayed)";
    case "delayedCompleted":
      return "Resolved (Delayed)";
    default:
      return "";
  }
};