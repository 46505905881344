import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import RTLNavbarLinks from './RTLNavbarLinks.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js';
import FormatList from '@material-ui/icons/FormatListBulleted';
import MoreVert from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const [isMiniActive, setIsMiniActive] = useState(false);
  const goBackHandler = () => {
    props.history.goBack();
  };

  useEffect(() => {
    const ismini = localStorage.getItem('isMiniActive');
    if (ismini === '1') {
      setIsMiniActive(true);
    } else {
      setIsMiniActive(false);
    }
  }, []);

  const sideBarHandler = () => {
    let val = !isMiniActive;
    setIsMiniActive(val);
    localStorage.setItem('isMiniActive', val ? 1 : 0);
    props.handleSidebar(val);
  };
  const classes = useStyles();
  function makeBrand() {
    // this is the name of every component display on top of the page
    var name;
    let content = '';
    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;

        content = (
          <div className={classes.flex}>
            <Button
              onClick={sideBarHandler}
              className={'generic-button-rounded'}
            >
              {isMiniActive ? <FormatList /> : <MoreVert />}
            </Button>
            <Button color="transparent" href="#" className={classes.title}>
              {/* {name} */}
            </Button>{' '}
          </div>
        );
        if (prop.isSubLink) {
          content = (
            <React.Fragment>
              <div className={classes.flex}>
                <Button
                  onClick={sideBarHandler}
                  className={'generic-button-rounded'}
                >
                  {isMiniActive ? <FormatList /> : <MoreVert />}
                </Button>
                <Button color="transparent" href="#" className={classes.title}>
                  {/* {name} */}
                </Button>{' '}
              </div>
              {
                <div
                  className={'generic-action-panel'}
                  style={{ paddingRight: 10 }}
                >
                  <Button
                    onClick={goBackHandler}
                    className={'generic-button-outlined'}
                  >
                    Go Back
                  </Button>
                </div>
              }
            </React.Fragment>
          );
        }
      }
      return null;
    });
    return content;
  }
  const { color } = props;
  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        {makeBrand()}
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
  handleSidebar: PropTypes.func,
};
