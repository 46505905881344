import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: 14,
    },
    '& .MuiFormLabel-root': {
      fontSize: 14,
    },
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },

  select: {
    '& ul': {
      backgroundColor: '#fff',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function SelectionList(props) {
  const {
    data,
    lable,
    catchChange,
    selectedDef,
    selectedDefVal,
    isRequired,
    disabled,
  } = props;
  const classes = useStyles();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    classes: {
      paper: classes.select,
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: 'menu',
  };
  const [selected, setSelected] = useState([]);
  const isAllSelected = data.length > 0 && selected.length === data.length;

  const handleChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      setSelected(
        selected.length === data.length ? [] : data.map((option) => option.name)
      );
      return;
    }
    setSelected(value);
  };

  useEffect(() => {
    props.selectedValue(selected);
  });

  useEffect(() => {
    setSelected([]);
  }, [data]);

  useEffect(() => {
    if (catchChange != null && catchChange.length === 0) {
      setSelected([]);
    }
  }, [catchChange]);

  // const defArrTest = ["En Route", "Supervisor", "Sales Head"];

  useEffect(() => {
    if (typeof selectedDef !== 'undefined') {
      if (selectedDef.length > 0) {
        setSelected(selectedDef);
      }
    }
  }, [selectedDef]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select-label">{lable}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        required={isRequired}
        disabled={disabled}
      >
        {data.length > 0 ? (
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < data.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
        ) : (
          <div></div>
        )}

        {data.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option.name) > -1} />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
