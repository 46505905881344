import React, { useState, useEffect, useRef } from 'react';
// @material-ui/core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import LoadingOverlay from 'react-loading-overlay';
import { handleErrorMessage } from 'services/CommonService';
import {
  workflowProperties,
  getCommonStageForm,
} from 'Util/Property/WorkflowProperties';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import CardBody from 'components/Card/CardBody.js';
import generalProperties from 'Util/Property/GeneralProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import {
  getWorkflowTypes,
  getCategoryByWorkflowType,
} from 'services/Workflow/WorkflowService';
import {
  getStageById,
  createStage,
  updateStage,
} from 'services/stage/StageService';

import Spinner from 'components/Spinner/Spinner.js';

const Stage = (props) => {
  const [stageForm, setStageForm] = useState(getCommonStageForm());
  const [stageId, setStageId] = useState(0);
  const [stageHeading, setStageHeading] = useState('Add Stage');
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });
  const [isLoading, setIsLoadingIcon] = useState(false);

  useEffect(() => {
    let stgid = 0;
    if (props.id && props.id > 0) {
      stgid = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        stgid = queryParam['id'];
        if (queryParam['isEdit'] === 'true') {
          setStageHeading('Edit Stage');
          setIsEdit(true);
        } else {
          setStageHeading('View Stage');
          setIsView(true);
        }
      }
    }
    if (stgid > 0) {
      getStageToProcess(stgid);
      setStageId(stgid);
    } else {
      getBackGroundData(true);
    }
  }, []);

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...stageForm };
      setStageForm(formData);
      let formData1 = { ...stageForm };
      setStageForm(formData1);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const getStageToProcess = async (wrflId) => {
    setIsLoading(true);
    await getStageById(wrflId)
      .then((result) => {
        if (result.status === 200) {
          for (let key in stageForm) {
            let value = result.data[key];

            if (!stageForm[key].isAsyncDropDown) {
              stageForm[key].inputProps.value = value;
            } else {
              stageForm[key].inputProps.initvalue = value;
            }
          }
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.loadStage
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.loadStage);
      });
    getBackGroundData(true);
  };

  const getBackGroundData = (isFirstTime) => {
    getFormNamesForWorkflow(isFirstTime);
  };

  const getFormNamesForWorkflow = (isFirstTime) => {
    setIsLoading(true);
    getWorkflowTypes()
      .then((result) => {
        if (result.status === 200) {
          let formOptions = [];
          result.data.map((form) =>
            formOptions.push({
              value: form.workflowType,
              displayValue: form.name,
            })
          );
          let defaultValue = setDataToStageForm(
            'type',
            formOptions,
            isFirstTime
          );
          getSubCategoriesForWorkflow(defaultValue, isFirstTime);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.workflowTypes
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.workflowTypes
        );
      });
  };

  const getSubCategoriesForWorkflow = (formId, isFirstTime) => {
    setIsLoading(true);
    getCategoryByWorkflowType(formId)
      .then((result) => {
        if (result.status === 200) {
          if (typeof result.data === 'undefined' || result.data.length === 0) {
          } else {
            let formOptions = [];
            result.data.map((form) =>
              formOptions.push({ value: form.id, displayValue: form.name })
            );
            let defaultValue = setDataToStageForm(
              'sub_type_id',
              formOptions,
              isFirstTime
            );
          }
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.workflowSubCategories
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.workflowSubCategories
        );
      });
  };

  const setDataToStageForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = stageForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    stageForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    //checkForStateUpdate(isFirstTime);
    return defaultValue;
  };

  let formElementArray = [];
  for (let key in stageForm) {
    formElementArray.push({
      id: key,
      config: stageForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;
    let formData = { ...stageForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elId === 'type') {
      getSubCategoriesForWorkflow(value);
    }
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setStageForm(formData);
  };

  const onStageSubmit = async (event) => {
    let stageData = { ...stageForm };
    let data = {};
    for (let key in stageData) {
      data[key] = stageData[key].inputProps.value;
    }

    let method = createStage;
    data['is_active'] = true;
    if (isEdit) {
      method = updateStage;
    }
    data['id'] = stageId;
    await method(data)
      .then((result) => {
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: 'Successfully saved data',
          });
          props.history.goBack();
          // if (serviceRequestId == 0) {
          //     setDefaultData();
          // }
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.stageSave
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.stageSave);
      });
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Form
                onSubmit={onStageSubmit}
                validate={(values, ss) => {
                  const errors = {};
                  if (stageForm.stage_name.inputProps.value === '') {
                    // setEror("first_name", true)
                    errors.name = generalProperties.emptyField;
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  reset,
                  submitting,
                  pristine,
                  valid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          if (!element.config.isHide) {
                            let mdVal = element.config.size;
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <Field name={element.id}>
                                  {({ input, meta, options, value }) => (
                                    <div style={{ position: 'relative' }}>
                                      <CustomInput
                                        labelText={element.config.label}
                                        id={element.id}
                                        inputProps={{
                                          ...input,
                                          ...element.config.inputProps,
                                          readOnly:
                                            isView || element.config.isDisabled
                                              ? true
                                              : false,
                                        }}
                                        type={
                                          element.id === 'userIdList' && isView
                                            ? 'input'
                                            : element.config.elType
                                        }
                                        formControlProps={
                                          element.config.formControlProps
                                        }
                                        adornmentText={
                                          element.config.adornmentText
                                        }
                                        adornmentPosition={
                                          element.config.adornmentPosition
                                        }
                                        multiple={element.config.multiple}
                                        changed={(event, value) => {
                                          input.onChange(event);
                                          onChangeHandeler(event, element.id);
                                        }}
                                        labelProps={{
                                          ...element.config.labelProps,
                                          error: meta.error && meta.touched,
                                        }}
                                      />
                                      {meta.error && meta.touched && (
                                        <span>{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      {!isView ? (
                        <Button
                          type="submit"
                          disabled={submitting || !valid}
                          color="primary"
                          autoFocus
                        >
                          Save
                        </Button>
                      ) : (
                        ''
                      )}
                    </CardFooter>
                  </form>
                )}
              />
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};
export default Stage;
