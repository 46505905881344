import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../Style/Style"
import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'
const useStyles = makeStyles(styles);
export default function HighlightsTypeOne(props) {

    const { colorCode, iconName, title, subTitle } = props;
    const classes = useStyles();
    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <div style={{ backgroundColor: '#fff', width: "3rem", height: "3rem" }}>
                            <Skeleton variant="rectangular" style={{ width: "3rem", height: "3rem" }} />
                        </div>
                    </Grid>
                    <Grid item xs={8} className={classes.topRight}>
                        {/* <Typography className={classes.title} >{title}</Typography> */}
                        <Skeleton variant="text" className={classes.title1} />
                        {/* <Skeleton variant="text" className={classes.title1} /> */}
                    </Grid>
                    <Skeleton variant="rectangular" className={classes.title1} style={{ width: '2rem', marginLeft: '14rem' }} />
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} style={{ marginTop: "2rem" }}>
                        <Skeleton variant="text" className={classes.title1} />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
