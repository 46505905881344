import * as ActionTypes from '../../store/actions/reportActionTypes';

const initialState = {
    reportFilterData: {
        data: [],
        isFetching: false,
        error: null
    },

};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_REPORT_DATA_START:
            return {
                ...state,
                reportFilterData: {
                    ...state.reportFilterData,
                    isFetching: true,
                    error: null
                }
            };
        case ActionTypes.GET_REPORT_DATA_SUCCESS:
            return {
                ...state,
                reportFilterData: {
                    ...state.reportFilterData,
                    isFetching: false,
                    error: null,
                    data: {...state.reportFilterData.data, ...action.payload}
                }
            };
        case ActionTypes.GET_REPORT_DATA_ERROR:
            return {
                ...state,
                reportFilterData: {
                    ...state.reportFilterData,
                    isFetching: false,
                    error: action.payload,
                }
            };
        default:
            return {
                ...state
            };
    }
};
