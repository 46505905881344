import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Style/Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SkeletonTypeFIve from './Components/SkeletonTypeFourMeter';
import SkeletonTypeOne from './Components/SkeletonTypeOne';
import BuildIcon from '@material-ui/icons/Build';
import IconSelector from 'components/IconSelector/IconSelector';
import Gaugechart from 'components/GaugeChart/Gaugechart';
import Gaugechart2 from 'components/GaugeChart/Gaugechart2';
import { ResponsiveContainer } from 'recharts';
import LinearProgressBar from '@material-ui/core/LinearProgress';
import axios from '../../axios/axios-default';
import LastUpdate from './LastUpdate';

export default function HighlightsTypeFourMeter(props) {
  const {
    loading,
    title,
    data,
    colorCode,
    iconName,
    icon,
    tileColor,
    dataRow,
  } = props;
  const color1 = makeStyles({
    root1: {
      marginBottom: '0.5rem',
      '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        //   backgroundColor: '#fa3916',
        backgroundColor: tileColor,
      },
      '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
          'radial-gradient(' + '#E1E1E1' + ' 0%, #f6ce95 16%, transparent 42%)',
      },
    },
    root2: {
      marginBottom: '0.5rem',
      '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        // backgroundColor: '#d00048',
        backgroundColor: tileColor,
      },
      '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
          'radial-gradient(' + '#E1E1E1' + ' 0%, #f6ce95 16%, transparent 42%)',
      },
    },
    root3: {
      marginBottom: '0.5rem',
      '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        // backgroundColor: '#ffa900',
        backgroundColor: tileColor,
      },
      '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
          'radial-gradient(' + '#E1E1E1' + ' 0%, #f6ce95 16%, transparent 42%)',
      },
    },
  });

  const color3 = makeStyles({
    root: {
      marginBottom: '0.5rem',
      '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#E1E1E1',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        // backgroundColor: '#fa3916',
        backgroundColor: tileColor,
      },
      '& .MuiLinearProgress-dashedColorPrimary': {
        backgroundImage:
          'radial-gradient(' + '#E1E1E1' + ' 0%, #f6ce95 16%, transparent 42%)',
      },
    },
  });

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const classesColor = color1();

  const getValue = (val) => {
    var value = 0;
    if (val < 10) {
      return '0' + val;
    } else {
      return val;
    }
  };

  const handleIconImage = (iconImage) => {
    // console.log("Image Icon Name",iconImage);
  };

  useEffect(() => {
    getDataForTiles(dataRow);
  }, [dataRow]);

  const getDataForTiles = async (config) => {
    let apiString = '';
    let newDataArray = [];
    if (config !== null) {
      apiString = 'Type4API';
      // apiString = 'getJobCountHighlight?highlightId='+config.id;

      await axios
        .get(apiString)
        .then((result) => {
          if (result.status == '200') {
            // console.log("AAAAAAAAAAAAAAAA____________++++++++++++++++++++############",result.data)
            // const DataForArraymrg = {...tileConf,"newData":result.data};
            // newDataArray.push(DataForArraymrg);
            // setData();
          } else {
          }
        })
        .catch((error) => {
          // notyDefault({ type: notyTypes.ERROR, text: 'Error Load Data Values For Tile' });
        });
    }
  };

  return (
    <div>
      {loading ? (
        <SkeletonTypeOne
          title={title}
          colorCode={colorCode}
          iconName={iconName}
        />
      ) : (
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={4} style={{ marginTop: '-0.5rem' }}>
              <div
                className={classes.imgBox}
                style={{ backgroundColor: colorCode }}
              >
                <IconSelector
                  IconImage={handleIconImage}
                  hasIconImage={icon}
                  TileColor={tileColor}
                />

                {/* </div> */}
              </div>
              {/* <div className={classes.imgBox} style={{ marginTop:'0.6rem' }}>
                                <Typography  style={{fontSize:'18px', fontWeight:'bold', color:tileColor, marginLeft:'0.5rem', }}>KPI</Typography>
                                <Typography  style={{fontSize:'15px', fontWeight:'bold', color:tileColor, marginLeft:'0.3rem',}}>75 </Typography>
                                </div> */}
            </Grid>

            <Grid item xs={8} xl={2} className={classes.topRight}>
              <Typography noWrap className={classes.title}>
                {title.toUpperCase()}{' '}
              </Typography>

              <div className={classes.desView3}>
                <Grid container style={{ marginTop: '0.3rem' }}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.title3}
                      style={{ textAlign: 'right' }}
                    >
                      Inquiries ( 80% )
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classesColor.root1}>
                      <LinearProgressBar
                        style={{ height: '0.3rem', borderRadius: '0.3rem' }}
                        variant="determinate"
                        value={80}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: '0.3rem' }}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.title3}
                      style={{ textAlign: 'right' }}
                    >
                      Visit Completed ( 60% )
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classesColor.root2}>
                      <LinearProgressBar
                        style={{ height: '0.3rem', borderRadius: '0.3rem' }}
                        variant="determinate"
                        value={60}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container style={{ marginTop: '0.3rem' }}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.title3}
                      style={{ textAlign: 'right' }}
                    >
                      Invoiced ( 40% )
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classesColor.root3}>
                      <LinearProgressBar
                        style={{ height: '0.3rem', borderRadius: '0.3rem' }}
                        variant="determinate"
                        value={40}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>

          <LastUpdate />
        </Paper>
      )}
    </div>
  );
}
