import React, { useState, useEffect, useRef } from "react";
import { IconPicker } from 'react-fa-icon-picker'

export default (props) => {
  const { TileColor, hasIconImage, Icon } = props;

  const [iconName, setIconName] = useState(props.Icon)


  useEffect(() => {
    hasIconImage ? setIconName(hasIconImage) : setIconName("FaDochub")

  }, []);

  useEffect(() => {
    // console.log("VALUEEEEE", iconName);
    props.IconImage(iconName);

  }, [iconName])

  useEffect(() => {
    if (props.loc === "tileConfig") {
      setIconName(props.Icon);
    }

  }, [props.Icon])


  return (
    <IconPicker value={iconName} onChange={(v) => setIconName(v)} buttonIconStyles={{ color: '#fff', size: 55 }} buttonStyles={{ borderBlockColor: `${TileColor}`, borderColor: `${TileColor}`, backgroundColor: `${TileColor}`, height: '2rem', width: "3.5rem" }} />
  )
}