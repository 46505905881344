
const RoleAndPermission = {
    roles: "Roles",
    permissions: "Action Permissions",
    dataPermissions: "Data Permissions",
    editRole : "Edit Role",
    addRole : "Add Role",
    deleteRole : "Delete Role",
    messages: {
        error: {
          loadData: "Could not load Role data. Please try again",
          saveData : "Could not save Role data. Please try again",
          deleteRole : "Could not delete Role. Please try again",
          alreadyMapped: "Could not delete Roles which mapped to users",
          loadPermissionData : "Could not load permission data",
          nameTaken: "Role Name is already used",
          permissionSaved:"Could not save permission data"
        },
        success:{
          saveData : "Role details saved successfully",
          deleteRole : "Role deleted successfully",
          permissionSaved:"Permission saved successfully"
        },
        normal: {
          deleteRole : "Do you want to delete this Role?"
        }
    
      }
}


export default RoleAndPermission;