export const workflowProperties = {
  trainingAndDevelopment: 'Workflow',
  viewWorkflow: 'View Workflow',
  createWorkflow: 'Create Workflow',
  messages: {
    error: {
      loadData: 'Could not load  Workflow please try again!',
      save: 'Could not save Workflow. please try again!',
      delete: 'Could not delete Workflow please try again!',
      loadAll: 'Could not load Workflowa please try again!',
      workflowTypes: 'Could not load workflow types!',
      workflowSubCategories: 'Could not load workflow sub categories!',
      stages: 'Could not load workflow stages!',
      status: 'Could not load workflow status!',
      loadStages: 'Could not load stages!',
      deleteStage: 'Could not delete Stage please try again!',
      loadStage: 'Could not load stage please try again!',
      stageSave: 'Could not save stage data please try again!',
    },
    success: {
      save: 'Workflow saved successfully!',
      delete: 'Workflow deleted successfully!',
      deleteStage: 'Stage deleted successfully!',
    },
    normal: {
      deleteConfirm: 'Do you want to delete this Workflow?',
    },
  },
};

const stageArray = [
  { value: 'New', displayValue: 'New' },
  { value: 'Assigned', displayValue: 'Assigned' },
  { value: 'Travelling', displayValue: 'Travelling' },
  { value: 'On-Hold', displayValue: 'On Hold' },
  { value: 'Need-Further-Attention', displayValue: 'Need Further Attention' },
  { value: 'Need-workshop-attention', displayValue: 'Need workshop attention' },
];

const statusArray = [
  { value: 'New', displayValue: 'New' },
  { value: 'Assigned', displayValue: 'Assigned' },
  { value: 'In-Progress', displayValue: 'In Progress' },
  { value: 'On-Hold', displayValue: 'On Hold' },
  { value: 'Pending', displayValue: 'Pending' },
];

const RequestStatus = [
  { value: 'PENDING', displayValue: 'PENDING' },
  { value: 'CLOSED', displayValue: 'CLOSED' },
];

export const getWorkflowForm = () => {
  return {
    name: {
      elType: 'input',
      label: 'Name',
      inputProps: {
        type: 'text',
        placeholder: 'Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 12,
    },
    form_type: {
      elType: 'select',
      label: 'Workflow Type',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      isDisabled: false,
    },
    sub_type: {
      elType: 'select',
      label: 'Request Category',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      isHide: false,
      isDisabled: false,
    },
  };
};

export const getStageForm = () => {
  return {
    sequence: {
      elType: 'input',
      label: 'Sequence',
      inputProps: {
        type: 'text',
        placeholder: 'Sequence',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 12,
    },
    stage_id: {
      elType: 'select',
      label: 'Stage',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
    },
    allow_status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: [],
        value: [],
        initvalue: [],
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      multiple: true,
    },
    allow_user_roles: {
      elType: 'select',
      label: 'Roles',
      inputProps: {
        options: [],
        value: [],
        initvalue: [],
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
      multiple: true,
    },
    forwardOnly: {
      elType: 'checkbox',
      label: 'Forward Only',
      inputProps: {
        type: 'checkbox',
        placeholder: '',
        value: false,
        initvalue: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
    bypassAllowed: {
      elType: 'checkbox',
      label: 'Bypass Allowed',
      inputProps: {
        type: 'checkbox',
        placeholder: '',
        value: false,
        initvalue: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
      size: 12,
    },
  };
};

export const getCommonStageForm = () => {
  return {
    stage_name: {
      elType: 'input',
      label: 'Name',
      inputProps: {
        type: 'text',
        placeholder: 'Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      size: 12,
    },
    type: {
      elType: 'select',
      label: 'Type',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
    },
    sub_type_id: {
      elType: 'select',
      label: 'Sub Type',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
    },
  };
};
