import { OverlayView } from "@react-google-maps/api";
import React from "react";

const PopupBoxHandler = ({ postion, text, width }) => {
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  return (
    <OverlayView
      position={postion}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div
        style={{
          position: "absolute",
          bottom: 42,
          backgroundColor: "white",
          left: -(width+20)/2 || -40,
          width: width || 60,
          borderRadius:"5px",
          padding: "0px 10px",
          textAlign: "center",
          textTransform:"uppercase",
          overflowWrap:"break-word",
          fontWeight:"600",
          border:"1px solid rgba(0,0,0,0.2)"
        }}
      >
        {text}
      </div>
    </OverlayView>
  );
};

export default PopupBoxHandler;
