  const priorityCategories = [
    { value: "CRITICAL", displayValue: 'Critical' },
    { value: "HIGH", displayValue: 'High' },
    { value: "MEDIUM", displayValue: 'Medium' },
    { value: "LOW", displayValue: 'Low' }
  ]
  
  
  export const priorityConfigurationProperties = {
      priorityConfiguration : "Priority Configuration",
      addPriorityConfiguration : "Add Priority Configuration",
      priorityConfigurations : "Priority Configurations",
      viewPriorityConfiguration : "View Priority Configuration",
      editPriorityConfiguration : "Edit Priority Configuration",
      addCheckItem : "Add Check Item",
      messages: {
          error: {
            loadData: "Could not load Priority Configuration data. Please try again",
            saveData: "Could not save Priority Configuration data. May be configuration already exists. Please try again",
            delete: "Could not delete Priority Configuration. Please try again",
            loadAll: "Could not load Priority Configuration. Please try again",
            loadProductCategories : "Could not load product categories",
            loadProductBrands : "Could not load product brands",
            loadProductModels : "Could not load product models",
          },
          success: {
            saveData: "Priority Configuration details saved successfully",
            delete: "Priority Configuration deleted successfully"
          },
          normal: {
            deleteConfirm: "Do you want to delete this Priority Configuration?",
          }
      
        }
  }
  
  export const getPriorityConfiguratoinForm = ()=>{
    return {
      name: {
          elType: 'input',
          label: 'Name',
          inputProps: {
            type: 'text',
            placeholder: 'Name',
            value: '',
            initvalue: ''
          },
          formControlProps: {
            fullWidth: true
          },
          labelProps: {
            required: true,
            error:false
          },
          size: 12,
        },
        customerId: {
            elType: 'select',
            label: 'Customer',
            inputProps: {
              options: [],
              value: '',
              initvalue: '',
              enableclearable:true
            },
            formControlProps: {
              fullWidth: true
            },
            size: 12,
            isAsyncDropDown : true
          },
        serviceRequestCategoryId: {
          elType: 'select',
          label: 'Request Type',
          inputProps: {
            options: [],
            value: '',
            initvalue: '',
            enableclearable:true
          },
          formControlProps: {
            fullWidth: true
          },
          size: 12 ,
          isAsyncDropDown : true
        },
        productCategoryId: {
          elType: 'select',
          label: 'Product Category',
          inputProps: {
            options: [],
            value: '',
            initvalue: '',
            enableclearable:true
          },
          formControlProps: {
            fullWidth: true
          },
          size: 12,
          isAsyncDropDown : true
        },
        productBrandId: {
          elType: 'select',
          label: 'Product Brand',
          inputProps: {
            options: [],
            value: '',
            initvalue: '',
            enableclearable:true
          },
          formControlProps: {
            fullWidth: true
          },
          size: 12,
          isAsyncDropDown : true
        },
        productModelId: {
          elType: 'select',
          label: 'Product Model',
          inputProps: {
            options: [],
            value: '',
            initvalue: '',
            enableclearable:true
          },
          formControlProps: {
            fullWidth: true
          },
          size: 12,
          isAsyncDropDown : true
        },
        priority: {
            elType: 'select',
            label: 'Priority',
            inputProps: {
              options: priorityCategories,
              value: "CRITICAL",
              initvalue: "CRITICAL"
            },
            formControlProps: {
              fullWidth: true
            },
            size: 12 
          },
      }   
  }
