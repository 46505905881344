import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import styles from './ServiceAgreements.module.css';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import axios from 'axios/axios-default';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ServiceAgreementProperties } from 'Util/Property/ServiceAgreementProperties';
import MaterialTable from 'material-table';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { Typography } from '@material-ui/core';
import { buildFilterObject } from '../../../Util/Util';
import Spinner from 'components/Spinner/Spinner.js';

const ServiceAgreements = (props) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});

  const tableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.viewServiceAgreement,
      PermissionProperties.addServiceAgreement,
      PermissionProperties.editServiceAgreement,
      PermissionProperties.deleteServiceAgreement,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const onUserEditHandler = (id, isEdit) => {
    props.history.push({
      pathname:
        props.match.url + '/' + urlProperties.serviceAgreement.serviceAgreement,
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const deleteUserDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteUserDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    axios
      .put('deleteServiceAgreement/' + deleteRowData.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text:
              ServiceAgreementProperties.messages.success
                .deleteServiceAgreement,
          });
          tableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data.message });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text:
            ServiceAgreementProperties.messages.error.deleteServiceAgreement,
        });
      });

    deleteUserDialogCloseHandler();
  };

  const deleteUserHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteUserDialogOpenHandler();
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* {
                        permissions[PermissionProperties.addServiceAgreement] ?
                            <div className={styles.serviceAgreementDiv}>
                              
                            </div> : ""
                            
                    } */}

            <CardBody>
              {/* customer delete confirm dialogBox */}

              <Dialog
                open={isDeleteDialogOpen}
                onClose={deleteUserDialogCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {
                      ServiceAgreementProperties.messages.normal
                        .deleteServiceAgreement
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={deleteUserDialogCloseHandler}
                    color="primary"
                  >
                    {GeneralProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={deleteConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {GeneralProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              {/* customer table content */}
              <div className={'material-grid-wrapper'}>
                <MaterialTable
                  components={{
                    OverlayLoading: () => (
                      <div className={styles.loadingOverlay}>
                        <Spinner />
                      </div>
                    ),
                  }}
                  title={
                    <div>
                      <span className={'grid-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        Service Agreements
                      </Typography>
                      {/* <Typography variant="body1">Manage service agreements</Typography> */}
                    </div>
                  }
                  tableRef={tableRef}
                  columns={[
                    { title: 'Name', field: 'name' },
                    {
                      title: 'Duration',
                      field: 'duration',
                      render: (rowData) =>
                        ServiceAgreementProperties.duration_enum[
                          rowData.duration
                        ],
                      isNumericFilter: true,
                    },
                    {
                      title: 'Category',
                      field: 'product_category.name',
                    },
                    {
                      title: 'Type',
                      field: 'type_name',
                      filterField: 'type_filter',
                    },
                    {
                      title: 'Frequency',
                      field: 'Service_frequency',
                      filterField: 'frequency_filter',
                    },
                    { title: 'Price', field: 'price', isNumericFilter: true },
                    {
                      title: 'Status',
                      field: 'duration',
                      render: (rowData) =>
                        rowData.status ? 'Active' : 'Inactive',
                      filterField: 'status_filter',
                    },
                  ]}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let url =
                        urlProperties.baseUrl +
                        urlProperties.serviceAgreement
                          .filterAllServiceAgreement +
                        '?';
                      url += 'perPage=' + query.pageSize;
                      url += '&page=' + query.page;
                      url +=
                        '&sortColumn=' +
                        (typeof query.orderBy === 'undefined'
                          ? 'name'
                          : query.orderBy.field);
                      url += '&sortOrder=' + query.orderDirection;
                      url += '&search=' + query.search;
                      fetch(url, {
                        headers: {
                          Authorization:
                            'Bearer ' + localStorage.getItem('token'),
                          'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify(buildFilterObject(query)),
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          resolve({
                            data: result.data,
                            page: result.page,
                            totalCount: result.total,
                          });
                        });
                    })
                  }
                  actions={[
                    {
                      icon: 'read_more',
                      tooltip: 'View More Details',
                      iconProps: { className: 'action-buttons view-button' },
                      onClick: (event, rowData) =>
                        onUserEditHandler(rowData.id, false),
                      hidden: !permissions[
                        PermissionProperties.viewServiceAgreement
                      ],
                    },

                    {
                      icon: 'edit',
                      tooltip: 'Edit Service Agreement',
                      iconProps: { className: 'action-buttons edit-button' },
                      onClick: (event, rowData) =>
                        onUserEditHandler(rowData.id, true),
                      hidden: !permissions[
                        PermissionProperties.editServiceAgreement
                      ],
                    },

                    // rowData => ({
                    //     icon: 'delete',
                    //     tooltip: 'Delete Service Agreement',
                    //     onClick: (event, rowData) => deleteUserHandler(rowData),
                    //     iconProps: { className: 'action-buttons delete-button' },
                    //     hidden: !permissions[PermissionProperties.deleteServiceAgreement]
                    // }),
                    {
                      icon: () => (
                        <Link
                          to={
                            props.match.url +
                            '/' +
                            urlProperties.serviceAgreement.serviceAgreement
                          }
                        >
                          <div
                            className={
                              'generic-button-class div-button-wrapper'
                            }
                            variant="contained"
                            color="primary"
                          >
                            {ServiceAgreementProperties.addNewServiceAgreement}
                          </div>
                        </Link>
                      ),
                      tooltip: 'Add Service Agreement',
                      isFreeAction: true,
                      hidden: !permissions[
                        PermissionProperties.addServiceAgreement
                      ],
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: false,
                    search: false,
                    maxBodyHeight: '70vh',
                    headerStyle: {
                      fontSize: '0.7rem',
                      textAlignL: 'center',
                    },
                    rowStyle: {
                      fontSize: '0.6rem',
                      fontWeight: 450,
                      padding: '5rem',
                    },
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ServiceAgreements;
