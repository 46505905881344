import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Style/Style";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SkeletonTypeFIve from "./Components/SkeletonTypeFIve";
import SkeletonTypeOne from "./Components/SkeletonTypeOne";
import BuildIcon from "@material-ui/icons/Build";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import IconSelector from "components/IconSelector/IconSelector";
import { ResponsiveContainer } from "recharts";
import Viewmore1 from "./Viewmore/HighlightsType5ViewMore/Viewmore1";
import Viewmoreee1 from "./Viewmore/Viewmoreee1";
import axios from "../../axios/axios-default";
import { PieChart, Pie, Sector, Cell } from "recharts";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LastUpdate from "./LastUpdate";
import { LightenDarkenColor } from "lighten-darken-color";
import reportsProperties, {
  requiredFormData,
} from "Util/Property/OpeReportProperty";
import { highlight5Properties } from "Util/Property/HighlightProperties";
const useStyles = makeStyles(styles);

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius - outerRadius * 0.97) * cos;
  const sy = cy + (outerRadius - outerRadius * 0.97) * sin;
  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill="#444"
    />
  );
};

const HighlightsTypeFive = (props) => {
  const {
    loading,
    title,
    data,
    colorCode,
    iconName,
    icon,
    tileColor,
    dataRow,
  } = props;

  const INTERVAL = Number(60000 * 10);

  // const COLORS = [
  //   LightenDarkenColor(tileColor, -20),
  //   LightenDarkenColor(tileColor, 20),
  //   LightenDarkenColor(tileColor, 50),
  // ];
  const COLORS = ["#ccc", "#92d050", tileColor];

  const classes = useStyles();
  const [subTitl, setSubTtil] = useState("");
  const progVal = 66.1;
  const [openViewMore1, setOpenViewMore1] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [chartDataSet, setChartDataSet] = useState([]);
  const [chartDataTotal, setChartDataTotal] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [loadingTile, setLoadingTile] = useState(false);
  const [lastUpdate, setLastUpdate] = React.useState("");

  const [tableTitle, setTableTitle] = useState();
  const [tableSubTitle, setTableSubTitle] = useState();

  const [isDataNull, setIsDataNull] = useState(true);

  const onMouseOver = useCallback((data, index) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback((data, index) => {
    setActiveIndex(null);
  }, []);

  // const dataSet = [
  //   { name: 'Achieved', value: 30 },
  //   { name: 'Not Achieved', value: 40 },
  //   { name: 'Not Completed', value: 30 },
  // ];

  const getValue = (val) => {
    var value = 0;
    if (val < 10) {
      return "0" + val;
    } else {
      return val;
    }
  };
  const onClickhandle = (subtitl) => {
    setSubTtil(subtitl);
    setOpenViewMore1(true);
  };

  const viewMoreClose = () => {
    setOpenViewMore1(false);
  };

  const handleIconImage = (iconImage) => {
    // console.log("Image Icon Name",iconImage);
  };

  // useEffect(() => {
  //   getDataForTiles(dataRow);
  // }, [dataRow]);

  React.useEffect(() => {
    intervalFun();
  }, []);

  const intervalFun = () => {
    const interval = setInterval(function() {
      if ("/trackable/dashboard" != window.location.pathname) {
        clearInterval(interval);
      } else {
        getDataForTiles(dataRow);
      }
    }, INTERVAL);
  };

  useEffect(() => {
    let key = "config_" + dataRow.id;
    let oldData = localStorage.getItem(key);

    if (oldData != null && oldData != undefined) {
      setLoadingTile(true);
      let json = JSON.parse(oldData);
      setLastUpdate(json.date);
      setDataToTile(json.data);
      setLoadingTile(false);
    } else {
      setLoadingTile(true);
      getDataForTiles(dataRow);
    }
  }, [dataRow]);

  const refresh = () => {
    setLoadingTile(true);
    getDataForTiles(dataRow);
  };

  const setDataToTile = (data) => {
    const chartDataSet = [];
    const tableDataSet = [];
    const tot = data.reduce(
      (acc, current) => acc + Number.parseInt(current.count),
      0
    );
    setChartDataTotal(tot);
    data.forEach((el) => {
      const title = highlight5Properties.pieChartTitles.find(
        (prop) => prop.value === el.title
      );
      if (title != null) {
        if (el.count !== 0) {
          chartDataSet.push({
            name: title.displayValue,
            value: el.count,
          });
        }
        tableDataSet.push({
          title: title.displayValue,
          first_table: el["first_table"],
        });
      }
    });
    setChartDataSet(chartDataSet);
    setDataSet(tableDataSet);
  };

  const getDataForTiles = async (config) => {
    let apiString = "";
    let newDataArray = [];
    if (config !== null) {
      apiString = "getKpiComplianceHighlight?highlightId=" + config.id;

      await axios
        .get(apiString)
        .then((result) => {
          if (result.status == "200") {
            if (result.data !== null) {
              setIsDataNull(false);
            }

            let date = new Date();
            let dateString =
              date.toLocaleDateString() + " | " + date.toLocaleTimeString();
            let obj = {
              configId: config.id,
              date: dateString,
              data: result.data,
            };
            let key = "config_" + config.id;
            localStorage.setItem(key, JSON.stringify(obj));
            setLastUpdate(dateString);
            setDataToTile(result.data);
            setLoadingTile(false);
          } else {
            setLoadingTile(false);
          }
        })
        .catch((error) => {
          setLoadingTile(false);
        });
    }
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
      value,
    } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g style={{ pointerEvents: "none" }}>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "10px", fontWeight: "450" }}
        >
          {value}
        </text>
      </g>
    );
  };

  const getKeyByValue = (obj, value) => {
    const key = Object.keys(obj).find((key) => obj[key].req === value);
    return key;
  };

  const assignTableData = (data) => {
    const tableArr = [];
    var num = 1;
    const fieldKeys = reportsProperties.reqFieldsMap;

    data.forEach((record) => {
      var obj = {};
      Object.assign(obj, {
        num: {
          id: "1",
          value: num,
        },
        subTableData: record["second_table"],
      });
      var keys = Object.keys(record);
      const tableFields = highlight5Properties.tableFields.table1;

      keys.forEach((field) => {
        const key = getKeyByValue(fieldKeys, field);
        if (key !== undefined) {
          const fieldObj = tableFields.find((ob) => ob.field === key);
          if (fieldObj != null) {
            obj[key] = {
              id: fieldObj.id,
              value: record[field] == null ? "-" : record[field],
            };
          }
        }
      });

      tableArr.push(obj);
      num++;
    });
    return tableArr;
  };

  return (
    <div>
      {openViewMore1 === true ? (
        <Viewmore1
          open={openViewMore1}
          fileUploadClose={viewMoreClose}
          title={tableTitle}
          subTitle={tableSubTitle}
          tileBody={title}
          tableData={tableData}
          //fileUploadClose={fileUploadClose1}
        />
      ) : (
        // <Viewmoreee1
        //   open={openViewMore1}
        //   viewMoreClose={viewMoreClose}
        //   title={title}
        //   SubTitle={subTitl}
        //   tileBody={title}
        //   tableData={tableData}
        //   //fileUploadClose={fileUploadClose1}
        // />
        ""
      )}
      {loadingTile ? (
        <SkeletonTypeOne
          title={title}
          colorCode={colorCode}
          iconName={iconName}
        />
      ) : (
        <Paper className={classes.paper}>
          {
            <Grid container spacing={0}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={classes.imgBox}
                  style={{
                    backgroundColor: colorCode,
                    marginTop: 8,
                    marginBottom: 9,
                  }}
                >
                  <IconSelector
                    IconImage={handleIconImage}
                    hasIconImage={icon}
                    TileColor={tileColor}
                  />
                </div>
                <Typography
                  noWrap
                  className={`${classes.title} ${classes.topRight}`}
                  style={{ marginRight: 0 }}
                >
                  {title.toUpperCase()}
                </Typography>
              </div>
              {isDataNull ? (
                <span
                  className={classes.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Data
                </span>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "-1.2rem",
                    marginLeft: "7rem",
                  }}
                >
                  <div
                    style={{
                      width: "1px",
                      height: "1px",
                    }}
                  ></div>
                  <Grid
                    item
                    xs={8}
                    style={{
                      height: "5rem",
                    }}
                  >
                    <ResponsiveContainer width="100%" height="130%">
                      <PieChart width={400} height={400}>
                        <Pie
                          data={chartDataSet}
                          cx="50%"
                          cy="50%"
                          onClick={(event) => {
                            const data = dataSet.find(
                              (el) => el.title === event.name
                            )["first_table"];

                            const tableData = assignTableData(data);
                            setTableData(tableData);
                            setTableTitle(title.toUpperCase());
                            setTableSubTitle(event.name);
                            setOpenViewMore1(true);
                          }}
                          innerRadius={0}
                          // startAngle={180}
                          // endAngle={0}
                          outerRadius={"100%"}
                          // outerRadius={40}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          dataKey="value"
                          activeIndex={activeIndex}
                          activeShape={renderActiveShape}
                          onMouseOver={onMouseOver}
                          onMouseLeave={onMouseLeave}
                        >
                          {chartDataSet.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                </div>
              )}
              {!isDataNull && (
                <Grid
                  item
                  xs={9}
                  xl={2}
                  className={classes.topRight}
                  style={{
                    marginTop: "-3rem",
                    marginLeft: "-1.5rem",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "0.5rem",
                        marginBottom: "0.2rem",
                      }}
                    >
                      {chartDataSet.map((val, index) => (
                        <Grid
                          container
                          justifyContent="center"
                          // alignContent="flex-end"
                          // alignItems="flex-end"
                          alignContent="center"
                          alignItems="center"
                          style={{
                            flexDirection: "row",
                            textAlign: "left",
                            height: "1rem",
                          }}
                          key={index}
                        >
                          {/* <Grid item xs={2}></Grid> */}
                          <Grid item xs={1}>
                            <CheckBoxOutlineBlankIcon
                              style={{
                                display: "flex",
                                width: "0.5rem",
                                height: "0.5rem",
                                marginRight: "0.5rem",
                                marginTop: "0",
                                marginBottom: "0",
                                color: COLORS[index % COLORS.length],
                                backgroundColor: COLORS[index % COLORS.length],
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            style={{
                              display: "flex",
                              flexDirection: "horizontal",
                            }}
                          >
                            <Typography
                              className={classes.title3}
                              noWrap
                              style={{
                                marginTop: "0",
                                marginBottom: "0",
                                marginRight: "0",
                                // fontSize: "0.5rem",
                                // fontWeight: 700,
                                // color: grayColor[0],
                                textAlign: "right",
                              }}
                            >
                              {val.name} &nbsp;
                            </Typography>
                            <Typography
                              // style={{ textAlign: 'center' }}
                              className={classes.title3}
                            >
                              ({((val.value / chartDataTotal) * 100).toFixed(0)}
                              %)
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
          <LastUpdate refresh={refresh} lastUpdate={lastUpdate} />
        </Paper>
      )}
    </div>
  );
};
export default React.memo(HighlightsTypeFive);
