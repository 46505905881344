import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import "./GenerateClaim.css";
import GridContainer from "../../components/Grid/GridContainer";

const GenerateClaim = props => {
  const { formElementArray, onChangeHandler } = props;
  return (
    <>
      <div className={"material-grid-wrapper "}>
        <Typography variant="h6" className="generate-claim-header" style={{ fontSize: "1rem", marginTop: "-0.7rem"}}>
          Generate Claim Details
        </Typography>
        <Typography variant="body1" className="generate-claim-sub-header">
          {/* Please select date range and employee name */}
        </Typography>
      </div>
      <GridContainer>
        {formElementArray.map(element => {
          if (!element.config.isHide) {
            let mdVal = 12;
            if (element.id !== "employeeName") {
              mdVal = 6;
            }
            return (
              <GridItem key={element.id} md={mdVal}>
                <CustomInput
                  labelText={element.config.label}
                  id={element.id}
                  formControlProps={element.config.formControlProps}
                  inputProps={{
                    ...element.config.inputProps,
                    readOnly: element.id === "id"
                  }}
                  type={element.config.elType}
                  value={element.config.value}
                  changed={event => onChangeHandler(event, element.id)}
                  error={!element.config.valid && element.config.touched}
                  labelProps={element.config.labelProps}
                  gridStyle={element.gridStyle}
                />
              </GridItem>
            );
          }
        })}
      </GridContainer>
    </>
  );
};
export default GenerateClaim;
