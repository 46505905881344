import axios from '../../axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';

const baseUrl = UrlProperties.baseUrl;
export default {
    getAllClaimsExpenseCategories() {
        return axios.get(`${baseUrl}getExpenseCategories`);
    },
    getClaimsExpenseCategoryById(id) {
        return axios.get(`${baseUrl}getExpenseCategories/${id}`);
    },
    deleteClaimsExpenseCategoryById(id) {
        return axios.delete(`${baseUrl}deleteExpenseCategories/${id}`);
    },
    createClaimsExpenseCategory(data) {
        return axios.post(`${baseUrl}createExpenseCategories`,data);
    },
    updateClaimsExpenseCategory(data,id) {
        return axios.put(`${baseUrl}updateExpenseCategories/${id}`,data);
    }
};
