import React, { useEffect, useState } from 'react'
import { fetchJourneyInfo, finalizeJourneys } from './utils/journey-utils';
import { getFormattedJourneyObject } from './utils/polyline-utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SideBar from './SideBar';
import ReportMap from 'views/Maps/ReportMap';
import { formatCustomersList } from './utils/customer-utils';

const tempCenter = {
  lat: 6.850401188194636,
  lng: 79.87379161670415,
};

const TechnicianRouteReport = () => {
  const { technicianId, date } = useParams();
  const [customerList, setCustomerList] = useState([]);
  const [center, setCenter] = useState(tempCenter);

  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);

  const [journeys, setJourneys] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [hasError, setHasError] = useState(false);

  const handleJourneyView = (journeyId, isSelected) => {
    if (isSelected) {
      const { origin } = journeys.find(
        (journey) => journey.id === journeyId
      );

      setCenter(origin || center);
    }

    setJourneys(
      journeys.map((journey) => {
        if (journey.id === journeyId) journey.isSelected = isSelected;
        return journey;
      })
    );
  };

  const handleAllSelected = () => {
    setJourneys(journeys.map(journey => ({...journey, isSelected: true})));
  }

  const handleNoneSelected = () => {
    setJourneys(journeys.map(journey => ({...journey, isSelected: false})));
  }

  const fetchJourneys = async() => {
    try {
      const {
        customerList: fetchedCustomers,
        journeyGroupBySrList,
      } = await fetchJourneyInfo(technicianId, date);

      setCustomerList(formatCustomersList(fetchedCustomers));

      // seperated journeys into formatted main and sr journeys
      const { main, sr } = finalizeJourneys(journeyGroupBySrList);
      const formattedJourneyList = [main, ...sr];
      setCenter(main.journey.origin);

      const waypoints = formattedJourneyList.map(
        (item) => item.journey.waypoints
      );
      setMarkers(waypoints[0]);

      let formattedFinalJourneyList = [];

      for (const journeyObject of formattedJourneyList) {
        const formattedFinalJourney = await getFormattedJourneyObject(journeyObject);
        formattedFinalJourneyList.push(formattedFinalJourney);
      }

      setJourneys(formattedFinalJourneyList.map((journey) => ({
        ...journey,
        isSelected: journey.id === -1,
      })));
    }
    catch(error) {
      console.error(error);
      setHasError(true);
    }
  }

  useEffect(() => {
    if (!isGoogleMapLoaded) return;
    fetchJourneys();
  }, [technicianId, date, isGoogleMapLoaded]);
  

  return (
    <div>
       <SideBar
        journeys={journeys}
        onSelect={handleJourneyView}
        hasError={hasError}
        selectAll={handleAllSelected}
        selectNone={handleNoneSelected}
      />
      <ReportMap
        journeys={journeys}
        center={center}
        setGoogleMapLoaded={setIsGoogleMapLoaded}
        markers={markers}
        customerList={customerList}
      />
    </div>
  )
}

export default TechnicianRouteReport
