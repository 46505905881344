import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import GeneralProperties from 'Util/Property/GeneralProperties';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { Form, Field } from 'react-final-form';
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import axios from 'axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import styles from './User.module.css';
import User from 'views/Users/User/User';
import { userProperties, getPasswordForm } from 'Util/Property/UserProperties';

const ModifyUser = (props) => {
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [passwordForm, setPasswordForm] = useState(getPasswordForm());

  useEffect(() => {
    let userId;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    if (typeof queryParam['id'] != 'undefined') {
      userId = queryParam['id'];
      setUserId(userId);
      if (queryParam['isEdit'] === 'true') {
        setIsEdit(true);
      }
    }
    // this is for to stop Rerendering of UserComponent when viewing user
    setIsStateChanged(true);

    let permissionArray = [PermissionProperties.passwordChange];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let formElementArray = [];
  for (let key in passwordForm) {
    formElementArray.push({
      id: key,
      config: passwordForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let formData = { ...passwordForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setPasswordForm(formData);
  };

  const onPasswordSubmit = async (values) => {
    setIsLoading(true);
    let data = {
      id: userId,
      password: values.newPassword,
    };
    await axios
      .patch(UrlProperties.user.resetPassword + '/' + userId, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: userProperties.messages.success.changePassword,
          });
        } else {
          notyDefault({ type: notyTypes.SUCCESS, text: result.data.message });
        }
        let formData = { ...passwordForm };
        for (let key in formData) {
          let elementData = formData[key];
          let inputProps = { ...elementData.inputProps };
          inputProps.value = '';
          elementData.inputProps = inputProps;
          formData[key] = elementData;
        }
        setPasswordForm(formData);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.changePassword,
        });
      });
    setIsLoading(false);
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          {permissions[PermissionProperties.passwordChange] && isEdit ? (
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <AppBar
                    position="static"
                    color="inherit"
                    className={styles.appBarStyle}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      <Tab
                        label={userProperties.userDetails}
                        {...a11yProps(0)}
                      />
                      {permissions[PermissionProperties.passwordChange] ? (
                        <Tab
                          label={userProperties.changePassword}
                          {...a11yProps(1)}
                        />
                      ) : (
                        ''
                      )}
                    </Tabs>
                  </AppBar>
                  <FullWidthTabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                  >
                    <User {...props} id={userId} isEdit={isEdit} />
                  </FullWidthTabPanel>
                  <FullWidthTabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <card>
                      <Form
                        onSubmit={onPasswordSubmit}
                        validate={(values) => {
                          const errors = {};
                          if (!values.newPassword) {
                            errors.newPassword = GeneralProperties.emptyField;
                          }

                          if (!values.confirmNewPassword) {
                            errors.confirmNewPassword =
                              GeneralProperties.emptyField;
                          }

                          if (values.newPassword && values.confirmNewPassword) {
                            if (
                              values.newPassword !== values.confirmNewPassword
                            ) {
                              errors.confirmNewPassword =
                                userProperties.passwordMismatch;
                            }
                          }
                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          reset,
                          submitting,
                          pristine,
                          valid,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <CardBody>
                              <GridContainer>
                                {formElementArray.map((element) => {
                                  return (
                                    <GridItem
                                      key={element.id}
                                      xs={12}
                                      sm={12}
                                      md={12}
                                    >
                                      <Field name={element.id}>
                                        {({ input, meta, options, value }) => (
                                          <div style={{ position: 'relative' }}>
                                            <CustomInput
                                              labelText={element.config.label}
                                              id={element.id}
                                              inputProps={{
                                                ...input,
                                                ...element.config.inputProps,
                                              }}
                                              type={element.config.elType}
                                              formControlProps={
                                                element.config.formControlProps
                                              }
                                              changed={(event, value) => {
                                                input.onChange(event);
                                                onChangeHandeler(
                                                  event,
                                                  element.id
                                                );
                                              }}
                                              labelProps={{
                                                ...element.config.labelProps,
                                                error:
                                                  meta.error && meta.touched,
                                              }}
                                            />
                                            {meta.error && meta.touched && (
                                              <span
                                                className={styles.formError}
                                              >
                                                {meta.error}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </GridItem>
                                  );
                                })}
                              </GridContainer>
                            </CardBody>
                            <CardFooter>
                              <Button
                                type="submit"
                                disabled={submitting || !valid || pristine}
                                color="primary"
                                autoFocus
                              >
                                {GeneralProperties.change}
                              </Button>{' '}
                              : {''}
                            </CardFooter>
                          </form>
                        )}
                      />
                    </card>
                  </FullWidthTabPanel>
                </CardBody>
              </Card>
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={12}>
              {isStateChanged ? (
                <User {...props} id={userId} isEdit={isEdit} />
              ) : (
                ''
              )}
            </GridItem>
          )}
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ModifyUser;
