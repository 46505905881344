import React, { useState, useEffect, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {
  priorityConfigurationProperties,
  getPriorityConfiguratoinForm,
} from 'Util/Property/PriorityConfigurationProperties';
import generalProperties from 'Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import CustomInput from 'components/CustomInput/CustomInput.js';
import UrlProperties from 'Util/Property/UrlProperties';
import { getActiveCategoryList } from 'services/Product/ProductService';
import {
  getActiveBrandList,
  getAllBrandList,
} from 'services/Product/ProductBrandService';
import {
  getActiveModelList,
  getAllModelList,
} from 'services/Product/ModelService';
import * as priorityConfigurationService from 'services/PriorityConfiguration/PriorityConfigurationService';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import { getAllCustomers } from 'services/Customer/CustomerService';
import styles from './PriorityConfiguration.module.css';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import { serviceRequestCategoryProperties } from 'Util/Property/ServiceRequestCategoryProperties';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import RouterProps from '../../../../Util/Property/RouterProperties';
import Spinner from 'components/Spinner/Spinner.js';

const PriorityConfiguration = (props) => {
  const [priorityConfigurationForm, setPriorityConfigurationForm] = useState(
    getPriorityConfiguratoinForm()
  );
  const [
    priorityConfigurationHeading,
    setPriorityConfigurationHeading,
  ] = useState(priorityConfigurationProperties.addPriorityConfiguration);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityConfigurationId, setPriorityConfigurationId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [permissions, setPermissions] = useState({});

  const tableRef = useRef(null);

  const [asyncDataState, setAsyncDataState] = useState({
    customerId: false,
    serviceRequestCategoryId: false,
    productCategoryId: false,
    productBrandId: false,
    productModelId: false,
  });

  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addPriorityConfiguration,
      PermissionProperties.viewPriorityConfiguration,
      PermissionProperties.editPriorityConfiguration,
      PermissionProperties.deletePriorityConfiguration,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  useEffect(() => {
    let priorityConfigurationId = 0;

    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      priorityConfigurationId = props.id;
      setPriorityConfigurationHeading(
        priorityConfigurationProperties.viewPriorityConfiguration
      );
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        priorityConfigurationId = queryParam['id'];
        setPriorityConfigurationHeading(
          priorityConfigurationProperties.viewPriorityConfiguration
        );
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
          setPriorityConfigurationHeading(
            priorityConfigurationProperties.editPriorityConfiguration
          );
        } else {
          setIsView(true);
        }
      }
    }
    if (priorityConfigurationId > 0) {
      // this is for view and edit Service Request
      //getServiceRequestData(serviceRequestId);
      //setServiceRequestId(serviceRequestId);
      setPriorityConfigurationId(priorityConfigurationId);
      getPriorityConfigurationData(priorityConfigurationId);
    } else {
      // this is loading for add new Service Request
      getBackGroundData();
    }
  }, [props.id]);

  const getPriorityConfigurationData = async (serviceRequestId) => {
    await priorityConfigurationService
      .getPriorityConfiguration(serviceRequestId)
      .then((result) => {
        setPriorityConfigurationHeading(result.data.name);
        for (let key in priorityConfigurationForm) {
          let value = result.data[key];
          if (!priorityConfigurationForm[key].isAsyncDropDown) {
            priorityConfigurationForm[key].inputProps.value = value;
          } else {
            priorityConfigurationForm[key].inputProps.initvalue = value;
          }
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: priorityConfigurationProperties.messages.error.loadData,
        });
        setIsLoading(false);
      });
    getBackGroundData();
  };

  const getBackGroundData = () => {
    setIsLoading(true);
    getAllCustomersForPRConifguration();
    getServiceRequestCategoryData();
    getActiveCategoryList()
      .then((result) => {
        asyncDataState.productCategoryId = true;
        if (result.status === 200) {
          let categoryOptions = [];
          result.data.map((category) =>
            categoryOptions.push({
              value: category.id,
              displayValue: category.name,
            })
          );
          let defaultValue = setDataToServiceRequestForm(
            'productCategoryId',
            categoryOptions,
            true
          );
          if (defaultValue !== '' && defaultValue !== null) {
            getActiveBarandListForPRConifguration(defaultValue, true);
          } else {
            getActiveBarandListForPRConifguration(0, true);
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : priorityConfigurationProperties.messages.error
                  .loadProductCategories,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text:
            priorityConfigurationProperties.messages.error
              .loadProductCategories,
        });
      });
  };

  const getServiceRequestCategoryData = () => {
    getServiceRequestCategoryCall().then((response) => {
      asyncDataState.serviceRequestCategoryId = true;
      if (!response.success) {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestCategoryProperties.message.error.loadingError,
        });
      } else {
        let categoryOptions = [];
        response.data.map((category) =>
          categoryOptions.push({
            value: category.id,
            displayValue: category.name,
          })
        );
        setDataToServiceRequestForm(
          'serviceRequestCategoryId',
          categoryOptions,
          true
        );
      }
    });
  };

  const getAllCustomersForPRConifguration = () => {
    getAllCustomers()
      .then((result) => {
        asyncDataState.customerId = true;
        if (result.status === 200) {
          let customerOptions = [];
          result.data.map((customer) =>
            customerOptions.push({
              value: customer.id,
              displayValue: customer.name,
            })
          );
          setDataToServiceRequestForm('customerId', customerOptions, true);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : priorityConfigurationProperties.messages.error.loadCustomers,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: priorityConfigurationProperties.messages.error.loadCustomers,
        });
      });
  };

  const getActiveBarandListForPRConifguration = (catId, isFirstTime) => {
    let fnc = getActiveBrandList;
    if (catId === 0) {
      fnc = getAllBrandList;
    }
    fnc(catId)
      .then((result) => {
        asyncDataState.productBrandId = true;
        if (result.status === 200) {
          let brandOptions = [];
          result.data.map((brand) =>
            brandOptions.push({ value: brand.id, displayValue: brand.name })
          );
          let defaultValue = setDataToServiceRequestForm(
            'productBrandId',
            brandOptions,
            isFirstTime
          );
          if (defaultValue !== '' && defaultValue !== null) {
            getActiveProductModelListForPRConifguration(
              defaultValue,
              isFirstTime
            );
          } else {
            // no brands for the seleted category
            getActiveProductModelListForPRConifguration(0, isFirstTime);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : priorityConfigurationProperties.messages.error
                  .loadProductBrands,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text:
            priorityConfigurationProperties.messages.error.loadProductBrands,
        });
      });
  };

  const getActiveProductModelListForPRConifguration = (
    brandId,
    isFirstTime
  ) => {
    let fnc = getActiveModelList;
    if (brandId === 0) {
      fnc = getAllModelList;
    }
    fnc(brandId)
      .then((result) => {
        asyncDataState.productModelId = true;
        if (result.status === 200) {
          let modelOptions = [];
          result.data.map((model) =>
            modelOptions.push({ value: model.id, displayValue: model.name })
          );
          setDataToServiceRequestForm(
            'productModelId',
            modelOptions,
            isFirstTime
          );
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : priorityConfigurationProperties.messages.error
                  .loadProductModels,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text:
            priorityConfigurationProperties.messages.error.loadProductModels,
        });
      });
  };

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;

    let formData = { ...priorityConfigurationForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setPriorityConfigurationForm(formData);
    if (elId === 'productCategoryId' && value !== '') {
      getActiveBarandListForPRConifguration(value);
    }
    if (elId === 'productBrandId' && value !== '') {
      getActiveProductModelListForPRConifguration(value);
    }
  };

  const onPriorityConfigurationSubmit = async (values) => {
    //TODO
    let method = '';
    let url = '';
    let data = {};
    let priorityConfigurationFormData = { ...priorityConfigurationForm };
    for (let key in priorityConfigurationFormData) {
      let val = priorityConfigurationFormData[key].inputProps.value;
      if (val !== '') {
        data[key] = val;
      } else {
        data[key] = 0;
      }
    }

    if (priorityConfigurationId > 0) {
      data['id'] = priorityConfigurationId;
      method = 'PUT';
      url =
        UrlProperties.priorityConfiguration.updatePriorityConfiguration +
        '/' +
        priorityConfigurationId;
    } else {
      data['id'] = 0;
      method = 'POST';
      url = UrlProperties.priorityConfiguration.addPriorityConfiguration;
    }

    setIsLoading(true);
    await priorityConfigurationService
      .savePriorityConfiguration(method, url, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: priorityConfigurationProperties.messages.success.saveData,
          });
          if (priorityConfigurationId == 0) {
            setDefaultData();
          }
          setIsLoading(false);
          props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: priorityConfigurationProperties.messages.error.saveData,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: priorityConfigurationProperties.messages.error.saveData,
        });
      });
  };

  /**
   * set default form data after a form submission or adding new user
   */
  const setDefaultData = () => {
    let formData = { ...priorityConfigurationForm };
    let defaultData = { ...getPriorityConfiguratoinForm() };
    for (let key in defaultData) {
      let elementData = formData[key]; // get data for form key
      let inputProps = { ...elementData.inputProps }; //  copy input data
      inputProps.value = defaultData[key].inputProps.initvalue;
      elementData.inputProps = inputProps;
      formData[key] = elementData;
    }
    setPriorityConfigurationForm(formData);
  };

  /**
   *
   * @param {*} key
   * @param {*} options
   * save api data to Service Request form
   */

  const setDataToServiceRequestForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = priorityConfigurationForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    priorityConfigurationForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    checkForStateUpdate();
    return defaultValue;
  };

  /**
   * check for state update if criterias met then update the state
   */
  const checkForStateUpdate = (isFirstTime) => {
    let status = true;
    for (let key in asyncDataState) {
      if (asyncDataState[key] === false) {
        status = false;
        return false;
      }
    }
    if (status) {
      let formData = { ...priorityConfigurationForm };
      setPriorityConfigurationForm(formData);
    }
  };

  /**
   *  arrange data to populate Form UI
   */
  let formElementArray = [];
  for (let key in priorityConfigurationForm) {
    formElementArray.push({
      id: key,
      config: priorityConfigurationForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <div className="generic-form-container">
                {!isEdit ? (
                  <div className={'generic-form-header-wrapper'}>
                    <div className={'generic-page-title'}>
                      <span className={'generic-page-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={'generic-header-text'}
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {priorityConfigurationHeading}
                      </Typography>
                      {/* <Typography variant="body1">Please add new priority configuration here.</Typography> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <Form
                  onSubmit={onPriorityConfigurationSubmit}
                  validate={(values, ss) => {
                    const errors = {};
                    if (
                      priorityConfigurationForm.name.inputProps.value === ''
                    ) {
                      // setEror("first_name", true)
                      errors.name = generalProperties.emptyField;
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field name={element.id}>
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        <CustomInput
                                          labelText={element.config.label}
                                          id={element.id}
                                          inputProps={{
                                            ...input,
                                            ...element.config.inputProps,
                                            readOnly: isView ? true : false,
                                          }}
                                          type={element.config.elType}
                                          formControlProps={
                                            element.config.formControlProps
                                          }
                                          adornmentText={
                                            element.config.adornmentText
                                          }
                                          adornmentPosition={
                                            element.config.adornmentPosition
                                          }
                                          changed={(event, value) => {
                                            input.onChange(event);
                                            onChangeHandeler(event, element.id);
                                          }}
                                          labelProps={{
                                            ...element.config.labelProps,
                                            error: meta.error && meta.touched,
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView ? (
                          <Button
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            {generalProperties.save}
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default PriorityConfiguration;
