const HighLightsStyle = {
  paper: {
    minheight: '30rem',
    padding: '1rem',
  },
  chartContainer: {
    marginTop: '2rem',
    height: '20rem',
  },
  titleContiner: {
    marginTop: '1rem',
  },
  title: {
    color: '#666',
    fontWeight: 600,
    fontSize: '0.9rem',
    textAlign: 'left',
  },
  subTitle: {
    color: '#757575',
    fontWeight: 500,
    fontSize: '0.8rem',
    textAlign: 'left',
    //marginTop: "0.2rem"
  },
  topContainer: {
    height: '5rem',
    marginTop: '2rem',
  },
  detailBox: {
    margin: '5px -10px',
    border: '1px solid #D2D2D2',
    borderRadius: '3px',
    padding: '10px 5px',
    display: 'inherit',
  },
  LegendContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  customToolTip: {
    backgroundColor: '#F4F4F4',
    opacity: 0.9,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    //paddingTop: "0.5rem",
    borderRadius: '0.6rem',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  viewMoreButton: {
    width: '4rem',
    height: '2rem',
    backgroundColor: '#999999',
    fontSize: '0.6rem',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#f15645',
    },
  },
};

export default HighLightsStyle;
