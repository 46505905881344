import Axios from "axios";
import urlProperties from 'Util/Property/UrlProperties';
import axios from '../../axios/axios-default';


export async  function getActiveModelList(brandId){
   
    try{
      
        return axios.get("getProductModelsByStatus?brandId=" + brandId + "&active=true")
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");

    }catch (error ) {
        
        console.log("Error Calling Brand Service");

    }



}

export async  function getAllModelList(brandId){
   
    try{
      
        return axios.get("getProductModels")
              // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");

    }catch (error ) {
        
        console.log("Error Calling Brand Service");

    }



}

