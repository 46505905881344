export const statusProperties = {
    messages: {
        error: {
          loadData: "Could not load Status data please try again",
          saveData: "Could not save Status data please try again",
          delete: "Could not delete Status please try again",
          loadAll : "Could not load Status please try again",
          load: "Could not load Status"
        },
        success: {
          saveData: "Status details saved successfully",
          delete: "Status deleted successfully"
        },
        normal: {
          deleteServiceRequest: "Do you want to delete this Status?",
        }
      }
}