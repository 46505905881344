/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import styles from './LeaveAttribute.module.css';
import LoadingOverlay from 'react-loading-overlay';
import { Typography } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import SpeedIcon from '@material-ui/icons/Speed';
import CardBody from 'components/Card/CardBody.js';
import GeneralProperties from 'Util/Property/GeneralProperties';
import {
  leaveSchemeProperties,
  getLeaveForm,
} from 'Util/Property/LeaveProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CardFooter from 'components/Card/CardFooter';
import TodayIcon from '@material-ui/icons/Today';
import axios from '../../../axios/axios-default';
import MaterialTable from 'material-table';

const LeaveAttributes = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [permissions, setPermissions] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [leaveForm, setLeaveForm] = useState(getLeaveForm());

  useEffect(() => {
    getLeaveSchemeData();
  }, []);

  let formElementArray = [];
  for (let key in leaveForm) {
    formElementArray.push({
      id: key,
      config: leaveForm[key],
    });
  }

  const getLeaveSchemeData = async () => {
    try {
      setIsLoading(true);
      const result = await axios({
        method: 'GET',
        url: 'getLeaveSchemes',
      });
      setTableData(result.data);
      clearFormData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notyDefault({
        type: notyTypes.ERROR,
        text: leaveSchemeProperties.messages.error.loadData,
      });
    }
  };

  const deleteLeaveScheme = async (rowData) => {
    try {
      setIsLoading(true);
      const result = await axios({
        method: 'DELETE',
        url: 'deleteLeaveScheme/' + rowData.id,
      });

      await getLeaveSchemeData();

      setIsLoading(false);
      notyDefault({
        type: notyTypes.SUCCESS,
        text: leaveSchemeProperties.messages.success.deleteScheme,
      });
    } catch (error) {
      setIsLoading(false);
      notyDefault({
        type: notyTypes.ERROR,
        text: leaveSchemeProperties.messages.error.deleteScheme,
      });
    }
  };

  const onChangeHandeler = (event, elId) => {
    let formData = { ...leaveForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    let value = '';

    inputProps.value = event.target.value;
    value = event.target.value;

    elementData.inputProps = inputProps;
    formData[elId] = elementData;

    setLeaveForm(formData);
  };

  const clearFormData = () => {
    let formData = { ...leaveForm };

    for (let key in formData) {
      formData[key].inputProps.value = '';
    }

    setLeaveForm(formData);
  };

  const onLeaveConfigSubmit = async (values) => {
    let method = '';
    let url = '';
    let data = {};

    let formData = { ...leaveForm };

    data['scheme_name'] = formData['schema_name'].inputProps.value;
    data['casual_count'] = formData['casual_leave'].inputProps.value;
    data['annual_count'] = formData['annual_leave'].inputProps.value;
    data['medical_count'] = formData['medical_leave'].inputProps.value;

    data['id'] = 0;
    method = 'POST';
    url = 'createLeaveScheme';

    setIsLoading(true);
    await axios({
      method: method,
      url: url,
      data: data,
    })
      .then((result) => {
        setIsLoading(false);
        clearFormData();
        notyDefault({
          type: notyTypes.SUCCESS,
          text: leaveSchemeProperties.messages.success.saveData,
        });
        getLeaveSchemeData();
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: leaveSchemeProperties.messages.error.saveData,
        });
      });
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <div className={`material-grid-wrapper`}>
                  <GridContainer>
                    <GridContainer spacing={3} md={6}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: '1rem',
                          marginTop: '0.7rem',
                          marginLeft: '2rem',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className={'grid-title-icon'}>
                          <TodayIcon />
                        </span>
                        Leave Configuration
                      </Typography>
                    </GridContainer>
                  </GridContainer>
                </div>
                <Form
                  onSubmit={onLeaveConfigSubmit}
                  // initialValues={initialData}
                  validate={(values, ss) => {
                    const errors = {};
                    if (!values.schema_name) {
                      errors.schema_name = GeneralProperties.emptyField;
                    }
                    if (!values.casual_leave) {
                      errors.casual_leave = GeneralProperties.emptyField;
                    }
                    if (!values.annual_leave) {
                      errors.annual_leave = GeneralProperties.emptyField;
                    }
                    if (!values.medical_leave) {
                      errors.medical_leave = GeneralProperties.emptyField;
                    }
                    if (values.annual_leave && values.annual_leave < 1) {
                      errors.annual_leave =
                        leaveSchemeProperties.messages.error.invalidLeaveDuration;
                    }
                    if (values.medical_leave && values.medical_leave < 1) {
                      errors.medical_leave =
                        leaveSchemeProperties.messages.error.invalidLeaveDuration;
                    }
                    if (values.casual_leave && values.casual_leave < 1) {
                      errors.casual_leave =
                        leaveSchemeProperties.messages.error.invalidLeaveDuration;
                    }

                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer spacing={3} md={9}>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = 12;
                              if (!element.config.isFullWidth) {
                                mdVal = 6;
                              }

                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={3}
                                >
                                  <Field
                                    name={element.id}
                                    // validate={getValidationFunction(
                                    //   element.config.validations
                                    // )}
                                  >
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        {
                                          <CustomInput
                                            labelText={element.config.label}
                                            id={element.id}
                                            inputProps={{
                                              ...input,
                                              ...element.config.inputProps,
                                              readOnly: isView ? true : false,
                                            }}
                                            type={element.config.elType}
                                            formControlProps={
                                              element.config.formControlProps
                                            }
                                            hideArrows={
                                              element.config.hideArrows
                                            }
                                            changed={(event, value) => {
                                              input.onChange(event);
                                              onChangeHandeler(
                                                event,
                                                element.id
                                              );
                                            }}
                                            multiple={element.config.multiple}
                                            labelProps={{
                                              ...element.config.labelProps,
                                              error: meta.error && meta.touched,
                                            }}
                                          />
                                        }
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {
                          <Button
                            type="submit"
                            // disabled={}
                            color="primary"
                            autoFocus
                          >
                            {GeneralProperties.save}
                          </Button>
                        }
                      </CardFooter>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={'material-grid-wrapper'}>
                      <MaterialTable
                        title={
                          <div>
                            <span className={'grid-title-icon'}>
                              <TodayIcon />
                            </span>
                            <Typography
                              variant="h6"
                              style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                            >
                              Leave Schema
                            </Typography>
                          </div>
                        }
                        components={{
                          OverlayLoading: () => (
                            <div className={styles.loadingOverlay}>
                              <Spinner />
                            </div>
                          ),
                        }}
                        columns={[
                          { title: 'Scheme Id', field: 'id', width: '5%' },
                          { title: 'Scheme Name', field: 'scheme_name' },
                          {
                            title: 'Annual Leaves',
                            field: 'annual_count',
                          },
                          {
                            title: 'Casual Leaves',
                            field: 'casual_count',
                          },
                          {
                            title: 'Medical Leaves',
                            field: 'medical_count',
                          },
                        ]}
                        data={tableData}
                        actions={[
                          (rowData) => ({
                            icon: 'delete',
                            tooltip: 'Delete Leave Scheme',
                            iconProps: {
                              className: 'action-buttons delete-button',
                            },
                            onClick: (event, rowData) => {
                              deleteLeaveScheme(rowData);
                            },
                            // hidden: true,
                          }),
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          filtering: false,
                          sorting: false,
                          search: false,
                          maxBodyHeight: '70vh',
                          headerStyle: {
                            backgroundColor: '#e6e4df',
                            fontSize: '0.7rem',
                            textAlign: 'center',
                          },
                          rowStyle: {
                            fontSize: '0.6rem',
                            fontWeight: 450,
                            padding: '5rem',
                          },
                          cellStyle: {
                            textAlign: 'center',
                          },
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default LeaveAttributes;
