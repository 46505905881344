import React, { useEffect, useState ,useMemo} from "react";
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import styles from './BulkSR.module.css';
import { productProperties, getBulkSRForm } from 'Util/Property/ProductProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { Form, Field } from "react-final-form";
import CardFooter from 'components/Card/CardFooter';
import { getAllUsers , getUser } from 'services/User/UserService';
import UrlProperties from 'Util/Property/UrlProperties';
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import  {saveProductSeriveAgreement} from 'services/ProductAndServices/ProductServiceAgreement';
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import axios from "axios";
import { Typography } from "@material-ui/core";
import ListOutlinedIcon from '@material-ui/icons/ShoppingBasket';
import { handleErrorMessage } from 'services/CommonService';
import { getActiveCategoryList } from 'services/Product/ProductService';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { getActiveModelList } from 'services/Product/ModelService';
import { serviceRequestProperties } from 'Util/Property/ServiceRequestProperties';
import LoadingOverlay from "react-loading-overlay";
import {createBulkSR} from 'services/ServiceRequest/ServiceRequestService';
import { getAllCustomers } from 'services/Customer/CustomerService';


export default function BulkSR(props){
    const {setIsLoading,selectedOutlets,setSelectedOutlets,routePlannerTableRef, setVehicle, open, SRBulkUploadClose ,selectedRowsofCustomers } = props;
    const [isView, setIsView] = useState(false);
    const [bulkSRForm, setBulkSRForm] = useState(getBulkSRForm());
    const [permissions, setPermissions] = useState({});
    const [asyncDataState, setAsyncDataState] = useState({
        serviceAgreementId: false,
        productCategoryId: false,
        productBrandId: false,
        productModelId: false,
        productIdList: true,
        getalluser:false,
        getAllCustomers:false
    })
    const [userType , setUserType] = useState(null);

    const DEFAULT_PRODUCT_BRAND = 1;
    const DEFAULT_PRODUCT_CATEGORY = 1;
    const DEFAULT_PRODUCT_MODEL = 1;
    const DEFAULT_SR_TYPE = 16;
    
    const CUSTOMER_TYPE_PARENT = "PARENT"

    const VEHICLE_TYPE_USER = "VEHICLE"
    const CBL_TYPE_USER = "USER"

    useEffect(() => {
        if(open){
            let permissionArray = [PermissionProperties.addProductAndServices, PermissionProperties.viewProductAndServices, PermissionProperties.deleteProductAndServices, PermissionProperties.editProductAndServices];
            setPermissions(checkPermissoinListAvailable(permissionArray));
            const userId = localStorage.getItem("userId");
            (async () => {
                let user = await getUserDetails(userId); 
                setUserType(user.type);
                await getAllUsersForServiceRequest(true);
            })();   
        }
    }, [open]);

    const handleSRBulkUploadClose = () => {
        SRBulkUploadClose();
        setBulkSRForm(getBulkSRForm());
    }
    
    const setFormDataUpdate = () => {
        setIsLoading(true);
        let formData = { ...bulkSRForm };
        setBulkSRForm(formData);
        setIsLoading(false);
    }

    useEffect(() => {
        let permissionArray = [
          PermissionProperties.addServiceRequest,
          PermissionProperties.viewServiceRequest,
          PermissionProperties.editServiceRequest,
          PermissionProperties.deleteServiceRequest,
        ];
        setPermissions(checkPermissoinListAvailable(permissionArray));
      }, []);
    

    const getAllUsersForServiceRequest = async  (isFirstTime) => {
        setIsLoading(true);
         getAllUsers().then(result => {
            asyncDataState.getalluser = true;
            if (result.status === 200) {
                let userOptions = [];
                result.data.map(user => {
                    if(user.type === VEHICLE_TYPE_USER){
                        userOptions.push({ "value": user.id, "displayValue": user.first_name + ' ' + user.last_name })
                    }
                });
                let defaultValue = setDataToBulkSRForm("assign_user_id", userOptions,isFirstTime, true);
                if (defaultValue !== "") {
                    setFormDataUpdate();
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                notyDefault({ type: notyTypes.ERROR, text: result.data ? result.data.message : serviceRequestProperties.messages.error.loadUsers });
            }
        }).catch(error => {
            setIsLoading(false);
            notyDefault({ type: notyTypes.ERROR, text: serviceRequestProperties.messages.error.loadUsers });
        });
    }

    const getUserDetails = async (userId) => {
        setIsLoading(true);
        try {
            const result = await getUser(userId);
            asyncDataState.getalluser = true;
            if (result.status === 200) {
                 return result.data;
            } else {
                notyDefault({
                    type: notyTypes.ERROR,
                    text: result.data ? result.data.message : serviceRequestProperties.messages.error.loadUsers
                });
                return null;
            }
        } catch (error) {
            notyDefault({
                type: notyTypes.ERROR,
                text: serviceRequestProperties.messages.error.loadUsers
            });
        } finally {
            setIsLoading(false);
        }
    };

    const setDataToBulkSRForm = (key, options, isFirstTime, isMultiple) => {
        let formData = bulkSRForm[key];
        let inputProps = { ...formData.inputProps };
        inputProps.options = options;
        let defaultValue;

        if (isFirstTime && inputProps.initvalue !== "") {
            defaultValue = inputProps.initvalue;
        } else {
            defaultValue = options.length > 0 ? options[0].value : "";
        }
        if (isMultiple) {
            let arr = [];
            defaultValue = arr;
        }
        inputProps.value = defaultValue;
        if (isFirstTime) {
            inputProps.initvalue = defaultValue;
        }
        formData.inputProps = inputProps;
        bulkSRForm[key] = formData;
        checkForStateUpdate();
        return defaultValue;
    }

    const clearForm = () => {
        setDataToBulkSRForm("assign_user_id", [],false, true);
        resetDate()
    }

    const resetDate = () => {
        let formData = { ...bulkSRForm };
        let elementData = formData["planned_completion_date"];
        let inputProps = { ...elementData.inputProps } 
        inputProps.value = null;
        elementData.inputProps = inputProps;
        formData["planned_completion_date"] = elementData;
        setBulkSRForm(formData);
    }

    const checkForStateUpdate = (isFirstTime) => {
        let status = true;
        for (let key in asyncDataState) {
            if (asyncDataState[key] === false) {
                status = false;
                return false;
            }
        }
        if (status) {
            let formData = { ...bulkSRForm }
            setBulkSRForm(formData);
        }
    }


    const onBulkSRSubmit =async(values) => {
        let selectedCustomers ={...selectedRowsofCustomers}
        let cusIDS = [];
        let method = "";
        let url = "";
        let data = {}
        let productFormData = { ...bulkSRForm};
        for (let cus in selectedCustomers) {
            let cusId = selectedCustomers[cus].id;
            cusIDS.push(cusId);
        }
        for (let key in productFormData) {
            let val = productFormData[key].inputProps.value;
            if (val !== "") {
                data[key] = val;
            } else {
                data[key] = 0;
            }
        }
        if (data["assign_user_id"].length ===0 ){
            data["assign_user_id"] = null;
        }
        data["customerIdList"] = cusIDS;

        data["product_brand_id"] = DEFAULT_PRODUCT_BRAND;
        data["product_category_id"] = DEFAULT_PRODUCT_CATEGORY;
        data["product_model_id"] = DEFAULT_PRODUCT_MODEL;
        data["request_type"] = DEFAULT_SR_TYPE;
        
        method = "POST";
        url = UrlProperties.serviceRequest.createBulkServiceRequests;
        setIsLoading(true);

        await createBulkSR(method, url, data).then((result) => {
            if (typeof result !== 'undefined') {
                if (result.status === 200) {
                    notyDefault({ type: notyTypes.SUCCESS, text: serviceRequestProperties.messages.success.createBulk});
                    setSelectedOutlets([]);
                    clearForm()
                    handleSRBulkUploadClose();
                } else {
                    notyDefault({ type: notyTypes.ERROR, text: serviceRequestProperties.messages.error.createBulk });
                }
            }
        }).catch(error => {
            notyDefault({ type: notyTypes.ERROR, text: serviceRequestProperties.messages.error.createBulk });
        });
        setIsLoading(false);
    }

    const onBulkSRChangeHandler = (event, elId) => {
        let value = "";
        let formData = { ...bulkSRForm };
        let elementData = formData[elId]; // get data for form key
        let inputProps = { ...elementData.inputProps } //  copy input data
        if (elementData.elType == 'date') {
            inputProps.value = event;
            value = event;
        } else {
            inputProps.value = event.target.value;
            value = event.target.value;
            if(elId === "assign_user_id"){
                setVehicle(value)
                setSelectedOutlets([])
                routePlannerTableRef.current.onQueryChange();
            }
        }
        elementData.inputProps = inputProps;
        formData[elId] = elementData;
        setBulkSRForm(formData);
    }

    let bulkSRFormElementArray = [];
    for (let key in bulkSRForm) {
        bulkSRFormElementArray.push({
            id: key,
            config: bulkSRForm[key]
        });
    }

    let hasFormErrors = ()=>{
        let hasErrors = false
        if(bulkSRForm["assign_user_id"].inputProps.value == ""){
            hasErrors = true;
        }
        if(bulkSRForm["planned_completion_date"].inputProps.value === null){
            hasErrors = true;
        }
        return hasErrors;
    }

    return(
        <div>
              <Form
                onSubmit={onBulkSRSubmit}
                validate={(values, ss) => {
                    const errors = {};
                    if(bulkSRForm["assign_user_id"].inputProps.value == ""){
                        errors.assign_user_id = "Assignee is required !";
                    }
                    if(bulkSRForm["planned_completion_date"].inputProps.value === null){
                        errors.planned_completion_date = "Planned completion date is required !";
                    }
                    return errors
                }}
                render={({ handleSubmit, reset, submitting, pristine, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <CardBody> 
                            <GridContainer>
                                {
                                    bulkSRFormElementArray.map((element) => {
                                        if (!element.config.isHide) {
                                            let mdVal = element.config.size/2;
                                            return (
                                                <GridItem key={element.id} xs={12} sm={12} md={mdVal}>
                                                    <Field name={element.id} type={element.config.elType}>
                                                        {({ input, meta, options, value }) => (
                                                            <div style={{ position: 'relative' }}>
                                                                <CustomInput
                                                                    labelText={element.config.label}
                                                                    id={element.id}
                                                                    inputProps={{ ...input, ...element.config.inputProps, readOnly: isView || element.config.readOnly ? true : false }}
                                                                    type={element.config.elType}
                                                                    formControlProps={element.config.formControlProps}
                                                                    adornmentText={element.config.adornmentText}
                                                                    adornmentPosition={element.config.adornmentPosition}
                                                                    multiple={element.config.multiple}
                                                                    changed={(event, value) => {
                                                                        input.onChange(event)
                                                                        onBulkSRChangeHandler(event, element.id, meta);
                                                                    }}
                                                                    labelProps={{ ...element.config.labelProps, error: meta.error && meta.touched }}
                                                                />
                                                                {meta.error && meta.touched && <span className={styles.formError}>{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                            );
                                        }
                                    })
                                }
                                {permissions[PermissionProperties.addServiceRequest]&&
                                <GridItem key="generate-bulk-sr-button" xs={12} sm={12} md={3} style={{marginTop:10}}>
                                    <div style={{display:'flex', flexDirection:'column' , marginBottom:10}}>
                                    <Button type="submit" style={{marginRight:10 }} color="primary" variant="contained">
                                        Generate Service Requests  
                                    </Button>  
                                     { !hasFormErrors() && selectedOutlets.length === 0 &&
                                        <span className={styles.formError}>Please select outlet(s)</span>
                                     }
                                    </div>
                                </GridItem>}
                            </GridContainer>
                        </CardBody>
                    </form>
                )}
            />
        </div>
    );
}