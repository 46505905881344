import chroma from "chroma-js";
import randomColor from "randomcolor";
import reportService from "services/Reports/ReportsService";

/*
  These methods are used for 
  fetching and formatting journey info
  into common object format
*/

export const fetchJourneyInfo = async (technicianId, date) => {
  return await new Promise((resolve, reject) => {
    reportService
      .getTechnicianJourneyRoutes(date, technicianId)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject("Unable to fetch service request report", err);
      });
  });
};

const locationObject = (lat, lng) => ({ lat, lng });

/**
 * Final Waypoint Object
 * id: number;
 * name: string;
 * type: string;
 * journey: {
 *    origin: locationObject
 *    waypoints: locationObject[]
 * }
 * distance: number
 */
const finalWaypointObject = (
  id,
  name,
  type,
  journey,
  routeColor,
  startTime,
  endTime,
  customerName
) => {
  return {
    id,
    name,
    type,
    journey,
    color: routeColor,
    startTime,
    endTime,
    customerName,
  };
};

export const seperateIntoMainSR = (journeyGroupBySrList) => {
  const mainJourney = [];
  const srJourneys = [];

  journeyGroupBySrList.forEach(journey => {
    const { srId, journeyDtoList } = journey;
    if (srId === -1) mainJourney.push(...journeyDtoList);
    else srJourneys.push(journey);
  });

  return { mainJourney, srJourneys };
}

// seperate journeys into main and sr
export const finalizeJourneys = (journeyGroupBySrList) => {
  const { mainJourney, srJourneys } = seperateIntoMainSR(journeyGroupBySrList);
  let srWaypoints = [];

  for (let key in srJourneys) {
    const currentSrJourney = srJourneys[key];
    srWaypoints.push(getFormattedSRWaypoints(currentSrJourney, mainJourney));
  }

  const finalSrWaypoints = generateRouteColors(srWaypoints);

  return {
    main: finalWaypointObject(
      -1,
      "main",
      "main",
      getFormattedWaypoints(mainJourney),
      "#0000ff",
      mainJourney[0].dateTime,
      mainJourney[mainJourney.length - 2].dateTime,
      "main"
    ),
    sr: finalSrWaypoints,
  };
};

// generate distinct colors
const generateRouteColors = (srWaypointObjectList) => {
  const suggestedColorCodes = [];

  const generateColor = () => {
    const color = randomColor({
      luminosity: "dark",
      hue: "random",
    });
    for (const colorCode of suggestedColorCodes) {
      if (chroma.deltaE(color, colorCode) < 5) return generateColor();
    }
    suggestedColorCodes.push(color);
    return color;
  };

  return srWaypointObjectList.map((waypoint) => ({
    ...waypoint,
    color: generateColor(),
  }));
};

// sorting waypoints by dateTime / TimeStamp
const sortWaypointsByTimeStamp = (waypointsList) => {
  return waypointsList.sort((a, b) => a.dateTime - b.dateTime);
};

// Final SR Waypoint Object
const getFormattedSRWaypoints = (journey, mainWaypoints) => {
  const {
    srId,
    srName,
    srTypeName: srType,
    journeyDtoList,
    customerName,
  } = journey;

  if (journeyDtoList.length === 0) {
    console.error(`SR Waypoints fetching failed for SRID: ${srId}`);
    return null;
  }

  const srJourney = journeyDtoList[0];
  const { startTime, endTime, startLatitude, startLongitude } = srJourney;

  const srWaypoints = [
    ...getSrWaypoints(startTime, endTime, mainWaypoints),
    srJourney,
  ];

  const formattedWaypoints = getFormattedWaypoints(srWaypoints);

  return finalWaypointObject(
    srId,
    srName,
    srType,
    {
      ...formattedWaypoints,
      origin: formattedWaypoints.waypoints[0],
      // origin: { lat: startLatitude, lng: startLongitude },
    },
    null,
    startTime,
    endTime,
    customerName
  );
};

// Filtering SR Waypoints using timestamps
export const getSrWaypoints = (
  originTimeStamp,
  destinationTimeStamp,
  mainWaypoints
) => {
    return mainWaypoints.filter(
      ({ dateTime }) =>
        dateTime > originTimeStamp && dateTime < destinationTimeStamp
    );
};

// formatted journey waypoints ({origing: {lat, lng}, waypoints: [{lat, lng}, ...])
const getFormattedWaypoints = (waypointsList) => {
  if (waypointsList.length === 0) return null;
  const { startLatitude, startLongitude } = waypointsList[0];
  const origin = locationObject(startLatitude, startLongitude);

  const waypoints = waypointsList.map(({ endLatitude, endLongitude }) =>
    locationObject(endLatitude, endLongitude)
  );
  return { origin, waypoints };
};

export const durationCalculator = (startTimeStamp, endTimeStamp, isShortTimeStamp = false) => {
  if (!startTimeStamp || !endTimeStamp) return null;
  return formatTimeDiff(Math.abs(endTimeStamp - startTimeStamp), isShortTimeStamp);
}

export const formatTimeDiff = (timeDiff, isShortTimeStamp = false) => {
  let timeDiffSec = Math.floor(timeDiff / 1000);
  let timeDiffMin = Math.floor(timeDiffSec / 60);
  let timeDiffHours = Math.floor(timeDiffMin / 60);
  timeDiffMin %= 60;
  timeDiffSec %= 60

  if (timeDiffSec > 30) {
    timeDiffMin++;
    if (timeDiffMin === 60) {
      timeDiffMin = 0;
      timeDiffHours++;
    }
  }
  
  let sec = isShortTimeStamp ? "S" : "sec";
  let min = isShortTimeStamp ? "M" : "min";
  let hours = isShortTimeStamp ? "H" : "hours";

  if (timeDiffMin === 0) return timeDiffSec+sec;
  else if (timeDiffHours === 0) return timeDiffMin+min;
  else return `${timeDiffHours+hours} ${timeDiffMin+min}`;
}

export const getFormattedDateTime = (timeStamp) => {
  if (!timeStamp) return null;

  const monthList = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const dateTime = new Date(timeStamp);
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  const date = dateTime.getDate();
  const month = monthList[dateTime.getMonth()];

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = `${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

  return {
    date,
    month,
    time: formattedTime,
  };
};
