import axios from "../../axios/axios-default";

 export function getKpis () {
      return axios.get("getKpisGrouped");
    //   return axios.get("getKpis");
}

export function getKpi (kpiType, srTypeID) {
    return axios.get("getKpisBySrTypeAndKpiType?srTypeId="+srTypeID+"&kpiType="+kpiType);
}


export async  function createBulkKpis(data){
    return  await axios({
        method: "POST",
        url: "createBulkKpis",
        data: data
    });
}


// export async  function updateWorkflow(data){
//     return  await axios({
//         method: "PUT",
//         url: "updateWorkFlow/"+data.id,
//         data: data
//     });
// }

export function DeleteKpi (kpiType, srTypId) {
    return axios.delete("deleteKpisBySrTypeAndKpiType?srTypeId="+srTypId+"&kpiType="+kpiType);
}

export function getActiveWorkflowByTypeAndSrTypeId (id) {
    return axios.get("getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId="+id);
}
