/* eslint-disable no-constant-condition */
import React, { useState, useEffect, useMemo , useRef  } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dropzone from "react-dropzone";
import axios from "../../../axios/axios-default";
import { notyDefault, notyTypes } from "../../Noty/NotyCustom";
import readXlsxFile from "read-excel-file";
import { Typography } from "@material-ui/core";
import { customerProperties } from "Util/Property/CustomerPorerties";
import * as moment from "moment";
import writeXlsxFile from "write-excel-file";
import UrlProperties from "Util/Property/UrlProperties";
import LoadingOverlay from "react-loading-overlay";
import MaterialTable from "material-table";
import Lottie from "react-lottie";
import animationData from "../../../assets/onsiteAnimation.json";
import { Row } from "rsuite";
import ReportView2 from "../Reports new/ReportView2";
import _ from "lodash";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import reportsProperties, {
  requiredFormData,
} from "Util/Property/OpeReportProperty";
import ExportIcon from "../../../assets/img/icons/export.svg";
import "./Reports.css";
import { getCustomerForm } from "../../../Util/Property/CustomerPorerties";
import ReactExport from "react-data-export";
import { FiDownload } from "react-icons/fi";
import pdfMake, { fonts } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { downloadAsXLSX } from "./excel-utils";
import OverflowTooltip from 'components/OverflowTooltip/OverflowTooltip';

pdfMake.vfs = pdfFonts.vfs;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function ReportView1(props) {
  const {
    open,
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
    uploadLoacation,
    acceptFileType,
    tableData,
    type,
    startDate,
    endDate,
    selectedReportType,
    pdfExport = false,
    technicianName,
    journeyDetails,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [subTableData = [], setSubTableData] = useState();
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileUpload2, setOpenFileUpload2] = useState(false);
  const [tableCols = [], setTableCols] = useState([]);
  const [spreedSheetData = [], setSpreedSheetData] = useState([]);
  const [isPdfDownload, setIsPdfDownload] = useState(false);
  const [reportFileName, setReportFileName] = useState("report");
  const [isFieldAgentJourney, setIsFieldAgentJourney] = useState(false);

  const ReportEssentailKeys = {
    FIELD_USER: "Field User",
    DATE: "Date",
    LOGGED_USER: "Report Generated By",
    REPORT_GENERATED_TIMESTAMP: "Report Generated Date Time",
    TOTAL_JOURNEY_DURATION: "Total Journey Duration",
    TOTAL_JOB_DURATION: "Total Job Duration",
    TOTAL_DURATION: "Total Duration",
    TOTAL_DISTANCE: "Total Distance",
  };

  const reportFieldType = {
    HEADER: "header",
    FOOTER: "footer",
  };

  const tableRef = useRef(null);

  const getCurrentDateTimeString = () => {
    const date = new Date();

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(date.getDate()).padStart(2, "0");
    const hh = String(date.getHours()).padStart(2, "0");
    const min = String(date.getMinutes()).padStart(2, "0");
    const ss = String(date.getSeconds()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
  };

  const [fieldAgentReportHeaders, setFieldAgentReportHeaders] = useState({
    [ReportEssentailKeys.FIELD_USER]: "",
    [ReportEssentailKeys.DATE]: "",
    [ReportEssentailKeys.LOGGED_USER]: "",
    [ReportEssentailKeys.REPORT_GENERATED_TIMESTAMP]: getCurrentDateTimeString(),
  });

  const [fieldAgentReportFooters, setFieldAgentReportFooters] = useState({
    [ReportEssentailKeys.TOTAL_JOURNEY_DURATION]: "",
    [ReportEssentailKeys.TOTAL_JOB_DURATION]: "",
    [ReportEssentailKeys.TOTAL_DURATION]: "",
    [ReportEssentailKeys.TOTAL_DISTANCE]: "",
  });

  const handleReportFields = (key, value, type) => {
    switch (type) {
      case reportFieldType.HEADER:
        setFieldAgentReportHeaders((prev) => ({ ...prev, [key]: value }));
        break;
      case reportFieldType.FOOTER:
        setFieldAgentReportFooters((prev) => ({ ...prev, [key]: value }));
        break;
      default:
        break;
    }
  };

  const baseUrl = UrlProperties.baseUrl.split(":8089")[0];

  const current = new Date();
  const date = `${current.getDate()}-${current.getMonth() +
    1}-${current.getFullYear()}`;

  const handleClose = () => {
    // setFile([]);
    fileUploadClose();
  };
  const fileUploadClose1 = () => {
    setOpenFileUpload2(false);
  };

  const rowRender = (rowData, key) => {
    if (key === "avg") {
      return (
        <div
          style={{
            // padding: '0.5rem',
            background:
              rowData["kpi"].value != null && rowData["avg"].value != null
                ? rowData["kpi"].value > rowData["avg"].value
                  ? "#92D050"
                  : rowData["kpi"].value < rowData["avg"].value
                  ? "#FF3300"
                  : rowData["kpi"].value === rowData["avg"].value
                  ? "#FFC000"
                  : null
                : null,
          }}
        >
          {rowData["avg"].value}
        </div>
      );
    }

    return (
      <div
        style={{
          padding: "0.5rem",
        }}
      >
        {key === "province"
          ? getProvince(rowData[key].value)
          : rowData[key].value}
      </div>
    );
  };

  const longRowRender = (rowData, key) => {
    return (
      <div title={rowData} className="remarks"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width: "200px",
          paddingRight: "40px",
        }}
      >
          {rowData}
      </div>
    );
  };

  useEffect(() => {
    const colsArr = [];
    if (tableData.length !== 0) {
      Object.entries(tableData[0]).forEach(([key, value]) => {
        let _val = reportsProperties.reqFieldsMap[key];
        if (value.req) {
          _val = {
            req: value.req,
            table: key,
          };
        }

        if (typeof _val !== "undefined") {
          let title;
          if (value.req) {
            title = key;
          } else {
            title = reportsProperties.reqFieldsMap[key]["table"];
          }
          if (title === "Title") {
            if (selectedReportType === "SR Type Wise") {
              title = "SR Name";
            } else if (selectedReportType === "Product Wise") {
              title = "Product Name";
            } else if (selectedReportType === "Employee Wise") {
              title = "Employee Name";
            } else if (selectedReportType === "Date Wise") {
              title = "SR Name";
            }
          }
          return colsArr.push({
            id: tableData[0][key].id,
            title: title,
            field: key,
            headerStyle: {
              backgroundColor: "#e6e4df",
              textAlign: "center",
            },
            cellStyle: {
              textAlign: "center",
              margin: "0",
              padding: "0",
            },
            render: (rowData) => {
              if (key === "remarks") {
                return longRowRender(rowData[key].value, key);
              } else {
                return rowRender(rowData, key);
              }
            },
          });
        }
      });
    }

    colsArr.sort(function(a, b) {
      return a.id - b.id;
    });
    const sDataArr = [];
    const sData = exportTable(tableData);
    sDataArr.push(sData);
    setSpreedSheetData(sDataArr);
    setTableCols(colsArr);
  }, []);

  useEffect(() => {
    let userName = localStorage.getItem("userFullName");
    if (!userName) return;
    handleReportFields(
      ReportEssentailKeys.LOGGED_USER,
      userName,
      reportFieldType.HEADER
    );
  }, []);

  useEffect(() => {}, [file]);

  const getProvince = (key) => {
    const obj = getCustomerForm().province.inputProps.options;
    const index = obj.findIndex((el) => el.value === key);
    if (obj[index] !== undefined) {
      return obj[index].displayValue;
    }
  };
  // const viewButtonClick = (event, rowData)=>{
  //   setSelectedData({
  //   num:rowData.num,
  //   srtype: rowData.srtype,
  //   srcount: rowData.srcount,
  //   avgtimetocomplete: Row.avgtimetocomplete,
  //   target: rowData.target
  //   })
  // }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "140px",
    width: "400px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#B22900",
    borderStyle: "dashed",
    backgroundColor: "#ffcec7",
    color: "#f73123",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const fileNameHandler = () => {
    let fileName = `${type ? type.value : "REPORT"}_of_${technicianName}`;
    fileName = `${fileName}_on_${startDate.replace(/-/g, "")}`;
    fileName = `${fileName}@${fieldAgentReportHeaders[
      ReportEssentailKeys.REPORT_GENERATED_TIMESTAMP
    ]
      .replace(" ", "_")
      .replace(/-/g, "")
      .replace(/:/g, "")}`;
    setReportFileName(fileName);
  };

  useEffect(() => {
    // this method only works for FIELD_AGENT_JOURNEY_REPORTS
    if (type.value !== "FIELD_AGENT_JOURNEY_REPORT") return;
    setIsFieldAgentJourney(true);
    if (technicianName) {
      fileNameHandler();
      handleReportFields(
        ReportEssentailKeys.FIELD_USER,
        technicianName,
        reportFieldType.HEADER
      );
    }
    if (startDate)
      handleReportFields(
        ReportEssentailKeys.DATE,
        startDate,
        reportFieldType.HEADER
      );
  }, [technicianName, type, startDate]);

  useEffect(() => {
    if (!isFieldAgentJourney || !journeyDetails) return;

    const {
      totalJourneyDuration,
      totalJobDuration,
      totalDistance,
      totalDuration,
    } = journeyDetails;
    handleReportFields(
      ReportEssentailKeys.TOTAL_JOURNEY_DURATION,
      totalJourneyDuration,
      reportFieldType.FOOTER
    );
    handleReportFields(
      ReportEssentailKeys.TOTAL_JOB_DURATION,
      totalJobDuration,
      reportFieldType.FOOTER
    );
    handleReportFields(
      ReportEssentailKeys.TOTAL_DISTANCE,
      totalDistance,
      reportFieldType.FOOTER
    );
    handleReportFields(
      ReportEssentailKeys.TOTAL_DURATION,
      totalDuration,
      reportFieldType.FOOTER
    );
  }, [isFieldAgentJourney, journeyDetails]);

  const getKeyByValue = (obj, value) => {
    const key = Object.keys(obj).find((key) => obj[key].req === value);
    return key;
  };

  const minutesToHoursAndMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  };

  const assignTableData = (data) => {
    const tableArr = [];
    var num = 1;
    const fieldKeys = reportsProperties.reqFieldsMap;

    data.forEach((record) => {
      var obj = {};
      Object.assign(obj, {
        num: {
          id: "1",
          value: num,
        },
        subTableData: record["third_table"],
      });
      var keys = Object.keys(record);
      const reportName = type.value;
      const tableFields = requiredFormData.find((ob) => ob.value === reportName)
        .tableFields.table2;

      keys.forEach((field) => {
        const key = getKeyByValue(fieldKeys, field);
        if (key !== undefined) {
          const fieldObj = tableFields.find((ob) => ob.field === key);
          if (fieldObj != null) {
            obj[key] = {
              id: fieldObj.id,
              value:
                record[field] == null
                  ? "-"
                  : key === "avgHrs" || key === "ageHrs"
                  ? minutesToHoursAndMinutes(record[field])
                  : key === "completedPercentage" || key === "percentage"
                  ? `${record[field]}%`
                  : record[field],
            };
          }
        }
      });

      tableArr.push(obj);
      num++;
    });
    return tableArr;
  };
  const borders = {
    top: { style: "thin" },
    bottom: { style: "thin" },
    left: { style: "thin" },
    right: { style: "thin" },
  };

  const alignment = {
    horizontal: "center",
  };

  // pdf utils
  const exportTableAsPdf = () => {
    if (!spreedSheetData || spreedSheetData.length === 0) return;
    const { columns, data } = spreedSheetData[0];
    const pdfFormat = extractPdfFormat(
      `${type.displayValue || "Report"}`,
      columns,
      data
    );

    pdfMake.createPdf(pdfFormat).download(`${reportFileName}.pdf`);
  };

  // this is for extra details fetcher
  const customObjects = (object, type) => {
    const objectKeys = Object.keys(object);
    return objectKeys.map((key, index) => ({
      text: `${key}: ${object[key]}`,
      style: "subheader",
      marginTop: index === 0 && type === reportFieldType.FOOTER ? 20 : 0,
      marginBottom:
        index === objectKeys.length - 1 && type === reportFieldType.FOOTER
          ? 20
          : 5,
    }));
  };

  const extractPdfFormat = (title, columns, rows) => {
    return {
      pageOrientation: "landscape",
      content: [
        { text: title.toUpperCase(), style: "header" },
        // { text: `Name: ${technicianName}`, style: "subheader" },
        // { text: `Date: ${startDate}`, style: "subheader", marginBottom: 20 },
        ...customObjects(fieldAgentReportHeaders, reportFieldType.HEADER),
        {
          table: {
            headerRows: 1,
            widths: columns.map((_) => "auto"),
            body: [
              columns.map((column) => ({
                text: column.title,
                style: "tableHeader",
              })),
              ...rows.map((row) =>
                row.map((rowData, index) => ({
                  text: rowData.value,
                  style:
                    columns[index].title === "Remarks"
                      ? "tableBodyCellRemarks"
                      : "tableBodyCell", // Apply different style for "Remarks" column
                }))
              ),
            ],
          },
          layout: {
            //   hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 1 : 0), // Top and bottom border
            vLineWidth: (i, node) =>
              i === 0 || i === node.table.widths.length ? 1 : 0, // Left and right border
            hLineColor: () => "#E5E5E5", // Optional: Set border color
            vLineColor: () => "#E5E5E5", // Optional: Set border color
          },
        },
        ...customObjects(fieldAgentReportFooters, reportFieldType.FOOTER),
      ],
      styles: {
        header: {
          fontSize: 15,
          bold: true,
          margin: [0, 0, 0, 10],
          alignment: "center",
        },
        subheader: {
          fontSize: 10,
          margin: [0, 0, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: "black", // Text color
          fillColor: "#E5E5E5", // Blue background
          alignment: "center", // Center-align text
          margin: [4, 4, 4, 4], // Padding inside cells
          border: 0,
        },
        tableBodyCell: {
          fontSize: 9,
          alignment: "center",
        },
        tableBodyCellRemarks: {
          fontSize: 7, // This will be applied only for "Remarks" column since it's a lengthy field
          alignment: "left",
        },
      },
    };
  };

  const exportTableAsXLSX = () => {
    const subHeaders = customObjects(fieldAgentReportHeaders).map(
      (ob) => ob.text
    );
    const footers = customObjects(fieldAgentReportFooters).map((ob) => ob.text);

    let { columns, data } = spreedSheetData[0];
    columns = columns.map((column) => column.title);
    data = data.map((row) => row.map((r) => r.value));

    const dataList = [
      type.displayValue,
      ...subHeaders,
      columns,
      ...data,
      ...footers,
    ];

    downloadAsXLSX(dataList, `${reportFileName}.xlsx`);
  };

  const exportTable = (tableData) => {
    const columnsArr = [];
    const dataArr = [];
    tableData.forEach((obj, id) => {
      const arr = [];

      let sortedObj = Object.entries(obj).sort((a, b) => a[1].id - b[1].id);
      sortedObj.forEach((el) => {
        const val = reportsProperties.reqFieldsMap[el[0]];

        if (val !== undefined) {
          if (
            columnsArr.filter((__el) => __el.title === val["table"]).length ===
            0
          ) {
            let title = val["table"];
            if (val["table"] === "Title") {
              if (selectedReportType === "SR Type Wise") {
                title = "SR Name";
              } else if (selectedReportType === "Product Wise") {
                title = "Product Name";
              } else if (selectedReportType === "Employee Wise") {
                title = "Employee Name";
              } else if (selectedReportType === "Date Wise") {
                title = "SR Name";
              }
            }

            columnsArr.push({
              title: title,
              style: {
                alignment: { ...alignment, wrapText: true, vertical: "top" },
                border: borders,
                font: { sz: "12", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "ceedf2" } },
              },
            });
          }

          let style;

          if (el[0] === "avg") {
            if (obj["kpi"].value !== null && el[1].value !== null) {
              if (obj["kpi"].value > el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "92D050" } },
                };
              } else if (obj["kpi"].value < el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "FF3300" } },
                };
              } else if (obj["kpi"].value === el[1].value) {
                style = {
                  fill: { patternType: "solid", fgColor: { rgb: "FFC000" } },
                };
              }
            }
          }
          arr.push({
            value: el[1].value !== null ? el[1].value : "",
            style: style
              ? { ...style, alignment: alignment, border: borders }
              : { alignment: alignment, border: borders },
          });
        }
      });

      dataArr.push(arr);
    });

    return {
      columns: columnsArr,
      data: dataArr,
    };
  };

  return (
    <div>
      {openFileUpload2 ? (
        <ReportView2
          open={openFileUpload2}
          type={type}
          fileUploadClose={fileUploadClose1}
          tableData={subTableData}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <div></div>
      )}
      <Dialog
        maxWidth={
          tableCols.length <= 5 ? "sm" : tableCols.length <= 9 ? "md" : "lg"
        }
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <DialogContent>
            <div className={"align-title"}>
              <div
                style={{ width: "10px", height: "10px" }}
              ></div>
              <b>
                {!isFieldAgentJourney ? (
                  <>
                    From: {`${startDate}     `} To: {endDate}
                  </>
                ) : (
                  <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"80px"}}>
                    <p>Field agent: <span style={{ fontWeight: 400 }}>{technicianName}</span></p>
                    <p>Date: <span style={{ fontWeight: 400 }}>{startDate}</span></p>
                  </div>
                )}
              </b>
              {spreedSheetData.length !== 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {isFieldAgentJourney && (
                    <div>
                      <button
                        onClick={exportTableAsPdf}
                        className="export-button"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          borderRadius: "20px",
                          color: "black",
                          padding: "10px 15px",
                          fontSize: "11px",
                          fontWeight: "600",
                        }}
                      >
                        PDF <FiDownload style={{ fontSize: "16px" }} />
                      </button>
                    </div>
                  )}
                  {!isFieldAgentJourney ? (
                    <ExcelFile
                      filename={
                        technicianName
                          ? reportFileName
                          : `${type.displayValue}-${date}-p1`
                      }
                      element={
                        <button className="export-button">
                          <img
                            src={ExportIcon}
                            alt="test"
                            className="export-icon"
                          />
                        </button>
                      }
                    >
                      <ExcelSheet
                        dataSet={spreedSheetData}
                        name="Organization"
                      />
                    </ExcelFile>
                  ) : (
                    <button
                      className="export-button"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        borderRadius: "20px",
                        color: "black",
                        padding: "10px 15px",
                        fontSize: "11px",
                        fontWeight: "600",
                      }}
                      onClick={exportTableAsXLSX}
                    >
                      EXCEL <FiDownload style={{ fontSize: "16px" }} />
                    </button>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={"material-grid-wrapper"}>
                    <MaterialTable
                      size="xl"
                      title={
                        <div>
                          <Typography
                            variant="h6"
                            style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                          >
                            {type.displayValue}
                          </Typography>
                        </div>
                      }
                      columns={tableCols}
                      data={tableData}
                      tableRef={tableRef}
                      options={{
                        actionsColumnIndex: -1,
                        selectionProps: (rowData) => {},
                        pageSize: 5,
                        sorting: false,
                        search: false,
                        columnsButton: true,
                        padding: "dense",
                        maxBodyHeight: "40vh",
                        headerStyle: {
                          fontSize: "0.7rem",
                          textAlignL: "center",
                        },
                        rowStyle: {
                          fontSize: "0.6rem",
                          fontWeight: 450,
                          lineHeight: 100,
                        },
                      }}
                      onRowClick={(event, rowData, toggleDetailPanel) => {
                        if (rowData.subTableData) {
                          const data = assignTableData(rowData.subTableData);
                          setSubTableData(data);
                          setOpenFileUpload2(true);
                        }
                      }}
                    />{" "}
                  </div>
                </GridItem>
              </GridContainer>
            }
          </DialogContent>
          <DialogActions>
            <Button
              className={"generic-button-class div-button-wrapper"}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            {/* spreedSheetData */}
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}

// return (
//   <div>
//     <ReportView2
//       open={openFileUpload2}
//       type={type}
//       fileUploadClose={fileUploadClose1}
//       tableData={subTableData}
//       // tableData={assignTableData()}
//     />

//     {/* <MaterialTable
//                         title="Today - Efficiency Summary - Time"
//                         columns={[
//                           { title: "#", field: "num" , headerStyle:{padding:"5px", textAlign:'left'}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
//                           { title: "SR Type", field: "srtype" , headerStyle:{padding:"5px", textAlign:'left'}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
//                           { title: "SR Count", field: "srcount" , headerStyle:{padding:"5px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
//                           { title: "AVG Time to Complete (Days)", field: "avgtimetocomplete" , headerStyle:{padding:"5px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
//                           { title: "Target/ KPI (Days)", field: "target", headerStyle:{padding:"5px"} , cellStyle:{textAlign:'left',paddingLeft:"0px"}},
//                         ]}
//                         data={[
//                             { num: '1', srtype: 'Breakdown', srcount: 20, avgtimetocomplete: 2 , target: 2},
//                             { num: '2', srtype: 'Services', srcount: 28, avgtimetocomplete: 3, target:2 },
//                             { num: '3', srtype: 'Sales', srcount: 3, avgtimetocomplete: 10, target: 11 }]}
//                             options={{
//                           actionsColumnIndex: -1,
//                           // selection: true,
//                           exportButton: true,
//                           selectionProps: rowData => {
//                             // const selectable = rowData.assignedTo === null;
//                             // //NOTE:: tableData object is not in documentation
//                             // rowData.tableData.disabled = !selectable;
//                             // return {
//                             //     disabled: !selectable
//                             // }
//                           },
//                           // pageSize: 10,
//                           // filtering: true,
//                           //maxBodyHeight:"85vh",
//                           sorting: false,
//                           search: false
//                         }}
//                         /> */}

//     {type == 'ON_SITE_TECHNICIANS_SUMMARY_REPORT' ? (
//       <Dialog
//         maxWidth="md"
//         fullWidth={true}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <LoadingOverlay active={isLoading} spinner text="Loading ...">
//           {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
//           <DialogContent>
//             {
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <div className={'material-grid-wrapper'}>
//                     <b>
//                       <center>From:2022-06-11 To:2022-07-02</center>
//                     </b>
//                     <MaterialTable
//                       title={
//                         <div>
//                           <Typography
//                             variant="h6"
//                             style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
//                           >
//                             On Site Field Agent Report
//                           </Typography>
//                         </div>
//                       }
//                       columns={[
//                         {
//                           title: 'Attended Field Agent',
//                           field: 'technicianName',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Attend Date',
//                           width: '10%',
//                           field: 'assignedDate',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Day Start',
//                           width: '10%',
//                           field: 'startTime',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Day End',
//                           width: '10%',
//                           field: 'endTime',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Assigned',
//                           width: '10%',
//                           field: 'assigned',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '0px 0px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Not Attend',
//                           width: '10%',
//                           field: 'active',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '0px 0px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'On Site',
//                           width: '5%',
//                           field: 'onSite',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '0px 0px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                           render: (row) => (
//                             <div>
//                               {row.onSite == 'On Site' ? (
//                                 <center>
//                                   <Lottie
//                                     options={defaultOptions}
//                                     height={25}
//                                     width={25}
//                                     isStopped={false}
//                                     isPaused={false}
//                                   />
//                                 </center>
//                               ) : (
//                                 'Off Site'
//                               )}
//                             </div>
//                           ),
//                         },
//                         {
//                           title: 'Completed',
//                           width: '10%',
//                           field: 'completed',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '0px 0px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         // { title: "Pending", field: "pending", headerStyle:{padding:"0px 10px", textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},
//                         {
//                           title: 'Incomplete',
//                           width: '10%',
//                           field: 'incomplete',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '0px 0px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         // { title: "Job Count", field: "countTotalJob" , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'center',paddingLeft:"0px"}}
//                       ]}
//                       data={[
//                         {
//                           technicianId: '21',
//                           technicianName: 'Layantha Lakranda',
//                           assignedDate: ['05/01/2022'],
//                           startTime: '10:15:30',
//                           endTime: '14:00:15',
//                           assigned: '4',
//                           active: '0',
//                           onSite: 'On Site',
//                           completed: '2',
//                           pending: '1',
//                           incomplete: '0',
//                         },
//                         {
//                           technicianId: '15',
//                           technicianName: 'Tharaka Dhanushka',
//                           assignedDate: '05/01/2022',
//                           startTime: '14:20:30',
//                           endTime: '17:15:00',
//                           assigned: '2',
//                           active: '1',
//                           onSite: 'On Site',
//                           completed: '0',
//                           pending: '0',
//                           incomplete: '0',
//                         },
//                       ]}
//                       options={{
//                         actionsColumnIndex: -1,
//                         // selection: true,
//                         exportButton: true,
//                         selectionProps: (rowData) => {
//                           // const selectable = rowData.assignedTo === null;
//                           // //NOTE:: tableData object is not in documentation
//                           // rowData.tableData.disabled = !selectable;
//                           // return {
//                           //     disabled: !selectable
//                           // }
//                         },
//                         pageSize: 5,
//                         // filtering: true,
//                         //maxBodyHeight:"85vh",
//                         sorting: false,
//                         search: false,
//                         padding: 'dense',
//                         maxBodyHeight: '70vh',
//                         headerStyle: {
//                           fontSize: '0.7rem',
//                           textAlignL: 'center',
//                         },
//                         rowStyle: {
//                           fontSize: '0.6rem',
//                           fontWeight: 450,
//                           padding: '5rem',
//                         },
//                       }}
//                       // options={{
//                       //   exportButton: true
//                       // }}
//                       onRowClick={
//                         (event, rowData, toggleDetailPanel) => {
//                           //setType1(this.type)
//                           // setOpenFileUpload2(true);
//                         }
//                         // Get your id from rowData and use with link.
//                         //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
//                         //event.stopPropagation();
//                       }
//                     />
//                   </div>
//                 </GridItem>
//               </GridContainer>
//             }
//           </DialogContent>
//           <DialogActions>
//             {/* {uploadLoacation === "customer" ?
//                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
//                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
//                           </Button>
//                       :""}																														 */}

//             <Button
//               className={'generic-button-class div-button-wrapper'}
//               onClick={handleClose}
//               color="primary"
//               variant="contained"
//             >
//               Cancel
//             </Button>
//             {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
//                           Uplaod
//                       </Button> */}
//           </DialogActions>
//         </LoadingOverlay>
//       </Dialog>
//     ) : //           : type =="sr_reports"?
//     //           <Dialog
//     // maxWidth="md"
//     // fullWidth={true}
//     //     open={open}
//     //     onClose={handleClose}
//     //     aria-labelledby="alert-dialog-title"
//     //     aria-describedby="alert-dialog-description"
//     // >
//     //     <LoadingOverlay
//     //         active={isLoading}
//     //         spinner
//     //         text='Loading ...'>
//     //         {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
//     //         <DialogContent>
//     //         {
//     //           <GridContainer>
//     //           <GridItem xs={12} sm={12} md={12}>
//     //           <div className={"material-grid-wrapper"}>
//     //               <MaterialTable
//     //               title="SR - History Reports"

//     //               columns={[
//     //                 { title: "Field Agent", field: "fieldagent" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{ textAlign:'center'}},
//     //                 { title: "Assign Date", field: "assigndate" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Day Start", field: "daystart" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Day End", field: "avgtimetocomplete" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',  textAlign:'center'}},
//     //                 { title: "Assigned", field: "kpi",  headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Not Attend", field: "num" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Attended-Delay", field: "srtype" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Attended-Not Delay", field: "srcount" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
//     //                 { title: "Complete", field: "avgtimetocomplete" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',  textAlign:'center'}},
//     //                 { title: "Incomplete", field: "kpi",  headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{fontSize:'14px', textAlign:'center'}}

//     //               ]}
//     //               data={[
//     //                 { num: 1, srtype: 'Breakdown', srcount: 20, avgtimetocomplete: 2 , kpi: 2},
//     //                 { num: 2, srtype: 'Services', srcount: 28, avgtimetocomplete: 3, kpi:2 },
//     //                 { num: 3, srtype: 'Sales', srcount: 3, avgtimetocomplete: 10, kpi: 11 }
//     //               ]}

//     //               options={
//     //                 {
//     //                 actionsColumnIndex: -1,
//     //                 // selection: true,
//     //                 exportButton: true,
//     //                 selectionProps: rowData => {
//     //                   // const selectable = rowData.assignedTo === null;
//     //                   // //NOTE:: tableData object is not in documentation
//     //                   // rowData.tableData.disabled = !selectable;
//     //                   // return {
//     //                   //     disabled: !selectable
//     //                   // }
//     //                 },
//     //                 pageSize: 5,
//     //                 // filtering: true,
//     //                 //maxBodyHeight:"85vh",
//     //                 sorting: false,
//     //                 search: false,
//     //                 padding: "dense"
//     //               }}

//     //               // options={{
//     //               //   exportButton: true
//     //               // }}
//     //               onRowClick={(event, rowData, toggleDetailPanel) => {                          //setType1(this.type)
//     //               setOpenFileUpload2(true)
//     //              }
//     //                 // Get your id from rowData and use with link.
//     //                 //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
//     //                 //event.stopPropagation();
//     //               }

//     //             /> </div></GridItem></GridContainer>
//     //             }</DialogContent></LoadingOverlay></Dialog>

//     type == 'Efficiency_Summary_Time' ? (
//       <Dialog
//         maxWidth="sm"
//         fullWidth={true}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <LoadingOverlay active={isLoading} spinner text="Loading ...">
//           {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
//           <DialogContent>
//             {
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <div className={'material-grid-wrapper'}>
//                     <b>
//                       <center>From:2022-06-11 To:2022-07-02</center>
//                     </b>
//                     <MaterialTable
//                       title={
//                         <div>
//                           <Typography
//                             variant="h6"
//                             style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
//                           >
//                             Efficiency Summary - Time
//                           </Typography>
//                         </div>
//                       }
//                       columns={[
//                         {
//                           title: '#',
//                           width: '10%',
//                           field: 'num',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'SR Type',
//                           width: '20%',
//                           field: 'srtype',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'SR Count',
//                           width: '25%',
//                           field: 'srcount',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Age (Days)',
//                           width: '30%',
//                           field: 'avgtimetocomplete',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                           render: (rowData) => (
//                             <div
//                               style={{
//                                 width: '20%',
//                                 marginLeft: '40%',
//                                 background:
//                                   rowData['avgtimetocomplete'] <
//                                   rowData['kpi']
//                                     ? '#92D050'
//                                     : rowData['avgtimetocomplete'] >
//                                       rowData['kpi']
//                                     ? ' #FF3300'
//                                     : '#FFC000',
//                               }}
//                             >
//                               {rowData['avgtimetocomplete']}
//                             </div>
//                           ),
//                         },
//                         {
//                           title: 'KPI',
//                           width: '25%',
//                           field: 'kpi',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                       ]}
//                       data={[
//                         {
//                           num: 1,
//                           srtype: 'Breakdown',
//                           srcount: 20,
//                           avgtimetocomplete: 2,
//                           kpi: 2,
//                         },
//                         {
//                           num: 2,
//                           srtype: 'Services',
//                           srcount: 28,
//                           avgtimetocomplete: 3,
//                           kpi: 2,
//                         },
//                         {
//                           num: 3,
//                           srtype: 'Sales',
//                           srcount: 3,
//                           avgtimetocomplete: 10,
//                           kpi: 11,
//                         },
//                       ]}
//                       options={{
//                         actionsColumnIndex: -1,
//                         // selection: true,
//                         exportButton: true,
//                         selectionProps: (rowData) => {
//                           // const selectable = rowData.assignedTo === null;
//                           // //NOTE:: tableData object is not in documentation
//                           // rowData.tableData.disabled = !selectable;
//                           // return {
//                           //     disabled: !selectable
//                           // }
//                         },
//                         pageSize: 5,
//                         // filtering: true,
//                         //maxBodyHeight:"85vh",
//                         sorting: false,
//                         search: false,
//                         padding: 'dense',
//                         maxBodyHeight: '70vh',
//                         headerStyle: {
//                           fontSize: '0.7rem',
//                           textAlignL: 'center',
//                         },
//                         rowStyle: {
//                           fontSize: '0.6rem',
//                           fontWeight: 450,
//                           padding: '5rem',
//                         },
//                       }}
//                       // options={{
//                       //   exportButton: true
//                       // }}
//                       onRowClick={
//                         (event, rowData, toggleDetailPanel) => {
//                           //setType1(this.type)
//                           setOpenFileUpload2(true);
//                         }
//                         // Get your id from rowData and use with link.
//                         //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
//                         //event.stopPropagation();
//                       }
//                     />{' '}
//                   </div>
//                 </GridItem>
//               </GridContainer>
//             }
//           </DialogContent>
//           <DialogActions>
//             {/* {uploadLoacation === "customer" ?
//                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
//                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
//                           </Button>
//                       :""}																														 */}

//             <Button
//               className={'generic-button-class div-button-wrapper'}
//               onClick={handleClose}
//               color="primary"
//               variant="contained"
//             >
//               Cancel
//             </Button>
//             {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
//                           Uplaod
//                       </Button> */}
//           </DialogActions>
//         </LoadingOverlay>
//       </Dialog>
//     ) : type == 'Efficiency_Summary_Count' ? (
//       <Dialog
//         maxWidth="lg"
//         fullWidth={true}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <LoadingOverlay active={isLoading} spinner text="Loading ...">
//           {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
//           <DialogContent>
//             {
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <div className={'material-grid-wrapper'}>
//                     <b>
//                       <center>From:2022-06-11 To:2022-07-02</center>
//                     </b>
//                     <MaterialTable
//                       size="xl"
//                       title={
//                         <div>
//                           <Typography
//                             variant="h6"
//                             style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
//                           >
//                             Efficiency Summary - Count
//                           </Typography>
//                         </div>
//                       }
//                       // columns={[
//                       //   {
//                       //     title: '#',
//                       //     field: 'num',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'Title',
//                       //     field: 'title',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'Count',
//                       //     field: 'count',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'Completed Count',
//                       //     field: 'completedCount',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'Completed Percentage',
//                       //     field: 'completedPercentage',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'New',
//                       //     field: 'new',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'Assigned',
//                       //     field: 'assigned',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       //   {
//                       //     title: 'En Route',
//                       //     field: 'enRoute',
//                       //     headerStyle: {
//                       //       backgroundColor: '#e6e4df',
//                       //       padding: '5px',
//                       //       textAlign: 'center',
//                       //     },
//                       //     cellStyle: { textAlign: 'center' },
//                       //   },
//                       // {
//                       //   title: 'On Hold',
//                       //   field: 'onHold',
//                       //   headerStyle: {
//                       //     backgroundColor: '#e6e4df',
//                       //     padding: '5px',
//                       //     textAlign: 'center',
//                       //   },
//                       //   cellStyle: { textAlign: 'center' },
//                       // },
//                       // ]}
//                       columns={tableCols}
//                       data={tableData}
//                       options={{
//                         actionsColumnIndex: -1,
//                         // selection: true,
//                         exportButton: true,
//                         selectionProps: (rowData) => {
//                           // const selectable = rowData.assignedTo === null;
//                           // //NOTE:: tableData object is not in documentation
//                           // rowData.tableData.disabled = !selectable;
//                           // return {
//                           //     disabled: !selectable
//                           // }
//                         },
//                         pageSize: 5,
//                         // filtering: true,
//                         //maxBodyHeight:"85vh",
//                         sorting: false,
//                         search: false,
//                         padding: 'dense',
//                         maxBodyHeight: '70vh',
//                         headerStyle: {
//                           fontSize: '0.7rem',
//                           textAlignL: 'center',
//                         },
//                         rowStyle: {
//                           fontSize: '0.6rem',
//                           fontWeight: 450,
//                           padding: '5rem',
//                         },
//                       }}
//                       // options={{
//                       //   exportButton: true
//                       // }}
//                       onRowClick={
//                         (event, rowData, toggleDetailPanel) => {
//                           //setType1(this.type)

//                           const data = assignTableData(rowData.subTableData);
//                           setSubTableData(data);

//                           setOpenFileUpload2(true);
//                           // setOpenFileUpload2(true);
//                         }
//                         // Get your id from rowData and use with link.
//                         //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
//                         //event.stopPropagation();
//                       }
//                     />{' '}
//                   </div>
//                 </GridItem>
//               </GridContainer>
//             }
//           </DialogContent>
//           <DialogActions>
//             {/* {uploadLoacation === "customer" ?
//                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
//                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
//                           </Button>
//                       :""}																														 */}

//             <Button
//               className={'generic-button-class div-button-wrapper'}
//               onClick={handleClose}
//               color="primary"
//               variant="contained"
//             >
//               Cancel
//             </Button>
//             {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
//                           Uplaod
//                       </Button> */}
//           </DialogActions>
//         </LoadingOverlay>
//       </Dialog>
//     ) : type == 'Average_field_Cost' ? (
//       <Dialog
//         maxWidth="lg"
//         fullWidth={true}
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <LoadingOverlay active={isLoading} spinner text="Loading ...">
//           {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
//           <DialogContent>
//             {
//               <GridContainer>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <div className={'material-grid-wrapper'}>
//                     <b>
//                       <center>From:2022-06-11 To:2022-07-02</center>
//                     </b>
//                     <MaterialTable
//                       title={
//                         <div>
//                           <Typography
//                             variant="h6"
//                             style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
//                           >
//                             Average field Cost
//                           </Typography>
//                         </div>
//                       }
//                       columns={[
//                         {
//                           title: '#',
//                           field: 'num',
//                           width: '1px',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Department',
//                           width: '10%',
//                           field: 'department',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Total Field Visits Count',
//                           field: 'totalfieldvisitscount',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Total Cost Per Field Job',
//                           field: 'totalcostperfieldjob',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Total Cost Per Travelling',
//                           field: 'totalcostpertravelling',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'Total Cost Per Idling Time',
//                           field: 'totalcostperidlingtime',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'AVG Cost',
//                           width: '10%',
//                           field: 'avgcost',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                         {
//                           title: 'AVG Cost Per Field Jobs',
//                           field: 'avgcostperfieldjobs',
//                           headerStyle: {
//                             backgroundColor: '#e6e4df',
//                             padding: '5px',
//                             textAlign: 'center',
//                           },
//                           cellStyle: { textAlign: 'center' },
//                         },
//                       ]}
//                       data={[
//                         {
//                           num: '1',
//                           department: 'Sales',
//                           totalfieldvisitscount: 150,
//                           totalcostperfieldjob: 15000.0,
//                           totalcostpertravelling: 10000.0,
//                           totalcostperidlingtime: 2500.0,
//                           avgcost: 27500.0,
//                           customervisit: 5,
//                           inprogress: 5,
//                           complete: 4,
//                           hold: 1,
//                           approvalprocess1: 1,
//                           avgcostperfieldjobs: 183.33,
//                         },
//                         {
//                           num: '2',
//                           department: 'Technical',
//                           totalfieldvisitscount: 75,
//                           totalcostperfieldjob: 12500.0,
//                           totalcostpertravelling: 9800.0,
//                           totalcostperidlingtime: 3500.0,
//                           avgcost: 25800.0,
//                           customervisit: 2,
//                           inprogress: 2,
//                           complete: 1,
//                           hold: 1,
//                           approvalprocess1: '-',
//                           avgcostperfieldjobs: 344.0,
//                         },
//                         {
//                           num: '3',
//                           department: 'Transport',
//                           totalfieldvisitscount: 230,
//                           totalcostperfieldjob: 25300.0,
//                           totalcostpertravelling: 17460.0,
//                           totalcostperidlingtime: 4500.0,
//                           avgcost: 47260.0,
//                           customervisit: 7,
//                           inprogress: 7,
//                           complete: 5,
//                           hold: 2,
//                           approvalprocess1: 2,
//                           avgcostperfieldjobs: 205.48,
//                         },
//                       ]}
//                       options={{
//                         actionsColumnIndex: -1,
//                         // selection: true,
//                         exportButton: true,
//                         selectionProps: (rowData) => {
//                           // const selectable = rowData.assignedTo === null;
//                           // //NOTE:: tableData object is not in documentation
//                           // rowData.tableData.disabled = !selectable;
//                           // return {
//                           //     disabled: !selectable
//                           // }
//                         },
//                         pageSize: 5,
//                         // filtering: true,
//                         //maxBodyHeight:"85vh",
//                         sorting: false,
//                         search: false,
//                         padding: 'dense',
//                         maxBodyHeight: '70vh',
//                         headerStyle: {
//                           fontSize: '0.7rem',
//                           textAlignL: 'center',
//                         },
//                         rowStyle: {
//                           fontSize: '0.6rem',
//                           fontWeight: 450,
//                           padding: '5rem',
//                         },
//                       }}
//                       // options={{
//                       //   exportButton: true
//                       // }}
//                       onRowClick={
//                         (event, rowData, toggleDetailPanel) => {
//                           //setType1(this.type)
//                           setOpenFileUpload2(true);
//                         }
//                         // Get your id from rowData and use with link.
//                         //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
//                         //event.stopPropagation();
//                       }
//                     />
//                   </div>
//                 </GridItem>
//               </GridContainer>
//             }
//           </DialogContent>
//           <DialogActions>
//             {/* {uploadLoacation === "customer" ?
//                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
//                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
//                           </Button>
//                       :""}																														 */}

//             <Button
//               className={'generic-button-class div-button-wrapper'}
//               onClick={handleClose}
//               color="primary"
//               variant="contained"
//             >
//               Cancel
//             </Button>
//             {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
//                           Uplaod
//                       </Button> */}
//           </DialogActions>
//         </LoadingOverlay>
//       </Dialog>
//     ) : null}

//     {/* {uploadLoacation === "customer" ?
//                           <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
//                               <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>
//                           </Button>
//                       :""}																														 */}

//     {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
//                           Uplaod
//                       </Button> */}
//   </div>
// );
