import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Style/Style';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HourglassEmptyIcon from '../../assets/img/highlights/HourglassEmptyIcon.png';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SkeletonTypeOne from './Components/SkeletonTypeOne';
import CancelIcon from '@material-ui/icons/Cancel';
import CommuteIcon from '@material-ui/icons/Commute';
import Viewmore1 from './Viewmore/Viewmore1';
import IconSelector from 'components/IconSelector/IconSelector';
import axios from '../../axios/axios-default';
import LastUpdate from './LastUpdate';
import urlProperties from 'Util/Property/UrlProperties';
const useStyles = makeStyles(styles);

export default function HighlightsTypeOne(props) {
  const INTERVAL = Number(60000 * 10);
  const DASHBOARDPATH = urlProperties.mainDomain + '/dashboard';
  const {
    loading,
    title,
    subTitle,
    colorCode,
    iconName,
    hasSub,
    icon,
    tileColor,
    categoryType,
    action,
    dataRow,
  } = props;
  const classes = useStyles();
  const [openViewMore1, setOpenViewMore1] = useState(false);
  const [tableTitle, setTableTitle] = useState('');
  const [firstTab, setFirstTab] = useState([]);
  const [data, setData] = useState([]);
  const [loadingTile, setLoadingTile] = useState(false);
  const [lastUpdate, setLastUpdate] = React.useState('');
  const getValue = (val) => {
    var value = 0;
    if (val < 10) {
      return '0' + val;
    } else {
      return val;
    }
  };

  const onClickHandle = (title, firstTab) => {
    console.log('title ', title);
    console.log('firstTab ', firstTab);

    if (firstTab != null) {
      setTableTitle(title);
      setFirstTab(firstTab);
      setOpenViewMore1(true);
    }
  };

  const viewMoreClose = () => {
    setOpenViewMore1(false);
  };

  const handleIconImage = (iconImage) => {
    // console.log("Image Icon Name",iconImage);
  };

  React.useEffect(() => {
    intervalFun();
  }, []);

  const intervalFun = () => {
    const interval = setInterval(function() {
      if ('/trackable/dashboard' != window.location.pathname) {
        clearInterval(interval);
      } else {
        getDataForTiles(dataRow);
      }
    }, INTERVAL);
  };

  useEffect(() => {
    let key = 'config_' + dataRow.id;
    let oldData = localStorage.getItem(key);

    if (oldData != null && oldData != undefined) {
      setLoadingTile(true);
      let json = JSON.parse(oldData);
      setLastUpdate(json.date);
      setData(json.data);
      setLoadingTile(false);
    } else {
      setLoadingTile(true);
      getDataForTiles(dataRow);
    }
  }, [dataRow]);

  const refresh = () => {
    setLoadingTile(true);
    getDataForTiles(dataRow);
  };

  const getDataForTiles = async (config) => {
    let apiString = '';
    let newDataArray = [];
    console.log(config);
    if (config !== null) {
      // console.log("AAAAAAAAAAAAAAAA____________++++++++++++++++++++############",config.action)

      if (config.action === 'Field Expenses') {
        apiString = 'getCostHighlight?highlightId=' + config.id;
      } else if (config.action === 'Job Details') {
        apiString = 'getJobInPeriodCountHighlight?highlightId=' + config.id;
        //   apiString = 'getJobCountHighlight?highlightId=' + config.id;
      } else if (config.action == 'Staff Activities') {
        apiString = 'getStaffActivityHighlight?highlightId=' + config.id;
      } else {
        apiString = 'notFound';
      }

      if (apiString != 'notFound') {
        await axios
          .get(apiString)
          .then((result) => {
            if (result.status == '200') {
              let date = new Date();
              let dateString =
                date.toLocaleDateString() + ' | ' + date.toLocaleTimeString();
              let obj = {
                configId: config.id,
                date: dateString,
                data: result.data,
              };
              let key = 'config_' + config.id;
              localStorage.setItem(key, JSON.stringify(obj));
              setLastUpdate(dateString);
              setData(result.data);
              setLoadingTile(false);
            } else {
              setLoadingTile(false);
            }
          })
          .catch((error) => {
            setLoadingTile(false);
          });
      } else {
        setLoadingTile(false);
      }
    }
  };

  const getTotalValue = (el) => {
    let total = 0;
    try {
      if (dataRow.action == 'Field Expenses') {
        total = Math.round(el.average);
      } else if (dataRow.action == 'Job Details') {
        total = el.count;
      } else if (dataRow.action == 'Staff Activities') {
        total = el.count;
      }
    } catch (e) {
      console.error(e);
    }

    return getValue(total);
  };

  const getTitleTotal = (el) => {
    let total = 0;
    try {
      if (dataRow.action == 'Field Expenses') {
        total = 'LKR ' + getValue(Math.round(el.total));
      } else if (dataRow.action == 'Job Details') {
        total = getValue(el.count);
      } else if (dataRow.action == 'Staff Activities') {
        total = getValue(el.count);
      }
    } catch (e) {
      console.error(e);
    }

    return total;
  };

  return (
    <div>
      {/* {console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^",firstTab)} */}
      {openViewMore1 === true ? (
        <Viewmore1
          open={openViewMore1}
          viewMoreClose={viewMoreClose}
          title={title}
          SubTitle={tableTitle}
          tileBody={title}
          categoryType={categoryType}
          action={action}
          tableData={firstTab}
        />
      ) : (
        ''
      )}

      {loadingTile ? (
        <SkeletonTypeOne
          title={title}
          subTitle={subTitle}
          colorCode={colorCode}
          iconName={iconName}
          hasSub={hasSub}
        />
      ) : (
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid item xs={4} style={{ marginTop: '-0.5rem' }}>
              <div
                className={classes.imgBox}
                style={{ backgroundColor: colorCode }}
              >
                <IconSelector
                  IconImage={handleIconImage}
                  hasIconImage={icon}
                  TileColor={tileColor}
                  disable={true}
                />

                {/* {
                                        iconName == "HourglassEmptyIcon" ? <img src={HourglassEmptyIcon} className={classes.icon} />
                                            : iconName == "AssignmentOutlinedIcon" ? <AssignmentOutlinedIcon className={classes.icon} />
                                                : iconName == "CancelIcon" ? <CancelIcon className={classes.icon} />
                                                    : iconName == "CommuteIcon" ? <CommuteIcon className={classes.icon} /> : null
                                    } */}
              </div>
            </Grid>
            {data !== null ? (
              data.length > 0 ? (
                <Grid
                  item
                  xs={8}
                  className={classes.topRight}
                  style={{ minHeight: '3.5rem' }}
                >
                  <Typography noWrap className={classes.title}>
                    {title.toUpperCase()}
                  </Typography>
                  {/* <Typography className={classes.title1} style={{ color: colorCode }}>{getValue(data.total)}</Typography> */}
                  <Typography
                    className={classes.title1}
                    style={{ color: colorCode }}
                  >
                    {getTitleTotal(data[0])}
                  </Typography>
                  <Typography className={classes.title3Nw}>
                    {data[0].title}
                  </Typography>
                </Grid>
              ) : (
                //for now only
                <Grid item xs={8} className={classes.topRight}>
                  <Typography className={classes.title}>
                    {title.toUpperCase()}
                  </Typography>
                  {/* <Typography className={classes.title1} style={{ color: colorCode }}>{getValue(data.total)}</Typography> */}
                  <Typography
                    className={classes.title1}
                    style={{ color: colorCode }}
                  >
                    {0}
                  </Typography>
                  <Typography className={classes.title3Nw}>
                    {'Total'}
                  </Typography>
                </Grid>
              )
            ) : (
              ''
            )}
          </Grid>

          {/* <Grid container spacing={2} className={classes.secondRow} style={{ marginTop: !hasSub ? "1.5rem" : "0rem" }}> */}

          <div className={classes.desView}>
            {data !== null && data.length > 0 ? (
              <Grid
                container
                spacing={2}
                className={classes.secondRow}
                style={{ marginTop: !hasSub ? '1.5rem' : '0rem' }}
              >
                {data.map((el, index) => {
                  if (index != 0) {
                    return (
                      <Grid
                        item
                        xs={3}
                        className={classes.titleMainBox}
                        onClick={() => {
                          onClickHandle(el.title, el.first_table);
                          console.log('ccccc');
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} className={classes.titleBox}>
                            <Typography
                              className={classes.title4}
                              style={{ color: colorCode, textAlign: 'center' }}
                            >
                              {getTotalValue(el)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.titleBox}>
                            <Typography
                              style={{ textAlign: 'center' }}
                              noWrap
                              className={classes.title3up}
                            >
                              ({el.percentage}%)
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Typography
                              style={{ textAlign: 'center' }}
                              noWrap
                              className={classes.title3}
                            >
                              {el.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            ) : null}
          </div>

          <LastUpdate refresh={refresh} lastUpdate={lastUpdate} />
        </Paper>
      )}
    </div>
  );
}
