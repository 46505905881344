import axios from "../../axios/axios-default";

 export function getAllConfigs () {
      return axios.get("getHighlightConfigs");
    //   return axios.get("getKpis");
}

export function getConfigbyId (configId) {
    return axios.get("getHighlightConfigs/"+configId);
}


export async  function createHighlightConfig(data){
    return  await axios({
        method: "POST",
        url: "createHighlightConfigs",
        data: data
    });
}


export async  function updateTileConfig(data, id){
    return  await axios({
        method: "PUT",
        url: "updateHighlightConfigs/"+id,
        data: data
    });
}

// export function DeleteKpi (kpiType, srTypId) {
//     return axios.delete("deleteKpisBySrTypeAndKpiType?srTypeId="+srTypId+"&kpiType="+kpiType);
// }

export function getActiveWorkflowByTypeAndSrTypeId (id) {
    return axios.get("getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId="+id);
}
