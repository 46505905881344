import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import React, { useEffect } from "react";
import CustomDirectionRenderer from "./CustomDirectionRenderer";
import CustomersLocationManager from "./CustomersLocationManager";
import { GoogleMapsAPI } from "../ClientConfig";

const googleMapsApiKey = GoogleMapsAPI;
const libraries = ["places", "geometry"];

const ReportMap = ({
  journeys,
  center,
  setGoogleMapLoaded,
  markers,
  customerList,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    setGoogleMapLoaded(isLoaded);
  }, [isLoaded]);

  if (loadError)
    return (
      <div style={{ color: "red" }}>
        There is a Problem with your google map!
      </div>
    );

  return isLoaded ? (
    <div
      style={{
        position: "fixed",
        right: 0,
        top: "0",
        height: "100vh",
        width: `calc(100% - 310px)`,
      }}
    >
      <GoogleMap
        zoom={13}
        center={center}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          minZoom: 5,
        }}
      >
        {journeys.map((journey, index) => (
          <DirectionGenerator
            markers={markers}
            key={index}
            journeyObject={journey}
          />
        ))}
        {/* {markers.map(marker => <Marker position={marker} />)} */}
        <CustomersLocationManager customerList={customerList} />
      </GoogleMap>
    </div>
  ) : (
    <div style={{ color: "red", backgroundColor: "white" }}>Loading...</div>
  );
};

const DirectionGenerator = ({ journeyObject }) => {
  const {
    path,
    isSelected,
    routeColor,
    origin,
    destination,
    id,
    name,
    type,
  } = journeyObject;

  return isSelected ? (
    <CustomDirectionRenderer
      srId={id}
      path={path}
      origin={origin}
      destination={destination}
      routeColor={routeColor}
      srType={type}
      srName={name}
    />
  ) : null;
};

export default ReportMap;
