import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { connect } from 'react-redux';
import urlProperties from '../../Util/Property/UrlProperties';
import { Typography } from '@material-ui/core';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import { PermContactCalendarRounded } from '@material-ui/icons';

class OnSiteUser extends Component {


    state = {
        // formElementArray: reportsProperties.reportForm,
        changeField: "",
        srHistoryState: false,
        SelectedReportName: "ON_SITE_TECHNICIANS_SUMMARY_REPORT",
        onSiteUserSummary: [
          {
            "num": "1",
            "srno": "001",
            "priority":"High",
            "cutomer":"HNB-Dehiwala",
            "province":"Western",
            "product":"Finger scanner",
            "sastatus":"Under SA",
            "currentstage":"Complete",
            "lastattendedby":"Tharaka",
            "startdate":"5/1/2022",
            "lastupdated":"5/10/2022",
            "age(days)":"10",
            "kpi":"5",
          },
          {
            "num": "2",
            "srno": "002",
            "priority":"Low",
            "cutomer":"Com Bank-Kottawa",
            "province":"Western",
            "product":"Note Counter",
            "sastatus":"Under Warranty",
            "currentstage":"Customeer Visit",
            "lastattendedby":"Layantha",
            "startdate":"5/2/2022",
            "lastupdated":"5/11/2022",
            "age(days)":"1",
            "kpi":"2",
          },
          {
            "num": "3",
            "srno": "003",
            "priority":"Mid",
            "cutomer":"SMI-Gampaha",
            "province":"Western",
            "product":"Photocopier",
            "sastatus":"SA Expired",
            "currentstage":"Inprogress",
            "lastattendedby":"Saman",
            "startdate":"5/3/2022",
            "lastupdated":"5/12/2022",
            "age(days)":"6",
            "kpi":"1",
          },
          {
            "num": "",
            "srno": <center>Total</center>,
            "priority":"",
            "cutomer":"",
            "province":"",
            "product":"",
            "sastatus":"",
            "currentstage":"",
            "lastattendedby":"",
            "startdate":"",
            "lastupdated":"",
            "age(days)":"",
            "kpi":"",
          },
          {
            "num": "",
            "srno": <center>AVG</center>,
            "priority":"",
            "cutomer":"",
            "province":"",
            "product":"",
            "sastatus":"",
            "currentstage":"",
            "lastattendedby":"",
            "startdate":"",
            "lastupdated":"",
            "age(days)":"",
            "kpi":"",
          },
          
        ]
      };
    



  render() {
    return (<div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>

          <Card>
            <CardBody>

              <div className={'material-grid-wrapper'}>
                <MaterialTable
                  title={
                    <div>
                      <span className={'grid-title-icon'}>
                        <PermContactCalendarRounded />
                      </span>
                      <Typography variant="h6">On Site User Detail Report</Typography>
                    </div>
                  }
                  tableRef={this.tableRef}
                  columns={[
                    { title: '#', field: 'num', headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'SR No', field: 'srno' , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'Priority', field: 'priority', headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"} },
                    { title: 'Customer', field: 'Customer' , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'Province', field: 'province', headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'Product', field: 'product', headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'SA Status', field: 'sastatus' , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'Current Stage', field: 'currentstage' , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                    { title: 'Last Attended By', field: 'lastattendedby', headerStyle:{padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},
                    { title: 'Start Date', field: 'startdate' , headerStyle:{padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},
                    { title: 'Last Updated', field: 'lastupdated' , headerStyle:{padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},
                    { title: 'Age (Days)', field: 'age(days)' , headerStyle:{padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}, 
                    render:(rowData) =><div style={{background:rowData["age(days)"]<5?"#86ff45":" #ff4747 "}}>{rowData["age(days)"]}</div>},
                    { title: 'KPI', field: 'kpi' , headerStyle:{padding:"0px 0px",textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},

                  ]}
                  data={this.state.onSiteUserSummary}
                //   data={query =>
                //     new Promise((resolve, reject) => {
                //       let url = urlProperties.baseUrl + urlProperties.reports.searchOnSiteTechDetailReport + '?';
                //       url += 'perPage=' + query.pageSize
                //       url += '&page=' + (query.page)
                //       url += '&sortColumn=' + (typeof query.orderBy === 'undefined' ? 'name' : query.orderBy.field)
                //       url += '&sortOrder=' + (query.orderDirection)
                //       url += '&searchDate=' + (this.props.match.params.assignedDate)
                //       url += '&technicianId=' + (this.props.match.params.technicianId)
                //       url += '&search=' + (query.search)
                //       fetch(url,
                //         {
                //           headers: {
                //             "Authorization": 'Bearer ' + localStorage.getItem("token"),
                //             'Content-Type': 'application/json'
                //           }
                //         })
                //         .then(response => response.json())
                //         .then(result => {
                //           const data = result.data;
                //           if(data !=null){
                //             data.map((val)=>{
                //               if(val.srNo == null){
                //                 val.srNo = "-";
                //               }else{
                //                 val.srNo = "SR-"+val.srNo;
                //               }
                //               if(val.checkIn == null){
                //                 val.checkIn = "-";
                //               }
                //               if(val.checkOut == null){
                //                 val.checkOut = "-";
                //               }
                //               if(val.workDone == null){
                //                 val.workDone = "-";
                //               }
                //               if(val.priority == null){
                //                 val.priority = "-";
                //               }
                //               if(val.genaratedDate == null){
                //                 val.genaratedDate = "-";
                //               }
                //               if(val.machine == null){
                //                 val.machine = "-";
                //               }
                //               if(val.address==null){
                //                 val.address = "-";
                //               }
                //               if(val.customerName==null){
                //                 val.customerName = "-";
                //               }
                //               if(val.customerName==null){
                //                 val.customerName = "-";
                //               }

                //             });
                //           }
                //           resolve({
                //             data: data,
                //             page: result.page,
                //             totalCount: result.total
                //           })
                //         })
                //     })
                //   }
                  options={{
                    actionsColumnIndex: -1,
                    // filtering: true,
                    sorting: false,
                    search: false,
                    exportButton: true
                  }}
                  onRowClick={(event, rowData) => {
                            // Get your id from rowData and use with link.
                            // window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                            // event.stopPropagation();
                          }}
                  // options={{
                   
                  // }}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>);
  }
}

export default connect()(OnSiteUser);
