import React, { useState, useEffect } from 'react';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from 'components/Card/CardFooter';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import GeneralProperties from 'Util/Property/GeneralProperties';
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Form, Field } from "react-final-form";
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import axios from 'axios/axios-default';
import UrlProperties from 'Util/Property/UrlProperties';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import styles from './User.module.css';
import {getPasswordForm, getUserPasswordResetForm, userProperties} from 'Util/Property/UserProperties';
import CustomerUser from "./CustomerUser";


const ModifyCustomerUser = (props) => {

    const [userId, setUserId] = useState(0);
    const [permissions, setPermissions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    //const [userName, setUserName] = useState("");
    //const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [passwordForm, setPasswordForm] = useState(getUserPasswordResetForm());


    useEffect(() => {
        let userId = localStorage.getItem("userId")
        if (typeof userId != 'undefined') {
            setUserId(userId);

        }
         // this is for to stop Rerendering of UserComponent when viewing user
         setIsStateChanged(true);
        
        // if (userId > 0) {
        //     getUserData(userId);
        // }
        let permissionArray = [PermissionProperties.passwordChange];
        setPermissions(checkPermissoinListAvailable(permissionArray));
    }, []);

    // const getUserData = (userId) => {
    //     axios.get(UrlProperties.user.getUser + '/' + userId).then(result => {
    //         setUserName(result.data["user_name"]);
    //     }).catch(error => {
    //         notyDefault({ type: notyTypes.ERROR, text: UserProperties.messages.error.loadData });
    //     })
    // }

    const a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    const theme = useTheme();
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);

    };

    // const deleteUserDialogCloseHandler = () => {
    //     setIsDeleteDialogOpen(false);
    // }

    let formElementArray = [];
    for (let key in passwordForm) {
        formElementArray.push({
            id: key,
            config: passwordForm[key]
        });
    }

    const onChangeHandeler = (event, elId) => {
        let formData = { ...passwordForm };
        let elementData = formData[elId]; // get data for form key
        let inputProps = { ...elementData.inputProps } //  copy input data
        inputProps.value = event.target.value;
        elementData.inputProps = inputProps;
        formData[elId] = elementData;
        setPasswordForm(formData);
    }


    const onPasswordSubmit = async (values) => {
        setIsLoading(true);
        let data = {
            id: userId
        }
        await axios.put(`${UrlProperties.user.changePassword}?oldPassword=${values.oldPassword}&newPassword=${values.newPassword}`, data).then(result => {
            //this.props.history.goBack();
            if (result.status === 200) {
                notyDefault({ type: notyTypes.SUCCESS, text: userProperties.messages.success.changePassword });
            } else {
                notyDefault({ type: notyTypes.SUCCESS, text: result.data.message });
            }
            let formData = { ...passwordForm };
            for (let key in formData) {
                let elementData = formData[key];
                let inputProps = { ...elementData.inputProps };
                inputProps.value = "";
                elementData.inputProps = inputProps;
                formData[key] = elementData;
            }
            setPasswordForm(formData);

        }).catch(error => {
            notyDefault({ type: notyTypes.ERROR, text: userProperties.messages.error.changePassword });

        });
        setIsLoading(false);
    }

    return (
        <div>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardBody>
                                <CustomerUser id={userId} isEdit={isEdit} />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </LoadingOverlay>
        </div>
    )
}


export default ModifyCustomerUser;