
export const GET_CLAIMS_RULE_LIST_START ="GET_CLAIMS_RULES_LIST_START";
export const GET_CLAIMS_RULE_LIST_SUCCESS ="GET_CLAIMS_RULES_LIST_SUCCESS";
export const GET_CLAIMS_RULE_LIST_FAILED ="GET_CLAIMS_RULES_LIST_FAILED";

export const GET_CLAIMS_RULE_BY_ID_START ="GET_CLAIMS_RULE_BY_ID_START";
export const GET_CLAIMS_RULE_BY_ID_SUCCESS ="GET_CLAIMS_RULE_BY_ID_SUCCESS";
export const GET_CLAIMS_RULES_BY_ID_FAILED ="GET_CLAIMS_RULES_BY_ID_FAILED";

export const CREATE_CLAIMS_RULE_START ="CREATE_CLAIMS_RULE_START";
export const CREATE_CLAIMS_RULE_SUCCESS ="CREATE_CLAIMS_RULE_SUCCESS";
export const CREATE_CLAIMS_RULE_FAILED ="CREATE_CLAIMS_RULE_FAILED";

export const DELETE_CLAIMS_RULE_START ="DELETE_CLAIMS_RULE_START";
export const DELETE_CLAIMS_RULE_SUCCESS ="DELETE_CLAIMS_RULE_SUCCESS";
export const DELETE_CLAIMS_RULE_FAILED ="DELETE_CLAIMS_RULE_FAILED";

export const UPDATE_CLAIMS_RULE_START ="UPDATE_CLAIMS_RULE_START";
export const UPDATE_CLAIMS_RULE_SUCCESS ="UPDATE_CLAIMS_RULE_SUCCESS";
export const UPDATE_CLAIMS_RULE_FAILED ="UPDATE_CLAIMS_RULE_FAILED";

export const GET_CLAIMS_ALLOWANCE_TYPES_START ="GET_CLAIMS_ALLOWANCE_TYPES_START";
export const GET_CLAIMS_ALLOWANCE_TYPES_SUCCESS ="GET_CLAIMS_ALLOWANCE_TYPES_SUCCESS";
export const GET_CLAIMS_ALLOWANCE_TYPES_FAILED ="GET_CLAIMS_ALLOWANCE_TYPES_FAILED";

export const GET_CLAIMS_MEAL_TYPES_START ="GET_CLAIMS_MEAL_TYPES_START";
export const GET_CLAIMS_MEAL_TYPES_SUCCESS ="GET_CLAIMS_MEAL_TYPES_SUCCESS";
export const GET_CLAIMS_MEAL_TYPES_FAILED ="GET_CLAIMS_MEAL_TYPES_FAILED";

export const GET_PRODUCT_CATEGORIES_START ="GET_PRODUCT_CATEGORIES_START";
export const GET_PRODUCT_CATEGORIES_SUCCESS ="GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAILED ="GET_PRODUCT_CATEGORIES_FAILED";

export const GET_SERVICE_REQUEST_TYPES_START ="GET_SERVICE_REQUEST_TYPES_START";
export const GET_SERVICE_REQUEST_TYPES_SUCCESS ="GET_SERVICE_REQUEST_TYPES_SUCCESS";
export const GET_SERVICE_REQUEST_TYPES_FAILED ="GET_SERVICE_REQUEST_TYPES_FAILED";