import { loadGoogleMapsScript } from "./google-maps-loader";
import {
  durationCalculator,
  fetchJourneyInfo,
  formatTimeDiff,
  getFormattedDateTime,
  seperateIntoMainSR,
} from "./journey-utils";
import {
  generateAirDistance,
  generateDistanceFromDistributor,
  generateDistanceFromPreviosJob,
} from "./polyline-utils";

export const getFormattedJourneyData = async (travelAgentId, date) => {
  // fetching journey list from backend
  const { journeyGroupBySrList } = await fetchJourneyInfo(travelAgentId, date);
  return await convertToReportObject(journeyGroupBySrList);
};

const convertToReportObject = async (journeyGroupBySrList) => {
  const finalReportObjectList = [];
  let totalJobDuration = 0;
  let totalJourneyDuration = 0;
  let { mainJourney, srJourneys } = seperateIntoMainSR(journeyGroupBySrList);

  let jobComments = []
  let finalComments = ""
  srJourneys.forEach((journey) => {
    let journeyComments = journey.jobComments
    if(journeyComments && journeyComments.length> 0) {
      journeyComments.forEach((comment) => {
        jobComments.push(comment)
      })
    }
  })
  if(jobComments.length > 0) {
    finalComments = jobComments.join("\n");
  }
  
  // filtering null journeys and sorting journeys over timestamp
  srJourneys = srJourneys
    .filter((journey) => {
      const { journeyDtoList } = journey;
      if (Array.isArray(journeyDtoList) && journeyDtoList[0]) {
        const { startTime, endTime } = journeyDtoList[0];

        return startTime && endTime;
      }
      return false;
    })
    .sort((a, b) => a.endTime - b.endTime);

  // creating a return trip journey from sr and main journey data
  const returnTripJourney = {
    customerName: "Return Trip",
    endTime: mainJourney[mainJourney.length - 2].dateTime,
    checkIn: null,
    checkOut: null,
  };

  // adding return trip journey data and sr data together
  const allJourneyList = [
    ...srJourneys.map(
      ({ journeyDtoList, customerName, checkIn, checkOut }) => ({
        checkIn,
        checkOut,
        customerName,
        ...journeyDtoList[0],
      })
    ),
    returnTripJourney,
  ];

  for (const index in allJourneyList) {
    const isReturnTrip = index == allJourneyList.length - 1;
    const {
      customerName,
      endTime: endTimeStamp,
      checkIn,
      checkOut,
    } = allJourneyList[index];

    let distanceFromDistributor = 0;
    let distanceFromPreviousJob = 0;
    let journeyStartTimeStamp =
      index == 0 ? mainJourney[0].dateTime : allJourneyList[index - 1].endTime;

    // calculating distances
    if (isReturnTrip) {
      distanceFromDistributor = await generateTotalDistance(mainJourney);

      const prevDistanceFromDistributor =
        finalReportObjectList.length > 0
          ? extractFloatFromKmString(
              finalReportObjectList[finalReportObjectList.length - 1]
                .distanceFromDistributor
            )
          : 0;

      distanceFromPreviousJob =
        parseFloat(distanceFromDistributor) - prevDistanceFromDistributor;
      distanceFromPreviousJob = distanceFromPreviousJob.toFixed(2);
    } else if (index == 0) {
      distanceFromPreviousJob = distanceFromDistributor = await generateDistanceFromDistributor(
        endTimeStamp,
        mainJourney
      );
    } else {
      distanceFromPreviousJob = await generateDistanceFromPreviosJob(
        journeyStartTimeStamp,
        endTimeStamp,
        mainJourney
      );

      let prevDistanceFromDistributor =
        finalReportObjectList[finalReportObjectList.length - 1]
          .distanceFromDistributor;

      distanceFromPreviousJob = parseFloat(distanceFromPreviousJob);

      prevDistanceFromDistributor = extractFloatFromKmString(
        prevDistanceFromDistributor
      );

      distanceFromDistributor =
        prevDistanceFromDistributor + distanceFromPreviousJob;

      distanceFromDistributor = distanceFromDistributor.toFixed(2);
    }

    // configuring timestamps and durations
    const journeyDuration = durationCalculator(
      journeyStartTimeStamp,
      endTimeStamp
    );

    if (journeyDuration)
      totalJourneyDuration += endTimeStamp - journeyStartTimeStamp;
    

    const jobDuration = isReturnTrip
      ? null
      : durationCalculator(checkIn, checkOut);

    if (jobDuration)
      totalJobDuration += checkOut - checkIn;

    const {
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    } = getAndFormatTimeStamp(journeyStartTimeStamp, endTimeStamp);
    const {
      startTime: formattedCheckIn,
      endTime: formattedCheckOut,
    } = getAndFormatTimeStamp(checkIn, checkOut);

    const reportObject = {
      outlet: customerName,
      journeyStartTime: formattedStartTime,
      journeyEndTime: formattedEndTime,
      journeyDuration,
      distanceFromPreviousJob: `${distanceFromPreviousJob}km`,
      distanceFromDistributor: `${distanceFromDistributor}km`,
      checkIn: formattedCheckIn,
      checkOut: formattedCheckOut,
      jobDuration,
      remarks:finalComments,
    };

    finalReportObjectList.push(reportObject);
  }

  totalJobDuration = formatTimeDiff(totalJobDuration);
  totalJourneyDuration = formatTimeDiff(totalJourneyDuration);

  return {
    totalJourneyDuration,
    totalJobDuration,
    totalDistance: finalReportObjectList[finalReportObjectList.length - 1].distanceFromDistributor,
    totalDuration: totalJourneyDuration,
    tableData: finalReportObjectList
  };
};

const extractFloatFromKmString = (kmString) => {
  let prevDistanceFromDistributor = kmString.slice(0, -2);

  return parseFloat(prevDistanceFromDistributor);
};

const generateTotalDistance = async (mainJourney) => {
  await loadGoogleMapsScript();
  const { startLatitude, startLongitude } = mainJourney[0];
  const mainWaypoints = [
    { lat: startLatitude, lng: startLongitude },
    ...mainJourney.map(({ endLatitude, endLongitude }) => ({
      lat: endLatitude,
      lng: endLongitude,
    })),
  ];
  return generateAirDistance(mainWaypoints);
};

// formatting start and end time stamps to 12 hours time format and also format the dates if needed
const getAndFormatTimeStamp = (startTimeStamp, endTimeStamp) => {
  let startTime;
  let endTime;
  const startDateTime = getFormattedDateTime(startTimeStamp);
  const endDateTime = getFormattedDateTime(endTimeStamp);

  const formatNotEqualDateObject = (dateTime) => {
    const { date, month, time } = dateTime;
    return `${month} ${date}, ${time}`;
  };

  // if the start time & end time not in the same day returning formatted date also
  if (
    !(startDateTime && endDateTime) ||
    startDateTime.date === endDateTime.date
  ) {
    startTime = startDateTime?.time || null;
    endTime = endDateTime?.time || null;
  } else {
    startTime = formatNotEqualDateObject(startDateTime);
    endTime = formatNotEqualDateObject(endDateTime);
  }
  return { startTime, endTime };
};
