import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';

export function handleErrorMessage(error, defaultMessage) {
    console.log(error.response);
    if (typeof error.response !== 'undefined') {
        let details = error.response.data;
        if (typeof details !== "undefined") {
            let message = details.details;
            if (typeof message === 'undefined') {
                message = details.error
            }
            notyDefault({ type: notyTypes.ERROR, text: typeof message !== 'undefined' ? message : defaultMessage });
        } else {
            notyDefault({ type: notyTypes.ERROR, text: defaultMessage });
        }
    }else{
        let details = error.data;
        if (typeof details !== "undefined") {
            let message = details.details;
            if (typeof message === 'undefined') {
                message = details.error
            }
            notyDefault({ type: notyTypes.ERROR, text: typeof message !== 'undefined' ? message : defaultMessage });
        } else {
            notyDefault({ type: notyTypes.ERROR, text: defaultMessage });
        }
    }
}