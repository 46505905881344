import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { handleErrorMessage } from 'services/CommonService';
import generalProperties from 'Util/Property/GeneralProperties';
import Button from 'components/CustomButtons/Button.js';
import { workflowProperties } from 'Util/Property/WorkflowProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import LoadingOverlay from 'react-loading-overlay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  workflowRouteProperties,
  getWorkflowRouteForm,
} from 'Util/Property/WorkflowRouteProperties';
import { Form, Field } from 'react-final-form';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CardFooter from 'components/Card/CardFooter';
import styles from './WorkflowRoute.module.css';
import { getUsersByRoles } from 'services/User/UserService';
import UploadFiles from 'components/FileUpload/UploadFiles';
import UrlProperties from 'Util/Property/UrlProperties';
import {
  productProperties,
  getProductForm,
} from 'Util/Property/ProductProperties';
import * as productService from 'services/ProductAndServices/ProductService';
import classNames from 'classnames';
import * as moment from 'moment';
import Spinner from 'components/Spinner/Spinner';

const WorkflowRoute = (props) => {
  const [workflowRouteForm, setWorkflowRouteForm] = useState(
    getWorkflowRouteForm()
  );
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [isWorkflowRouteDialogOpen, setIsworkflowRouteDialogOpen] = useState(
    false
  );
  const {
    isWfRouteDialogOpen,
    routeOptions,
    routeSubmitHandler,
    routeCloseHandler,
    brandID,
    catID,
    modelID,
    serialNumber,
    loc,
    cusID,
    serviceAgreementID,
    productID,
    SAissuDate,
    subStatusID,
    productSaleInvoiceNo,
    productMid,
    productSoldPrice,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [statusNames, setStatusNames] = useState({});
  const [statusList, setStatusList] = useState({});
  const [roleList, setRoleList] = useState({});
  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });
  const [stagesById, setStagesById] = useState({});
  const [statusById, setStatusById] = useState({});
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [duplicateSerial, setDuplicateSerial] = useState(false);
  const [isSNExist, setIsSNExist] = useState(false);

  useEffect(() => {
    if (typeof isWfRouteDialogOpen !== 'undefined') {
      setIsworkflowRouteDialogOpen(isWfRouteDialogOpen);
    }
  }, [props.isWfRouteDialogOpen]);

  useEffect(() => {
    if (routeOptions.length > 0) {
      setDataToFields(true);
    }
  }, [props.routeOptions]);

  useEffect(() => {
    setDuplicateSerial(false);
  }, []);

  useEffect(() => {
    //hide fields
    if (loc !== 'serviceRequest') {
      workflowRouteForm['serial_number'].isHide = true;
      workflowRouteForm['planned_completion_date'].isHide = true;
    }
  }, []);

  const fileUploadClose = () => {
    setOpenFileUpload(false);
  };

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...workflowRouteForm };
      setWorkflowRouteForm(formData);
    } else {
      setIsLoadingIcon(true);
    }
  };

  const setDataToFields = (isFirstTime) => {
    let statusOptions = [];
    let roles = {};
    let status = {};
    let stageOptions = [];
    let stages = {};
    routeOptions.map((data) => {
      stageOptions.push({
        value: data.stage_id,
        displayValue: data.stage_name,
      });
      roles[data.stage_id] = data.allow_user_roles;
      status[data.stage_id] = data.status;
      stages[data.stage_id] = data.stage_name;
    });
    setStagesById(stages);
    setStatusList(status);
    setRoleList(roles);
    let defaultValue = setDataToWorkflowRouteFormForm(
      'stage_name',
      stageOptions,
      isFirstTime
    );
    setStatusToDropDown(defaultValue, status[defaultValue], isFirstTime);
    setUserToDropDown(defaultValue, roles[defaultValue], isFirstTime);
  };

  const setStatusToDropDown = (stageId, status, isFirstTime) => {
    if (typeof status === 'undefined') {
      status = statusList[stageId];
    }

    let statusOptions = [];
    let stsById = {};
    status.map((data) => {
      statusOptions.push({ value: data.id, displayValue: data.status_name });
      stsById[data.id] = data.status_name;
    });
    setStatusById(stsById);
    setDataToWorkflowRouteFormForm('status', statusOptions, isFirstTime);
  };

  const setUserToDropDown = (stageId, roles, isFirstTime) => {
    if (typeof roles === 'undefined') {
      roles = roleList[stageId];
    }

    // setIsLoading(true);
    getUsersByRoles(roles)
      .then((result) => {
        if (result.status === 200) {
          let userOptions = [];
          result.data.map((user) =>
            userOptions.push({
              value: user.userId,
              displayValue: user.firstName + ' ' + user.lastName,
            })
          );

          setDataToWorkflowRouteFormForm(
            'assign_user_id',
            userOptions,
            isFirstTime
          );
        } else {
          handleErrorMessage(
            result.data,
            workflowRouteProperties.messages.error.users
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowRouteProperties.messages.error.users);
      });
  };

  const setDataToWorkflowRouteFormForm = (key, options, isFirstTime) => {
    let formData = workflowRouteForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    workflowRouteForm[key] = formData;
    return defaultValue;
  };

  const getStagesForTable = () => {
    setIsLoading(true);
    // getStages().then(result => {
    //     if (result.status === 200) {
    //         setStages(result.data);
    //     } else {
    //       handleErrorMessage(result.data, workflowProperties.messages.error.loadStages);
    //     }
    //     setIsLoading(false);
    //   }).catch(error => {
    //     setIsLoading(false);
    //     handleErrorMessage(error, workflowProperties.messages.error.loadStages);
    //   });
  };

  let formElementArray = [];
  for (let key in workflowRouteForm) {
    formElementArray.push({
      id: key,
      config: workflowRouteForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let value = '';
    let formData = { ...workflowRouteForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elementData.elType == 'date') {
      inputProps.value = event.toDate();
      value = event.toDate();
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }

    if (elId === 'stage_name') {
      setStatusToDropDown(value);
      setUserToDropDown(value);
    }

    if (elId === 'serial_number') {
      /////// api call to check

      let brandId = inputProps.value.brandId;
      setDuplicateSerial(false);
      checkSerialNumber(value, brandID, catID, modelID);
    }

    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setWorkflowRouteForm(formData);
  };

  const checkSerialNumber = async (serialNo, brandId, CatId, ModelId) => {
    setDuplicateSerial(false);
    console.log('selected brand cat model', brandId, CatId, ModelId);
    let data = {};
    let method = '';
    let url = '';
    // data["serial"] = "SN-548542";
    // data["productBrandId"] = 2;
    // data["productCategoryId"] = 1;
    // data["productModelId"] = 3;
    data['serial'] = serialNo;
    data['productBrandId'] = brandId;
    data['productCategoryId'] = CatId;
    data['productModelId'] = ModelId;

    method = 'POST';
    url = UrlProperties.product.createProduct;
    await productService
      .saveProduct(method, url, data)
      .then((result) => {})
      .catch((error) => {
        const { response = {} } = error || {};
        const { data = {} } = response || {};
        const { details = '' } = data || {};
        setIsLoading(false);
        if (details != null) {
          if (details.startsWith('Product is used by customer')) {
            console.log('Serial----------------', details);
            setDuplicateSerial(true);
          } else {
            // notyDefault({ type: notyTypes.ERROR, text: details || productProperties.messages.error.saveData });
          }
        }
      });
  };

  const workflowRouteHandler = (rowData) => {
    setIsworkflowRouteDialogOpen(true);
  };

  const workflowRouteDialogCloseHandler = () => {
    routeCloseHandler(false);
  };

  const routeConfirmHandler = async () => {};

  const onWFRouteSubmit = () => {
    let formData = { ...workflowRouteForm };

    let data = {};
    for (let key in formData) {
      let val = formData[key].inputProps.value;
      if (key === 'stage_name') {
        data['currentStage'] = val;
        data['stage_name'] = stagesById[val];
      } else if (key === 'status') {
        data['status'] = val;
        data['status_name'] = statusById[val];
      } else if (key === 'serial_number') {
        if (val === ' ' || val.length === 0) {
          data['serial_number'] = serialNumber;
        } else {
          // data["serial_number"] = val;
          if (typeof productID !== 'undefined') {
            data['serial_number'] = serialNumber;
            updateProductSerial(serialNumber);
          } else {
            notyDefault({
              type: notyTypes.ERROR,
              text:
                'This Customer have Empty Products. Please add Products to customer First...',
            });
            workflowRouteDialogCloseHandler();
          }
          // updateProductSerial(val);
        }
      } else {
        data[key] = val;
      }
    }
    console.log('------------> data', productID, loc);
    // routeSubmitHandler(data);
    if (typeof productID === 'undefined' && loc === 'serviceRequest') {
      //
    } else {
      routeSubmitHandler(data);
    }
  };

  const updateProductSerial = async (serial) => {
    let method = '';
    let url = '';
    let data = {};
    // let issuDate = moment(SAissuDate);
    method = 'PATCH';
    url = UrlProperties.product.updateProduct + '/' + productID;

    data['id'] = productID;
    data['customerId'] = cusID;
    data['serviceAgreementId'] = serviceAgreementID;
    data['issueDate'] = SAissuDate;
    // data["issueDate"] = issuDate;
    data['productCategoryId'] = catID;
    data['productBrandId'] = brandID;
    data['productModelId'] = modelID;
    data['active'] = true;
    data['serial'] = serial;
    data['ownershipChanged'] = false;
    data['productSubStatusId'] = subStatusID;
    data['saleInvoiceNumber'] = productSaleInvoiceNo;
    data['mid'] = productMid;
    data['soldPrice'] = productSoldPrice;

    setIsLoading(true);
    await productService
      .saveProduct(method, url, data)
      .then((result) => {
        //this.props.history.goBack();
        if (result.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: productProperties.messages.success.updateSerial,
          });
          // if (productId == 0) {
          //     setDefaultData();
          // }
          setIsLoading(false);
          props.history.goBack();
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: productProperties.messages.error.updateSerial,
          });
        }
      })
      .catch((error) => {
        const { response = {} } = error || {};
        const { data = {} } = response || {};
        const { details = '' } = data || {};
        setIsLoading(false);
        // if (details.startsWith("Product is used by customer")){
        //     setDuplicateSerialDialogOpen(true);
        //     setExistingCustomerData(details);
        // } else {
        //     notyDefault({ type: notyTypes.ERROR, text: details || productProperties.messages.error.saveData });
        // }
      });
  };

  return (
    <div>
      {openFileUpload === true ? (
        <UploadFiles
          //  url={urlProperties.customerUpload}
          open={openFileUpload}
          fileUploadClose={fileUploadClose}
          // uploadLoacation={"customer"}
          // acceptFileType={".xlsx"}
        />
      ) : (
        ''
      )}
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Dialog
              open={isWorkflowRouteDialogOpen}
              onClose={workflowRouteDialogCloseHandler}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Card>
                  <Form
                    onSubmit={onWFRouteSubmit}
                    validate={(values, ss) => {
                      const errors = {};

                      if (
                        workflowRouteForm.stage_name.inputProps.value === ''
                      ) {
                        // setEror("first_name", true)
                        errors.name = generalProperties.emptyField;
                      }
                      if (workflowRouteForm.status.inputProps.value === '') {
                        // setEror("first_name", true)
                        errors.name = generalProperties.emptyField;
                      }
                      if (
                        workflowRouteForm.assign_user_id.inputProps.value === ''
                      ) {
                        // setEror("first_name", true)
                        errors.name = generalProperties.emptyField;
                      }
                      if (duplicateSerial === true) {
                        // setEror("erial Number is Used", true)
                        errors.serial_number = 'Serial Number is Already Used';
                      }

                      return errors;
                    }}
                    render={({
                      handleSubmit,
                      reset,
                      submitting,
                      pristine,
                      valid,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardBody>
                          <GridContainer>
                            {formElementArray.map((element) => {
                              if (!element.config.isHide) {
                                let mdVal = element.config.size;
                                return (
                                  <GridItem
                                    key={element.id}
                                    xs={12}
                                    sm={12}
                                    md={mdVal}
                                  >
                                    <Field name={element.id}>
                                      {({ input, meta, options, value }) => (
                                        <div style={{ position: 'relative' }}>
                                          <CustomInput
                                            labelText={element.config.label}
                                            id={element.id}
                                            inputProps={{
                                              ...input,
                                              ...element.config.inputProps,
                                              readOnly:
                                                isView ||
                                                element.config.isDisabled
                                                  ? true
                                                  : false,
                                            }}
                                            type={
                                              element.id === 'userIdList' &&
                                              isView
                                                ? 'input'
                                                : element.config.elType
                                            }
                                            formControlProps={
                                              element.config.formControlProps
                                            }
                                            adornmentText={
                                              element.config.adornmentText
                                            }
                                            adornmentPosition={
                                              element.config.adornmentPosition
                                            }
                                            multiple={element.config.multiple}
                                            changed={(event, value) => {
                                              input.onChange(event);
                                              onChangeHandeler(
                                                event,
                                                element.id
                                              );
                                            }}
                                            labelProps={{
                                              ...element.config.labelProps,
                                              error: meta.error && meta.touched,
                                            }}
                                          />
                                          {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </GridItem>
                                );
                              }
                            })}
                          </GridContainer>
                        </CardBody>
                        <CardFooter className={styles.buttonLeftAlign}>
                          <Button
                            size="sm"
                            className={styles.buttonMargin}
                            onClick={() => {
                              setOpenFileUpload(true);
                            }}
                            color="primary"
                          >
                            Upload File
                          </Button>

                          <Button
                            size="sm"
                            className={styles.buttonMargin}
                            onClick={workflowRouteDialogCloseHandler}
                            color="primary"
                          >
                            {generalProperties.cancel}
                          </Button>

                          <Button
                            size="sm"
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            Save
                          </Button>
                        </CardFooter>
                      </form>
                    )}
                  />
                </Card>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default WorkflowRoute;
