const RepImagStyles = {
  paper: {
    // height: 130,
    width: '14.5rem',
    padding: '0.4rem',
    borderRadius: '0.8rem',
    boxShadow: 'none',
  },
  selected: {
    cursor: 'pointer',
    padding: '0.4rem',
    borderRadius: '0.5rem',
    width: 250,
    height: 185,
    // backgroundColor: "#000000",
    background: '#fbccce',
    // background: "rgba(241, 86, 66, 1)",
    display: 'flex',
    alignItems: 'flex-end',
  },
  radio: {
    marginLeft: '5rem',
    marginTop: '0rem',
    marginBottom: '0.4rem',
    color: '#000000',
    rootColor: '#000000',
    // background: "rgba(255, 119, 58, 1)",
  },

  titleMainBox: {
    // padding: "0.2rem",
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    padding: '0.4rem',
    width: 250,
    height: 185,
    //backgroundColor: "#F3F3F3",
    // background: "rgba(237, 241, 245, 255)",
    background: '#efefef',
    borderRadius: '0.5rem',
    '&:hover': {
      //backgroundColor: "#fcddde",
      boxShadow: 'none',
      background: '#feeeef',
    },
  },
};

export default RepImagStyles;
