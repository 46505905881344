import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { BiMessageAltError } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';

const BlueOnGreenTooltip = withStyles({
  arrow: {
    '&:before': {
      boxShadow: '0px 0px 5px 2px #ccc',
    },
    color: 'rgba(255, 255, 255)',
  },
  tooltip: {
    fontSize: '12px',
    padding: '10px',
    backgroundColor: 'rgba(255, 255, 255)',
    boxShadow: '0px 0px 5px 2px #ccc',
    color: '#000',
  },
})(Tooltip);

const OverflowTooltip = (props) => {
  const textElementRef = useRef(null);

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <BlueOnGreenTooltip
      title={props.tooltip}
      interactive
      disableHoverListener={!hoverStatus}
      placement={props.placement}
      arrow
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          ref={textElementRef}
        >
          {props.text}
        </p>
        {hoverStatus && <BiMessageAltError color="blue" />}
      </div>
    </BlueOnGreenTooltip>
  );
};

export default OverflowTooltip;
