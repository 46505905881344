import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { feedbackProperties } from 'Util/Property/FeedbackProperties';
import { getAllCustomerFeedback } from 'services/Feedback/FeedbackService';
import { handleErrorMessage } from 'services/CommonService';
import { Typography } from '@material-ui/core';
import { MdFeedback } from 'react-icons/md';
import Spinner from 'components/Spinner/Spinner';
import styles from './CustomerFeedback.module.css';

const CustomerFeedbacks = (props) => {
  //const [permissions, setPermissions] = useState({});
  const [feedbacks, setFeedBacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);
  useEffect(() => {
    //let permissionArray = [PermissionProperties.addTraining, PermissionProperties.viewTraining];
    //setPermissions(checkPermissoinListAvailable(permissionArray));
    let custId = 0;
    if (props.id && props.id > 0) {
      custId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        custId = queryParam['id'];
      }
    }
    getFeedBacksForCustomer(custId);
  }, [props.id]);

  const getFeedBacksForCustomer = (custId) => {
    setIsLoading(true);
    getAllCustomerFeedback(custId)
      .then((result) => {
        if (result.status === 200) {
          setFeedBacks(result.data);
          setIsLoading(false);
        } else {
          handleErrorMessage(
            result.data,
            feedbackProperties.messages.error.loadData
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, feedbackProperties.messages.error.loadData);
      });
  };
  const onFeedBackViewHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/feedback',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* {
                        permissions[PermissionProperties.addUser] ?
                            <div className={styles.AddUserDiv}>
                                <Link to={props.match.url + "/user"}>
                                    <Button className={styles.AddUserButton} variant="contained" color="primary">{userProperties.addNewUser}</Button>
                                </Link>
                            </div> : ""
                    } */}
            <CardBody>
              {/* customer table content */}
              <div className={'material-grid-wrapper'}>
                <MaterialTable
                  components={{
                    OverlayLoading: () => (
                      <div className={styles.loadingOverlay}>
                        <Spinner />
                      </div>
                    ),
                  }}
                  title={
                    <div>
                      <span className={'grid-title-icon'}>
                        <MdFeedback style={{ fontSize: '1.2rem' }} />
                      </span>
                      <Typography
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {feedbackProperties.feedbacks}
                      </Typography>
                      {/* <Typography variant="body1">Manage products here</Typography> */}
                    </div>
                  }
                  tableRef={tableRef}
                  isLoading={isLoading}
                  columns={[
                    {
                      title: 'Customer',
                      field: 'customerName',
                      align: 'center',
                    },
                    { title: 'Sub Customer', field: 'subCustomerName' },
                    {
                      title: 'Service Request',
                      field: 'srId',
                      align: 'center',
                    },
                    { title: 'Product', field: 'product' },
                    { title: 'Rating', field: 'rating', align: 'center' },
                    {
                      title: 'Status',
                      field: 'feedbackStatus',
                      align: 'center',
                    },
                  ]}
                  data={feedbacks}
                  actions={[
                    {
                      icon: 'visibility',
                      iconProps: { className: 'action-buttons view-button' },
                      tooltip: 'View More Details',
                      onClick: (event, rowData) =>
                        onFeedBackViewHandler(rowData.id, false),
                    },
                    {
                      icon: 'edit',
                      iconProps: { className: 'action-buttons edit-button' },
                      tooltip: 'Edit Feedback',
                      onClick: (event, rowData) =>
                        onFeedBackViewHandler(rowData.id, true),
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    sorting: false,
                    search: false,
                    maxBodyHeight: '70vh',
                    headerStyle: {
                      backgroundColor: '#e6e4df',
                      fontSize: '0.7rem',
                      textAlign: 'center',
                    },
                    rowStyle: {
                      fontSize: '0.6rem',
                      fontWeight: 450,
                      padding: '5rem',
                    },
                  }}
                  onRowClick={(event, rowData, toggleDetailPanel) =>
                    onFeedBackViewHandler(rowData.id, false)
                  }
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CustomerFeedbacks;
