import React from 'react';
import sytles from './Spinner.module.css';
import { ThreeCircles } from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const spinner = () => {
  // return (<div className={sytles.loader} ></div>);
  return (
    <ThreeCircles
      height="60"
      width="60"
      color="#ed3705"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  );
};

export default spinner;
