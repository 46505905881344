import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';


export async function updateCustomerFeedBack(method, url, data) {
    return await axios({
        method: method,
        url: url,
        data: data
    });
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
}


export async function sendCustomerFeedbackEmail(srId) {

    return axios.get(urlProperties.feedback.sendCustomerFeedbackEmail + "/" + srId)
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true")
}

export async function findByFeedbackToken(token) {


    return axios.get(urlProperties.feedback.findByFeedbackToken + "/" + token)
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true")
}

export async function getAllFeedback() {

    return axios.get(urlProperties.feedback.getAllFeedback)

}

export async function getFeedback(token) {

    return axios.get(urlProperties.feedback.getFeedbackById + "/" + token)

}

export async function getAllCustomerFeedback(customerId) {

    return axios.get(urlProperties.feedback.getAllCustomerFeedback + "/" + customerId)
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
}


