import React, { useState, useEffect, useRef } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from './Workflow.module.css';
import LoadingOverlay from 'react-loading-overlay';
import { handleErrorMessage } from 'services/CommonService';
import { Typography } from '@material-ui/core';
import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';
import {
  workflowProperties,
  getWorkflowForm,
  getStageForm,
} from 'Util/Property/WorkflowProperties';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import generalProperties from 'Util/Property/GeneralProperties';
import { userProperties } from 'Util/Property/UserProperties';
import { getAllChildRoles, getAllRoles } from 'services/User/UserService';
import { TrainRounded } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import WorkflowBreadcrumb from 'views/Settings/Workflows/Workflow/WorkflowBreadcrumb';
import { getStageByFormAndSubCategory } from 'services/stage/StageService';
import { getStatusByFormAndSubCategory } from 'services/status/StatusService';
import {
  getWorkflowTypes,
  getCategoryByWorkflowType,
  createWorkflow,
  updateWorkflow,
  getWorkflow,
} from 'services/Workflow/WorkflowService';
import Spinner from 'components/Spinner/Spinner.js';

const useStyles = makeStyles(styles);

const Workflow = (props) => {
  const [workflowForm, setWorkflowForm] = useState(getWorkflowForm());
  const [stageForm, setStageForm] = useState(getStageForm());
  const [workflowId, setWorkflowId] = useState(0);
  const [workflowHeading, setWorkflowHeading] = useState('Add Workflow');
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isStageView, setIsStageView] = useState(false);
  const tableRef = useRef(null);
  const [isStageDialogOpen, setIsStageDialogOpen] = useState(false);
  const [stageButton, setStageButton] = useState(generalProperties.add);
  const [userRole, setUserRole] = useState(localStorage.getItem('role'));
  const [loadingCount, setLoadingCount] = useState({
    count: 0,
  });
  const [isLoading, setIsLoadingIcon] = useState(false);
  const [stages, setStages] = useState([]);
  const [isStageAdd, setIsStageAdd] = useState(true);
  const [rowData, setRowData] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [stageNames, setStageNames] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [breadcurmbStages, setBreadcrumbStages] = useState([]);
  const [allRolesById, setAllRolesById] = useState({});
  const [statusById, setStatusById] = useState({});
  const [stagesById, setStagesById] = useState({});

  useEffect(() => {
    let wrflId = 0;
    if (props.id && props.id > 0) {
      wrflId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        workflowForm['form_type'].isDisabled = true;
        workflowForm['sub_type'].isDisabled = true;
        wrflId = queryParam['id'];
        if (queryParam['isEdit'] === 'true') {
          setWorkflowHeading('Edit Workflow');
          setIsEdit(true);
        } else {
          setWorkflowHeading('View Workflow');
          setIsView(true);
        }
      }
    }
    if (wrflId > 0) {
      getWorkflowToProcess(wrflId);
      setWorkflowId(wrflId);
    } else {
      getBackGroundData(true);
    }
  }, []);

  useEffect(() => {
    createBreadcrumbStages(stages);
  }, [stagesById]);

  const setIsLoading = (val) => {
    if (val) {
      loadingCount.count += 1;
    } else if (loadingCount.count > 0) {
      loadingCount.count -= 1;
    }

    if (loadingCount.count === 0) {
      setIsLoadingIcon(false);
      let formData = { ...workflowForm };
      setWorkflowForm(formData);
      let formData1 = { ...stageForm };
      setStageForm(formData1);
    } else {
      setIsLoadingIcon(true);
    }
  };
  const getWorkflowToProcess = async (wrflId) => {
    setIsLoading(true);
    await getWorkflow(wrflId)
      .then((result) => {
        if (result.status === 200) {
          for (let key in workflowForm) {
            let value = result.data[key];

            if (!workflowForm[key].isAsyncDropDown) {
              workflowForm[key].inputProps.value = value;
            } else {
              workflowForm[key].inputProps.initvalue = value;
            }
          }
          let stgs = result.data.stages_list;
          for (let key in stgs) {
            stgs[key]['sequence'] = parseInt(key) + 1;
          }
          setStages(stgs);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.loadData
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.loadData);
      });
    getBackGroundData(true);
  };

  const getBackGroundData = (isFirstTime) => {
    getRolesForUser();
    getFormNamesForWorkflow(isFirstTime);
  };

  const getFormNamesForWorkflow = (isFirstTime) => {
    setIsLoading(true);
    getWorkflowTypes()
      .then((result) => {
        if (result.status === 200) {
          let formOptions = [];
          result.data.map((form) =>
            formOptions.push({
              value: form.workflowType,
              displayValue: form.name,
            })
          );
          let defaultValue = setDataToWorkflowForm(
            'form_type',
            formOptions,
            isFirstTime
          );
          getSubCategoriesForWorkflow(defaultValue, isFirstTime);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.workflowTypes
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.workflowTypes
        );
      });
  };

  const getSubCategoriesForWorkflow = (formId, isFirstTime) => {
    setIsLoading(true);
    getCategoryByWorkflowType(formId)
      .then((result) => {
        if (result.status === 200) {
          if (typeof result.data === 'undefined' || result.data.length === 0) {
            getStagesForWorkflows(formId);
            getStatusForWorkflows(formId);
            workflowForm.sub_type.isHide = true;
          } else {
            workflowForm.sub_type.isHide = false;
            let formOptions = [];
            result.data.map((form) =>
              formOptions.push({ value: form.id, displayValue: form.name })
            );
            let defaultValue = setDataToWorkflowForm(
              'sub_type',
              formOptions,
              isFirstTime
            );
            getStagesForWorkflows(formId, defaultValue, isFirstTime);
            getStatusForWorkflows(formId, defaultValue, isFirstTime);
          }
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.workflowSubCategories
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(
          error,
          workflowProperties.messages.error.workflowSubCategories
        );
      });
  };

  const getStagesForWorkflows = (form, subType, isFirstTime) => {
    setIsLoading(true);
    getStageByFormAndSubCategory(form, subType)
      .then((result) => {
        if (result.status === 200) {
          let stageOptions = [];
          let stgById = {};
          result.data.map((stage) => {
            stgById[stage.id] = stage.stage_name;
            stageOptions.push({
              value: stage.id,
              displayValue: stage.stage_name,
            });
          });
          setStageNames(stageOptions);
          setStagesById(stgById);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.stages
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.stages);
      });
  };

  const getStatusForWorkflows = (form, subType, isFirstTime) => {
    setIsLoading(true);
    getStatusByFormAndSubCategory(form, subType)
      .then((result) => {
        if (result.status === 200) {
          let statusOptions = [];
          let stsById = {};
          result.data.map((status) => {
            stsById[status.id] = status.status_name;
            statusOptions.push({
              value: status.id,
              displayValue: status.status_name,
            });
          });
          setStatusNames(statusOptions);
          setStatusById(stsById);
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.status
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.status);
      });
  };

  const getRolesForUser = (isFirstTime) => {
    // setIsLoading(true);
    // getAllChildRoles(userRole).then(result => {
    //     if (result.status === 200) {
    //         let userRoleOptions = [];
    //         result.data.map(role => userRoleOptions.push({ "value": role.id, "displayValue": role.name }));
    //         setDataToStageForm("allow_user_roles", userRoleOptions, isFirstTime, TrainRounded);
    //         setUserRoles(userRoleOptions);
    //     } else {
    //         handleErrorMessage(result.data, userProperties.messages.error.userRoles);
    //     }
    //     setIsLoading(false);
    // }).catch(error => {
    //     setIsLoading(false);
    //     handleErrorMessage(error, userProperties.messages.error.userRoles);
    // });

    setIsLoading(true);
    getAllRoles()
      .then((result) => {
        if (result.status === 200) {
          let roleOptions = [];
          let rolesById = {};
          result.data.map((role) => {
            rolesById[role.id] = role.name;
            roleOptions.push({ value: role.id, displayValue: role.name });
          });
          setDataToStageForm(
            'allow_user_roles',
            roleOptions,
            isFirstTime,
            true
          );
          setUserRoles(roleOptions);
          setAllRoles(roleOptions);
          setAllRolesById(rolesById);
        } else {
          handleErrorMessage(
            result.data,
            userProperties.messages.error.userRoles
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, userProperties.messages.error.userRoles);
      });
  };

  const setDataToStageForm = (key, options, isFirstTime, isMultiple) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = stageForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    if (isMultiple) {
      let arr = [];
      defaultValue = arr;
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    stageForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    return defaultValue;
  };

  const setDataToWorkflowForm = (key, options, isFirstTime) => {
    // let serviceRequestFormData = { ...serviceRequestForm };
    let formData = workflowForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== '') {
      defaultValue = inputProps.initvalue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : '';
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    workflowForm[key] = formData;
    //setServiceRequestForm(serviceRequestFormData);
    //checkForStateUpdate(isFirstTime);
    return defaultValue;
  };

  let formElementArray = [];
  for (let key in workflowForm) {
    formElementArray.push({
      id: key,
      config: workflowForm[key],
    });
  }

  const onChangeHandeler = (event, elId) => {
    let value = event.target.value;
    let formData = { ...workflowForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elId === 'form_type') {
      getSubCategoriesForWorkflow(value);
    }
    if (elId === 'sub_type') {
      getStagesForWorkflows(formData.form_type.inputProps.value, value);
      getStatusForWorkflows(formData.form_type.inputProps.value, value);
    }
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setWorkflowForm(formData);
  };

  const checkForSavedStage = (oldFormType, currentFormType) => {};

  const onWorflowSubmit = async (event) => {
    let workflowFormData = { ...workflowForm };
    let data = {};
    for (let key in workflowFormData) {
      data[key] = workflowFormData[key].inputProps.value;
    }
    data['stages_list'] = stages;
    let method = createWorkflow;
    data['active'] = true;
    if (isEdit) {
      method = updateWorkflow;
    }
    data['id'] = workflowId;
    await method(data)
      .then((result) => {
        if (result.status === 200) {
          notyDefault({ type: notyTypes.SUCCESS, text: 'saved data' });
          props.history.goBack();
          // if (serviceRequestId == 0) {
          //     setDefaultData();
          // }
        } else {
          handleErrorMessage(
            result.data,
            workflowProperties.messages.error.status
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, workflowProperties.messages.error.status);
      });
  };

  const onStageViewHandler = (rowData, isView) => {
    setIsStageView(isView);
    let stageForm = getStageForm();
    stageForm.allow_user_roles.inputProps.options = allRoles;
    for (let key in stageForm) {
      stageForm[key].inputProps.value = rowData[key];
    }
    stageForm.stage_id.inputProps.options = stageNames;
    stageForm.allow_status.inputProps.options = statusNames;
    setStageButton(generalProperties.update);
    setIsStageAdd(false);
    setRowData(rowData);
    setStageForm(stageForm);
    setIsStageDialogOpen(true);
  };

  const stageCloseHander = () => {
    setIsStageDialogOpen(false);
  };

  const onStageSubmit = (values) => {
    let formData = { ...stageForm };
    let data = {};
    for (let key in formData) {
      data[key] = formData[key].inputProps.value;
    }
    if (typeof data.stage_id === 'undefined' || data.stage_id === '') {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'please select a correct stage',
      });
      return;
    }
    if (
      typeof data.allow_user_roles === 'undefined' ||
      data.allow_user_roles.length === 0
    ) {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'please select at least one role',
      });
      return;
    }
    if (
      typeof data.allow_status === 'undefined' ||
      data.allow_status.length === 0
    ) {
      notyDefault({
        type: notyTypes.ERROR,
        text: 'please select atl east one status',
      });
      return;
    }
    let sts = [];
    if (isStageAdd) {
      sts = setSequene(data, 'add');
      setStages(sts);
    } else {
      let newSequence = 0;
      let sequenceValue = 0;
      if (rowData.tableData.id + 1 === parseInt(data.sequence)) {
        let stgs = [...stages];
        stgs[rowData.tableData.id] = data;
        setStages(stgs);
        sts = stgs;
      } else {
        sts = setSequene(data, 'edit', rowData.tableData.id + 1);
        setStages(sts);
      }
    }
    createBreadcrumbStages(sts);
    setIsStageDialogOpen(false);
  };

  const createBreadcrumbStages = (stages) => {
    let bcStages = [];
    for (let ind in stages) {
      let stg = stages[ind];
      // let roleString = getDisplayRolesString(stg.allow_user_roles);
      // let displayString = <div>
      //     <div>{getStageString(stg.stage_id)}</div>
      //     <div>Allow roles</div>
      //     <div>{roleString}</div>
      // </div>
      // bcStages.push(displayString);
      let bcStage = {
        sequence: stg.sequence,
        stageName: getStageString(stg.stage_id),
      };
      bcStages.push(bcStage);
    }
    setBreadcrumbStages(bcStages);
  };

  const getDisplayRolesString = (roles) => {
    let roleString = '';
    for (let ind in roles) {
      roleString += allRolesById[roles[ind]] + ', ';
    }
    return <div>{roleString}</div>;
  };

  const getStatusString = (status) => {
    let statusString = '';
    for (let ind in status) {
      statusString += statusById[status[ind]] + ', ';
    }
    return <div>{statusString}</div>;
  };

  const getStageString = (stageId) => {
    return stagesById[stageId];
  };

  const onStageChangeHandler = (event, elId) => {
    let value = event.target.value;
    let formData = { ...stageForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    if (elId === 'bypassAllowed' || elId === 'forwardOnly') {
      value = event.target.checked;
    }
    if (elId === 'sequence') {
      value = validateSequence(value, inputProps.value, !isStageAdd);
    }
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setStageForm(formData);
  };

  const onStageAddHandler = () => {
    setDefaultValuesforStageForm(true);
    setIsStageAdd(true);
    setStageButton(generalProperties.add);
    setIsStageDialogOpen(true);
  };

  const setDefaultValuesforStageForm = (isAdd) => {
    let stageForm = getStageForm();
    if (isAdd) {
      stageForm.allow_user_roles.inputProps.options = userRoles;
    } else {
      stageForm.allow_user_roles.inputProps.options = allRoles;
    }
    stageForm.stage_id.inputProps.options = stageNames;
    stageForm.allow_status.inputProps.options = statusNames;
    setStageForm(stageForm);
  };
  const validateSequence = (value, sequence, isEdit) => {
    if (value == '') {
      return value;
    }
    let val = parseInt(value);
    if (!isNaN(val)) {
      value = '' + val;
    }
    if (!value.match('^([0-9]|[1-9][0-9]|100)$') && value !== '') {
      return sequence;
    }
    if (isEdit) {
      if (value > stages.length) {
        return sequence;
      }
    } else {
      if (value > stages.length + 1) {
        return sequence;
      }
    }
    return value;
  };

  const setSequene = (newData, action, index) => {
    let data = [...stages];
    let loopData = [...stages];
    let key;
    if (action === 'add') {
      key =
        typeof newData.sequence === 'undefined' ||
        newData.sequence === '0' ||
        newData.sequence === ''
          ? stages.length + 1
          : parseInt(newData.sequence);
      newData.sequence = parseInt(key);
      if (key <= data.length) {
        key = key - 1;
      }
      for (let i = key; i < data.length; i++) {
        data[i].sequence = parseInt(data[i].sequence) + 1;
      }
      data.push(newData);
    } else if (action === 'delete') {
      let key = parseInt(index);
      for (let i = key; i < loopData.length; i++) {
        data[i].sequence = loopData[i].sequence - 1;
      }
      data.splice(key - 1, 1);
    } else {
      key = parseInt(newData.sequence);
      if (index < key) {
        for (let i = index; i < key; i++) {
          data[i].sequence = parseInt(data[i].sequence) - 1;
        }
      } else {
        for (let i = key - 1; i < index; i++) {
          data[i].sequence = parseInt(data[i].sequence) + 1;
        }
      }
      data[index - 1] = newData;
    }
    data.sort(sortBySequence);
    return data;
  };

  const sortBySequence = (a, b) => {
    return a.sequence - b.sequence;
  };

  const onStageDeleteHandler = (rowData) => {
    setRowData(rowData);
    setIsDeleteDialogOpen(true);
  };

  const deleteStageCloseHandler = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteConfirmHandler = () => {
    let data = setSequene('', 'delete', rowData.tableData.id + 1);
    setStages(data);
    createBreadcrumbStages(data);
    setIsDeleteDialogOpen(false);
  };

  let stageFormElementArray = [];
  for (let key in stageForm) {
    stageFormElementArray.push({
      id: key,
      config: stageForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <Dialog
                open={isDeleteDialogOpen}
                onClose={deleteStageCloseHandler}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you want to delete this stage
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    size="sm"
                    onClick={deleteStageCloseHandler}
                    color="primary"
                  >
                    {generalProperties.cancel}
                  </Button>
                  <Button
                    size="sm"
                    onClick={deleteConfirmHandler}
                    color="primary"
                    autoFocus
                  >
                    {generalProperties.confirm}
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="generic-form-container">
                {!isEdit ? (
                  <div className={'generic-form-header-wrapper'}>
                    <div className={'generic-page-title'}>
                      <span className={'generic-page-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={'generic-header-text'}
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {workflowHeading}
                      </Typography>
                      {/* <Typography variant="body1">Manage your workflow here</Typography> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <Dialog
                  open={isStageDialogOpen}
                  onClose={stageCloseHander}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <Card>
                      <div className={'generic-form-header-wrapper'}>
                        <div className={'generic-page-title'}>
                          <span className={'generic-page-title-icon'}>
                            <ListOutlinedIcon />
                          </span>
                          <Typography
                            className={'generic-header-text'}
                            variant="h6"
                            style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                          >
                            Stages
                          </Typography>
                          {/* <Typography variant="body1">Manage stages here.</Typography> */}
                        </div>
                      </div>
                      <Form
                        onSubmit={onStageSubmit}
                        validate={(values, ss) => {
                          const errors = {};

                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          reset,
                          submitting,
                          pristine,
                          valid,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <CardBody>
                              <GridContainer>
                                {stageFormElementArray.map((element) => {
                                  if (!element.config.isHide) {
                                    let mdVal = element.config.size;
                                    return (
                                      <GridItem
                                        key={element.id}
                                        xs={12}
                                        sm={12}
                                        md={mdVal}
                                      >
                                        {element.id === 'comments' ? (
                                          <div> Comments</div>
                                        ) : (
                                          ''
                                        )}
                                        <Field
                                          name={element.id}
                                          type={element.config.elType}
                                        >
                                          {({
                                            input,
                                            meta,
                                            options,
                                            value,
                                          }) => (
                                            <div
                                              style={{ position: 'relative' }}
                                            >
                                              <CustomInput
                                                labelText={element.config.label}
                                                id={element.id}
                                                inputProps={{
                                                  ...input,
                                                  ...element.config.inputProps,
                                                  readOnly:
                                                    isStageView ||
                                                    element.config.readOnly
                                                      ? true
                                                      : false,
                                                }}
                                                type={element.config.elType}
                                                formControlProps={
                                                  element.config
                                                    .formControlProps
                                                }
                                                adornmentText={
                                                  element.config.adornmentText
                                                }
                                                adornmentPosition={
                                                  element.config
                                                    .adornmentPosition
                                                }
                                                multiple={
                                                  element.config.multiple
                                                }
                                                changed={(event, value) => {
                                                  input.onChange(event);
                                                  onStageChangeHandler(
                                                    event,
                                                    element.id,
                                                    meta
                                                  );
                                                }}
                                                labelProps={{
                                                  ...element.config.labelProps,
                                                  error:
                                                    meta.error && meta.touched,
                                                }}
                                              />
                                              {meta.error && meta.touched && (
                                                <span
                                                  className={styles.formError}
                                                >
                                                  {meta.error}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      </GridItem>
                                    );
                                  }
                                })}
                              </GridContainer>
                            </CardBody>
                            <CardFooter style={{ display: 'block' }}>
                              {!isStageView ? (
                                <Button
                                  type="submit"
                                  disabled={submitting || !valid}
                                  color="primary"
                                  autoFocus
                                >
                                  {stageButton}
                                </Button>
                              ) : (
                                ''
                              )}
                              <Button
                                style={{ marginLeft: '20px' }}
                                onClick={stageCloseHander}
                                color="primary"
                                autoFocus
                              >
                                {generalProperties.cancel}
                              </Button>
                            </CardFooter>
                          </form>
                        )}
                      />
                    </Card>
                  </DialogContent>
                </Dialog>
                <Form
                  onSubmit={onWorflowSubmit}
                  validate={(values, ss) => {
                    const errors = {};
                    if (workflowForm.name.inputProps.value === '') {
                      // setEror("first_name", true)
                      errors.name = generalProperties.emptyField;
                    }
                    if (stages.length === 0) {
                      // setEror("first_name", true)
                      errors.stages = generalProperties.emptyField;
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        <GridContainer>
                          {formElementArray.map((element) => {
                            if (!element.config.isHide) {
                              let mdVal = element.config.size;
                              return (
                                <GridItem
                                  key={element.id}
                                  xs={12}
                                  sm={12}
                                  md={mdVal}
                                >
                                  <Field name={element.id}>
                                    {({ input, meta, options, value }) => (
                                      <div style={{ position: 'relative' }}>
                                        <CustomInput
                                          labelText={element.config.label}
                                          id={element.id}
                                          inputProps={{
                                            ...input,
                                            ...element.config.inputProps,
                                            readOnly:
                                              isView ||
                                              element.config.isDisabled
                                                ? true
                                                : false,
                                          }}
                                          type={
                                            element.id === 'userIdList' &&
                                            isView
                                              ? 'input'
                                              : element.config.elType
                                          }
                                          formControlProps={
                                            element.config.formControlProps
                                          }
                                          adornmentText={
                                            element.config.adornmentText
                                          }
                                          adornmentPosition={
                                            element.config.adornmentPosition
                                          }
                                          multiple={element.config.multiple}
                                          changed={(event, value) => {
                                            input.onChange(event);
                                            onChangeHandeler(event, element.id);
                                          }}
                                          labelProps={{
                                            ...element.config.labelProps,
                                            error: meta.error && meta.touched,
                                          }}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className={styles.formError}>
                                            {meta.error}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                  </Field>
                                </GridItem>
                              );
                            }
                          })}
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <WorkflowBreadcrumb stages={breadcurmbStages} />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={'material-grid-wrapper'}>
                              <MaterialTable
                                components={{
                                  OverlayLoading: () => (
                                    <div className={styles.loadingOverlay}>
                                      <Spinner />
                                    </div>
                                  ),
                                }}
                                title={
                                  <div>
                                    <span className={'grid-title-icon'}>
                                      <ListAltOutlinedIcon
                                        style={{ fontSize: '1.2rem' }}
                                      />
                                    </span>
                                    <Typography
                                      variant="h6"
                                      style={{
                                        fontSize: '1rem',
                                        marginTop: '-0.7rem',
                                      }}
                                    >
                                      Stages
                                    </Typography>
                                    {/* <Typography variant="body1">Manage products here</Typography> */}
                                  </div>
                                }
                                tableRef={tableRef}
                                columns={[
                                  { title: 'Sequence', field: 'sequence' },
                                  {
                                    title: 'Stage',
                                    field: 'stage',
                                    render: (rowData) =>
                                      getStageString(rowData.stage_id),
                                  },
                                  {
                                    title: 'Roles',
                                    field: 'stage',
                                    render: (rowData) =>
                                      getDisplayRolesString(
                                        rowData.allow_user_roles
                                      ),
                                    cellStyle: {
                                      width: 230,
                                      minWidth: 230,
                                    },
                                  },
                                  {
                                    title: 'Forward Only',
                                    field: 'forwardOnly',
                                    render: (rowData) => (
                                      <Checkbox
                                        checked={rowData.forwardOnly}
                                        name="checkedB"
                                        color="primary"
                                        readOnly
                                      />
                                    ),
                                  },
                                  {
                                    title: 'Bypass Allowed',
                                    field: 'bypassAllowed',
                                    render: (rowData) => (
                                      <Checkbox
                                        checked={rowData.bypassAllowed}
                                        name="checkedB"
                                        color="primary"
                                        readOnly
                                      />
                                    ),
                                  },
                                  // { title: 'Status', field: 'status', render: rowData => getStatusString(rowData.allow_status) }
                                ]}
                                data={stages}
                                actions={[
                                  {
                                    icon: 'visibility',
                                    tooltip: 'View Stage',
                                    iconProps: {
                                      className: 'action-buttons view-button',
                                    },
                                    tooltip: 'View More Details',
                                    onClick: (event, rowData) =>
                                      onStageViewHandler(rowData, true),
                                  },
                                  {
                                    icon: 'edit',
                                    tooltip: 'Edit Stage',
                                    iconProps: {
                                      className: 'action-buttons edit-button',
                                    },
                                    onClick: (event, rowData) =>
                                      onStageViewHandler(rowData, false),
                                    position: 'row',
                                    hidden: isView,
                                  },

                                  {
                                    icon: 'delete',
                                    tooltip: 'Delete Stage',
                                    iconProps: {
                                      className: 'action-buttons delete-button',
                                    },
                                    onClick: (event, rowData) =>
                                      onStageDeleteHandler(rowData),
                                    position: 'row',
                                    hidden: isView,
                                  },
                                  {
                                    icon: () => (
                                      <div
                                        className={
                                          'generic-button-class div-button-wrapper'
                                        }
                                        variant="contained"
                                        color="primary"
                                      >
                                        Add New Stage
                                      </div>
                                    ),
                                    tooltip: 'Add New Stage',
                                    isFreeAction: true,
                                    onClick: (event, rowData) =>
                                      onStageAddHandler(),
                                    hidden: isView,
                                  },
                                ]}
                                options={{
                                  actionsColumnIndex: -1,
                                  filtering: true,
                                  sorting: false,
                                  search: false,
                                  maxBodyHeight: '70vh',
                                  headerStyle: {
                                    fontSize: '0.7rem',
                                    textAlignL: 'center',
                                  },
                                  rowStyle: {
                                    fontSize: '0.6rem',
                                    fontWeight: 450,
                                    padding: '5rem',
                                  },
                                }}
                                onRowClick={(
                                  event,
                                  rowData,
                                  toggleDetailPanel
                                ) => onStageViewHandler(rowData, true)}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView ? (
                          <Button
                            type="submit"
                            disabled={submitting || !valid}
                            color="primary"
                            autoFocus
                          >
                            Save
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Workflow;
