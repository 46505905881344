const frequencyArray = [
  { value: 'QUARTERLY', displayValue: 'QUARTERLY' },
  { value: 'BI_ANNUALLY', displayValue: 'BI-ANUALLY' },
  { value: 'ANNUALLY', displayValue: 'ANNUALLY' },
  { value: 'MONTHLY', displayValue: 'MONTHLY' },
];
const serviceAgreementType = [
  { value: 'WARRANTY', displayValue: 'WARRANTY' },
  { value: 'LABOUR_ONLY', displayValue: 'LABOUR-ONLY' },
  { value: 'COMPREHENSIVE', displayValue: 'COMPREHENSIVE' },
];
const duration = [
  { value: 3, displayValue: '3-MONTH' },
  { value: 6, displayValue: '6-MONTH' },
  { value: 12, displayValue: '12-MONTH' },
  { value: 24, displayValue: '24-MONTH' },
  { value: 36, displayValue: '36-MONTH' },
];

const statusArray = [
  { value: true, displayValue: 'Active' },
  { value: false, displayValue: 'Inactive' },
];
const datePickerFormat = 'MM/DD/yyyy';

const priorityCategories = [
  { value: 'CRITICAL', displayValue: 'Critical' },
  { value: 'HIGH', displayValue: 'High' },
  { value: 'MEDIUM', displayValue: 'Medium' },
  { value: 'LOW', displayValue: 'Low' },
];

const duration_enum = {
  3: '3-MONTH',
  6: '6-MONTH',
  12: '12-MONTH',
  24: '24-MONTH',
  36: '36-MONTH',
};

export const ServiceAgreementProperties = {
  addSa: 'Add Service Agreement',
  editSa: 'Edit Service Agreement',
  viewSa: 'View Service Agreement',
  duration_enum: duration_enum,
  addServiceAgreement: 'Add Service Agreement',
  editServiceAgreement: 'Edit Service Agreement',
  messages: {
    error: {
      loadData: 'Could not load service agreement data. Please try again!',
      saveData: 'Could not save service agreement. Please try again!',
      deleteServiceAgreement:
        'Could not terminate service agreement. Please try again!',
      nameTaken: 'Name is already taken!',
      loadAll: 'Could not load service agreements. Please try again!',
      loadProductCategories: 'Could not load product categories!',
      loadServiceAgreementModules: 'Could not load service agreement modules!',
      loadServiceAgreementTypes: 'Could not load service agreement types!',
      loadProductBrands: 'Could not load product brands!',
      loadProductModels: 'Could not load product models!',
      loadProductSubStatuses: 'Could not load product sub statuses!',
      loadCustomer: 'Could not load Customer!',
    },
    success: {
      saveData: 'Service agreement details saved successfully!',
      deleteServiceAgreement: 'Service agreement deleted successfully!',
    },
    normal: {
      deleteServiceAgreement: 'Do you want to delete this service agreement?',
    },
    warning: {
      noData: 'No expiring Product Service Agreements for the given date!',
    },
  },
  addNewServiceAgreement: 'Add New Service Agreement',
  serviceAgreements: 'Service Agreements',
};

export const getServiceAgreementForm = () => {
  return {
    name: {
      elType: 'input',
      label: 'Service Agreement Name',
      inputProps: {
        type: 'text',
        placeholder: 'Service Agreement Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    type_id: {
      elType: 'select',
      label: 'Type',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        disabled: false,
      },

      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      isAsyncDropDown: true,
    },

    duration: {
      elType: 'select',
      label: 'Duration',
      inputProps: {
        options: duration,
        value: duration[0].value,
        initvalue: duration[0].value,
        disabled: false,
      },

      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
    },
    Service_frequency: {
      elType: 'select',
      label: 'Service Frequency',
      inputProps: {
        options: frequencyArray,
        value: frequencyArray[0].value,
        initvalue: frequencyArray[0].value,
        disabled: false,
      },

      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
    },
    productCategory: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },

      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      isAsyncDropDown: true,
    },
    // productBrand: {
    //   elType: 'select',
    //   label: 'Product Brand',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //     disabled: false,
    //   },

    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   isAsyncDropDown: true,
    //   isHide: true,
    // },
    // productModel: {
    //   elType: 'select',
    //   label: 'Product Model',
    //   inputProps: {
    //     options: [],
    //     value: '',
    //     initvalue: '',
    //     disabled: false,
    //   },

    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   isAsyncDropDown: true,
    //   isHide: true,
    // },
    price: {
      elType: 'input',
      label: 'Price',
      inputProps: {
        type: 'number',
        placeholder: 'Price',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: true,
    },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: statusArray,
        value: statusArray[0].value,
        initvalue: statusArray[0].value,
        disabled: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      isHide: false,
    },
  };
};

export const getAddServiceAgreementForm = () => {
  return {
    service_agreement_no: {
      idt1: 1,
      idt2: 9,
      elType: 'input',
      label: 'Product Service Agreement No',
      inputProps: {
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isHide: false,
      readOnly: true,
    },
    sa_module_name: {
      idt1: 2,
      idt2: 2,
      elType: 'select',
      label: 'SA Module Name',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },

      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      multiple: true,
    },
    customer_id: {
      idt1: 3,
      idt2: 1,
      elType: 'select',
      label: 'Customer *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        customSearchable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    service_agreement_type: {
      idt1: 4,
      idt2: 4,
      elType: 'select',
      label: 'Service Agreement Type',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      isHide: false,
      readOnly: true,
    },
    product_category_id: {
      idt1: 5,
      idt2: 3,
      elType: 'select',
      label: 'Product Category *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    start_date: {
      idt1: 6,
      idt2: 6,
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Start Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
    },
    product_brand_id: {
      idt1: 7,
      idt2: 5,
      elType: 'select',
      label: 'Product Brand *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    terminate_date: {
      idt1: 8,
      idt2: 8,
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: null,
        label: 'Terminate Date',
        initvalue: null,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      size: 6,
      readOnly: true,
    },
    product_model_id: {
      idt1: 9,
      idt2: 7,
      elType: 'select',
      label: 'Product Model *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    serial_number: {
      idt1: 10,
      idt2: 10,
      elType: 'select',
      label: 'Serial Number *',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
      },

      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
      multiple: true,
      isHide: true,
    },
    stage: {
      idt1: 1,
      idt2: 9,
      elType: 'input',
      label: 'Stage ',
      inputProps: {
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isHide: false,
      readOnly: true,
    },
  };
};
