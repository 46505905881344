import { loadGoogleMapsScript } from "./google-maps-loader";
import { getSrWaypoints } from "./journey-utils";

export const generateAirDistance = (waypoints) => {
  if (!Array.isArray(waypoints)) return null;
  const latLngPath = waypoints.filter(waypoints => waypoints.lat !== null && waypoints.lng !== null).map(
    (point) => new window.google.maps.LatLng(point.lat, point.lng)
  );
  const airDistanceInMeters = window.google.maps.geometry.spherical.computeLength(
    latLngPath
  );
  const airDistanceInKm = airDistanceInMeters / 1000;

  return airDistanceInKm.toFixed(2);
};

export const getFormattedJourneyObject = async (journeyObject) => {
  const {
    id,
    type,
    name,
    journey,
    color: routeColor,
    startTime,
    endTime,
    customerName,
  } = journeyObject;

  const { origin, waypoints } = journey;
  const destination = waypoints[waypoints.length - 1];

  let path = [origin, ...waypoints].filter((path) => path.lat && path.lng);
  const distance = generateAirDistance(path);

  return {
    id,
    name,
    type,
    distance,
    startTime,
    endTime,
    origin,
    destination,
    routeColor,
    path,
    customerName,
  };
};

export const generateDistanceFromPreviosJob = async (
  previousTimeStamp,
  currentTimeStamp,
  mainWaypoints
) => {
  await loadGoogleMapsScript();

  const waypoints = getSrWaypoints(
    previousTimeStamp,
    currentTimeStamp,
    mainWaypoints
  );

  const formattedWaypoints = waypoints.map(({ endLatitude, endLongitude }) => ({
    lat: endLatitude,
    lng: endLongitude,
  }));

  return generateAirDistance(formattedWaypoints);
};

export const generateDistanceFromDistributor = async (
  currentTimeStamp,
  mainWaypoints
) => {
  await loadGoogleMapsScript();

  const waypoints = getSrWaypoints(mainWaypoints[0].dateTime, currentTimeStamp, mainWaypoints);
  const formattedWaypoints = waypoints.map(({ endLatitude, endLongitude }) => ({
    lat: endLatitude,
    lng: endLongitude,
  }));

  return generateAirDistance(formattedWaypoints);
};
