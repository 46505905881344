import * as ActionTypes from "./FuelRatesActionTypes";
import FuelRatesService from "../../services/ClaimsSettings/FuelRatesService";

export const geAllFuelRates = () => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.GET_FUEL_RATES_LIST_START });

      const response = await FuelRatesService.getAllActiveFuelRates();
      dispatch({
        type: ActionTypes.GET_FUEL_RATES_LIST_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_FUEL_RATES_LIST_FAILED,
        payload: error
      });
    }
  };
};

export const getFuelRateById = id => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.GET_FUEL_RATE_BY_ID_START });

      const response = await FuelRatesService.getFuelRateById(id);
      dispatch({
        type: ActionTypes.GET_FUEL_RATES_BY_ID_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_FUEL_RATES_BY_ID_SUCCESS,
        payload: error
      });
    }
  };
};
export const createFuelRate = data => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.CREATE_FUEL_RATE_START });
      const response = await FuelRatesService.createFuelRate(data);
      dispatch({
        type: ActionTypes.CREATE_FUEL_RATE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_FUEL_RATE_FAILED,
        payload: error
      });
    }
  };
};
export const updateFuelRate = (data, id) => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.UPDATE_FUEL_RATE_START });
      const response = await FuelRatesService.updateFuelRate(data, id);
      dispatch({
        type: ActionTypes.UPDATE_FUEL_RATE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_FUEL_RATE_FAILED,
        payload: error
      });
    }
  };
};
export const deleteFuelRate = id => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.DELETE_FUEL_RATE_START });
      const fuelRateResponse = await FuelRatesService.getFuelRateById(id);
      let fuelRate = fuelRateResponse.data;
      fuelRate.is_active = false;
      const response = await FuelRatesService.updateFuelRate(
        fuelRate,
        id
      );
      dispatch({
        type: ActionTypes.DELETE_FUEL_RATE_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.DELETE_FUEL_RATE_FAILED,
        payload: error
      });
    }
  };
};
export const getVehicleTypes = () => {
  return async dispatch => {
    try {
      dispatch({ type: ActionTypes.GET_ACTIVE_VEHICLE_TYPES_START });
      const response = await FuelRatesService.getActiveVehicleTypes();
      dispatch({
        type: ActionTypes.GET_ACTIVE_VEHICLE_TYPES_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ACTIVE_VEHICLE_TYPES_FAILED,
        payload: error
      });
    }
  };
};
