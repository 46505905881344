import React, { useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import { Avatar, Button, Box, Slider } from "@material-ui/core";

const ImageEditor = (props) => {
  //   const [base64Image, setBase64Image] = useState("");
  const [hasIMG, sethasIMG] = useState(false);
  var editor = null;
  const handleImageupload = (base64Image, hasIMG) => {
    props.getConvertedImage(base64Image, hasIMG);
  };
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png",
  });

  useEffect(() => {
    props.getConvertedImage(picture.croppedImg);
  }, [picture.croppedImg]);

  useEffect(() => {
    handleSave();
  }, [picture.zoom]);

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const ImageBase64Converted = await convertToBase64(file);
    // setBase64Image(ImageBase64Converted);
    setPicture({
      ...picture,
      img: ImageBase64Converted,
    });
    console.log(ImageBase64Converted);
    handleImageupload(ImageBase64Converted, true);
  };
  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = () => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      //   props.onImageCrop(croppedImg);
      setPicture({
        ...picture,
        cropperOpen: false,
        croppedImg: croppedImg,
      });
    }
  };

  return (
    <diV>
      <AvatarEditor
        ref={setEditorRef}
        image={picture.img}
        width={250}
        height={250}
        border={10}
        scale={picture.zoom}
        onPositionChange={(e) => {
          handleSave();
        }}
        onImageChange={() => {
          if (editor != null) {
            handleSave();
          }
        }}
      />

      <div>
        <label
          className={"generic-button-class div-button-wrapper"}
          variant="contained"
          color="primary"
        >
          {"Choose an Image"}
          <input
            type="file"
            accept={"image/*"}
            style={{
              "background-color": "coral",
              padding: "1px 1px",
              display: "none",
            }}
            onChange={(e) => {
              uploadImage(e);
              sethasIMG(true);
            }}
          />
        </label>
      </div>
      <Slider
        aria-label="raceSlider"
        value={picture.zoom}
        min={1}
        max={10}
        step={0.1}
        onChange={handleSlider}
      ></Slider>
    </diV>
  );
};

export default ImageEditor;
