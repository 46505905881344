import React, { useState, useEffect, useRef } from 'react';
import styles from './ServiceRequestsSettings.module.css';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import LoadingOverlay from 'react-loading-overlay';
import checklistProperties from 'Util/Property/CheckListProperties';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { priorityConfigurationProperties } from 'Util/Property/PriorityConfigurationProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import { Link } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import generalProperties from 'Util/Property/GeneralProperties';
import MaterialTable from 'material-table';
import { deleteChecklist } from 'services/CheckList/ChecklistService';
import { deletePriorityConfiguration } from 'services/PriorityConfiguration/PriorityConfigurationService';
import ServiceRequestCategoryTable from './serviceRequestCategory/serviceRequestCategoryTable';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import SettingsApplicationsOutlined from '@material-ui/icons/SettingsApplicationsOutlined';
import { Typography } from '@material-ui/core';
import { buildFilterObject } from '../../../Util/Util';
import Spinner from 'components/Spinner/Spinner.js';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));

const ServiceRequestsSettings = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({
    id: {},
    message: checklistProperties.messages.normal.deleteConfirm,
    method: deleteChecklist,
  });
  let serviceRequestSettingsTab = 'serviceRequestSettingsTab';

  const checkListTableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addCheckList,
      PermissionProperties.viewCheckList,
      PermissionProperties.editCheckList,
      PermissionProperties.deleteCheckList,
      PermissionProperties.addPriorityConfiguration,
      PermissionProperties.viewPriorityConfiguration,
      PermissionProperties.editPriorityConfiguration,
      PermissionProperties.deletePriorityConfiguration,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(serviceRequestSettingsTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setTabIndex(parseInt(tab));
    }
  }, []);

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    localStorage.setItem(serviceRequestSettingsTab, newValue);
  };

  const onPriorityConfigurationEditHandler = (id, isEdit) => {
    props.history.push({
      pathname:
        props.match.url +
        '/' +
        urlProperties.priorityConfiguration.priorityConfiguration,
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const onCheckListEditHandler = (id, isEdit) => {
    props.history.push({
      pathname: props.match.url + '/' + urlProperties.checklist.checklist,
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const deleteChecklistDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteChecklistDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    deleteRowData
      .method(deleteRowData.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: generalProperties.message.success.delete,
          });
          checkListTableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({ type: notyTypes.ERROR, text: result.data });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: generalProperties.message.error.delete,
        });
      });

    deleteChecklistDialogCloseHandler();
  };

  const deleteChecklistHandler = (rowData) => {
    let rd = { ...deleteRowData };
    if (tabIndex === 1) {
      rd.message = checklistProperties.messages.normal.deleteConfirm;
      rd.method = deleteChecklist;
    } else {
      rd.message =
        priorityConfigurationProperties.messages.normal.deleteConfirm;
      rd.method = deletePriorityConfiguration;
    }
    rd.id = rowData.id;
    setDeleteRowData(rd);
    deleteChecklistDialogOpenHandler();
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text={generalProperties.pleaseWait}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Dialog
              open={isDeleteDialogOpen}
              onClose={deleteChecklistDialogCloseHandler}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {deleteRowData.message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="sm"
                  onClick={deleteChecklistDialogCloseHandler}
                  color="primary"
                >
                  {generalProperties.cancel}
                </Button>
                <Button
                  size="sm"
                  onClick={deleteConfirmHandler}
                  color="primary"
                  autoFocus
                >
                  {generalProperties.confirm}
                </Button>
              </DialogActions>
            </Dialog>
            <Card>
              <CardBody>
                <AppBar position="static" color="inherit">
                  <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    className={classes.tabsWrapper}
                  >
                    <Tab label={generalProperties.category} {...a11yProps(0)} />
                    {permissions[PermissionProperties.viewCheckList] ? (
                      <Tab
                        label={generalProperties.checkList}
                        {...a11yProps(1)}
                      />
                    ) : (
                      ''
                    )}
                    <Tab
                      label={generalProperties.priorityConfiguration}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={tabIndex}
                  index={0}
                  dir={theme.direction}
                >
                  <ServiceRequestCategoryTable />
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={tabIndex}
                  index={1}
                  dir={theme.direction}
                >
                  {/* {
                                        permissions[PermissionProperties.addCheckList] ?
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className={styles.AddCheckListDiv}>
                                                        <Link to={props.match.url + "/" + urlProperties.checklist.checklist}>
                                                            <Button className="add-data-button" variant="contained" color="primary">{checklistProperties.addChecklist}</Button>
                                                        </Link>
                                                    </div>
                                                </GridItem>
                                            </GridContainer> : ""
                                    } */}

                  {/* Checklist table content */}
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={'material-grid-wrapper'}>
                            <MaterialTable
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              title={
                                <div>
                                  <span className={'grid-title-icon'}>
                                    <PlaylistAddCheck />
                                  </span>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    {checklistProperties.checklists}
                                  </Typography>
                                  {/* <Typography variant="body1">Manage your checklists</Typography> */}
                                </div>
                              }
                              tableRef={checkListTableRef}
                              columns={[
                                { title: 'Name', field: 'name' },
                                {
                                  title: 'Service Request Type',
                                  field: 'serviceRequestCategoryName',
                                },
                                {
                                  title: 'Product Category',
                                  field: 'productCategoryName',
                                },
                                {
                                  title: 'Product Brand',
                                  field: 'productBrandName',
                                },
                                {
                                  title: 'Product Model',
                                  field: 'productModelName',
                                },
                              ]}
                              data={(query) =>
                                new Promise((resolve, reject) => {
                                  let url =
                                    urlProperties.baseUrl +
                                    urlProperties.checklist.filterChecklists +
                                    '?';
                                  url += 'perPage=' + query.pageSize;
                                  url += '&page=' + query.page;
                                  url +=
                                    '&sortColumn=' +
                                    (typeof query.orderBy === 'undefined'
                                      ? 'name'
                                      : query.orderBy.field);
                                  url += '&sortOrder=' + query.orderDirection;
                                  url += '&search=' + query.search;
                                  fetch(url, {
                                    headers: {
                                      Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                      'Content-Type': 'application/json',
                                    },
                                    method: 'POST',
                                    body: JSON.stringify(
                                      buildFilterObject(query)
                                    ),
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total,
                                      });
                                    });
                                })
                              }
                              actions={[
                                {
                                  icon: 'read_more',
                                  tooltip: 'View More Details',
                                  iconProps: {
                                    className: 'action-buttons view-button',
                                  },
                                  onClick: (event, rowData) =>
                                    onCheckListEditHandler(rowData.id, false),
                                  hidden: !permissions[
                                    PermissionProperties.viewCheckList
                                  ],
                                },

                                {
                                  icon: 'edit',
                                  tooltip: 'Edit CheckList',
                                  iconProps: {
                                    className: 'action-buttons edit-button',
                                  },
                                  onClick: (event, rowData) =>
                                    onCheckListEditHandler(rowData.id, true),
                                  hidden: !permissions[
                                    PermissionProperties.editCheckList
                                  ],
                                },

                                {
                                  icon: 'delete',
                                  tooltip: 'Delete CheckList',
                                  iconProps: {
                                    className: 'action-buttons delete-button',
                                  },
                                  onClick: (event, rowData) =>
                                    deleteChecklistHandler(rowData),
                                  hidden: !permissions[
                                    PermissionProperties.deleteCheckList
                                  ],
                                },
                                {
                                  icon: () => (
                                    <Link
                                      to={
                                        props.match.url +
                                        '/' +
                                        urlProperties.checklist.checklist
                                      }
                                    >
                                      <div
                                        className={
                                          'generic-button-class div-button-wrapper'
                                        }
                                        variant="contained"
                                        color="primary"
                                      >
                                        {checklistProperties.addChecklist}
                                      </div>
                                    </Link>
                                  ),
                                  tooltip: 'Add Checklist',
                                  isFreeAction: true,
                                  hidden: !permissions[
                                    PermissionProperties.addCheckList
                                  ],
                                },
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={tabIndex}
                  index={2}
                  dir={theme.direction}
                >
                  {/* {
                                        permissions[PermissionProperties.addPriorityConfiguration] ?
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div className={styles.AddCheckListDiv}>
                                                        <Link to={props.match.url + "/" + urlProperties.priorityConfiguration.priorityConfiguration}>
                                                            <Button className="add-data-button" variant="contained" color="primary">{priorityConfigurationProperties.addPriorityConfiguration}</Button>
                                                        </Link>
                                                    </div>
                                                </GridItem>
                                            </GridContainer> : ""
                                    } */}

                  {/* Checklist table content */}
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={'material-grid-wrapper'}>
                            <MaterialTable
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              title={
                                <div>
                                  <span className={'grid-title-icon'}>
                                    <SettingsApplicationsOutlined />
                                  </span>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    {
                                      priorityConfigurationProperties.priorityConfigurations
                                    }
                                  </Typography>
                                  {/* <Typography variant="body1">Manage Priority Configurations here</Typography> */}
                                </div>
                              }
                              tableRef={checkListTableRef}
                              columns={[
                                { title: 'Name', field: 'name' },
                                { title: 'Customer', field: 'customerName' },
                                {
                                  title: 'Request Type',
                                  field: 'serviceRequestCategory.name',
                                  filterField: 'serviceRequestCategoryName',
                                },
                                {
                                  title: 'Product Category',
                                  field: 'productCategory.name',
                                  filterField: 'productCategoryName',
                                },
                                {
                                  title: 'Product Brand',
                                  field: 'productBrand.name',
                                  filterField: 'productBrandName',
                                },
                                {
                                  title: 'Product Model',
                                  field: 'productModel.name',
                                  filterField: 'productModelName',
                                },
                                { title: 'Priority', field: 'priority' },
                              ]}
                              data={(query) =>
                                new Promise((resolve, reject) => {
                                  let url =
                                    urlProperties.baseUrl +
                                    urlProperties.priorityConfiguration
                                      .filterPriorityConfigurations +
                                    '?';
                                  url += 'perPage=' + query.pageSize;
                                  url += '&page=' + query.page;
                                  url +=
                                    '&sortColumn=' +
                                    (typeof query.orderBy === 'undefined'
                                      ? 'name'
                                      : query.orderBy.field);
                                  url += '&sortOrder=' + query.orderDirection;
                                  url += '&search=' + query.search;
                                  fetch(url, {
                                    headers: {
                                      Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                      'Content-Type': 'application/json',
                                    },
                                    method: 'POST',
                                    body: JSON.stringify(
                                      buildFilterObject(query)
                                    ),
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total,
                                      });
                                    });
                                })
                              }
                              actions={[
                                {
                                  icon: 'read_more',
                                  tooltip: 'View More Details',
                                  iconProps: {
                                    className: 'action-buttons view-button',
                                  },
                                  onClick: (event, rowData) =>
                                    onPriorityConfigurationEditHandler(
                                      rowData.id,
                                      false
                                    ),
                                  hidden: !permissions[
                                    PermissionProperties
                                      .viewPriorityConfiguration
                                  ],
                                },

                                {
                                  icon: 'edit',
                                  tooltip: 'Edit Priority Configuration',
                                  iconProps: {
                                    className: 'action-buttons edit-button',
                                  },
                                  onClick: (event, rowData) =>
                                    onPriorityConfigurationEditHandler(
                                      rowData.id,
                                      true
                                    ),
                                  hidden: !permissions[
                                    PermissionProperties
                                      .editPriorityConfiguration
                                  ],
                                },

                                {
                                  icon: 'delete',
                                  tooltip: 'Delete Priority Configuration',
                                  iconProps: {
                                    className: 'action-buttons delete-button',
                                  },
                                  onClick: (event, rowData) =>
                                    deleteChecklistHandler(rowData),
                                  hidden: !permissions[
                                    PermissionProperties
                                      .deletePriorityConfiguration
                                  ],
                                },
                                {
                                  icon: () => (
                                    <Link
                                      to={
                                        props.match.url +
                                        '/' +
                                        urlProperties.priorityConfiguration
                                          .priorityConfiguration
                                      }
                                    >
                                      <div
                                        className={
                                          'generic-button-class div-button-wrapper'
                                        }
                                        variant="contained"
                                        color="primary"
                                      >
                                        {
                                          priorityConfigurationProperties.addPriorityConfiguration
                                        }
                                      </div>
                                    </Link>
                                  ),
                                  tooltip: 'Add Priority Configuration',
                                  isFreeAction: true,
                                  hidden: !permissions[
                                    PermissionProperties
                                      .addPriorityConfiguration
                                  ],
                                },
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ServiceRequestsSettings;
