
import Axios from "axios";
import urlProperties from 'Util/Property/UrlProperties';
import axios from '../../axios/axios-default';



export async function getActiveCategoryList(){
    try{

        return axios.get("getProductCategoriesByStatus/true")

    }catch (error ) {

        console.log("Error Calling Product Service");

    }

}
export async function getProductHistory(id){

    return  axios.get(urlProperties.product.getProductHistory+"/"+id);
}