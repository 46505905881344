export const responseProperties = {
  response: 'Response',
  viewMessage: 'View Message',
  response: 'response',
  viewRequest: 'View Request',
  messages: {
    error: {
      // loadData: "Could not load  message please try again",
      // sent: "Could not sent message. please try again",
      // delete: "Could not delete message please try again",
      // loadAll: "Could not load messages please try again",
      // loadEmails: "Coud not load user emails",
      // loadUsers : "Could not load Users",
    },
    success: {
      // sent: "Message sent successfully",
      // delete: "Message deleted successfully"
    },
    normal: {
      // deleteConfirm: "Do you want to delete this Message",
    },
  },
};

const datePickerFormat = 'MM/DD/yyyy';

const response = [
  { value: 'APPROVE', displayValue: 'Approve' },
  { value: 'REJECT', displayValue: 'Reject' },
];

export const getResponseForm = () => {
  return {
    response: {
      elType: 'select',
      label: 'Response',
      inputProps: {
        options: response,
        value: 'APPROVE',
        initvalue: 'APPROVE',
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 12,
      isAsyncDropDown: true,
    },
    message: {
      elType: 'textarea',
      label: '',
      inputProps: {
        type: 'text',
        placeholder: 'Remark',
        value: '',
        initvalue: '',
        rowsMin: 7,
        rowsMax: 20,
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          padding: '10px',
        },
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        error: false,
      },
      size: 12,
      isHide: false,
    },
  };
};
