import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios';


export async function updateCustomerFeedBack(method, url, data) {
    return await axios({
        method: method,
        url: urlProperties.baseUrl+url,
        data: data,
    });
    // axios.get("getProductBrandsByStatus?categoryId=" + catId + "&active=true");
}


export async function getFeedback(token) {

    return axios.get(urlProperties.baseUrl+urlProperties.feedback.getFeedback + "/" + token)

}
