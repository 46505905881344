/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import styles from './Leave.module.css';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import PersonIcon from '@material-ui/icons/Person';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CategoryIcon from '@material-ui/icons/Category';
import MaterialTable, { MTableToolbar } from 'material-table';
import urlProperties from '../../Util/Property/UrlProperties';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import { buildFilterObject } from '../../Util/Util';
import { responseProperties } from 'Util/Property/ResponseProperties';
import ListAltOutlinedIcon from '@material-ui/icons/Timer';
import { Typography } from '@material-ui/core';
import Spinner from 'components/Spinner/Spinner';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  sendLeaveResponse,
  getLeaves,
} from 'services/LeaveService/LeaveService';
import Response from './Response';
import LoadingOverlay from 'react-loading-overlay';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));
const Leave = (props) => {
  const [userId, setUserId] = useState(0);
  const [permissions, setPermissions] = useState({});
  const [pendingTableData, setPendingTableData] = useState([]);
  const [approvedTableData, setApprovedTableData] = useState([]);
  const [rejectedTableData, setRejectedTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDialogContent, setIsLoadingDialogContent] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = React.useState({});
  const [selectedIdList, setSelectedIdList] = React.useState([]);
  const classes = useStyles();

  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setResponse({});
    setOpen(false);
  };
  const handleToSave = async () => {
    setIsLoadingDialogContent(true);
    let selectedIdListClone = JSON.parse(JSON.stringify(selectedIdList));
    const message = response.message.inputProps.value;
    const status = response.response.inputProps.value;

    selectedIdListClone.map((leave) => {
      leave.leave_status = status === 'APPROVE' ? 'APPROVED' : 'REJECTED';
      leave.remarks = message !== '' ? message : null;
      delete leave.tableData;
    });
    await sendLeaveResponse(selectedIdListClone);
    setIsLoadingDialogContent(false);
    setOpen(false);

    await getLeavesForTables();
  };

  let trainingTab = 'trainingTab';
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(trainingTab, newValue);
  };

  const tableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addTraining,
      PermissionProperties.viewTraining,
      PermissionProperties.editTraining,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(trainingTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  }, []);

  const getLeavesForTables = async () => {
    setIsLoading(true);

    try {
      const result = await getLeaves();
      const tableData = result.data;
      setPendingTableData(tableData.applied);
      if (tableData.approved.length > 0) {
        setApprovedTableData(tableData.approved);
      }
      if (tableData.rejected.length > 0) {
        setRejectedTableData(tableData.rejected);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLeavesForTables();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {/* {
                        permissions[PermissionProperties.addUser] ?
                            <div className={styles.AddUserDiv}>
                                <Link to={props.match.url + "/user"}>
                                    <Button className={styles.AddUserButton} variant="contained" color="primary">{userProperties.addNewUser}</Button>
                                </Link>
                            </div> : ""
                    } */}
            <CardBody>
              <AppBar
                position="static"
                color="inherit"
                className={styles.appBarStyle}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  classes={{
                    indicator: classes.indicator,
                  }}
                  className={classes.tabsWrapper}
                >
                  <Tab label="Pending Approval" {...a11yProps(0)} />

                  <Tab label="Approved" {...a11yProps(1)} />
                  <Tab label="Rejected" {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <FullWidthTabPanel value={value} index={0} dir={theme.direction}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    isLoading={isLoading}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    localization={{
                      toolbar: {
                        nRowsSelected: '',
                      },
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Leave Management
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Emp No', field: 'user_id' },
                      { title: 'Requested By', field: 'created_by' },
                      {
                        title: 'Leave Type',
                        field: 'leave_type',
                        render: (row) => (
                          <div>
                            {row.leave_type.split('_')[0] +
                              ' ' +
                              row.leave_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Leave Duration',
                        field: 'leave_duration_type',
                        render: (row) => (
                          <div>
                            {row.leave_duration_type.split('_')[0] +
                              ' ' +
                              row.leave_duration_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Start Date',
                        field: 'start_date',
                        render: (row) => (
                          <div>
                            {row.start_date[0] +
                              '/' +
                              row.start_date[1] +
                              '/' +
                              row.start_date[2]}
                          </div>
                        ),
                      },
                      {
                        title: 'End Date',
                        field: 'end_date',
                        render: (row) => (
                          <div>
                            {row.end_date[0] +
                              '/' +
                              row.end_date[1] +
                              '/' +
                              row.end_date[2]}
                          </div>
                        ),
                      },
                      { title: 'Number of Days', field: 'number_of_days' },
                    ]}
                    data={pendingTableData}
                    actions={[
                      {
                        // hidden: !this.state.permissions[
                        //   PermissionProperties.addClaims
                        // ],
                        tooltip: 'Send leave response',
                        icon: () => (
                          <div stlye={{}}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleClickToOpen}
                            >
                              Response
                            </Button>
                            <Dialog
                              maxWidth="sm"
                              fullWidth={true}
                              open={open}
                              onClose={handleToClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <LoadingOverlay
                                active={isLoadingDialogContent}
                                spinner={<Spinner />}
                                text="Loading ..."
                              >
                                <DialogContent>
                                  <Response setResponse={setResponse} />
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleToClose}
                                    color="primary"
                                    autoFocus
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    onClick={handleToSave}
                                    color="primary"
                                    autoFocus
                                  >
                                    Save
                                  </Button>
                                </DialogActions>
                              </LoadingOverlay>
                            </Dialog>
                          </div>
                        ),
                      },
                    ]}
                    onSelectionChange={(rows) => {
                      setSelectedIdList(rows);
                    }}
                    options={{
                      selection: true,
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        backgroundColor: '#e6e4df',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                  />
                </div>
              </FullWidthTabPanel>
              <FullWidthTabPanel value={value} index={1} dir={theme.direction}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    isLoading={isLoading}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Approved Leave Details
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Emp No', field: 'user_id' },
                      { title: 'Requested By', field: 'created_by' },
                      {
                        title: 'Leave Type',
                        field: 'leave_type',
                        render: (row) => (
                          <div>
                            {row.leave_type.split('_')[0] +
                              ' ' +
                              row.leave_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Leave Duration',
                        field: 'leave_duration_type',
                        render: (row) => (
                          <div>
                            {row.leave_duration_type.split('_')[0] +
                              ' ' +
                              row.leave_duration_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Start Date',
                        field: 'start_date',
                        render: (row) => (
                          <div>
                            {row.start_date[0] +
                              '/' +
                              row.start_date[1] +
                              '/' +
                              row.start_date[2]}
                          </div>
                        ),
                      },
                      {
                        title: 'End Date',
                        field: 'end_date',
                        render: (row) => (
                          <div>
                            {row.end_date[0] +
                              '/' +
                              row.end_date[1] +
                              '/' +
                              row.end_date[2]}
                          </div>
                        ),
                      },
                      { title: 'No of Days', field: 'number_of_days' },
                      { title: 'Responsed By', field: 'updated_by' },
                      { title: 'Remark', field: 'remarks' },
                    ]}
                    data={approvedTableData}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: false,
                      search: false,
                      filtering: true,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                        backgroundColor: '#e6e4df',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                  />
                </div>
              </FullWidthTabPanel>
              <FullWidthTabPanel value={value} index={2} dir={theme.direction}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    isLoading={isLoading}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Rejected Leave Details
                        </Typography>
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Emp No', field: 'user_id' },
                      { title: 'Requested By', field: 'created_by' },
                      {
                        title: 'Leave Type',
                        field: 'leave_type',
                        render: (row) => (
                          <div>
                            {row.leave_type.split('_')[0] +
                              ' ' +
                              row.leave_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Leave Duration',
                        field: 'leave_duration_type',
                        render: (row) => (
                          <div>
                            {row.leave_duration_type.split('_')[0] +
                              ' ' +
                              row.leave_duration_type.split('_')[1]}
                          </div>
                        ),
                      },
                      {
                        title: 'Start Date',
                        field: 'start_date',
                        render: (row) => (
                          <div>
                            {row.start_date[0] +
                              '/' +
                              row.start_date[1] +
                              '/' +
                              row.start_date[2]}
                          </div>
                        ),
                      },
                      {
                        title: 'End Date',
                        field: 'end_date',
                        render: (row) => (
                          <div>
                            {row.end_date[0] +
                              '/' +
                              row.end_date[1] +
                              '/' +
                              row.end_date[2]}
                          </div>
                        ),
                      },
                      { title: 'No of Days', field: 'number_of_days' },
                      { title: 'Responsed By', field: 'updated_by' },
                      { title: 'Remark', field: 'remarks' },
                    ]}
                    data={rejectedTableData}
                    options={{
                      actionsColumnIndex: -1,
                      // filtering: true,
                      sorting: false,
                      search: false,
                      filtering: true,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                        backgroundColor: '#e6e4df',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                  />
                </div>
              </FullWidthTabPanel>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Leave;
