import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from '../../Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { customerProperties } from '../../Util/Property/CustomerPorerties';
import generalProperties from '../../Util/Property/GeneralProperties';
import axios from '../../axios/axios-default';
import { notyDefault, notyTypes } from '../../components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { PermContactCalendarRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { buildFilterObject } from '../../Util/Util';
import UploadFiles from 'components/FileUpload/UploadFiles';
import Spinner from 'components/Spinner/Spinner';
import styles from './Customers.module.css';

class Customers extends Component {
  state = {
    isDeleteDialogOpen: false,
    deleteId: 0,
    permissions: {},
    chkUpload: true,
    fileName: 'UPLOAD EXCEL FILE',
    chkCallapi: false,
    openFileUpload: false,
    openSRBulkUpload: false,
    selectedRows: {},
    tableData: { page: 1, pageSize: 5 },
  };

  selectedFiles = undefined;
  DISTRIBUTOR_TYPE_USER = "DISTRIBUTOR"

  fileUploadClose = () => {
    this.setState({
      openFileUpload: false,
    });
  };

  SRBulkUploadCloase = () => {
    this.setState({
      openSRBulkUpload: false,
    });
  };

  onCustomerEditHandler = (id, isEdit) => {
    this.props.history.push({
      pathname: this.props.match.url + '/customerDetails',
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  addCustomersToState = (customers) => {
    this.props.getCustomersHandler(customers);
    return customers;
  };

  deleteCustomerDialogCloseHandler = () => {
    this.setState({
      isDeleteDialogOpen: false,
    });
  };

  deleteCustomerDialogOpenHandler(id) {
    this.setState({
      isDeleteDialogOpen: true,
    });
  }

  deleteConfirmHandler = () => {
    axios
      .delete('/deleteCustomer/' + this.state.deleteId)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: customerProperties.messages.success.deleteCustomer,
          });
          this.tableRef.current.onQueryChange();
        } else {
          // could not delete succesfully
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.deleteCustomer,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: customerProperties.messages.error.deleteCustomer,
        });
      });

    this.deleteCustomerDialogCloseHandler();
  };

  deleteCustomerHandler = (id) => {
    this.setState({
      deleteId: id,
    });
    this.deleteCustomerDialogOpenHandler();
  };

  componentDidMount() {
    let permissionArray = [
      PermissionProperties.addCustomer,
      PermissionProperties.viewCustomer,
      PermissionProperties.deleteCustomer,
      PermissionProperties.editCustomer,
      PermissionProperties.addServiceRequest,
      PermissionProperties.editServiceRequest,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }

  onPaginationProperyChange = (val, key) => {
    let tData = { ...this.state.tableData };
    tData[key] = val;
    this.setState({
      tableData: tData,
      selectedRows: {},
    });
  };

  selectFile = (event) => {
    this.selectedFiles = event.target.files;
    this.fileName = this.selectedFiles[0].name;
    console.log(this.selectedFiles[0].name);
    this.state.chkUpload = false;
    this.setState({
      chkUpload: false,
      fileName: this.selectedFiles[0].name,
    });
  };

  upload = () => {
    this.setState({
      chkCallapi: true,
    });
    let currentFile = this.selectedFiles[0];
    let formData = new FormData();

    formData.append('file', currentFile);
    formData.append('fileType', 'excel');

    const url = urlProperties.customerUpload;
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((results) => {
        this.chkCallapi = false;
        if (results.status == 200) {
          notyDefault({ type: notyTypes.SUCCESS, text: results.data.message });
        } else {
          notyDefault({ type: notyTypes.ERROR, text: results.data.message });
        }
        this.setState({
          chkUpload: true,
          fileName: 'Browse Excel File',
          chkCallapi: false,
        });
      });
  };

  rowSelectionChangeHandler = (rows) => {
    let selectedRowData = { ...this.state.selectedRows };
    console.log('SELECTEDDDDD', selectedRowData);
    // let totInMin = 0;
    this.setState({
      selectedRows: rows,
    });
  };

  render() {
    return (
      <div>
        <UploadFiles
          url={urlProperties.customerUpload}
          open={this.state.openFileUpload}
          fileUploadClose={this.fileUploadClose}
          uploadLoacation={'customer'}
          acceptFileType={'.xlsx'}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Dialog
                  open={this.state.isDeleteDialogOpen}
                  onClose={this.deleteCustomerDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {customerProperties.messages.normal.deleteCustomer}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={this.deleteCustomerDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={this.deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* customer table content */}
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <PermContactCalendarRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Registered Customers
                        </Typography>
                      </div>
                    }
                    tableRef={this.tableRef}
                    columns={[
                      { title: 'Outlet Id', field: 'tid' , emptyValue: () => <div>-</div>, },
                      { title: 'Outlet/Distributor Name', field: 'name' ,emptyValue: () => <div>-</div>, },
                      { title: 'Distributor', field: 'parent_customer_name' },
                      { title: 'Province', field: 'province' },
                      { title: 'District', field: 'district' },
                      {
                        title: 'Address',
                        field: 'address',
                        cellStyle: {
                          width: 200,
                          minWidth: 200,
                        },
                      },
                      {
                        title: customerProperties.category1,
                        field: 'category1',
                      },
                      {
                        title: customerProperties.category2,
                        field: 'category2',
                      },
                      {
                        title: customerProperties.assignee,
                        field: 'vehicle_user_name',
                      },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        const filterDto = buildFilterObject(query);
                        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
                        if (userDetails.type === this.DISTRIBUTOR_TYPE_USER) {
                          filterDto['parent_customer_id'] = userDetails.customer_distributor_id ? userDetails.customer_distributor_id : 0;
                        }
                        
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.filterAllCustomers +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'id'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(filterDto),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    actions={[
                      {
                        icon: 'visibility',
                        tooltip: 'View More Details',
                        iconProps: { className: 'action-buttons view-button' },
                        onClick: (event, rowData) =>
                          this.onCustomerEditHandler(rowData.id, false),
                        position: 'row',
                        hidden: !this.state.permissions[
                          PermissionProperties.viewCustomer
                        ],
                      },
                      {
                        icon: 'edit',
                        tooltip: 'Edit Customer',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          this.onCustomerEditHandler(rowData.id, true),
                        position: 'row',
                        hidden: !this.state.permissions[
                          PermissionProperties.editCustomer
                        ],
                      },
                      {
                        icon: () => (
                          <Link to={this.props.match.url + '/addCustomer'}>
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              variant="contained"
                              color="primary"
                            >
                              Add New Customer
                            </div>
                          </Link>
                        ),
                        tooltip: 'Add New Customer',
                        isFreeAction: true,
                        hidden: !this.state.permissions[
                          PermissionProperties.addCustomer
                        ],
                      },
                    ]}
                    options={{
                      // actionsColumnIndex: -1,
                      // filtering: true,
                      // sorting: false,
                      // search: false
                      columnsButton: true,
                      actionsColumnIndex: -1,
                      selection: false,
                      pageSize: this.state.tableData.pageSize,
                      filtering: true,
                      sorting: false,
                      search: false,
                      pageSizeOptions: [5, 10, 20, 50],
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        backgroundColor: '#e6e4df',
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    onSelectionChange={(rows) =>
                      this.rowSelectionChangeHandler(rows)
                    }
                    onChangeRowsPerPage={(pageSize) =>
                      this.onPaginationProperyChange(pageSize, 'pageSize')
                    }
                    onChangePage={(page, pageSize) =>
                      this.onPaginationProperyChange(page, 'page')
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    custsomerArray: state.customer.customers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomersHandler: (customers) =>
      dispatch(actions.getCustomers(customers)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers);
