const statusArray = [
  { value: 'ACTIVE', displayValue: 'Active' },
  { value: 'INACTIVE', displayValue: 'Inactive' },
];

const provinceArray = [
  { value: 'CENTRAL', displayValue: 'Central' },
  { value: 'EASTERN', displayValue: 'Eastern' },
  { value: 'NORTHERN', displayValue: 'Northern' },
  { value: 'NORTH_CENTRAL', displayValue: 'North Central' },
  { value: 'NORTH_WESTERN', displayValue: 'North Western' },
  { value: 'SABARAGAMUWA', displayValue: 'Sabaragamuwa' },
  { value: 'SOUTHERN', displayValue: 'Southern' },
  { value: 'UVA', displayValue: 'Uva' },
  { value: 'WESTERN', displayValue: 'Western' },
];

const BusinessType = [
  { value: 'SOLE PROPRIETOR', displayValue: 'SOLE PROPRIETOR' },
  { value: 'PRIVATE LIMITED', displayValue: 'PRIVATE LIMITED' },
  { value: 'PARTNERSHIP', displayValue: 'PARTNERSHIP' },
  { value: 'INDIVIDUAL', displayValue: 'INDIVIDUAL' },
];

const districts = {
  WESTERN: [
    { value: 'COLOMBO', displayValue: 'Colombo' },
    { value: 'GAMPAHA', displayValue: 'Gampaha' },
    { value: 'KALUTARA', displayValue: 'Kalutara' },
  ],
  NORTHERN: [
    { value: 'JAFFNA', displayValue: 'Jaffna' },
    { value: 'KILINOCHCHI', displayValue: 'Kilinochchi' },
    { value: 'MANNAR', displayValue: 'Mannar' },
    { value: 'MULATIVU', displayValue: 'Mullaitivu' },
    { value: 'VAVUNIYA', displayValue: 'Vavuniya' },
  ],
  CENTRAL: [
    { value: 'MATALE', displayValue: 'Matale' },
    { value: 'KANDY', displayValue: 'Kandy' },
    { value: 'NUWARA_ELIYA', displayValue: 'Nuwara Eliya' },
  ],
  EASTERN: [
    { value: 'TRINCOMALEE', displayValue: 'Trincomalee' },
    { value: 'BATTICALOA', displayValue: 'Batticaloa' },
    { value: 'AMPARA', displayValue: 'Ampara' },
  ],
  NORTH_CENTRAL: [
    { value: 'ANURADHAPURA', displayValue: 'Anuradhapura' },
    { value: 'POLONNARUWA', displayValue: 'Polonnaruwa' },
  ],
  NORTH_WESTERN: [
    { value: 'KURUNEGALA', displayValue: 'Kurunegala' },
    { value: 'PUTTALAM', displayValue: 'Puttalam' },
  ],
  SABARAGAMUWA: [
    { value: 'KEGALLE', displayValue: 'Kegalle' },
    { value: 'RATNAPURA', displayValue: 'Ratnapura' },
  ],
  SOUTHERN: [
    { value: 'GALLE', displayValue: 'Galle' },
    { value: 'MATARA', displayValue: 'Matara' },
    { value: 'HAMBANTOTA', displayValue: 'Hambantota' },
  ],
  UVA: [
    { value: 'BADULLA', displayValue: 'Badulla' },
    { value: 'MONARAGALA', displayValue: 'Monaragala' },
  ],
};

const customerTypeArray = [
  { value: 'PARENT', displayValue: 'Parent' },
  // { value: 'CHILD', displayValue: 'Child Profile (Outlet)' },
];

const datePickerFormat = 'DD/MM/yyyy';

const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const customerProperties = {
  category1: 'Category1',
  category2: 'Category2',
  category3: 'Business Type',
  assignee: 'Assigned to',
  districts: districts,
  messages: {
    error: {
      loadData: 'Could not load customer data. Please try again',
      saveData: 'Could not save customer data. Please try again',
      deleteCustomer: 'Could not delete customer. Please try again',
      loadCustomers: 'Could not load Customers. Please try again',
    },
    success: {
      saveData: 'Customer details saved successfully',
      deleteCustomer: 'Customer data deleted successfully',
    },
    normal: {
      deleteCustomer: 'Do you want to delete this customer?',
    },
  },
};

export const getCustomerForm = () => {
  return {
    name: {
      elType: 'input',
      label: 'Customer Name',
      inputProps: {
        type: 'text',
        placeholder: 'Customer Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    address: {
      elType: 'input',
      label: 'Address',
      inputProps: {
        type: 'text',
        placeholder: 'Address',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    status: {
      elType: 'select',
      label: 'Status',
      inputProps: {
        options: statusArray,
        value: 'ACTIVE',
        initvalue: 'ACTIVE',
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: true,
      valid: true,
    },
    province: {
      elType: 'select',
      label: 'Province',
      inputProps: {
        options: provinceArray,
        value: '',
        initvalue: '',
        enableclearable: true,
        required: true, 
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid:true,
    },
    district: {
      elType: 'select',
      label: 'District',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        enableclearable: true,
        required: true, 
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid:true,
    },
    phone: {
      elType: 'input',
      label: 'Phone No',
      inputProps: {
        type: 'text',
        placeholder: 'Phone No',
        value: '',
        required: false,
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    fax: {
      elType: 'input',
      label: 'Fax',
      inputProps: {
        type: 'text',
        placeholder: 'Fax',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
    },
    email: {
      elType: 'input',
      label: 'Email',
      inputProps: {
        type: 'text',
        placeholder: 'Email',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        pattern: emailPattern,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        error: false,
        errormessage: '',
      },
    },
    join_date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: datePickerFormat,
        value: new Date(),
        label: 'Registered Date',
        initvalue: new Date(),
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid:true,
    },
    person: {
      elType: 'input',
      label: 'Contact Person',
      inputProps: {
        type: 'text',
        placeholder: 'Contact Person',
        value: '',
        required: false,
        initvalue: '',
      },
      validations: {
        required: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    person_designation: {
      elType: 'input',
      label: 'Designation of Contact Person',
      inputProps: {
        type: 'text',
        placeholder: 'Designation of Contact Person',
        value: '',
        required: false,
        initvalue: '',
      },
      validations: {
        required: false,
      },
      value: '',
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    customer_type: {
      elType: 'select',
      label: 'Customer Type',
      inputProps: {
        options: customerTypeArray,
        value: 'PARENT',
        initvalue: 'PARENT',
        required: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
      ishide: true,
    },
    parent: {
      elType: 'select',
      label: 'Parent',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        required: true,
        customSearchable: true,
        enableclearable: true,
      },
      validations: {
        required: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
      isFullWidth: false,
      valid:true,
      isHide: true,
      size: 6,
    },
    vehicle_user_id: {
      elType: 'select',
      label: 'Vehicle',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        tempValue: '',
        customSearchable: true,
        enableclearable: true,
      },
      validations: {
        required: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isHide: false,
      size: 6,
    },
    tid: {
      elType: 'input',
      label: 'Distributor Id',
      inputProps: {
        type: 'text',
        placeholder: 'Distributor Id',
        value: '',
        required: true,
        initvalue: '',
      },
      validations: {
        required: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid:true,
      touched: false,
      isHide: true,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    metadata: {
      elType: 'input',
      label: 'Beat No',
      inputProps: {
        type: 'text',
        placeholder: 'Beat No',
        value: '',
        required: false,
        initvalue: '',
      },
      validations: {
        required: false,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid:true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
      isHide:false
    },
    category2: {
        elType: 'input',
        label: "Territory",
        inputProps: {
            type: 'text',
            placeholder: "Territory",
            value: '',
            required:false,
            initvalue : ''
        },
        validations: {
          required: false,
        },
        formControlProps: {
            fullWidth: true
        },
        isFullWidth :false,
        valid:true,
        isHide:false,
        touched:false,
        labelProps:{
            // required : true,
            error:false,
            errormessage: ''
        }
    },
    // category3: {
    //   elType: 'select',
    //   label: customerProperties.category3,
    //   inputProps: {
    //     type: 'text',
    //     placeholder: customerProperties.category3,
    //     value: '',
    //     options: BusinessType,
    //     // required:true,
    //     initvalue: '',
    //   },

    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   touched: false,
    //   labelProps: {
    //     // required : true,
    //     error: false,
    //     errormessage: '',
    //   },
    // },
  };
};
