import React, { useEffect, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import UrlProperties from 'Util/Property/UrlProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { userProperties, getUserForm } from 'Util/Property/UserProperties';
import LoadingOverlay from 'react-loading-overlay';
import Card from 'components/Card/Card';
import { Typography } from '@material-ui/core';
import HighlightsChartDetails from './HighlightsChartDetails';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

export default function Viewmore1(props) {
  const [images, setImages] = React.useState([]);
  const [imageApi, setImageApi] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    open,
    viewMoreClose,
    tileBody,
    isDragReject,
    title,
    SubTitle,
    categoryType,
    action,
    tableData,
  } = props;

  const handleClose = () => {
    viewMoreClose();
    setImageApi(false);
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#B22900',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  useEffect(() => {
    // userData(srID);
    tableData.forEach((obj) => {
      Object.entries(obj).forEach(([key, val]) => {
        if (key !== 'second_table') {
          obj[key] = val == null ? '-' : obj[key];
        }
      });
    });
  }, [open]);

  useEffect(() => {}, []);

  return (
    <div>
      <Dialog
        //  fullScreen

        maxWidth="sm"
        //width={900}
        //maxHeight="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        open={open}
      >
        <DialogContent>
          <center>
            <b>{title}</b>
          </center>
          {/* <Typography variant="body1">{SubTitle}</Typography> */}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <LoadingOverlay active={isLoading} spinner text="Loading ...">
                <HighlightsChartDetails
                  chartTitle={SubTitle}
                  tileStage={'1'}
                  chartType={tileBody}
                  categoryType={categoryType}
                  action={action}
                  tableData={tableData}
                />
              </LoadingOverlay>
            </GridItem>
          </GridContainer>
        </DialogContent>

        <DialogActions>
          <Button
            className={'generic-button-class div-button-wrapper'}
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
