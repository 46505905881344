import React, {Component} from "react";
import urlProperties from "../../Util/Property/UrlProperties";
import axios from "../../axios/axios-default";
import LoadingOverlay from "react-loading-overlay";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import CardFooter from "../../components/Card/CardFooter";
import reportsProperties, {reportDisabledFields, reportTypes, saCategories} from "../../Util/Property/ReportProperty";
import Button from "../../components/CustomButtons/Button";
import generalProperties from "../../Util/Property/GeneralProperties";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {CircularProgress, Typography} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import {notyDefault, notyTypes} from "../../components/Noty/NotyCustom";
import ReportView1 from "components/Reports/ReportView1";

class StandardReports extends Component {
  constructor() {
    super();
  }
  state = {
    openFileUpload: false,
    formElementArray: reportsProperties.reportForm,
    initialDataLoading: true,
    changeField: "",
    tableData: [],
  };
  fileUploadClose = () => {
    this.setState({
      openFileUpload: false
    });
  }
  componentDidMount() {
    this.props.getDataForReports();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      reportFilterData,
      reportFilterDataIsLoading,
      reportFilterDataError
    } = this.props;
    const { initialDataLoading, changeField } = this.state;
    if (
      prevProps.reportFilterData !== reportFilterData &&
      !reportFilterDataIsLoading &&
      !reportFilterDataError
    ) {
      const { formElementArray } = this.state;
      let formData = { ...formElementArray };
      if (initialDataLoading) {
        for (let key in reportsProperties.reportDataMap) {
          const dataList =
            reportFilterData[reportsProperties.reportDataMap[key]];
          if (dataList) {
            formData[key].inputProps.options = this.buildOptions(dataList);
            formData[key].inputProps.value = null;
          }
        }
        if (reportFilterData.allUsers && reportFilterData.allUsers.length > 0) {
          formData.technicianId.inputProps.options = this.buildUserOptions(
            reportFilterData.allUsers
          );
        }
      } else {
        if (changeField === "productCategoryId") {
          if (reportFilterData.allProdBrands) {
            formData.productBrandId.inputProps.options = this.buildOptions(
              reportFilterData.allProdBrands
            );
            formData.productBrandId.inputProps.value = null;
          }
          if (reportFilterData.allProdModels) {
            formData.productModelId.inputProps.options = this.buildOptions(
              reportFilterData.allProdModels
            );
            formData.productModelId.inputProps.value = null;
          }
        }
        if (changeField === "productBrandId") {
          if (reportFilterData.allProdModels) {
            formData.productModelId.inputProps.options = this.buildOptions(
              reportFilterData.allProdModels
            );
            formData.productModelId.inputProps.value = null;
          }
        }
        if (changeField === "customerId") {
          if (reportFilterData.allSubCustomers) {
            formData.subCustomerId.inputProps.options = this.buildOptions(
              reportFilterData.allSubCustomers
            );
            formData.subCustomerId.inputProps.value = null;
          }
        }
        if (
          changeField === "productCategoryId" ||
          changeField === "productBrandId" ||
          changeField === "productModelId" ||
          changeField === "srCategoryId" ||
          changeField === "customerId"
        ) {
          if (reportFilterData.allSRPriorities) {
            formData.srPriorityId.inputProps.options = this.buildOptions(
              reportFilterData.allSRPriorities
            );
            formData.srPriorityId.inputProps.value = null;
          }
        }
      }
//Set ALL
      //console.log("form datra ",formData)
      let currentSrList = formData.srCategoryId.inputProps.options;
      let arr = [];
      let newArr = [];
      const index = currentSrList.findIndex(sr => sr.value == -1);
      if (index == -1) {
        arr.push({ value: "-1", displayValue: 'All' });
      }
      newArr = arr.concat(currentSrList);
      formData.srCategoryId.inputProps.options = newArr;

      this.setState({
        formElementArray: formData,
        initialDataLoading: false,
        formIsValid: this.isValidForm
      });
    }
    if (
      prevProps.reportFilterData !== reportFilterData &&
      !reportFilterDataIsLoading &&
      reportFilterDataError
    ) {
      notyDefault({
        type: notyTypes.ERROR,
        text: "Error fetching report filter data"
      });
      this.setState({
        initialDataLoading: false
      });
    }
  }
  buildOptions = data => {
    return data.map(({ name, id }) => ({ value: id, displayValue: name }));
  };
  buildSAOptions = data => {
    return data.map(({ serviceAgreementName, id }) => ({
      value: id,
      displayValue: serviceAgreementName
    }));
  };
  buildUserOptions = data => {
    return data.map(({ first_name, last_name, id }) => ({
      value: id,
      displayValue: `${first_name} ${last_name}`
    }));
  };
  resetValues = elId => {
    if (elId === "reportName") {
      const { formElementArray = {} } = this.state;
      const formData = { ...formElementArray };
      for (let key in formElementArray) {
        if (this.isDisabledField(key)) {
          formData[key].inputProps.value = null;
        }
      }
    }
  };
  resetSubFieldValues = elId => {
    const { formElementArray = {} } = this.state;
    const formData = { ...formElementArray };
    const subFields = reportsProperties.reportSubFieldMap[elId];
    formData[elId].inputProps.value = null;
    if (subFields) {
      subFields.forEach(key => {
        formData[key].inputProps.value = null;
        if (formData[key].elType === 'select') {
          formData[key].inputProps.options = [];
        }
      });
    }
    this.setState({
      formElementArray: formData
    })
  };

  isValidForm = () => {
    const { formElementArray } = this.state;
    const mainCustomerId = formElementArray.customerId.inputProps.value;
    const subCustomerId = formElementArray.subCustomerId.inputProps.value;
    const customerId = subCustomerId || mainCustomerId;
    let isValidForm = true;
    for (let key in formElementArray) {
      if (!this.isDisabledField(key)) {
        if (key === "customerId" || key === "subCustomerId") {
          isValidForm = Boolean(customerId);
        } else {
          isValidForm = Boolean(formElementArray[key].inputProps.value);
        }
        if (!isValidForm) {
          break;
        }
      }
    }
    const startDate = formElementArray.dataRangeFrom.inputProps.value;
    const endDate = formElementArray.dataRangeTo.inputProps.value;
    if (startDate && endDate && startDate.isAfter(endDate)) {
      isValidForm = false;
    }
    return isValidForm;
  };

  findById = (elemId, array = []) => {
    const item = array.find(({ id }) => elemId === id);
    if (item && item.name) {
      return item.name;
    }
    return undefined;
  };
  
  generateReport = () => {
    console.log(this.state.formElementArray);
  };
  
  fileUpload = () => {
    this.setState({
      openFileUpload: true
    });
  };
  downloadReport = () => {
    const { formElementArray } = this.state;
    const { reportFilterData } = this.props;
    if (
      formElementArray.reportName.inputProps.value ===
        "TECHNICIAN_ANALYSIS_REPORT" &&
      (!Boolean(formElementArray.dataRangeFrom.inputProps.value) ||
      !Boolean(formElementArray.dataRangeTo.inputProps.value))
    ) {
      notyDefault({
        type: notyTypes.ERROR,
        text: "Please Select valid Date Range"
      });
      return false;
    }


    this.setState({
      reportDownloading: true
    });
    const {
      allCustomers,
      allSubCustomers,
      allProdBrands,
      allProdCategories,
      allProdModels,
      allSRCategories,
      allSRPriorities,
      allUsers
    } = reportFilterData;
    const mainCustomerId = formElementArray.customerId.inputProps.value;
    const subCustomerId = formElementArray.subCustomerId.inputProps.value;
    const customerId = subCustomerId || mainCustomerId;
    const reportName = formElementArray.reportName.inputProps.value;
    const reportType = formElementArray.reportType.inputProps.value;
    const technicianId= formElementArray.technicianId.inputProps.value;
    const date = formElementArray.date.inputProps.value &&
                    formElementArray.date.inputProps.value.format("yyyy-MM-DD");
    
    
    if (reportName === "TECHNICIAN_ROUTE_REPORT") {
      //
      if (date === null || technicianId === null) {
        notyDefault({
          type: notyTypes.ERROR,
          text: "Please select a date and a technician"
        });
        this.setState({
          reportDownloading: false
        });
        return false;
      }
      this.setState({
        reportDownloading: false
      });
      window.open(this.props.match.url+"/technicianroute/"+technicianId+"/" + date);

      return false;
    }

    const reqBody = {
      reportName: reportName,
      reportType: reportType,
      customerId: customerId,
      customerLocation: formElementArray.customerLocation.inputProps.value,
      customerName: this.findById(customerId, allCustomers),
      dataRangeFrom:
        formElementArray.dataRangeFrom.inputProps.value &&
        formElementArray.dataRangeFrom.inputProps.value.format("yyyy-MM-DD"),
      dataRangeTo:
        formElementArray.dataRangeTo.inputProps.value &&
        formElementArray.dataRangeTo.inputProps.value.format("yyyy-MM-DD"),
      productBrandId: formElementArray.productBrandId.inputProps.value,
      productBrandName: this.findById(
        formElementArray.productBrandId.inputProps.value,
        allProdBrands
      ),
      productCategoryId: formElementArray.productCategoryId.inputProps.value,
      productCategoryName: this.findById(
        formElementArray.productCategoryId.inputProps.value,
        allProdCategories
      ),
      productModelId: formElementArray.productModelId.inputProps.value,
      productModelName: this.findById(
        formElementArray.productModelId.inputProps.value,
        allProdModels
      ),
      productStatus: formElementArray.productStatus.inputProps.value,
      saCategoeryId: formElementArray.saCategoeryId.inputProps.value,
      saCategoryName: this.findById(
        formElementArray.saCategoeryId.inputProps.value,
        saCategories
      ),
      serial: formElementArray.serial.inputProps.value,
      srCategoryId: formElementArray.srCategoryId.inputProps.value,
      srCategoryName: this.findById(
        formElementArray.srCategoryId.inputProps.value,
        allSRCategories
      ),
      srPriorityId: formElementArray.srPriorityId.inputProps.value,
      srPriorityName: this.findById(
        formElementArray.srPriorityId.inputProps.value,
        allSRPriorities
      ),
      subCustomerId: subCustomerId,
      subCustomerName: this.findById(subCustomerId, allSubCustomers),
      technicianId: formElementArray.technicianId.inputProps.value,
      technicianName: this.findById(
        formElementArray.technicianId.inputProps.value,
        allUsers
      )
    };
    let config = {
      responseType: "blob"
    };
    axios
      .post(urlProperties.reports.generateReport, reqBody, config)
      .then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportName}.pdf`);
        if (reportType === "EXCEL") {
          link.setAttribute("download", `${reportName}.xlsx`);
        }
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({
          reportDownloading: false
        });
      })
      .catch(() => {
        notyDefault({
          type: notyTypes.ERROR,
          text: "Error generating report"
        });
        this.setState({
          reportDownloading: false
        });
      });
  };

  getActiveWorkFlow = async (srID) => {
    let stagesArray = [];
    const { formElementArray } = this.state;
    this.setState({ initialDataLoading: true });
    await axios.get("getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId=" + srID).then(result => {
      let StagesList = result.data.stages_list;
      StagesList.map((stg) => {
        stagesArray.push({ value: stg.id, displayValue: stg.stage_name })
      });

      formElementArray.reportStages.inputProps.options = stagesArray;
      this.setState({ formElementArray: formElementArray, initialDataLoading: false });

    }).catch(error => {
      this.setState({ initialDataLoading: false });

    })
  }

  onChangeHandler = (event, elId) => {
    let formData = { ...this.state.formElementArray };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    let value = "";
    if (elId === "dataRangeFrom" || elId === "dataRangeTo" || elId === "date") {
      inputProps.value = event;
      value = event;
    } else {
      inputProps.value = event.target.value;
      value = event.target.value;
    }
    if (elId === "srCategoryId") {
      this.getActiveWorkFlow(event.target.value);
    }
    if(!Boolean(value)){
      this.resetSubFieldValues(elId);
      return;
    }
    let params = {};
    for (let key in formData) {
      params[key] = formData[key].inputProps.value;
    }
    if (elId === "reportName") {
      if(value === 'TECHNICIAN_ANALYSIS_REPORT'){
        formData.reportType.inputProps.options =[{
          value: "EXCEL",
          displayValue: "Excel"
        }]
        formData.reportType.inputProps.value = "EXCEL"
      }else if(value === 'TECHNICIAN_ROUTE_REPORT'){
        formData.reportType.inputProps.options =[{
          value: "HTML",
          displayValue: "HTML"
        }]
        formData.reportType.inputProps.value = "HTML"
      }else if(value === 'SERVICE_REQUEST_DETAIL_REPORT'){
          formData.reportType.inputProps.options =[{
            value: "PDF",
            displayValue: "PDF"
          }]
          formData.reportType.inputProps.value = "PDF"
        }else {
        formData.reportType.inputProps.options =reportTypes
        formData.reportType.inputProps.value = reportTypes[0].value
      }
      this.setState(
        {
          initialDataLoading: true
        },
        () => {
          this.props.getDataForReports();
        }
      );
    } else {
      this.setState(
        {
          changeField: elId
        },
        () => {
          this.props.getDataForReports(elId, value, params);
        }
      );
    }
    let validityObj = this.checkValidity(
      inputProps.value,
      elementData.validations,
      elId
    );
    elementData.valid = validityObj.isValid;
    if (typeof elementData.labelProps !== "undefined") {
      elementData.labelProps.error = !elementData.valid;
      elementData.labelProps.errormessage = validityObj.errormessage;
    }
    elementData.touched = true;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    let isValidForm = true;
    if (elId !== "reportName" && elId !== "reportType") {
      isValidForm = this.isValidForm();
    }
    this.setState(
      {
        formElementArray: formData,
        formIsValid: isValidForm
      },
      () => this.resetValues(elId)
    );
  };

  checkValidity(value, rules, elId) {
    let returnObj = {
      isValid: true,
      errormessage: ""
    };
    if (elId === "reportName" || elId === "reportType") {
      return returnObj;
    }
    const { formElementArray } = this.state;
    if (typeof rules == "undefined") {
      return returnObj;
    }

    if (rules.required) {
      if (typeof value !== "object") {
        if (value.trim() == "") {
          returnObj.isValid = false;
          returnObj.errormessage = generalProperties.emptyField;
        }
      } else {
        if (!value._isAMomentObject) {
          returnObj.isValid = false;
          returnObj.errormessage = generalProperties.emptyField;
        } else {
          let startDate = "";
          let endDate = "";
          if (elId === "dataRangeFrom") {
            startDate = value;
            endDate = formElementArray.dataRangeTo.inputProps.value;
          }
          if (elId === "dataRangeTo") {
            startDate = formElementArray.dataRangeFrom.inputProps.value;
            endDate = value;
          }
          if (startDate && startDate.isAfter(endDate)) {
            returnObj.isValid = false;
            returnObj.errormessage =
              reportsProperties.messages.error.invalidDateRange;
          }
        }
      }
    }

    if (rules.pattern) {
      if (!rules.pattern.test(value)) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.validEmail;
      }
    }

    return returnObj;
  }
  onFormSubmitHandler = event => {
    this.setState({
      // set loadin icon
      isLoading: true
    });
    event.preventDefault();
    let formData = {};

    for (let key in this.state.formElementArray) {
      let inputPropValue = this.state.formElementArray[key].inputProps.value;
      formData[key] = inputPropValue; // get the value of each input element
    }

    // here we are not storing added or modified customers in states so we do not need reducers
    //this.props.addCustomerHandler(formData);
  };

  isDisabledField(fieldId) {
    const { formElementArray = {} } = this.state;
    const reportName = formElementArray.reportName.inputProps.value;
    return reportDisabledFields[reportName].includes(fieldId);
  }

  render() {
    const { formElementArray = {} } = this.state;
    const { reportFilterDataIsLoading } = this.props;
    let customerFormArray = [];
    for (let key in formElementArray) {
      customerFormArray.push({
        id: key,
        config: formElementArray[key]
      });
    }
    return (
      <div>
        <LoadingOverlay
          active={this.state.initialDataLoading || reportFilterDataIsLoading}
          spinner
          text="Loading ..."
        >
           <ReportView1
            tableData={this.state.tableData}
            type={this.state.SelectedReportName}
            url={urlProperties.customerUpload}
            open={this.state.openFileUpload}
            fileUploadClose={this.fileUploadClose}
          // uploadLoacation={"customer"}
          // acceptFileType={".xlsx"}
          />
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card>
                <div className="generic-form-container">
                  <form onSubmit={this.onFormSubmitHandler}>
                    <div className={"generic-form-header-wrapper"}>
                      <div className={"generic-page-title"}>
                        {/* <span className={"generic-page-title-icon"}>
                          <AssignmentIndIcon />
                        </span> */}
                        {/* <Typography
                          className={"generic-header-text"}
                          variant="h6"
                        >
                          Reports
                        </Typography>
                        <Typography variant="body1">
                          Generate your reports here.
                        </Typography> */}
                      </div>
                    </div>
                    <CardBody>
                      <GridContainer>
                        {customerFormArray.map(element => {
                          if (!element.config.isHide) {
                            let mdVal = 12;
                            if (!element.config.isFullWidth) {
                              mdVal = 6;
                            }
                            return (
                              <GridItem
                                key={element.id}
                                xs={12}
                                sm={12}
                                md={mdVal}
                              >
                                <CustomInput
                                  labelText={element.config.label}
                                  id={element.id}
                                  formControlProps={
                                    element.config.formControlProps
                                  }
                                  inputProps={{
                                    ...element.config.inputProps,
                                    readOnly: this.isDisabledField(element.id),
                                    disabled: this.isDisabledField(element.id)
                                  }}
                                  disabled={this.isDisabledField(element.id)}
                                  type={element.config.elType}
                                  value={element.config.value}
                                  changed={event =>
                                    this.onChangeHandler(event, element.id)
                                  }
                                  error={
                                    !element.config.valid &&
                                    element.config.touched
                                  }
                                  labelProps={element.config.labelProps}
                                />
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                    <CardFooter>
                      <Button
                        type="submit"
                        color="primary"
                        //onClick={this.downloadReport}
                        onClick={() => {
                          this.downloadReport(); this.fileUpload();
                        }}
                        style={{width:'10vw'}}
                        disabled={this.state.reportDownloading}
                      >
                        {this.chkCallapi ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={21}
                          />
                        ) : (
                          "Generate Report"
                        )}
                      </Button>
                    </CardFooter>
                  </form>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    reportFilterData: state.reportFilter.reportFilterData.data,
    reportFilterDataIsLoading: state.reportFilter.reportFilterData.isFetching,
    reportFilterDataError: state.reportFilter.reportFilterData.error
  };
};

const mapDispatchToProps = dispath => {
  return {
    getDataForReports: (changeField, value, data) =>
      dispath(actions.getDataForReports(changeField, value, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardReports);
