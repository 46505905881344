/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import generalProperties from '../../../Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { handleErrorMessage } from 'services/CommonService';
import DashboardProperties from '../../../Util/Property/DashboardProperties';
import _ from 'lodash';
import { getStatusChartLabel } from '../../../Util/Util';
import Viewmore2 from './Viewmore2';
import Viewmore3 from './Viewmore3';
import { getCustomerForm } from '../../../Util/Property/CustomerPorerties';

const HighlightsChartDetails2 = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [openThirdPreview, setOpenThirdPreview] = useState(false);
  const [bgColor, setBgColor] = useState('red');
  const [nextTitle, setNextTitle] = useState('');
  const [tab3Data, setTab3Data] = useState([]);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    if (props.tab2Data !== null) {
      setTableData(props.tab2Data);
    }
  }, []);

  const getProvince = (key) => {
    const obj = getCustomerForm().province.inputProps.options;
    const index = obj.findIndex((el) => el.value === key);
    if (obj[index] !== undefined) {
      return obj[index].displayValue;
    }
  };

  const getChartColumns = () => {
    if ('Staff Activities' === props.action && 'type1' === props.categoryType) {
      return [
        {
          title: 'Name',
          field: 'name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'left' },
        },
        {
          title: 'Jobs Count',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
        },
        {
          title: 'First Job Started',
          field: 'first_job_start_time',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Job Completed',
          field: 'last_job_finish_time',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Total Time Spent per Job (Min)',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          // render: (rowData) => (
          //   <div
          //     style={{
          //       background:
          //         rowData['avg'] !== '-'
          //           ? rowData.avg < rowData.kpi
          //             ? '#92D050'
          //             : rowData.avg == rowData.kpi
          //             ? 'ffc000'
          //             : ' #ff4747 '
          //           : null,
          //     }}
          //   >
          //     {rowData.avg}
          //   </div>
          // ),
        },
        // {
        //   title: 'KPI',
        //   field: 'kpi',
        //   width: '1%',
        //   headerStyle: {
        //     backgroundColor: '#e6e4df',
        //     padding: '5px',
        //     textAlign: 'center',
        //   },
        //   cellStyle: { textAlign: 'center' },
        // },
        // {title: "On Time", field: "f7",headerStyle: {backgroundColor: ' rgba(255,255,255,0.7) ',color: '#000000' ,border:"3px solid black",outline: "1px solid black"}},
      ];
    } else if (
      'Job Details' === props.action &&
      'type1' === props.categoryType
    ) {
      return [
        {
          title: 'SR No',
          field: 'sr_id',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Priority',
          width: '5%',
          field: 'priority',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Customer',
          field: 'customer_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Province',
          field: 'province',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => {
            const val = getProvince(rowData.province);
            return <div>{val}</div>;
          },
        },
        {
          title: 'Product',
          field: 'product_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Transport',
          field: 'transport_medium',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Current Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Started',
          field: 'started_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Completed',
          field: 'completed_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend',
          field: 'updated_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend By',
          field: 'updated_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Age (Days)',
          field: 'age',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          //render:(rowData) =><div style={{background:rowData.age<rowData.kpi?"#92D050": rowData.age>rowData.kpi?'#f9f519':" #ff4747 "}}>{rowData.age}</div>},
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['age'] !== '-'
                    ? rowData['age'] < rowData['kpi']
                      ? '#92D050'
                      : rowData['age'] > rowData['kpi']
                      ? ' #FF3300'
                      : '#FFC000'
                    : null,
              }}
            >
              {rowData['age'].toFixed(0)}
            </div>
          ),
        },
        {
          title: 'KPI (Days)',
          field: 'kpi',
          width: '1%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },

        // {title: "SR No", field: "f31",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{textAlign:'center'}},
        // {title: "Priority", field: "f32",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Customer", field: "f33",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Province", field: "f34",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Product", field: "f35",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Transport", field: "f36",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Current Stage", field: "f37",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Started", field: "f38",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Completed", field: "f39",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Last Attend", field: "f40",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Last Attend By", field: "f41",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
        // {title: "Age", field: "f42",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'},
        // render:(rowData) =><div style={{background:rowData.f42>4?"#92D050": rowData.f42>2?'#f9f519':" #ff4747 "}}>{rowData.Field1}</div>},
        // {title: "KPI", field: "f43",headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',textAlign:'center'}},
      ];
    } else if (
      'Field Expenses' === props.action &&
      'type1' === props.categoryType
    ) {
      return [
        {
          title: 'SR Name',
          field: 'sr_name',
          width: '6%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Priority',
          field: 'priority',
          width: '6%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Customer',
          field: 'customer_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Province',
          field: 'province',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => {
            const val = getProvince(rowData.province);
            return <div>{val}</div>;
          },
          //   render: (rowData) => <div>{JSON.stringify(rowData)}</div>,
        },
        {
          title: 'Product',
          field: 'product_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Transport',
          field: 'transport_medium',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Current Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Started',
          field: 'started_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Completed',
          field: 'completed_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend',
          field: 'updated_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend By',
          field: 'updated_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Actual Cost (LKR)',
          field: 'f42',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData.cost < rowData.kpi
                    ? '#92D050'
                    : rowData.cost === rowData.kpi
                    ? '#ffc000'
                    : ' #ff4747 ',
              }}
            >
              {rowData.cost.toFixed(2)}
            </div>
          ),
        },
        {
          title: 'Budgeted Cost/KPI (LKR)',
          field: 'kpi',
          width: '1%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(2),
        },
      ];
    } else if (
      'Customer Satisfaction' === props.action &&
      'type2' === props.categoryType
    ) {
      return [
        {
          title: 'SR Name',
          width: '5%',
          field: 'sr_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Priority',
          field: 'priority',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Customer',
          field: 'customer_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Province',
          field: 'province',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => {
            const val = getProvince(rowData.province);
            return <div>{val}</div>;
          },
        },
        {
          title: 'Product',
          field: 'product_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Rate',
          field: 'customer_rate',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => {
            return (
              <div
                style={{
                  background:
                    rowData.customer_rate >= 80
                      ? '#92D050'
                      : rowData.customer_rate >= 50
                      ? '#ffc000'
                      : ' #ff4747 ',
                }}
              >
                {rowData.customer_rate}%
              </div>
            );
          },
        },
        {
          title: 'Current Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Start Date',
          field: 'started_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Completed Date',
          field: 'completed_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Update',
          field: 'updated_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attended',
          field: 'updated_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AGE (Days)',
          field: 'avgrate',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avgrate'] !== '-'
                    ? Number(rowData.age) > rowData.kpi
                      ? '#ff4747'
                      : rowData.age === rowData.kpi
                      ? '#ffc000'
                      : ' #92D050 '
                    : null,
              }}
            >
              {rowData.age.toFixed(0)}
            </div>
          ),
        },
        {
          title: 'KPI (Days)',
          field: 'kpi',
          width: '1%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },
      ];
    } else if (
      'Employee Satisfaction' === props.action &&
      'type2' === props.categoryType
    ) {
      return [
        {
          title: 'SR Name',
          width: '5%',
          field: 'sr_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Priority',
          field: 'priority',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Employee Name',
          field: 'customer_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Department',
          field: 'department',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Product',
          field: 'product_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Rate',
          field: 'customer_rate',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => {
            return (
              <div
                style={{
                  background:
                    rowData.customer_rate >= 80
                      ? '#92D050'
                      : rowData.customer_rate >= 50
                      ? '#ffc000'
                      : ' #ff4747 ',
                }}
              >
                {rowData.customer_rate}%
              </div>
            );
          },
        },
        {
          title: 'Current Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Start Date',
          field: 'started_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Completed Date',
          field: 'completed_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Update',
          field: 'updated_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attended',
          field: 'updated_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AGE (Days)',
          field: 'avgrate',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avgrate'] !== '-'
                    ? Number(rowData.age) > rowData.kpi
                      ? '#ff4747'
                      : rowData.age === rowData.kpi
                      ? '#ffc000'
                      : ' #92D050 '
                    : null,
              }}
            >
              {rowData.age.toFixed(0)}
            </div>
          ),
        },
        {
          title: 'KPI (Days)',
          field: 'kpi',
          width: '1%',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },
      ];
    }
  };

  // const fileUploadClose = () => {
  //     setOpenSecondPreview(false);
  //  }

  const chartDetailsClose = () => {
    setOpenThirdPreview(false);
  };

  // const handleOnClickEvent=(ev)=>{
  //     setNextTitle(ev.id);
  // }

  return (
    <div>
      {/* {openSecondPreview === true ?
            <Viewmore2
            open={openSecondPreview}
            viewMoreClose={fileUploadClose}
            title={"On the Job"}
            SubTitle ={"Technical"}
            />:''
        } */}

      {openThirdPreview === true ? (
        <Viewmore3
          open={openThirdPreview}
          viewMoreClose={chartDetailsClose}
          title={props.SubTitle}
          SubTitle={nextTitle}
          tileBody={props.chartType}
          categoryType={props.categoryType}
          action={props.action}
          tab3Data={tab3Data}
        />
      ) : (
        ''
      )}
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: '4px' }}>
              <CardBody>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    size="small"
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          {props.chartTitle}
                        </Typography>
                        {/* <Typography variant="body1">More Details</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={getChartColumns()}
                    data={tableData}
                    options={{
                      // filtering: true,
                      pageSize: 10,
                      sorting: false,
                      search: false,
                      padding: 'dense',
                      border: '1px',
                      maxBodyHeight: '40vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                        lineHeight: 100,
                      },
                    }}
                    onRowClick={(event, rowData) => {
                      if ('2' === props.tileStage) {
                        if (rowData.third_table != null) {
                          setOpenThirdPreview(true);
                          setTab3Data(rowData.third_table);
                          setNextTitle(rowData.name);
                        }
                      }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default HighlightsChartDetails2;
