import * as ActionTypes from './claimsManagementActionTypes';
import { getAllUsers } from '../../services/User/UserService';
import ClaimsManagementService from '../../services/ClaimsSettings/ClaimsManagementService';
import * as trainingService from 'services/Training/TrainingService';

export const getUsers = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_ALL_USERS_START });
      const response = await ClaimsManagementService.getAllUserForClaims();
      dispatch({
        type: ActionTypes.GET_ALL_USERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ALL_USERS_ERROR,
        payload: error,
      });
    }
  };
};
export const createClaimReport = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.CREATE_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.generateClaim(data);
      dispatch({
        type: ActionTypes.CREATE_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.CREATE_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const updateClaimReport = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.updateClaimReport(data);
      dispatch({
        type: ActionTypes.UPDATE_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const getAllClaimReports = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_ALL_CLAIM_REPORTS_START });
      const response = await ClaimsManagementService.getAllClaimsReports();
      dispatch({
        type: ActionTypes.GET_ALL_CLAIM_REPORTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_ALL_CLAIM_REPORTS_ERROR,
        payload: error,
      });
    }
  };
};

export const geClaimReportById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.getAllClaimsReportById(id);
      dispatch({
        type: ActionTypes.GET_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const updateClaimReportById = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.updateClaimReportById(
        data
      );
      dispatch({
        type: ActionTypes.UPDATE_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UPDATE_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const generateClaimReport = (uuid) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GENEARATE_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.generateClaimReport(uuid);
      dispatch({
        type: ActionTypes.GENEARATE_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${uuid}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      dispatch({
        type: ActionTypes.GENEARATE_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const sendClaimMessage = (uid, uuid, reportName) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.SEND_CLAIM_REPORT_START });
      const response = await ClaimsManagementService.sendClaimReport(
        uid,
        uuid,
        reportName
      );
      dispatch({
        type: ActionTypes.SEND_CLAIM_REPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SEND_CLAIM_REPORT_ERROR,
        payload: error,
      });
    }
  };
};

export const getRouteOptionsByClaimId = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.GET_CLAIM_ROUTE_OPTIONS_START });
      const response = await ClaimsManagementService.getRouteOptionsForCalim(
        id
      );
      dispatch({
        type: ActionTypes.GET_CLAIM_ROUTE_OPTIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.GET_CLAIM_ROUTE_OPTIONS_ERROR,
        payload: error,
      });
    }
  };
};
