import React, { Component } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import styles from './ExpenseRuleForm.module.css';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import LoadingOverlay from 'react-loading-overlay';
import {
  notyDefault,
  notyTypes,
} from '../../../../../components/Noty/NotyCustom';
import generalProperties from 'Util/Property/GeneralProperties';
import ClaimsSettingsProperty, {
  formInitialData,
  ruleTypes,
} from 'Util/Property/ClaimsSettingsProperty';
import Input from '@material-ui/core/Input';
import './ExpenseRuleForm.module.css';
import MaterialTable from 'material-table';
import { PermContactCalendarRounded } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import PermissionProperties from '../../../../../Util/Property/PermissionProperties';
import { checkPermissoinListAvailable } from '../../../../../Util/Permission/CheckPermission';
import Spinner from 'components/Spinner/Spinner.js';

class ExpenseRuleForm extends Component {
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  state = {
    expenseRuleForm: { ...ClaimsSettingsProperty.expenseRuleForm },
    id: null,
    formIsValid: false,
    isLoading: true,
    data: [{ distanceFrom: '', distanceTo: '', allowance: '', count: 1 }],
    lateNightAllowance: '',
    permissions: {},
  };

  checkValidity(value, rules) {
    let returnObj = {
      isValid: true,
      errormessage: '',
    };
    if (typeof rules == 'undefined') {
      return returnObj;
    }

    if (rules.required) {
      if (!value) {
        returnObj.isValid = false;
        returnObj.errormessage = generalProperties.emptyField;
      }
    }
    return returnObj;
  }
  constructMealAllowanceRuleItems = (data) => {
    return data.map((item) => {
      return { mealTypeId: item.mealTypeId, allowance: item.allowance };
    });
  };
  getAllowanceTypeOptions = (allowanceTypesList) => {
    let returnList = [];
    allowanceTypesList.forEach((type) => {
      returnList.push({ value: type.id, displayValue: type.name });
    });
    return returnList;
  };

  onChangeHandler = (event, elId) => {
    const { allowanceTypesList, mealTypesList } = this.props;
    let formData = { ...this.state.expenseRuleForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    if (elId === 'type') {
      let allowanceName = '';
      allowanceTypesList.forEach((type) =>
        type.id === event.target.value ? (allowanceName = type.name) : ''
      );
      if (allowanceName === ruleTypes[2].displayValue) {
        const dataForMealType = mealTypesList.map((mealType, index) => {
          return {
            mealTypeName: mealType.name,
            allowance: '',
            mealTypeId: mealType.id,
            count: index + 1,
          };
        });
        this.setState({
          data: dataForMealType,
        });
      } else {
        this.setState({
          data: formInitialData[allowanceName],
        });
      }
    }
    if (elId !== 'status') {
      let validityObj = this.checkValidity(
        event.target.value,
        elementData.validations
      );
      formData[elId].inputProps.value = event.target.value;
      elementData.valid = validityObj.isValid;
      if (typeof elementData.labelProps !== 'undefined') {
        elementData.labelProps.error = !elementData.valid;
        elementData.labelProps.errormessage = validityObj.errormessage;
        elementData.touched = true;
      }
    } else {
      formData[elId].inputProps.value = !formData[elId].inputProps.value;
    }
    let isFormValid = true;
    for (let key in this.state.expenseRuleForm) {
      isFormValid = this.state.expenseRuleForm[key].valid && isFormValid;
    }
    this.setState({
      expenseRuleForm: formData,
      formIsValid: isFormValid,
    });
  };

  saveDetails = () => {
    const validData = this.validateData();
    if (validData) {
      const { expenseRuleForm, id, data, lateNightAllowance } = this.state;
      const {
        createExpenseRule,
        updateExpenseRule,
        allowanceTypesList,
      } = this.props;
      let allowanceName = '';
      let ruleItems = [];
      allowanceTypesList.forEach((type) =>
        type.id === expenseRuleForm.type.inputProps.value
          ? (allowanceName = type.name)
          : ''
      );
      if (allowanceName === ruleTypes[2].displayValue) {
        ruleItems = this.constructMealAllowanceRuleItems(data);
      } else if (allowanceName === ruleTypes[3].displayValue) {
        ruleItems = [{ allowance: lateNightAllowance }];
      } else {
        ruleItems = data;
      }
      const saveData = {
        name: expenseRuleForm['name'].inputProps.value,
        allowanceTypeId: expenseRuleForm.type.inputProps.value,
        ruleItems: ruleItems,
        isActive: expenseRuleForm['status'].inputProps.value,
        id: id,
      };
      if (id) {
        updateExpenseRule(saveData, id);
      } else {
        createExpenseRule(saveData);
      }
    }
  };
  addRow = () => {
    const { data, expenseRuleForm } = this.state;
    const { allowanceTypesList } = this.props;
    const value = expenseRuleForm.type.inputProps.value;
    let allowanceName = '';
    allowanceTypesList.forEach((type) =>
      type.id === value ? (allowanceName = type.name) : ''
    );
    data.push({ ...formInitialData[allowanceName][0], count: data.length + 1 });
    this.setState({
      data: data,
    });
  };

  getSelectedAllowanceTypeName = (value) => {
    const { allowanceTypesList } = this.props;
    let allowanceName = '';
    allowanceTypesList.forEach((type) =>
      type.id === value ? (allowanceName = type.name) : ''
    );
    return allowanceName;
  };

  async componentDidMount() {
    const {
      match,
      getAllowanceTypes,
      getMealTypes,
      getServiceRequestTypes,
      getProductCategories,
    } = this.props;
    const { params } = match;
    const { id, type } = params;
    await getAllowanceTypes();
    await getMealTypes();
    await getServiceRequestTypes();
    await getProductCategories();
    if (id) {
      this.props.geExpenseRuleById(id);
    } else {
      const { expenseRuleForm } = this.state;
      expenseRuleForm['name'].inputProps.value = '';
      expenseRuleForm['name'].touched = false;
      expenseRuleForm['name'].valid = false;
      this.setState({
        expenseRuleForm: expenseRuleForm,
        id: null,
      });
    }
    if (type === ClaimsSettingsProperty.actionType.edit) {
      this.setState({
        formIsValid: true,
      });
    }
    let permissionArray = [
      PermissionProperties.addExpenseRule,
      PermissionProperties.viewExpenseRule,
      PermissionProperties.deleteExpenseRule,
      PermissionProperties.editExpenseRule,
    ];

    this.setState({
      permissions: checkPermissoinListAvailable(permissionArray),
    });
  }
  constructDataForUI = (items = [], type) => {
    let allowanceName = this.getSelectedAllowanceTypeName(type);
    if (allowanceName === ruleTypes[2].displayValue) {
      return items.map((item, index) => {
        return {
          mealTypeName: item.mealTypeName,
          allowance: item.allowance,
          mealTypeId: item.mealTypeId,
          count: index + 1,
        };
      });
    } else if (allowanceName === ruleTypes[1].displayValue) {
      return items.map((item, index) => {
        return {
          productCategoryId: item.productCategoryId,
          serviceRequestCategoryId: item.serviceRequestCategoryId,
          allowance: item.allowance,
          count: index + 1,
        };
      });
    } else if (allowanceName === ruleTypes[0].displayValue) {
      return items.map((item, index) => {
        return {
          distanceFrom: item.distanceFrom,
          distanceTo: item.distanceTo,
          allowance: item.allowance,
          count: index + 1,
        };
      });
    } else {
      return items;
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      claimExpenseRuleObject,
      createInProgress,
      history,
      createFailed,
      allowanceTypesList,
    } = this.props;
    const { expenseRuleForm } = this.state;
    if (
      prevProps.claimExpenseRuleObject !== claimExpenseRuleObject &&
      claimExpenseRuleObject !== {}
    ) {
      const { expenseRuleForm } = this.state;
      expenseRuleForm['name'].inputProps.value =
        claimExpenseRuleObject.name || '';
      expenseRuleForm['name'].touched = false;
      expenseRuleForm['name'].valid = true;
      expenseRuleForm['type'].inputProps.value =
        claimExpenseRuleObject.allowanceTypeId || '';
      expenseRuleForm['type'].touched = false;
      expenseRuleForm['type'].valid = true;
      expenseRuleForm['id'].inputProps.value = claimExpenseRuleObject.id || '';
      expenseRuleForm['id'].touched = false;
      expenseRuleForm['id'].valid = true;
      expenseRuleForm['status'].inputProps.value =
        claimExpenseRuleObject.isActive;
      expenseRuleForm['status'].touched = false;
      expenseRuleForm['status'].valid = true;
      this.setState({
        expenseRuleForm: expenseRuleForm,
        id: claimExpenseRuleObject.id,
        data: this.constructDataForUI(
          claimExpenseRuleObject.ruleItems,
          claimExpenseRuleObject.allowanceTypeId
        ),
      });
      if (
        claimExpenseRuleObject.allowanceTypeName === ruleTypes[3].displayValue
      ) {
        this.setState({
          lateNightAllowance:
            claimExpenseRuleObject.ruleItems.length > 0
              ? claimExpenseRuleObject.ruleItems[0].allowance
              : '',
        });
      }
    }
    if (!createInProgress && prevProps.createInProgress !== createInProgress) {
      if (createFailed) {
        notyDefault({
          type: notyTypes.ERROR,
          text: ClaimsSettingsProperty.messages.error.saveData,
        });
      } else {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: ClaimsSettingsProperty.messages.success.saveData,
        });
      }
      history.push('/trackable/settings/claimsSettings?to=rules');
    }
    if (
      allowanceTypesList !== prevProps.allowanceTypesList &&
      allowanceTypesList.length > 0
    ) {
      const allowanceTypeOptionsList = this.getAllowanceTypeOptions(
        allowanceTypesList
      );
      expenseRuleForm['type'].inputProps.options = allowanceTypeOptionsList;
      expenseRuleForm['type'].inputProps.value =
        allowanceTypeOptionsList[0].value;
      expenseRuleForm['type'].inputProps.initValue =
        allowanceTypeOptionsList[0].value;
      this.setState({
        expenseRuleForm: expenseRuleForm,
      });
    }
  }

  render() {
    const {
      match,
      allowanceTypesList,
      claimExpenseRuleObjectLoading,
      allowanceTypesListLoading,
      mealTypesListLoading,
      serviceRequestsListLoading,
      productCategoriesListLoading,
    } = this.props;
    const { data, expenseRuleForm = {}, lateNightAllowance, id } = this.state;
    const allowanceType = expenseRuleForm.type.inputProps.value;
    let allowanceName = '';
    allowanceTypesList.forEach((type) =>
      type.id === allowanceType ? (allowanceName = type.name) : ''
    );
    const { params } = match;
    const { type = '' } = params || {};
    let titleText = ClaimsSettingsProperty.actionText.add;
    if (type) {
      titleText =
        type === ClaimsSettingsProperty.actionType.view
          ? ClaimsSettingsProperty.actionText.view
          : ClaimsSettingsProperty.actionText.edit;
    }
    let formElementArray = [];
    for (let key in this.state.expenseRuleForm) {
      formElementArray.push({
        id: key,
        config: expenseRuleForm[key],
      });
    }
    let submitButton = (
      <Button
        disabled={!this.state.formIsValid}
        onClick={this.saveDetails}
        type="submit"
        color="primary"
      >
        Save
      </Button>
    );
    if (type === ClaimsSettingsProperty.actionType.view) {
      submitButton = null;
    }
    let permissionAvailable = true;
    if (id && !this.state.permissions[PermissionProperties.editExpenseRule]) {
      permissionAvailable = false;
    }
    if (!id && !this.state.permissions[PermissionProperties.addExpenseRule]) {
      permissionAvailable = false;
    }
    const isDataFetching =
      claimExpenseRuleObjectLoading ||
      productCategoriesListLoading ||
      allowanceTypesListLoading ||
      serviceRequestsListLoading ||
      mealTypesListLoading;

    return (
      <div>
        <LoadingOverlay
          active={isDataFetching}
          spinner={<Spinner />}
          text="Loading ..."
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <Card style={{ minHeight: '400px' }}>
                <CardBody>
                  <div
                    className={`material-grid-wrapper generic-form-container`}
                  >
                    <GridContainer>
                      <Grid spacing={3} md={6}>
                        <span className={'grid-title-icon'}>
                          <PermContactCalendarRounded />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Claim Rule Management
                        </Typography>
                        <Typography variant="body1">
                          {id ? 'Update claims rule' : ''}
                        </Typography>
                      </Grid>
                      <Grid spacing={3} md={6}>
                        {permissionAvailable && (
                          <Button
                            onClick={this.saveDetails}
                            variant="contained"
                            color="primary"
                            style={{ float: 'right' }}
                          >
                            {id
                              ? 'Update Expense Rule'
                              : 'Save New Expense Rule'}
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                          color="primary"
                          style={{ float: 'right' }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </GridContainer>
                  </div>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        {formElementArray.map((element) => {
                          if (!element.config.isHide) {
                            let mdVal = 12;
                            if (!element.config.isFullWidth) {
                              mdVal = 6;
                            }
                            return (
                              <GridItem key={element.id} md={3}>
                                <CustomInput
                                  labelText={element.config.label}
                                  id={element.id}
                                  formControlProps={
                                    element.config.formControlProps
                                  }
                                  inputProps={{
                                    ...element.config.inputProps,
                                    readOnly: element.id === 'id',
                                    className: styles.width250,
                                  }}
                                  type={element.config.elType}
                                  value={element.config.value}
                                  changed={(event) =>
                                    this.onChangeHandler(event, element.id)
                                  }
                                  error={
                                    !element.config.valid &&
                                    element.config.touched
                                  }
                                  labelProps={element.config.labelProps}
                                  gridStyle={element.gridStyle}
                                />
                              </GridItem>
                            );
                          }
                        })}
                      </GridContainer>
                    </CardBody>
                  </Card>
                  {!isDataFetching && (
                    <GridContainer>
                      {allowanceName === ruleTypes[3].displayValue ? (
                        <>
                          <InputLabel
                            style={{
                              marginTop: '10px',
                              marginLeft: '15px',
                            }}
                          >
                            Allowance
                          </InputLabel>
                          <Input
                            className={styles.ruleInput}
                            style={{ marginLeft: '15px' }}
                            value={lateNightAllowance}
                            onChange={(e) => {
                              this.changeLateNightAllowance(e);
                            }}
                            type="number"
                          />
                        </>
                      ) : (
                        <div
                          className={'material-grid-wrapper'}
                          style={{ width: '100%' }}
                        >
                          <MaterialTable
                            title={
                              <div>
                                {/* <Typography variant="body1">Add items to your claims rule.</Typography> */}
                              </div>
                            }
                            components={{
                              OverlayLoading: () => (
                                <div className={styles.loadingOverlay}>
                                  <Spinner />
                                </div>
                              ),
                            }}
                            tableRef={this.tableRef}
                            columns={this.getColumns(allowanceName)}
                            data={data}
                            actions={[
                              (rowData) => ({
                                icon: 'delete',
                                tooltip: 'Deactivate Expense Rule',
                                iconProps: {
                                  className: 'action-buttons delete-button',
                                },
                                onClick: (event, rowData) => {
                                  this.removeRuleItem(rowData.count);
                                },
                              }),
                              {
                                icon: () =>
                                  (allowanceName ===
                                    ruleTypes[0].displayValue ||
                                    allowanceName ===
                                      ruleTypes[1].displayValue) && (
                                    <Button
                                      onClick={this.addRow}
                                      variant="contained"
                                      color="primary"
                                    >
                                      Add Item
                                    </Button>
                                  ),
                                tooltip: 'Add New Expense Rule',
                                isFreeAction: true,
                              },
                            ]}
                            options={{
                              actionsColumnIndex: -1,
                              search: false,
                              sorting: false,
                              maxBodyHeight: '70vh',
                              headerStyle: {
                                fontSize: '0.7rem',
                                textAlignL: 'center',
                              },
                              rowStyle: {
                                fontSize: '0.6rem',
                                fontWeight: 450,
                                padding: '5rem',
                              },
                            }}
                            onRowClick={(
                              event,
                              rowData,
                              toggleDetailPanel
                            ) => {}}
                          />
                        </div>
                      )}
                    </GridContainer>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </LoadingOverlay>
      </div>
    );
  }
  onTableInputChange = (dataIndex, event, key) => {
    const { data } = this.state;
    let newData = data.slice();
    let changedRow = { ...data[dataIndex - 1] };
    changedRow[key] = event.target.value;
    newData[dataIndex - 1] = changedRow;
    this.setState({
      data: newData,
    });
  };
  changeLateNightAllowance = (event) => {
    this.setState({
      lateNightAllowance: event.target.value,
    });
  };
  removeRuleItem = (count) => {
    const { data } = this.state;
    let newData = data.slice();
    let dataIndex = -1;
    data.forEach((allow, index) => {
      if (allow.count === count) {
        dataIndex = index;
      }
    });
    newData.splice(dataIndex, 1);
    this.setState({
      data: newData,
    });
  };
  getColumns = (type) => {
    const { serviceRequestsList, productCategoriesList } = this.props;
    if (type === ruleTypes[0].displayValue) {
      return [
        { title: '#', field: 'count' },
        {
          title: 'From KMs (Range)',
          field: 'distanceFrom',
          render: (rowData) => (
            <Input
              className={styles.ruleInput}
              value={rowData.distanceFrom}
              onChange={(e) => {
                this.onTableInputChange(rowData.count, e, 'distanceFrom');
              }}
              type="number"
            />
          ),
        },
        {
          title: 'To KMs (Range)',
          field: 'distanceTo',
          render: (rowData) => (
            <Input
              className={styles.ruleInput}
              value={rowData.distanceTo}
              onChange={(e) => {
                this.onTableInputChange(rowData.count, e, 'distanceTo');
              }}
              type="number"
            />
          ),
        },
        {
          title: 'Allowance',
          field: 'allowance',
          render: (rowData) => (
            <Input
              className={styles.ruleInput}
              value={rowData.allowance}
              onChange={(e) => {
                this.onTableInputChange(rowData.count, e, 'allowance');
              }}
              type="number"
            />
          ),
        },
      ];
    } else if (type === ruleTypes[1].displayValue) {
      return [
        { title: '#', field: 'count' },
        {
          title: 'Product Category',
          field: 'productCategoryId',
          render: (rowData) => (
            <CustomInput
              inputProps={{
                value: rowData.productCategoryId,
                options: this.getAllowanceTypeOptions(productCategoriesList),
                className: '',
              }}
              changed={(e) => {
                this.onTableInputChange(rowData.count, e, 'productCategoryId');
              }}
              formControlProps={{ fullWidth: true }}
              type="select"
            />
          ),
        },
        {
          title: 'SR Type',
          field: 'serviceRequestCategoryId',
          render: (rowData) => (
            <CustomInput
              inputProps={{
                value: rowData.serviceRequestCategoryId,
                options: this.getAllowanceTypeOptions(serviceRequestsList),
                className: '',
              }}
              changed={(e) => {
                this.onTableInputChange(
                  rowData.count,
                  e,
                  'serviceRequestCategoryId'
                );
              }}
              formControlProps={{ fullWidth: true }}
              type="select"
            />
          ),
        },
        {
          title: 'Allowance',
          field: 'allowance',
          render: (rowData) => (
            <Input
              className={styles.ruleInput}
              value={rowData.allowance}
              onChange={(e) => {
                this.onTableInputChange(rowData.count, e, 'allowance');
              }}
              type="number"
            />
          ),
        },
      ];
    } else if (type === ruleTypes[2].displayValue) {
      return [
        { title: '#', field: 'count' },
        { title: 'Meal Type', field: 'mealTypeName' },
        {
          title: 'Allowance',
          field: 'allowance',
          render: (rowData) => (
            <Input
              className={styles.ruleInput}
              value={rowData.allowance}
              onChange={(e) => {
                this.onTableInputChange(rowData.count, e, 'allowance');
              }}
              type="number"
            />
          ),
        },
      ];
    }
  };
  onTableInputChangeForMeal = (dataIndex, event, key) => {
    const { data } = this.state;
    let newData = data.slice();
    let changedRow = { ...data[dataIndex] };
    changedRow[key] = event.target.value;
    newData[dataIndex] = changedRow;
    this.setState({
      data: newData,
    });
  };
  validateData = () => {
    const { data, expenseRuleForm, lateNightAllowance } = this.state;
    const { allowanceTypesList } = this.props;
    let allowanceName = '';
    allowanceTypesList.forEach((type) =>
      type.id === expenseRuleForm.type.inputProps.value
        ? (allowanceName = type.name)
        : ''
    );
    let isValid = true;
    if (!expenseRuleForm['name'].inputProps.value) {
      isValid = false;
    }
    if (allowanceName !== ruleTypes[3].displayValue) {
      isValid = data.length !== 0;
      data.forEach((row) => {
        Object.values(row).forEach((value) => {
          if (value === undefined || value === null || value === '') {
            isValid = false;
          }
        });
      });
    } else {
      isValid = Boolean(lateNightAllowance);
    }
    if (!isValid) {
      notyDefault({
        type: notyTypes.ERROR,
        text: ClaimsSettingsProperty.messages.error.invalidRulesData,
      });
      return false;
    }
    return true;
  };
  getInputTable = (obj = {}, dataIndex, allowanceName) => {
    if (allowanceName === ruleTypes[2].displayValue) {
      const { mealTypesList = [] } = this.props;
      return (
        <div className={styles.inputTableRow}>
          {Object.keys(obj).map((key, index) => {
            if (key !== 'mealTypeId') {
              return key === 'mealTypeName' ? (
                <span
                  style={{
                    minWidth: '175px',
                    marginTop: '8px',
                    marginLeft: '5px',
                  }}
                >
                  {mealTypesList[dataIndex].name}
                </span>
              ) : (
                <div className={`col-md-3 ${styles.inputDiv}`}>
                  <Input
                    className={styles.ruleInput}
                    value={obj[key]}
                    onChange={(e) => {
                      this.onTableInputChange(dataIndex, e, key);
                    }}
                    type="number"
                  />
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.inputTableRow}>
          {Object.keys(obj).map((key, index) => {
            return (
              <div className={`col-md-3 ${styles.inputDiv}`}>
                <Input
                  className={styles.ruleInput}
                  value={obj[key]}
                  onChange={(e) => {
                    this.onTableInputChange(dataIndex, e, key);
                  }}
                  type="number"
                />
              </div>
            );
          })}
        </div>
      );
    }
  };
  getInputHeaders = (allowanceType) => {
    const { allowanceTypesList } = this.props;
    let allowanceName = '';
    allowanceTypesList.forEach((type) =>
      type.id === allowanceType ? (allowanceName = type.name) : ''
    );
    if (allowanceName === ruleTypes[0].displayValue) {
      return (
        <div className={`col-md-12 ${styles.inputTableRow}`}>
          <div className={`col-md-6 ${styles.middleHeader}`}>
            <span>Kms</span>
          </div>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Allowance (Rs.)</span>
          </div>
        </div>
      );
    } else if (allowanceName === ruleTypes[1].displayValue) {
      return (
        <div className={`col-md-12 ${styles.inputTableRow}`}>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Product Category</span>
          </div>
          {/*<div className={`col-md-3 ${styles.otherHeader}`}>*/}
          {/*    <span>Product Brand</span>*/}
          {/*</div>*/}
          {/*<div className={`col-md-3 ${styles.otherHeader}`}>*/}
          {/*    <span>Product Model</span>*/}
          {/*</div>*/}
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>SR Type</span>
          </div>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Allowance (Rs.)</span>
          </div>
        </div>
      );
    } else if (allowanceName === ruleTypes[2].displayValue) {
      return (
        <div className={`col-md-12 ${styles.inputTableRow}`}>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Meal</span>
          </div>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Allowance (Rs.)</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`col-md-12 ${styles.inputTableRow}`}>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span></span>
          </div>
          <div className={`col-md-3 ${styles.otherHeader}`}>
            <span>Allowance (Rs.)</span>
          </div>
        </div>
      );
    }
  };
}

const mapStateToProps = (state) => {
  const { claimRules } = state;
  const {
    claimExpenseRuleObject,
    createExpenseRule,
    allowanceType,
    mealType,
    productCategories,
    serviceRequests,
  } = claimRules;
  return {
    claimExpenseRuleObject: claimExpenseRuleObject.data,
    claimExpenseRuleObjectLoading: claimExpenseRuleObject.isFetching,
    createInProgress: createExpenseRule.inProgress,
    createFailed: createExpenseRule.error,
    allowanceTypesList: allowanceType.data,
    allowanceTypesListLoading: allowanceType.inProgress,
    mealTypesList: mealType.data,
    mealTypesListLoading: mealType.inProgress,
    serviceRequestsList: serviceRequests.data,
    serviceRequestsListLoading: serviceRequests.inProgress,
    productCategoriesList: productCategories.data,
    productCategoriesListLoading: productCategories.inProgress,
  };
};

const mapDispatchToProps = (dispath) => {
  return {
    geExpenseRuleById: (id) => dispath(actions.getExpenseRuleById(id)),
    createExpenseRule: (data) => dispath(actions.createExpenseRule(data)),
    getAllowanceTypes: () => dispath(actions.getAllowanceTypes()),
    getMealTypes: () => dispath(actions.getMealTypes()),
    getProductCategories: () => dispath(actions.getProductCategories()),
    getServiceRequestTypes: () => dispath(actions.getServiceRequestTypes()),
    updateExpenseRule: (data, id) =>
      dispath(actions.updateExpenseRule(data, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseRuleForm);
