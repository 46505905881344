import React, { useEffect, useState, useRef } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import { Typography } from "@material-ui/core";
import generalProperties from "../../Util/Property/GeneralProperties";
import LoadingOverlay from "react-loading-overlay";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import { handleErrorMessage } from "services/CommonService";
import * as moment from "moment";

const Estimations = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [autidId, setAuditId] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    let auId = 0;
    let getHistoryMethod;
    if (props.id && props.id > 0) {
      auId = props.id;
      getHistoryMethod = props.method;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam["id"] != "undefined") {
        auId = queryParam["id"];
      }
    }

    getAllHistoryDataToDisplay(auId, getHistoryMethod);
    setAuditId(auId);
  }, [autidId]);

  const getAllHistoryDataToDisplay = (auditId, method) => {
    setIsLoading(true);
    method(auditId)
      .then((result) => {
        if (result.status === 200) {
          setIsLoading(false);
          setHistoryData(result.data);
        } else {
          setIsLoading(false);
          handleErrorMessage(
            result.data,
            generalProperties.messages.error.loadHistory
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorMessage(error, generalProperties.messages.error.loadHistory);
      });
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <div className={"material-grid-wrapper"}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={"grid-title-icon"}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                        >
                          History
                        </Typography>
                        {/* <Typography variant="body1">Audit changes here</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      {
                        title: "Date",
                        field: "timestamp",
                        render: (rowData) =>
                          moment(rowData.timestamp)
                            .subtract(1, "months")
                            .format("YYYY/MM/DD"),
                        customFilterAndSearch: (term, rowData) =>
                          moment(rowData.timestamp)
                            .subtract(1, "months")
                            .format("YYYY/MM/DD")
                            .includes(term),
                      },

                      { title: "Revision Id", field: "revision_id" },
                      { title: "Action", field: "action" },
                      { title: "Field Name", field: "attribute" },
                      { title: "Old Value", field: "old_value" },
                      { title: "New Value", field: "new_value" },
                      { title: "User", field: "user_name" },
                    ]}
                    data={historyData}
                    options={{
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: "70vh",
                      headerStyle: {
                        fontSize: "0.7rem",
                        textAlignL: "center",
                      },
                      rowStyle: {
                        fontSize: "0.6rem",
                        fontWeight: 450,
                        padding: "5rem",
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default Estimations;
