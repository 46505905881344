import * as ActionTypes from '../../store/actions/claimsExpenseRulesActionTypes';

const initialState = {
    claimExpenseRuleList: {
        data: [],
        isFetching: false,
        error: null
    },
    claimExpenseRuleObject:{
        data: {},
        isFetching: false,
        error: null
    },
    createExpenseRule: {
        data:{},
        inProgress : false,
        error: null
    },
    deleteExpenseRule: {
        success:false,
        inProgress : false,
        error: null
    },
    allowanceType: {
        data:[],
        inProgress : false,
        error: null
    },
    mealType: {
        data:[],
        inProgress : false,
        error: null
    },
    serviceRequests: {
        data:[],
        inProgress : false,
        error: null
    },
    productCategories: {
        data:[],
        inProgress : false,
        error: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CLAIMS_RULE_LIST_START:
            return {
                ...state,
                claimExpenseRuleList: {
                    ...state.claimExpenseRuleList,
                    isFetching: true,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_RULE_LIST_SUCCESS:
            return {
                ...state,
                claimExpenseRuleList: {
                    ...state.claimExpenseRuleList,
                    isFetching: false,
                    error: null,
                    data: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_RULE_LIST_FAILED:
            return {
                ...state,
                claimExpenseRuleList: {
                    ...state.claimExpenseRuleList,
                    isFetching: false,
                    error: action.payload,
                }
            };
        case ActionTypes.GET_CLAIMS_RULE_BY_ID_START:
            return {
                ...state,
                claimExpenseRuleObject: {
                    ...state.claimExpenseRuleObject,
                    isFetching: true,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_RULE_BY_ID_SUCCESS:
            return {
                ...state,
                claimExpenseRuleObject: {
                    ...state.claimExpenseRuleObject,
                    isFetching: false,
                    error: null,
                    data: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_RULES_BY_ID_FAILED:
            return {
                ...state,
                claimExpenseRuleObject: {
                    ...state.claimExpenseRuleObject,
                    isFetching: false,
                    error: action.payload,
                }
            };
        case ActionTypes.CREATE_CLAIMS_RULE_START :
            return {
                ...state,
                createExpenseRule: {
                    data:{},
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.CREATE_CLAIMS_RULE_SUCCESS :
            return {
                ...state,
                createExpenseRule: {
                    data: action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.CREATE_CLAIMS_RULE_FAILED :
            return {
                ...state,
                createExpenseRule: {
                    data:{},
                    inProgress : false,
                    error: action.payload
                }
            };
       case ActionTypes.UPDATE_CLAIMS_RULE_START :
            return {
                ...state,
                createExpenseRule: {
                    data:{},
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.UPDATE_CLAIMS_RULE_SUCCESS :
            return {
                ...state,
                createExpenseRule: {
                    data: action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.UPDATE_CLAIMS_RULE_FAILED :
            return {
                ...state,
                createExpenseRule: {
                    data:{},
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.DELETE_CLAIMS_RULE_START :
            return {
                ...state,
                deleteExpenseRule: {
                    success:false,
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.DELETE_CLAIMS_RULE_SUCCESS :
            return {
                ...state,
                deleteExpenseRule: {
                    success:true,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.DELETE_CLAIMS_RULE_FAILED :
            return {
                ...state,
                deleteExpenseRule: {
                    success:false,
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_START :
            return {
                ...state,
                allowanceType: {
                    ...state.allowanceType,
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_SUCCESS :
            return {
                ...state,
                allowanceType: {
                    data:action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_FAILED :
            return {
                ...state,
                allowanceType: {
                    ...state.allowanceType,
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_MEAL_TYPES_START :
            return {
                ...state,
                mealType: {
                    ...state.mealType,
                    inProgress : true,
                    error: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_MEAL_TYPES_SUCCESS :
            return {
                ...state,
                mealType: {
                    data:action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_MEAL_TYPES_FAILED :
            return {
                ...state,
                mealType: {
                    data:[],
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.GET_SERVICE_REQUEST_TYPES_START :
            return {
                ...state,
                serviceRequests: {
                    data:[],
                    inProgress : true,
                    error: action.payload
                }
            };
        case ActionTypes.GET_SERVICE_REQUEST_TYPES_SUCCESS :
            return {
                ...state,
                serviceRequests: {
                    data:action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.GET_SERVICE_REQUEST_TYPES_FAILED :
            return {
                ...state,
                serviceRequests: {
                    data:[],
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.GET_PRODUCT_CATEGORIES_START :
            return {
                ...state,
                productCategories: {
                    data:[],
                    inProgress : true,
                    error: action.payload
                }
            };
        case ActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS :
            return {
                ...state,
                productCategories: {
                    data:action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.GET_PRODUCT_CATEGORIES_FAILED :
            return {
                ...state,
                productCategories: {
                    data:[],
                    inProgress : false,
                    error: action.payload
                }
            };
        default:
            return {
                ...state
            };
    }
};
