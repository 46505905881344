import React, { useState } from "react";
import CustomMarker from "./CustomMarker";
import { buildingMarker } from "./SvgIcons";
import OutletMarker from "assets/icons/outlet new.png";
import { Marker, OverlayView } from "@react-google-maps/api";
import PopupBoxHandler from "./PopupBoxHandler";

const CustomersLocationManager = ({ customerList }) => {
  const [activeCustomer, setActiveCustomer] = useState();
  
  
  const handleActiveCustomer = (customerName, customerLocation) => {

    setActiveCustomer({
      name: customerName, 
      position: customerLocation,
    })
  }

  const removeActiveCustomer = () => {
    setActiveCustomer(null);
  }


  return (
     <>
     {customerList?.map((customer, index) => (
        <Marker
      key={index}
        icon={{
          url: OutletMarker,
          scaledSize: new window.google.maps.Size(30, 40),
        }}
        position={customer.location}
        onMouseOver={() => handleActiveCustomer(customer.name, customer.location)}
        onMouseOut={() => {
          if (activeCustomer?.name === customer.name)
            setActiveCustomer(null)
        }}
      />
    ))}
    {activeCustomer && <PopupBoxHandler postion={activeCustomer.position} text={<p>{activeCustomer.name}</p>} removePopup={removeActiveCustomer} />}
     </>
  );
};

export default CustomersLocationManager;
