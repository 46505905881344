/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import styles from './ProductAttribute.module.css';
import GeneralProperties from 'Util/Property/GeneralProperties';
import Button from 'components/CustomButtons/Button.js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { Form, Field } from 'react-final-form';
import LoadingOverlay from 'react-loading-overlay';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import axios from '../../../axios/axios-default';
import MaterialTable from 'material-table';
import urlProperties from 'Util/Property/UrlProperties';
import format from 'string-format';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { Typography } from '@material-ui/core';
import { buildFilterObject } from '../../../Util/Util';
import Spinner from 'components/Spinner/Spinner.js';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { Edit } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: '#e6e4df',
    height: '10px',
    top: '45px',
  },
  tabsWrapper: {
    background: '#e6e4df',
  },
}));

const ProductAttribute = (props) => {
  const classes = useStyles();

  const attributeEnum = {
    CATEGORY: 'Category',
    BRAND: 'Brand',
    MODEL: 'Model',
    SUBSTATUS: 'Sub Status',
    PART: 'Parts',
  };
  const [attributeType, setAttributeType] = useState(attributeEnum.CATEGORY);
  const [isAttributeDialogOpen, setIsAttributeDialogOpen] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [attributeDialogDetails, setAttributeDialogDetails] = useState({
    attributeDialogHeader: GeneralProperties.productAttribute.addNewCategory,
    isEditDialog: false,
    selectedCategory: '',
    selectedBrand: '',
    activeCategoryList: [],
    activeBrandList: [],
    selectedId: 0,
    generatePSR: false,
    generateSA: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const categoryTableRef = useRef(null);
  const brandTableRef = useRef(null);
  const modelTableRef = useRef(null);
  const subStatusTableRef = useRef(null);
  const partsTableRef = useRef(null);
  const [deleteDialogDetails, setDeleteDialogDetails] = useState({
    isDeleteDialogOpen: false,
    deleteDialogContext: '',
    url: '',
    rowData: {},
  });
  const [permissions, setPermissions] = useState({});
  let productAttributesTab = 'productAttributesTab';
  useEffect(() => {
    // axios.get("http://localhost:8081/cba/accessLevel/initiallData/1").
    //     then(result => {
    //         myRole = result.data.selectedRole;
    //         setSelectedRole(result.data.selectedRole);
    //         setDefaultSelectedPermissions(result.data.defaultSelectedPermissions);
    //         setRoleTreeDataArray(result.data.roleTreeData);
    //         setPermissionTreeDataArray(result.data.permissionTreeeData);
    //     })
    //     .catch(error => {
    //         // notyDefault({type:notyTypes.ERROR, text:customerProperties.messages.error.deleteCustomer});
    //     });
    getActiveCategoryList();
    let permissionArray = [
      PermissionProperties.addProductAttribute,
      PermissionProperties.editProductAttribute,
      PermissionProperties.deleteProductAttribute,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(productAttributesTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  }, []);

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(productAttributesTab, newValue);
    if (newValue === 2) {
      // select add model tab
      setIsLoading(true);
      getActiveBrands(attributeDialogDetails.selectedCategory);
    }
  };

  const attributeDialogBoxCloseHandler = () => {
    setIsAttributeDialogOpen(false);
  };

  const getActiveCategoryList = () => {
    axios
      .get('getProductCategoriesByStatus/true')
      .then((result) => {
        let categoryOptions = [];
        result.data.map((cat) => {
          categoryOptions.push({ value: cat.id, displayValue: cat.name });
        });
        if (categoryOptions.length > 0) {
          let dialogDetails = { ...attributeDialogDetails };
          dialogDetails.activeCategoryList = categoryOptions;
          dialogDetails.selectedCategory = categoryOptions[0].value;
          setAttributeDialogDetails(dialogDetails);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onProductAttributeSubmit = async (values) => {
    let method = 'POST';
    let url = '';
    let data = { name: values.name, is_active: true };

    if (attributeType === attributeEnum.CATEGORY) {
      data = { ...data, is_active: true };
      if (attributeDialogDetails.isEditDialog) {
        method = 'PUT';
        url = 'updateProductCategory/' + attributeDialogDetails.selectedId;
        data['id'] = attributeDialogDetails.selectedId;
      } else {
        url = 'createProductCategory';
      }
    } else if (attributeType === attributeEnum.BRAND) {
      data = { ...data, is_active: true };
      if (attributeDialogDetails.isEditDialog) {
        method = 'PUT';
        url = 'updateProductBrand/' + attributeDialogDetails.selectedId;
        data['id'] = attributeDialogDetails.selectedId;
      } else {
        url = 'createProductBrand';
      }
      data['productCategory'] = { id: attributeDialogDetails.selectedCategory };
    } else if (attributeType === attributeEnum.MODEL) {
      data = { ...data, is_active: true };
      if (attributeDialogDetails.isEditDialog) {
        method = 'PUT';
        url = 'updateProductModel/' + attributeDialogDetails.selectedId;
        data['id'] = attributeDialogDetails.selectedId;
      } else {
        url = 'createProductModel';
      }
      data['productBrand'] = { id: attributeDialogDetails.selectedBrand };
    } else if (attributeType === attributeEnum.SUBSTATUS) {
      url = 'createProductSubStatus';
      data['generateSA'] = attributeDialogDetails.generateSA;
      data['generatePSR'] = attributeDialogDetails.generatePSR;
      attributeDialogDetails.generateSA = false;
      attributeDialogDetails.generatePSR = false;
    } else if (attributeType === attributeEnum.PART) {
      data = { ...data, is_active: true };
      if (attributeDialogDetails.isEditDialog) {
        method = 'PUT';
        url = 'createPartCategories/' + attributeDialogDetails.selectedId;
        data['id'] = attributeDialogDetails.selectedId;
      } else {
        url =
          'createPartCategories?prodCatId=' +
          attributeDialogDetails.selectedCategory;
      }
      data['productCategory'] = { id: attributeDialogDetails.selectedCategory };
    }

    setIsDialogLoading(true);
    await axios({
      method: method,
      url: url,
      data: data,
    })
      .then((result) => {
        //this.props.history.goBack();
        notyDefault({
          type: notyTypes.SUCCESS,
          text: GeneralProperties.message.success.dataSave,
        });
        setIsAttributeDialogOpen(false);
        if (
          categoryTableRef.current !== null &&
          attributeType === attributeEnum.CATEGORY
        ) {
          getActiveCategoryList();
          categoryTableRef.current.onQueryChange();
        }
        if (
          brandTableRef.current !== null &&
          attributeType === attributeEnum.BRAND
        ) {
          getActiveBrands(attributeDialogDetails.selectedCategory);
          brandTableRef.current.onQueryChange();
        }
        if (
          modelTableRef.current !== null &&
          attributeType === attributeEnum.MODEL
        ) {
          modelTableRef.current.onQueryChange();
        }
        if (
          subStatusTableRef.current !== null &&
          attributeType === attributeEnum.SUBSTATUS
        ) {
          subStatusTableRef.current.onQueryChange();
        }
        if (
          partsTableRef.current !== null &&
          attributeType === attributeEnum.PART
        ) {
          partsTableRef.current.onQueryChange();
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: GeneralProperties.message.error.dataSave,
        });
      });
    setIsDialogLoading(false);
  };

  const addProductAttribute = (type, isEdit, rowData) => {
    let dialogDetails = { ...attributeDialogDetails };
    dialogDetails.isEditDialog = isEdit;
    if (isEdit) {
      let statusString = '';
      if (type === attributeEnum.CATEGORY) {
        if (!rowData.is_active) {
          statusString += GeneralProperties.productAttribute.category;
          const message = format(
            GeneralProperties.productAttribute.message.error.statusToAcitve,
            statusString
          );
          notyDefault({ type: notyTypes.ERROR, text: message });
          return;
        }
        setAttributeType(attributeEnum.CATEGORY);
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.editCategory;
        setInitialData({ name: rowData.name });
      } else if (type == attributeEnum.BRAND) {
        if (!rowData.productCategory.is_active) {
          statusString += ' ' + GeneralProperties.productAttribute.category;
        }
        if (!rowData.is_active) {
          statusString += ' ' + GeneralProperties.productAttribute.brand;
        }

        if (statusString != '') {
          const message = format(
            GeneralProperties.productAttribute.message.error.statusToAcitve,
            statusString
          );
          notyDefault({ type: notyTypes.ERROR, text: message });
          return;
        }
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.editBrand;
        setAttributeType(attributeEnum.BRAND);
        dialogDetails.selectedCategory = rowData.productCategory.id;
        setInitialData({
          name: rowData.name,
          category: rowData.productCategory.id,
        });
      } else {
        if (!rowData.productBrand.productCategory.is_active) {
          statusString += ' ' + GeneralProperties.productAttribute.category;
        }
        if (!rowData.productBrand.is_active) {
          statusString += ' ' + GeneralProperties.productAttribute.brand;
        }

        if (!rowData.is_active) {
          statusString += ' ' + GeneralProperties.productAttribute.model;
        }

        if (statusString != '') {
          const message = format(
            GeneralProperties.productAttribute.message.error.statusToAcitve,
            statusString
          );
          notyDefault({ type: notyTypes.ERROR, text: message });
          return;
        }
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.editModel;
        dialogDetails.selectedCategory =
          rowData.productBrand.productCategory.id;
        dialogDetails.selectedBrand = rowData.productBrand.id;
        setInitialData({
          name: rowData.name,
          category: dialogDetails.selectedCategory,
          brand: dialogDetails.selectedBrand,
        });
        setAttributeType(attributeEnum.MODEL);
      }
      dialogDetails.selectedId = rowData.id;
    } else {
      if (type === attributeEnum.CATEGORY) {
        setAttributeType(attributeEnum.CATEGORY);
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.addNewCategory;
      } else if (type == attributeEnum.BRAND) {
        setAttributeType(attributeEnum.BRAND);
        if (attributeDialogDetails.activeCategoryList.length === 0) {
          notyDefault({
            type: notyTypes.ERROR,
            text: GeneralProperties.message.error.categroryNotFound,
          });
          return;
        }
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.addNewBrand;
      } else if (type == attributeEnum.MODEL) {
        if (attributeDialogDetails.activeCategoryList.length === 0) {
          notyDefault({
            type: notyTypes.ERROR,
            text: GeneralProperties.message.error.categroryNotFound,
          });
          return;
        }

        setAttributeType(attributeEnum.MODEL);
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.addNewModel;
      } else if (type == attributeEnum.SUBSTATUS) {
        if (attributeDialogDetails.activeCategoryList.length === 0) {
          notyDefault({
            type: notyTypes.ERROR,
            text: GeneralProperties.message.error.categroryNotFound,
          });
          return;
        }

        setAttributeType(attributeEnum.SUBSTATUS);
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.addNewSubStatus;
      } else if (type == attributeEnum.PART) {
        if (attributeDialogDetails.activeCategoryList.length === 0) {
          notyDefault({
            type: notyTypes.ERROR,
            text: GeneralProperties.message.error.categroryNotFound,
          });
          return;
        }
        setAttributeType(attributeEnum.PART);
        dialogDetails.attributeDialogHeader =
          GeneralProperties.productAttribute.addNewPart;
      }

      setInitialData({ name: '' });
    }
    setAttributeDialogDetails(dialogDetails);
    setIsAttributeDialogOpen(true);
  };

  const onChangeHandeler = (event, type, target) => {
    let dialogDetails = { ...attributeDialogDetails };
    let val = event.target.value;
    if (type === attributeEnum.CATEGORY) {
      dialogDetails.selectedCategory = val;
      if (attributeType == attributeEnum.MODEL) {
        setIsDialogLoading(true);
        getActiveBrands(val);
      } else {
        setAttributeDialogDetails(dialogDetails);
      }
    } else {
      dialogDetails.selectedBrand = val;
      setAttributeDialogDetails(dialogDetails);
    }

    if (type === attributeEnum.SUBSTATUS) {
      if (target.type === 'PSR') {
        dialogDetails.generatePSR = target.value;
      }
      if (target.type === 'SA') {
        dialogDetails.generateSA = target.value;
      }
    }
  };

  const getActiveBrands = async (catId) => {
    try {
      const response = await axios.get(
        'getProductBrandsByStatus?categoryId=' + catId + '&active=true'
      );
      let brandOptions = [];
      response.data.map((brand) => {
        brandOptions.push({ value: brand.id, displayValue: brand.name });
      });
      let dialogDetails = { ...attributeDialogDetails };
      dialogDetails.selectedCategory = catId;
      if (brandOptions.length > 0) {
        dialogDetails.activeBrandList = brandOptions;
        dialogDetails.selectedBrand = brandOptions[0].value;
      } else {
        dialogDetails.activeBrandList = [];
        dialogDetails.selectedBrand = '';
      }
      setAttributeDialogDetails(dialogDetails);
      setIsDialogLoading(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteProductAttribute = (type, rowData) => {
    let message = '';
    let delDetails = { ...deleteDialogDetails };
    if (type === attributeEnum.CATEGORY) {
      setAttributeType(attributeEnum.CATEGORY);
      delDetails.url = 'updateProductCategory/' + rowData.id;
    } else if (type === attributeEnum.BRAND) {
      setAttributeType(attributeEnum.BRAND);
      delDetails.url = 'updateProductBrand/' + rowData.id;
    } else if (type === attributeEnum.MODEL) {
      setAttributeType(attributeEnum.MODEL);
      delDetails.url = 'updateProductModel/' + rowData.id;
    } else if (type === attributeEnum.SUBSTATUS) {
      setAttributeType(attributeEnum.SUBSTATUS);
      delDetails.url = 'updateProductSubStatus/' + rowData.id;
    } else if (type === attributeEnum.PART) {
      setAttributeType(attributeEnum.PART);
      delDetails.url = 'updatePartCategories/' + rowData.id;
    }
    let data = { ...rowData };

    if (rowData.is_active) {
      data.is_active = false;
      message = format(
        GeneralProperties.productAttribute.message.alert.deleteAttribute,
        type
      );
    } else {
      data.is_active = true;
      message = format(
        GeneralProperties.productAttribute.message.alert.activeAttribute,
        type
      );
    }
    delDetails.deleteDialogContext = message;
    delDetails.isDeleteDialogOpen = true;
    delDetails.rowData = data;
    setDeleteDialogDetails(delDetails);
  };

  const deleteAttributeDialogCloseHandler = () => {
    let delDetails = { ...deleteDialogDetails };
    delDetails.isDeleteDialogOpen = false;
    setDeleteDialogDetails(delDetails);
  };

  const dleteAttributeConfirmHandler = async () => {
    setIsLoading(true);
    let data = { ...deleteDialogDetails.rowData };
    await axios
      .put(deleteDialogDetails.url, data)
      .then((result) => {
        notyDefault({
          type: notyTypes.SUCCESS,
          text: GeneralProperties.message.success.dataSave,
        });
        if (attributeType === attributeEnum.CATEGORY) {
          categoryTableRef.current.onQueryChange();
        } else if (attributeType === attributeEnum.BRAND) {
          brandTableRef.current.onQueryChange();
        } else {
          modelTableRef.current.onQueryChange();
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: GeneralProperties.message.error.dataSave,
        });
      });
    deleteAttributeDialogCloseHandler();
    setIsLoading(false);
  };

  const subStatusTab = () => {
    return (
      <FullWidthTabPanel value={value} index={3} dir={theme.direction}>
        <Card>
          <CardBody>
            {/* {permissions[PermissionProperties.addProductAttribute] ?
                          <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                  <div className={styles.AddProductAttributeDiv}>

                                      

                                  </div>
                              </GridItem>
                          </GridContainer> : ""
                      } */}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    tableRef={subStatusTableRef}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: '1rem',
                            marginTop: '-0.7rem',
                          }}
                        >
                          Sub Status
                        </Typography>
                        {/* <Typography variant="body1">Manage models here</Typography> */}
                      </div>
                    }
                    columns={[
                      {
                        title: '#',
                        field: 'id',
                        editable: 'never',
                        width: '10%',
                      },
                      {
                        title: 'Sub Status',
                        field: 'name',
                        editable: 'never',
                      },
                      {
                        title: 'Generate SA',
                        field: 'generateSA',
                        align: 'center',
                        editComponent: (props) => {
                          return (
                            <input
                              type="checkbox"
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          );
                        },
                        render: (rowdata) => (
                          <input type="checkbox" checked={rowdata.generateSA} />
                        ),
                      },
                      {
                        title: 'Generate PSR',
                        field: 'generatePSR',
                        align: 'center',
                        editComponent: (props) => {
                          return (
                            <input
                              type="checkbox"
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          );
                        },
                        render: (rowdata) => (
                          <input
                            type="checkbox"
                            checked={rowdata.generatePSR}
                          />
                        ),
                      },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        const filterDto = buildFilterObject(query);
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.filterProductSubStatus +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'name'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(filterDto),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            let url =
                              urlProperties.baseUrl +
                              urlProperties.updateProductSubStatus +
                              oldData.id;
                            fetch(url, {
                              headers: {
                                Authorization:
                                  'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'application/json',
                              },
                              method: 'PUT',
                              body: JSON.stringify(newData),
                            })
                              .then((response) => response.json())
                              .then((result) => {
                                resolve({
                                  data: result.data,
                                  page: result.page,
                                  totalCount: result.total,
                                });
                              });
                          }, 1000);
                        }),
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: false,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        backgroundColor: '#e6e4df',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    actions={[
                      {
                        icon: () => (
                          <div
                            className={
                              'generic-button-class div-button-wrapper'
                            }
                            style={{ borderRadius: '0.5rem' }}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              addProductAttribute(
                                attributeEnum.SUBSTATUS,
                                false
                              )
                            }
                          >
                            {GeneralProperties.productAttribute.addNewSubStatus}
                          </div>
                        ),
                        tooltip: 'Add Sub Status',
                        isFreeAction: true,
                        hidden: !permissions[
                          PermissionProperties.addProductAttribute
                        ],
                      },
                      permissions[PermissionProperties.deleteProductAttribute]
                        ? (rowData) => ({
                            icon: rowData.is_active
                              ? 'visibility_off'
                              : 'visibility',
                            iconProps: {
                              className: 'action-buttons view-button',
                            },
                            tooltip: rowData.is_active
                              ? GeneralProperties.productAttribute.makeInactive
                              : GeneralProperties.productAttribute.makeActive,
                            onClick: (event, rowData) =>
                              deleteProductAttribute(
                                attributeEnum.CATEGORY,
                                rowData
                              ),
                          })
                        : {},
                    ]}
                    icons={{
                      Edit: () => (
                        <div
                          className="action-buttons edit-button"
                          style={{
                            width: '14px',
                            height: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Edit style={{ color: '#fff', fontSize: 15 }}></Edit>
                        </div>
                      ),
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </FullWidthTabPanel>
    );
  };

  const partsTab = () => {
    return (
      <FullWidthTabPanel value={value} index={4} dir={theme.direction}>
        <Card>
          <CardBody>
            {/* {permissions[PermissionProperties.addProductAttribute] ?
                          <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                  <div className={styles.AddProductAttributeDiv}>

                                      

                                  </div>
                              </GridItem>
                          </GridContainer> : ""
                      } */}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    tableRef={partsTableRef}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: '1rem',
                            marginTop: '-0.7rem',
                          }}
                        >
                          Parts
                        </Typography>
                      </div>
                    }
                    columns={[
                      {
                        title: '#',
                        field: 'id',
                        editable: 'never',
                        width: '10%',
                      },
                      {
                        title: 'Part',
                        field: 'name',
                      },
                      {
                        title: 'Description',
                        field: 'description',
                      },
                      {
                        title: 'Product Category',
                        field: 'productCategoryName',
                        editable: 'never',
                      },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        const filterDto = buildFilterObject(query);
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.filterPartCategory +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'name'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(filterDto),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    editable={{
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          setTimeout(() => {
                            let url =
                              urlProperties.baseUrl +
                              urlProperties.updateProductSubStatus +
                              oldData.id;
                            fetch(url, {
                              headers: {
                                Authorization:
                                  'Bearer ' + localStorage.getItem('token'),
                                'Content-Type': 'application/json',
                              },
                              method: 'PUT',
                              body: JSON.stringify(newData),
                            })
                              .then((response) => response.json())
                              .then((result) => {
                                resolve({
                                  data: result.data,
                                  page: result.page,
                                  totalCount: result.total,
                                });
                              });
                          }, 1000);
                        }),
                    }}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: false,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        backgroundColor: '#e6e4df',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                    actions={[
                      {
                        icon: () => (
                          <div
                            className={
                              'generic-button-class div-button-wrapper'
                            }
                            style={{ borderRadius: '0.5rem' }}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              addProductAttribute(attributeEnum.PART, false)
                            }
                          >
                            {GeneralProperties.productAttribute.addNewPart}
                          </div>
                        ),
                        tooltip: 'Add Part',
                        isFreeAction: true,
                        hidden: !permissions[
                          PermissionProperties.addProductAttribute
                        ],
                      },
                      permissions[PermissionProperties.deleteProductAttribute]
                        ? (rowData) => ({
                            icon: rowData.is_active
                              ? 'visibility_off'
                              : 'visibility',
                            iconProps: {
                              className: 'action-buttons view-button',
                            },
                            tooltip: rowData.is_active
                              ? GeneralProperties.productAttribute.makeInactive
                              : GeneralProperties.productAttribute.makeActive,
                            onClick: (event, rowData) =>
                              deleteProductAttribute(
                                attributeEnum.PART,
                                rowData
                              ),
                          })
                        : {},
                    ]}
                    icons={{
                      Edit: () => (
                        <div
                          className="action-buttons edit-button"
                          style={{
                            width: '14px',
                            height: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Edit style={{ color: '#fff', fontSize: 15 }}></Edit>
                        </div>
                      ),
                    }}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </FullWidthTabPanel>
    );
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                {/* delete attribute dialog */}
                <Dialog
                  open={deleteDialogDetails.isDeleteDialogOpen}
                  onClose={deleteAttributeDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {deleteDialogDetails.deleteDialogContext}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteAttributeDialogCloseHandler}
                      color="primary"
                    >
                      {GeneralProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={dleteAttributeConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {GeneralProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* end of delete attribute dialog */}

                {/* dialog box for add product attributes */}
                <Dialog
                  fullWidth={true}
                  maxWidth="xs"
                  open={isAttributeDialogOpen}
                  onClose={attributeDialogBoxCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <LoadingOverlay
                    active={isDialogLoading}
                    spinner={<Spinner />}
                    text="Loading ..."
                  >
                    <DialogTitle>
                      {' '}
                      {attributeDialogDetails.attributeDialogHeader}{' '}
                    </DialogTitle>
                    <Form
                      onSubmit={onProductAttributeSubmit}
                      initialValues={initialData}
                      validate={(values, ss) => {
                        const errors = {};
                        if (!values.name) {
                          errors.name = GeneralProperties.emptyField;
                        }
                        if (
                          (attributeType == attributeEnum.BRAND ||
                            attributeType == attributeEnum.MODEL ||
                            attributeType == attributeEnum.PART) &&
                          attributeDialogDetails.selectedCategory === ''
                        ) {
                          errors.category = GeneralProperties.emptyField;
                        }
                        if (
                          attributeType == attributeEnum.MODEL &&
                          attributeDialogDetails.selectedBrand === ''
                        ) {
                          errors.brand = GeneralProperties.emptyField;
                        }
                        return errors;
                      }}
                      render={({
                        handleSubmit,
                        reset,
                        submitting,
                        pristine,
                        valid,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Field name="name">
                              {({ input, meta }) => (
                                <div style={{ position: 'relative' }}>
                                  {/* <input {...input} type="text" placeholder="Name" />  */}

                                  <CustomInput
                                    labelText="Name"
                                    id="name"
                                    value=""
                                    inputProps={{ ...input, width: '80%' }}
                                    type="input"
                                    formControlProps={{ fullWidth: true }}
                                  />
                                  {meta.error && meta.touched && (
                                    <span>{meta.error}</span>
                                  )}
                                </div>
                              )}
                            </Field>
                            {attributeType == attributeEnum.BRAND ||
                            attributeType == attributeEnum.MODEL ||
                            attributeType == attributeEnum.PART ? (
                              <Field name="category">
                                {({ input, meta, options, value }) => (
                                  <div style={{ position: 'relative' }}>
                                    <CustomInput
                                      labelText="Category"
                                      id="category"
                                      inputProps={{
                                        ...input,
                                        width: '80%',
                                        options:
                                          attributeDialogDetails.activeCategoryList,
                                        value:
                                          attributeDialogDetails.selectedCategory,
                                      }}
                                      type="select"
                                      formControlProps={{ fullWidth: true }}
                                      changed={(event, value) => {
                                        input.onChange(value);
                                        onChangeHandeler(
                                          event,
                                          attributeEnum.CATEGORY
                                        );
                                      }}
                                    />
                                    {meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            ) : (
                              ''
                            )}
                            {attributeType == attributeEnum.MODEL ? (
                              <Field name="brand">
                                {({ input, meta }) => (
                                  <div style={{ position: 'relative' }}>
                                    {/* <input {...input} type="text" placeholder="Name" />  */}

                                    <CustomInput
                                      labelText="Brand"
                                      id="brand"
                                      inputProps={{
                                        ...input,
                                        width: '80%',
                                        options:
                                          attributeDialogDetails.activeBrandList,
                                        value:
                                          attributeDialogDetails.selectedBrand,
                                      }}
                                      type="select"
                                      value=""
                                      formControlProps={{ fullWidth: true }}
                                      changed={(event, value) => {
                                        input.onChange(value);
                                        onChangeHandeler(
                                          event,
                                          attributeEnum.BRAND
                                        );
                                      }}
                                    />
                                    {meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            ) : (
                              ''
                            )}
                            {attributeType == attributeEnum.SUBSTATUS ? (
                              <Field name="generateSA">
                                {({ input, meta }) => (
                                  <div
                                    style={{
                                      position: 'relative',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    {/* <input {...input} type="text" placeholder="Name" />  */}

                                    <CustomInput
                                      labelText="Generate SA"
                                      id="generateSA"
                                      inputProps={{
                                        ...input,
                                        width: '80%',
                                      }}
                                      type="checkbox"
                                      value=""
                                      formControlProps={{ fullWidth: true }}
                                      changed={(event, value) => {
                                        input.onChange(value);
                                        onChangeHandeler(
                                          event,
                                          attributeEnum.SUBSTATUS,
                                          { value: value, type: 'SA' }
                                        );
                                      }}
                                    />
                                    {meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            ) : (
                              ''
                            )}
                            {attributeType == attributeEnum.SUBSTATUS ? (
                              <Field name="generatePSR">
                                {({ input, meta }) => (
                                  <div
                                    style={{
                                      position: 'relative',
                                      marginTop: '1rem',
                                      marginBottom: '1rem',
                                    }}
                                  >
                                    {/* <input {...input} type="text" placeholder="Name" />  */}

                                    <CustomInput
                                      labelText="Generate PSR"
                                      id="generatePSR"
                                      inputProps={{
                                        ...input,
                                        width: '80%',
                                      }}
                                      type="checkbox"
                                      value=""
                                      formControlProps={{ fullWidth: true }}
                                      changed={(event, value) => {
                                        input.onChange(value);

                                        onChangeHandeler(
                                          event,
                                          attributeEnum.SUBSTATUS,
                                          { value: value, type: 'PSR' }
                                        );
                                      }}
                                    />
                                    {meta.error && <span>{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            ) : (
                              ''
                            )}
                          </DialogContent>
                          <DialogActions>
                            <Button
                              size="sm"
                              onClick={attributeDialogBoxCloseHandler}
                              color="primary"
                            >
                              {GeneralProperties.cancel}
                            </Button>
                            <Button
                              size="sm"
                              type="submit"
                              disabled={submitting || !valid || pristine}
                              color="primary"
                              autoFocus
                            >
                              {GeneralProperties.save}
                            </Button>
                          </DialogActions>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </Dialog>
                {/* ... end of dialog box */}

                <AppBar
                  position="static"
                  color="inherit"
                  className={styles.appBarStyle}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    className={classes.tabsWrapper}
                  >
                    <Tab
                      label={GeneralProperties.productAttribute.category}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={GeneralProperties.productAttribute.brand}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={GeneralProperties.productAttribute.model}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label={GeneralProperties.productAttribute.subStatus}
                      {...a11yProps(3)}
                    />

                    <Tab
                      label={GeneralProperties.productAttribute.parts}
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </AppBar>

                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Card>
                    <CardBody>
                      {/* {permissions[PermissionProperties.addProductAttribute] ?
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className={styles.AddProductAttributeDiv}>

                                                    

                                                </div>
                                            </GridItem>
                                        </GridContainer> : ""
                                    } */}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={'material-grid-wrapper'}>
                            <MaterialTable
                              title={
                                <div>
                                  <span className={'grid-title-icon'}>
                                    <ListAltOutlinedIcon />
                                  </span>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    Categories
                                  </Typography>
                                  {/* <Typography variant="body1">Manage categories here</Typography> */}
                                </div>
                              }
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              tableRef={categoryTableRef}
                              columns={[
                                { title: 'Name', field: 'name' },
                                {
                                  title: 'Status',
                                  field: 'is_active',
                                  render: (rowData) =>
                                    rowData.is_active
                                      ? GeneralProperties.active
                                      : GeneralProperties.inactive,
                                },
                              ]}
                              data={(query) =>
                                new Promise((resolve, reject) => {
                                  const filterDto = buildFilterObject(query);
                                  let url =
                                    urlProperties.baseUrl +
                                    urlProperties.filterProductCategory;
                                  url += '?perPage=' + query.pageSize;
                                  url += '&page=' + query.page;
                                  url +=
                                    '&sortColumn=' +
                                    (typeof query.orderBy === 'undefined'
                                      ? 'name'
                                      : query.orderBy.field);
                                  url += '&sortOrder=' + query.orderDirection;
                                  url += '&search=' + query.search;
                                  fetch(url, {
                                    headers: {
                                      Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                      'Content-Type': 'application/json',
                                    },
                                    method: 'POST',
                                    body: JSON.stringify(filterDto),
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total,
                                      });
                                    });
                                })
                              }
                              actions={[
                                permissions[
                                  PermissionProperties.editProductAttribute
                                ]
                                  ? {
                                      icon: 'edit',
                                      tooltip:
                                        GeneralProperties.productAttribute
                                          .editCategory,
                                      iconProps: {
                                        className: 'action-buttons edit-button',
                                      },
                                      onClick: (event, rowData) =>
                                        addProductAttribute(
                                          attributeEnum.CATEGORY,
                                          true,
                                          rowData
                                        ),
                                    }
                                  : {},
                                permissions[
                                  PermissionProperties.deleteProductAttribute
                                ]
                                  ? (rowData) => ({
                                      icon: rowData.is_active
                                        ? 'visibility_off'
                                        : 'visibility',
                                      iconProps: {
                                        className: 'action-buttons view-button',
                                      },
                                      tooltip: rowData.is_active
                                        ? GeneralProperties.productAttribute
                                            .makeInactive
                                        : GeneralProperties.productAttribute
                                            .makeActive,
                                      onClick: (event, rowData) => {},
                                      // deleteProductAttribute(
                                      //   attributeEnum.CATEGORY,
                                      //   rowData
                                      // ),
                                    })
                                  : {},
                                {
                                  icon: () => (
                                    <div
                                      className={
                                        'generic-button-class div-button-wrapper'
                                      }
                                      style={{ borderRadius: '0.5rem' }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        addProductAttribute(
                                          attributeEnum.CATEGORY,
                                          false
                                        )
                                      }
                                    >
                                      {
                                        GeneralProperties.productAttribute
                                          .addNewCategory
                                      }
                                    </div>
                                  ),
                                  tooltip: 'Add Product Category',
                                  isFreeAction: true,
                                  hidden: !permissions[
                                    PermissionProperties.addProductAttribute
                                  ],
                                },
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  backgroundColor: '#e6e4df',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <Card>
                    <CardBody>
                      {/* {permissions[PermissionProperties.addProductAttribute] ?
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className={styles.AddProductAttributeDiv}>

                                                    

                                                </div>
                                            </GridItem>

                                        </GridContainer> : ""
                                    } */}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={'material-grid-wrapper'}>
                            <MaterialTable
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              title={
                                <div>
                                  <span className={'grid-title-icon'}>
                                    <ListAltOutlinedIcon />
                                  </span>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    Brands
                                  </Typography>
                                  {/* <Typography variant="body1">Manage brands here</Typography> */}
                                </div>
                              }
                              tableRef={brandTableRef}
                              columns={[
                                { title: 'Name', field: 'name' },
                                {
                                  title: 'Category',
                                  field: 'productCategory.name',
                                  render: (rowData) =>
                                    rowData.productCategory.name,
                                },
                                {
                                  title: 'Status',
                                  field: 'is_active',
                                  render: (rowData) =>
                                    rowData.is_active
                                      ? GeneralProperties.active
                                      : GeneralProperties.inactive,
                                },
                              ]}
                              data={(query) =>
                                new Promise((resolve, reject) => {
                                  const filterDto = buildFilterObject(query);
                                  let url =
                                    urlProperties.baseUrl +
                                    urlProperties.filterProductBrand +
                                    '?';
                                  url += 'perPage=' + query.pageSize;
                                  url += '&page=' + query.page;
                                  url +=
                                    '&sortColumn=' +
                                    (typeof query.orderBy === 'undefined'
                                      ? 'name'
                                      : query.orderBy.field);
                                  url += '&sortOrder=' + query.orderDirection;
                                  url += '&search=' + query.search;
                                  fetch(url, {
                                    headers: {
                                      Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                      'Content-Type': 'application/json',
                                    },
                                    method: 'POST',
                                    body: JSON.stringify(filterDto),
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total,
                                      });
                                    });
                                })
                              }
                              actions={[
                                {
                                  icon: 'edit',
                                  iconProps: {
                                    className: 'action-buttons edit-button',
                                  },
                                  tooltip:
                                    GeneralProperties.productAttribute
                                      .editBrand,
                                  onClick: (event, rowData) =>
                                    addProductAttribute(
                                      attributeEnum.BRAND,
                                      true,
                                      rowData
                                    ),
                                  hidden: !PermissionProperties.editProductAttribute,
                                },

                                (rowData) => ({
                                  icon: rowData.is_active
                                    ? 'visibility_off'
                                    : 'visibility',
                                  iconProps: {
                                    className: 'action-buttons view-button',
                                  },
                                  tooltip: rowData.is_active
                                    ? GeneralProperties.productAttribute
                                        .makeInactive
                                    : GeneralProperties.productAttribute
                                        .makeActive,
                                  onClick: (event, rowData) =>
                                    deleteProductAttribute(
                                      attributeEnum.BRAND,
                                      rowData
                                    ),
                                  hidden: !PermissionProperties.deleteProductAttribute,
                                }),
                                {
                                  icon: () => (
                                    <div
                                      className={
                                        'generic-button-class div-button-wrapper'
                                      }
                                      style={{ borderRadius: '0.5rem' }}
                                      variant="contained"
                                      onClick={() =>
                                        addProductAttribute(
                                          attributeEnum.BRAND,
                                          false
                                        )
                                      }
                                      color="primary"
                                    >
                                      {
                                        GeneralProperties.productAttribute
                                          .addNewBrand
                                      }
                                    </div>
                                  ),
                                  tooltip: 'Add Product Brand',
                                  isFreeAction: true,
                                  hidden: !permissions[
                                    PermissionProperties.addProductAttribute
                                  ],
                                },
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  backgroundColor: '#e6e4df',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  <Card>
                    <CardBody>
                      {/* {permissions[PermissionProperties.addProductAttribute] ?
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <div className={styles.AddProductAttributeDiv}>

                                                    

                                                </div>
                                            </GridItem>
                                        </GridContainer> : ""
                                    } */}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={'material-grid-wrapper'}>
                            <MaterialTable
                              components={{
                                OverlayLoading: () => (
                                  <div className={styles.loadingOverlay}>
                                    <Spinner />
                                  </div>
                                ),
                              }}
                              title={
                                <div>
                                  <span className={'grid-title-icon'}>
                                    <ListAltOutlinedIcon />
                                  </span>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontSize: '1rem',
                                      marginTop: '-0.7rem',
                                    }}
                                  >
                                    Models
                                  </Typography>
                                  {/* <Typography variant="body1">Manage models here</Typography> */}
                                </div>
                              }
                              tableRef={modelTableRef}
                              columns={[
                                { title: 'Name', field: 'name' },
                                {
                                  title: 'Category',
                                  field: 'productBrand.productCategory.name',
                                  render: (rowData) =>
                                    rowData.productBrand.productCategory.name,
                                },
                                {
                                  title: 'Brand',
                                  field: 'productBrand.name',
                                  isNested: true,
                                  render: (rowData) =>
                                    rowData.productBrand.name,
                                },
                                {
                                  title: 'Status',
                                  field: 'is_active',
                                  render: (rowData) =>
                                    rowData.is_active
                                      ? GeneralProperties.active
                                      : GeneralProperties.inactive,
                                },
                              ]}
                              data={(query) =>
                                new Promise((resolve, reject) => {
                                  const filterDto = buildFilterObject(query);
                                  let url =
                                    urlProperties.baseUrl +
                                    urlProperties.filterProductModel +
                                    '?';
                                  url += 'perPage=' + query.pageSize;
                                  url += '&page=' + query.page;
                                  url +=
                                    '&sortColumn=' +
                                    (typeof query.orderBy === 'undefined'
                                      ? 'name'
                                      : query.orderBy.field);
                                  url += '&sortOrder=' + query.orderDirection;
                                  url += '&search=' + query.search;
                                  fetch(url, {
                                    headers: {
                                      Authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                      'Content-Type': 'application/json',
                                    },
                                    method: 'POST',
                                    body: JSON.stringify(filterDto),
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total,
                                      });
                                    });
                                })
                              }
                              actions={[
                                {
                                  icon: 'edit',
                                  iconProps: {
                                    className: 'action-buttons edit-button',
                                  },
                                  tooltip:
                                    GeneralProperties.productAttribute
                                      .editModel,
                                  onClick: (event, rowData) =>
                                    addProductAttribute(
                                      attributeEnum.MODEL,
                                      true,
                                      rowData
                                    ),
                                  hidden: !permissions[
                                    PermissionProperties.editProductAttribute
                                  ],
                                },

                                (rowData) => ({
                                  icon: rowData.is_active
                                    ? 'visibility_off'
                                    : 'visibility',
                                  iconProps: {
                                    className: 'action-buttons view-button',
                                  },
                                  tooltip: rowData.is_active
                                    ? GeneralProperties.productAttribute
                                        .makeInactive
                                    : GeneralProperties.productAttribute
                                        .makeActive,
                                  onClick: (event, rowData) =>
                                    deleteProductAttribute(
                                      attributeEnum.MODEL,
                                      rowData
                                    ),
                                  hidden: !permissions[
                                    PermissionProperties.deleteProductAttribute
                                  ],
                                }),
                                {
                                  icon: () => (
                                    <div
                                      className={
                                        'generic-button-class div-button-wrapper'
                                      }
                                      style={{ borderRadius: '0.5rem' }}
                                      variant="contained"
                                      onClick={() =>
                                        addProductAttribute(
                                          attributeEnum.MODEL,
                                          false
                                        )
                                      }
                                      color="primary"
                                    >
                                      {
                                        GeneralProperties.productAttribute
                                          .addNewModel
                                      }
                                    </div>
                                  ),
                                  tooltip: 'Add Product Model',
                                  isFreeAction: true,
                                  hidden: !permissions[
                                    PermissionProperties.addProductAttribute
                                  ],
                                },
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                sorting: false,
                                search: false,
                                maxBodyHeight: '70vh',
                                headerStyle: {
                                  fontSize: '0.7rem',
                                  backgroundColor: '#e6e4df',
                                  textAlignL: 'center',
                                },
                                rowStyle: {
                                  fontSize: '0.6rem',
                                  fontWeight: 450,
                                  padding: '5rem',
                                },
                              }}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </FullWidthTabPanel>
                {subStatusTab()}
                {partsTab()}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ProductAttribute;
