import axios from '../axios/axios-default';
import { ServiceAgreementProperties } from 'Util/Property/ServiceAgreementProperties';
import urlProperties from 'Util/Property/UrlProperties';

export async function getActiveCategoryList() {
  try {
    return axios.get('getProductCategoriesByStatus/true');
  } catch (error) {
    console.log();
  }
}

export async function postServiceAgreements(values, sucess, fail) {
  let method = '';
  let url = '';
  let data = { ...values };
  if (data.id > 0) {
    method = 'PUT';
    url = 'updateServiceAgreements/' + data.id;
  } else {
    method = 'POST';
    url = 'createServiceAgreement';
  }

  await axios({
    method: method,
    url: url,
    data: data,
  })
    .then((result) => {
      sucess(result);
    })
    .catch((error) => {
      fail(error);
    });
}
export async function getServiceAgrementbyId(srId) {
  try {
    return axios.get('/getServiceAgreements/' + srId);
  } catch (error) {
    console.log();
  }
}

export async function getAllServiceAgreements() {
  try {
    return axios.get(urlProperties.serviceAgreement.getServiceAgreements);
  } catch (error) {
    console.log(ServiceAgreementProperties.messages.error.loadAll);
  }
}

export async function getServiceAgreementsByModel(modelId, category) {
  try {
    // return axios.get(urlProperties.serviceAgreement.getServiceAgreementsByTypeAndModel+"/"+category+"/"+modelId);
    return axios.get(
      urlProperties.serviceAgreement.getServiceAgreementsByModel + '/' + modelId
    );
  } catch (error) {
    console.log(ServiceAgreementProperties.messages.error.loadAll);
  }
}

export async function getServiceAgreementsByCategory(categoryId) {
  try {
    return axios.get(
      urlProperties.serviceAgreement.getServiceAgreementsByCategory +
        '/' +
        categoryId
    );
  } catch (error) {
    console.log(ServiceAgreementProperties.messages.error.loadAll);
  }
}

export async function getServiceAgreementTypes() {
  try {
    return axios.get(urlProperties.serviceAgreement.getServiceAgreementTypes);
  } catch (error) {
    console.log(ServiceAgreementProperties.messages.error.loadAll);
  }
}

export async function getProductSubStatusData() {
  try {
    return axios.get(urlProperties.product.getProductSubStatuses);
  } catch (error) {
    console.log(
      ServiceAgreementProperties.messages.error.loadProductSubStatuses
    );
  }
}

// const getActiveCategoryList = () => {

//     axios.get("getProductCategoriesByStatus/true").then(result => {
//         let categoryOptions = []
//         result.data.map(cat => {
//             categoryOptions.push({ value: cat.id, displayValue: cat.name });
//         });
//         if (categoryOptions.length > 0) {
//             let dialogDetails = { ...attributeDialogDetails }
//             dialogDetails.activeCategoryList = categoryOptions;
//             dialogDetails.selectedCategory = categoryOptions[0].value;
//             setAttributeDialogDetails(dialogDetails);
//         }
//         setIsLoading(false);
//     }).catch(error => {
//         setIsLoading(false);
//     }
//     );
// }
