/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dropzone from "react-dropzone";
import axios from "../../axios/axios-default";
import { notyDefault, notyTypes } from "../../components/Noty/NotyCustom";
import readXlsxFile from "read-excel-file";
import { Typography } from "@material-ui/core";
import { customerProperties } from "Util/Property/CustomerPorerties";
import * as moment from "moment";
import writeXlsxFile from "write-excel-file";
import UrlProperties from "Util/Property/UrlProperties";
import LoadingOverlay from "react-loading-overlay";
import MaterialTable from "material-table";
import Lottie from "react-lottie";
import animationData from "../../assets/onsiteAnimation.json";
import { Row } from "rsuite";
import ReportView2 from "./ReportView2";
import _ from "lodash";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
export default function ReportView1(props) {
  const {
    open,
    fileUploadClose,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    url,
    uploadLoacation,
    acceptFileType,
    type,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [dataArray, setDataArray] = React.useState([]);
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileUpload2, setOpenFileUpload2] = useState(false);
  const [type1, setType1] = useState("");

  const baseUrl = UrlProperties.baseUrl.split(":8089")[0];

  const handleClose = () => {
    // setFile([]);
    fileUploadClose();
  };
  const fileUploadClose1 = () => {
    setOpenFileUpload2(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [file]);

  // const viewButtonClick = (event, rowData)=>{
  //   setSelectedData({
  //   num:rowData.num,
  //   srtype: rowData.srtype,
  //   srcount: rowData.srcount,
  //   avgtimetocomplete: Row.avgtimetocomplete,
  //   target: rowData.target
  //   })
  // }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "140px",
    width: "400px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#B22900",
    borderStyle: "dashed",
    backgroundColor: "#ffcec7",
    color: "#f73123",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() +
    1}/${current.getFullYear()}`;
  return (
    <div>
      <ReportView2
        open={openFileUpload2}
        type={type}
        fileUploadClose={fileUploadClose1}
      />

      {/* <MaterialTable
                          title="Today - Efficiency Summary - Time"
                          columns={[
                            { title: "#", field: "num" , headerStyle:{padding:"5px", textAlign:'left'}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                            { title: "SR Type", field: "srtype" , headerStyle:{padding:"5px", textAlign:'left'}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                            { title: "SR Count", field: "srcount" , headerStyle:{padding:"5px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                            { title: "AVG Time to Complete (Days)", field: "avgtimetocomplete" , headerStyle:{padding:"5px"}, cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                            { title: "Target/ KPI (Days)", field: "target", headerStyle:{padding:"5px"} , cellStyle:{textAlign:'left',paddingLeft:"0px"}},
                          ]}
                          data={[
                              { num: '1', srtype: 'Breakdown', srcount: 20, avgtimetocomplete: 2 , target: 2}, 
                              { num: '2', srtype: 'Services', srcount: 28, avgtimetocomplete: 3, target:2 },
                              { num: '3', srtype: 'Sales', srcount: 3, avgtimetocomplete: 10, target: 11 }]}
                              options={{
                            actionsColumnIndex: -1,
                            // selection: true,
                            exportButton: true,
                            selectionProps: rowData => {
                              // const selectable = rowData.assignedTo === null;
                              // //NOTE:: tableData object is not in documentation
                              // rowData.tableData.disabled = !selectable;
                              // return {
                              //     disabled: !selectable
                              // }
                            },
                            // pageSize: 10,
                            // filtering: true,
                            //maxBodyHeight:"85vh",
                            sorting: false,
                            search: false
                          }}
                          /> */}

      {type == "ON_SITE_TECHNICIANS_SUMMARY_REPORT" ? (
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={isLoading} spinner text="Loading ...">
            {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
            <DialogContent>
              {
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={"material-grid-wrapper"}>
                      <b>
                        <center>From:2022-06-11 To:2022-07-02</center>
                      </b>
                      <MaterialTable
                        title={
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                            >
                              On Site Field Agent Report
                            </Typography>
                          </div>
                        }
                        columns={[
                          {
                            title: "Attended Field Agent",
                            field: "technicianName",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Attend Date",
                            width: "10%",
                            field: "assignedDate",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Day Start",
                            width: "10%",
                            field: "startTime",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Day End",
                            width: "10%",
                            field: "endTime",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Assigned",
                            width: "10%",
                            field: "assigned",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "0px 0px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Not Attend",
                            width: "10%",
                            field: "active",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "0px 0px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "On Site",
                            width: "5%",
                            field: "onSite",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "0px 0px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                            render: (row) => (
                              <div>
                                {row.onSite == "On Site" ? (
                                  <center>
                                    <Lottie
                                      options={defaultOptions}
                                      height={25}
                                      width={25}
                                      isStopped={false}
                                      isPaused={false}
                                    />
                                  </center>
                                ) : (
                                  "Off Site"
                                )}
                              </div>
                            ),
                          },
                          {
                            title: "Completed",
                            width: "10%",
                            field: "completed",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "0px 0px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          // { title: "Pending", field: "pending", headerStyle:{padding:"0px 10px", textAlign:'center'}, cellStyle:{textAlign:'center',paddingLeft:"0px"}},
                          {
                            title: "Incomplete",
                            width: "10%",
                            field: "incomplete",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "0px 0px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          // { title: "Job Count", field: "countTotalJob" , headerStyle:{padding:"0px 0px"}, cellStyle:{textAlign:'center',paddingLeft:"0px"}}
                        ]}
                        data={[
                          {
                            technicianId: "21",
                            technicianName: "Layantha Lakranda",
                            assignedDate: ["05/01/2022"],
                            startTime: "10:15:30",
                            endTime: "14:00:15",
                            assigned: "4",
                            active: "0",
                            onSite: "On Site",
                            completed: "2",
                            pending: "1",
                            incomplete: "0",
                          },
                          {
                            technicianId: "15",
                            technicianName: "Tharaka Dhanushka",
                            assignedDate: "05/01/2022",
                            startTime: "14:20:30",
                            endTime: "17:15:00",
                            assigned: "2",
                            active: "1",
                            onSite: "On Site",
                            completed: "0",
                            pending: "0",
                            incomplete: "0",
                          },
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          // selection: true,
                          exportButton: true,
                          selectionProps: (rowData) => {
                            // const selectable = rowData.assignedTo === null;
                            // //NOTE:: tableData object is not in documentation
                            // rowData.tableData.disabled = !selectable;
                            // return {
                            //     disabled: !selectable
                            // }
                          },
                          pageSize: 5,
                          // filtering: true,
                          //maxBodyHeight:"85vh",
                          sorting: false,
                          search: false,
                          padding: "dense",
                          maxBodyHeight: "70vh",
                          headerStyle: {
                            fontSize: "0.7rem",
                            textAlignL: "center",
                          },
                          rowStyle: {
                            fontSize: "0.6rem",
                            fontWeight: 450,
                            padding: "5rem",
                          },
                        }}
                        // options={{
                        //   exportButton: true
                        // }}
                        onRowClick={
                          (event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload2(true);
                          }
                          // Get your id from rowData and use with link.
                          //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                          //event.stopPropagation();
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              }
            </DialogContent>
            <DialogActions>
              {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

              <Button
                className={"generic-button-class div-button-wrapper"}
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
      ) : //           : type =="sr_reports"?
      //           <Dialog
      // maxWidth="md"
      // fullWidth={true}
      //     open={open}
      //     onClose={handleClose}
      //     aria-labelledby="alert-dialog-title"
      //     aria-describedby="alert-dialog-description"
      // >
      //     <LoadingOverlay
      //         active={isLoading}
      //         spinner
      //         text='Loading ...'>
      //         {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
      //         <DialogContent>
      //         {
      //           <GridContainer>
      //           <GridItem xs={12} sm={12} md={12}>
      //           <div className={"material-grid-wrapper"}>
      //               <MaterialTable
      //               title="SR - History Reports"

      //               columns={[
      //                 { title: "Field Agent", field: "fieldagent" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{ textAlign:'center'}},
      //                 { title: "Assign Date", field: "assigndate" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Day Start", field: "daystart" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Day End", field: "avgtimetocomplete" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',  textAlign:'center'}},
      //                 { title: "Assigned", field: "kpi",  headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Not Attend", field: "num" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Attended-Delay", field: "srtype" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Attended-Not Delay", field: "srcount" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px', textAlign:'center'}},
      //                 { title: "Complete", field: "avgtimetocomplete" , headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'}, cellStyle:{fontSize:'14px',  textAlign:'center'}},
      //                 { title: "Incomplete", field: "kpi",  headerStyle:{backgroundColor: '#e6e4df', padding:"5px", textAlign:'center'} , cellStyle:{fontSize:'14px', textAlign:'center'}}

      //               ]}
      //               data={[
      //                 { num: 1, srtype: 'Breakdown', srcount: 20, avgtimetocomplete: 2 , kpi: 2},
      //                 { num: 2, srtype: 'Services', srcount: 28, avgtimetocomplete: 3, kpi:2 },
      //                 { num: 3, srtype: 'Sales', srcount: 3, avgtimetocomplete: 10, kpi: 11 }
      //               ]}

      //               options={
      //                 {
      //                 actionsColumnIndex: -1,
      //                 // selection: true,
      //                 exportButton: true,
      //                 selectionProps: rowData => {
      //                   // const selectable = rowData.assignedTo === null;
      //                   // //NOTE:: tableData object is not in documentation
      //                   // rowData.tableData.disabled = !selectable;
      //                   // return {
      //                   //     disabled: !selectable
      //                   // }
      //                 },
      //                 pageSize: 5,
      //                 // filtering: true,
      //                 //maxBodyHeight:"85vh",
      //                 sorting: false,
      //                 search: false,
      //                 padding: "dense"
      //               }}

      //               // options={{
      //               //   exportButton: true
      //               // }}
      //               onRowClick={(event, rowData, toggleDetailPanel) => {                          //setType1(this.type)
      //               setOpenFileUpload2(true)
      //              }
      //                 // Get your id from rowData and use with link.
      //                 //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
      //                 //event.stopPropagation();
      //               }

      //             /> </div></GridItem></GridContainer>
      //             }</DialogContent></LoadingOverlay></Dialog>

      type == "Efficiency_Summary_Time" ? (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={isLoading} spinner text="Loading ...">
            {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
            <DialogContent>
              {
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={"material-grid-wrapper"}>
                      <b>
                        <center>From:2022-06-11 To:2022-07-02</center>
                      </b>
                      <MaterialTable
                        title={
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                            >
                              Efficiency Summary - Time
                            </Typography>
                          </div>
                        }
                        columns={[
                          {
                            title: "#",
                            width: "10%",
                            field: "num",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "SR Type",
                            width: "20%",
                            field: "srtype",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "SR Count",
                            width: "25%",
                            field: "srcount",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Age (Days)",
                            width: "30%",
                            field: "avgtimetocomplete",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                            // eslint-disable-next-line react/display-name
                            render: (rowData) => (
                              <div
                                style={{
                                  width: "20%",
                                  marginLeft: "40%",
                                  background:
                                    rowData["avgtimetocomplete"] <
                                    rowData["kpi"]
                                      ? "#92D050"
                                      : rowData["avgtimetocomplete"] >
                                        rowData["kpi"]
                                      ? " #FF3300"
                                      : "#FFC000",
                                }}
                              >
                                {rowData["avgtimetocomplete"]}
                              </div>
                            ),
                          },
                          {
                            title: "KPI",
                            width: "25%",
                            field: "kpi",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                        ]}
                        data={[
                          {
                            num: 1,
                            srtype: "Breakdown",
                            srcount: 20,
                            avgtimetocomplete: 2,
                            kpi: 2,
                          },
                          {
                            num: 2,
                            srtype: "Services",
                            srcount: 28,
                            avgtimetocomplete: 3,
                            kpi: 2,
                          },
                          {
                            num: 3,
                            srtype: "Sales",
                            srcount: 3,
                            avgtimetocomplete: 10,
                            kpi: 11,
                          },
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          // selection: true,
                          exportButton: true,
                          selectionProps: (rowData) => {
                            // const selectable = rowData.assignedTo === null;
                            // //NOTE:: tableData object is not in documentation
                            // rowData.tableData.disabled = !selectable;
                            // return {
                            //     disabled: !selectable
                            // }
                          },
                          pageSize: 5,
                          // filtering: true,
                          //maxBodyHeight:"85vh",
                          sorting: false,
                          search: false,
                          padding: "dense",
                          maxBodyHeight: "70vh",
                          headerStyle: {
                            fontSize: "0.7rem",
                            textAlignL: "center",
                          },
                          rowStyle: {
                            fontSize: "0.6rem",
                            fontWeight: 450,
                            padding: "5rem",
                          },
                        }}
                        // options={{
                        //   exportButton: true
                        // }}
                        onRowClick={
                          (event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload2(true);
                          }
                          // Get your id from rowData and use with link.
                          //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                          //event.stopPropagation();
                        }
                      />{" "}
                    </div>
                  </GridItem>
                </GridContainer>
              }
            </DialogContent>
            <DialogActions>
              {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

              <Button
                className={"generic-button-class div-button-wrapper"}
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
      ) : type == "Efficiency_Summary_Count" ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={isLoading} spinner text="Loading ...">
            {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
            <DialogContent>
              {
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={"material-grid-wrapper"}>
                      <b>
                        <center>From:2022-06-11 To:2022-07-02</center>
                      </b>
                      <MaterialTable
                        size="xl"
                        title={
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                            >
                              Efficiency Summary - Count
                            </Typography>
                          </div>
                        }
                        columns={[
                          {
                            title: "#",
                            field: "num",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "SR Type",
                            field: "srtype",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "SR Count",
                            field: "srcount",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Completions",
                            field: "completions",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Completion%",
                            field: "completion",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "New",
                            field: "new",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Assigned",
                            field: "assigned",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Customer_Visit",
                            field: "customervisit",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Inprogress",
                            field: "inprogress",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Complete",
                            field: "complete",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Hold",
                            field: "hold",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Process 1",
                            field: "approvalprocess1",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Process 2",
                            field: "approvalprocess2",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                        ]}
                        data={[
                          {
                            num: "1",
                            srtype: "Breakdown",
                            srcount: 10,
                            completions: 1,
                            completion: 10,
                            new: 10,
                            assigned: 8,
                            customervisit: 5,
                            inprogress: 5,
                            complete: 4,
                            hold: 1,
                            approvalprocess1: 1,
                            approvalprocess2: 1,
                          },
                          {
                            num: "2",
                            srtype: "Services",
                            srcount: 7,
                            completions: 1,
                            completion: 14,
                            new: 7,
                            assigned: 5,
                            customervisit: 2,
                            inprogress: 2,
                            complete: 1,
                            hold: 1,
                            approvalprocess1: "-",
                            approvalprocess2: "-",
                          },
                          {
                            num: "3",
                            srtype: "Sales",
                            srcount: 10,
                            completions: 5,
                            completion: 50,
                            new: 10,
                            assigned: 8,
                            customervisit: 7,
                            inprogress: 7,
                            complete: 5,
                            hold: 2,
                            approvalprocess1: 2,
                            approvalprocess2: "-",
                          },
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          // selection: true,
                          exportButton: true,
                          selectionProps: (rowData) => {
                            // const selectable = rowData.assignedTo === null;
                            // //NOTE:: tableData object is not in documentation
                            // rowData.tableData.disabled = !selectable;
                            // return {
                            //     disabled: !selectable
                            // }
                          },
                          pageSize: 5,
                          // filtering: true,
                          //maxBodyHeight:"85vh",
                          sorting: false,
                          search: false,
                          padding: "dense",
                          maxBodyHeight: "70vh",
                          headerStyle: {
                            fontSize: "0.7rem",
                            textAlignL: "center",
                          },
                          rowStyle: {
                            fontSize: "0.6rem",
                            fontWeight: 450,
                            padding: "5rem",
                          },
                        }}
                        // options={{
                        //   exportButton: true
                        // }}
                        onRowClick={
                          (event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload2(true);
                          }
                          // Get your id from rowData and use with link.
                          //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                          //event.stopPropagation();
                        }
                      />{" "}
                    </div>
                  </GridItem>
                </GridContainer>
              }
            </DialogContent>
            <DialogActions>
              {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

              <Button
                className={"generic-button-class div-button-wrapper"}
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
      ) : type == "Average_field_Cost" ? (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <LoadingOverlay active={isLoading} spinner text="Loading ...">
            {/* <DialogTitle style={{ color: '#f73123', height: '20px' }} id="alert-dialog-title">{"File Upload"}</DialogTitle> */}
            <DialogContent>
              {
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={"material-grid-wrapper"}>
                      <b>
                        <center>From:2022-06-11 To:2022-07-02</center>
                      </b>
                      <MaterialTable
                        title={
                          <div>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1rem", marginTop: "-0.7rem" }}
                            >
                              Average field Cost
                            </Typography>
                          </div>
                        }
                        columns={[
                          {
                            title: "#",
                            field: "num",
                            width: "1px",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Department",
                            width: "10%",
                            field: "department",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Total Field Visits Count",
                            field: "totalfieldvisitscount",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Total Cost Per Field Job",
                            field: "totalcostperfieldjob",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Total Cost Per Travelling",
                            field: "totalcostpertravelling",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "Total Cost Per Idling Time",
                            field: "totalcostperidlingtime",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "AVG Cost",
                            width: "10%",
                            field: "avgcost",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                          {
                            title: "AVG Cost Per Field Jobs",
                            field: "avgcostperfieldjobs",
                            headerStyle: {
                              backgroundColor: "#e6e4df",
                              padding: "5px",
                              textAlign: "center",
                            },
                            cellStyle: { textAlign: "center" },
                          },
                        ]}
                        data={[
                          {
                            num: "1",
                            department: "Sales",
                            totalfieldvisitscount: 150,
                            totalcostperfieldjob: 15000.0,
                            totalcostpertravelling: 10000.0,
                            totalcostperidlingtime: 2500.0,
                            avgcost: 27500.0,
                            customervisit: 5,
                            inprogress: 5,
                            complete: 4,
                            hold: 1,
                            approvalprocess1: 1,
                            avgcostperfieldjobs: 183.33,
                          },
                          {
                            num: "2",
                            department: "Technical",
                            totalfieldvisitscount: 75,
                            totalcostperfieldjob: 12500.0,
                            totalcostpertravelling: 9800.0,
                            totalcostperidlingtime: 3500.0,
                            avgcost: 25800.0,
                            customervisit: 2,
                            inprogress: 2,
                            complete: 1,
                            hold: 1,
                            approvalprocess1: "-",
                            avgcostperfieldjobs: 344.0,
                          },
                          {
                            num: "3",
                            department: "Transport",
                            totalfieldvisitscount: 230,
                            totalcostperfieldjob: 25300.0,
                            totalcostpertravelling: 17460.0,
                            totalcostperidlingtime: 4500.0,
                            avgcost: 47260.0,
                            customervisit: 7,
                            inprogress: 7,
                            complete: 5,
                            hold: 2,
                            approvalprocess1: 2,
                            avgcostperfieldjobs: 205.48,
                          },
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          // selection: true,
                          exportButton: true,
                          selectionProps: (rowData) => {
                            // const selectable = rowData.assignedTo === null;
                            // //NOTE:: tableData object is not in documentation
                            // rowData.tableData.disabled = !selectable;
                            // return {
                            //     disabled: !selectable
                            // }
                          },
                          pageSize: 5,
                          // filtering: true,
                          //maxBodyHeight:"85vh",
                          sorting: false,
                          search: false,
                          padding: "dense",
                          maxBodyHeight: "70vh",
                          headerStyle: {
                            fontSize: "0.7rem",
                            textAlignL: "center",
                          },
                          rowStyle: {
                            fontSize: "0.6rem",
                            fontWeight: 450,
                            padding: "5rem",
                          },
                        }}
                        // options={{
                        //   exportButton: true
                        // }}
                        onRowClick={
                          (event, rowData, toggleDetailPanel) => {
                            //setType1(this.type)
                            setOpenFileUpload2(true);
                          }
                          // Get your id from rowData and use with link.
                          //window.open(this.props.match.url+`/onSiteTecDetail/${rowData.technicianId}/${rowData.assignedDate}`, "_blank")
                          //event.stopPropagation();
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              }
            </DialogContent>
            <DialogActions>
              {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

              <Button
                className={"generic-button-class div-button-wrapper"}
                onClick={handleClose}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
            </DialogActions>
          </LoadingOverlay>
        </Dialog>
      ) : null}

      {/* {uploadLoacation === "customer" ? 
                            <Button  color= '#B22900'  title= 'Download Customer Template' href={(`${baseUrl}/public/documents/customers.xlsx`)} target="_blank">
                                <text style={{color:'#B22900', textDecorationLine:'underline'}}> Download Customer Template</text>         
                            </Button>	
                        :""}																														 */}

      {/* <Button className={'generic-button-class div-button-wrapper'} onClick={(e) => {upload(e)}} color="primary" variant="contained" autoFocus >
                            Uplaod
                        </Button> */}
    </div>
  );
}
