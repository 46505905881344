/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import customerReducer from './store/reducers/customer';
import expenseCategoriesReducer from './store/reducers/expenseCategoriesReducer';
import ClaimsManagement from './store/reducers/claimsManagement';

import 'assets/css/material-dashboard-react.css?v=1.8.0';
import AuthReducer from './store/reducers/auth';
import App from './App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import expenseRulesReducer from './store/reducers/expenseRulesReducer';
import ReportsReducer from './store/reducers/ReportsReducer';
import FuelRatesReducer from './store/reducers/fuelRatesReducer';
import SearchReducer from './store/reducers/search';

const rootReduecer = combineReducers({
  customer: customerReducer,
  auth: AuthReducer,
  claimExpenseSettings: expenseCategoriesReducer,
  claimRules: expenseRulesReducer,
  claimsManagement: ClaimsManagement,
  reportFilter: ReportsReducer,
  FuelRatesReducer: FuelRatesReducer,
  search: SearchReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReduecer,
  composeEnhancers(applyMiddleware(thunk))
);

//const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);
