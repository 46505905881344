import * as ActionTypes from '../../store/actions/claimsManagementActionTypes';
import { UPDATE_CLAIM_REPORT_START } from '../../store/actions/claimsManagementActionTypes';

const initialState = {
  users: {
    data: [],
    isFetching: false,
    error: null,
  },
  claimsReports: {
    data: [],
    isLoading: false,
    error: null,
  },
  claimsReportData: {
    data: {},
    isFetching: false,
    error: null,
  },
  claimsReportUpdate: {
    data: {},
    isLoading: false,
    error: null,
    success: true,
  },
  claimsReportCreate: {
    data: {},
    isLoading: false,
    error: null,
    success: true,
  },
  claimReportGenerate: {
    data: {},
    isLoading: false,
    error: null,
    success: true,
  },
  claimReportSend: {
    data: {},
    isLoading: false,
    error: null,
    success: true,
  },
  routeOptionsData: {
    data: {},
    isLoading: false,
    error: null,
    success: true,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_USERS_START:
      return {
        ...state,
        users: {
          data: [],
          isFetching: true,
          error: null,
        },
      };
    case ActionTypes.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: {
          data: action.payload,
          isFetching: false,
          error: null,
        },
      };
    case ActionTypes.GET_ALL_USERS_ERROR:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: false,
          error: action.payload,
        },
      };
    case ActionTypes.CREATE_CLAIM_REPORT_START:
      return {
        ...state,
        claimsReportCreate: {
          data: {},
          isLoading: true,
          error: null,
          success: true,
        },
      };
    case ActionTypes.GET_CLAIM_REPORT_START:
      return {
        ...state,
        claimsReportData: {
          ...state.claimsReportData,
          isFetching: true,
          error: null,
        },
      };
    case ActionTypes.CREATE_CLAIM_REPORT_SUCCESS:
      return {
        ...state,
        claimsReportCreate: {
          data: action.payload,
          isLoading: false,
          error: null,
          success: true,
        },
      };
    case ActionTypes.GET_CLAIM_REPORT_SUCCESS:
      return {
        ...state,
        claimsReportData: {
          data: action.payload,
          isFetching: false,
          error: null,
        },
      };
    case ActionTypes.CREATE_CLAIM_REPORT_ERROR:
      return {
        ...state,
        claimsReportCreate: {
          data: {},
          isLoading: false,
          error: action.payload,
          success: false,
        },
      };
    case ActionTypes.GET_CLAIM_REPORT_ERROR:
      return {
        ...state,
        claimsReportData: {
          ...state.claimsReportData,
          isFetching: false,
          error: action.payload,
        },
      };
    case ActionTypes.GET_ALL_CLAIM_REPORTS_START:
      return {
        ...state,
        claimsReports: {
          data: [],
          isLoading: true,
          error: null,
        },
      };
    case ActionTypes.GET_ALL_CLAIM_REPORTS_ERROR:
      return {
        ...state,
        claimsReports: {
          data: [],
          isFetching: false,
          error: action.payload,
        },
      };
    case ActionTypes.GET_ALL_CLAIM_REPORTS_SUCCESS:
      return {
        ...state,
        claimsReports: {
          data: action.payload,
          isFetching: false,
          error: null,
        },
      };
    case ActionTypes.UPDATE_CLAIM_REPORT_START:
      return {
        ...state,
        claimsReportUpdate: {
          data: {},
          isLoading: true,
          error: null,
          success: true,
        },
      };
    case ActionTypes.UPDATE_CLAIM_REPORT_SUCCESS:
      return {
        ...state,
        claimsReportUpdate: {
          data: {},
          isLoading: false,
          error: null,
          success: true,
        },
        claimsReportData: {
          ...state.claimsReportData,
          data: action.payload,
        },
      };
    case ActionTypes.UPDATE_CLAIM_REPORT_ERROR:
      return {
        ...state,
        claimsReportUpdate: {
          data: {},
          isLoading: false,
          error: action.payload,
          success: false,
        },
      };
    case ActionTypes.GET_CLAIM_ROUTE_OPTIONS_SUCCESS:
      return {
        ...state,
        routeOptionsData: {
          data: action.payload,
          isFetching: false,
          error: null,
        },
      };
    case ActionTypes.GET_CLAIM_ROUTE_OPTIONS_ERROR:
      return {
        ...state,
        routeOptionsData: {
          ...state.routeOptionsData,
          isFetching: false,
          error: action.payload,
        },
      };
    case ActionTypes.GENEARATE_CLAIM_REPORT_START:
      return {
        ...state,
        claimReportGenerate: {
          data: {},
          isLoading: true,
          error: null,
          success: true,
        },
      };
    case ActionTypes.GENEARATE_CLAIM_REPORT_SUCCESS:
      return {
        ...state,
        claimReportGenerate: {
          data: action.payload,
          isLoading: false,
          error: null,
          success: true,
        },
      };
    case ActionTypes.GENEARATE_CLAIM_REPORT_ERROR:
      return {
        ...state,
        claimReportGenerate: {
          data: {},
          isLoading: false,
          error: action.payload,
          success: false,
        },
      };
    case ActionTypes.SEND_CLAIM_REPORT_START:
      return {
        ...state,
        claimReportSend: {
          data: {},
          isLoading: true,
          error: null,
          success: true,
        },
      };
    case ActionTypes.SEND_CLAIM_REPORT_SUCCESS:
      return {
        ...state,
        claimReportSend: {
          data: action.payload,
          isLoading: false,
          error: null,
          success: true,
        },
      };
    case ActionTypes.SEND_CLAIM_REPORT_ERROR:
      return {
        ...state,
        claimReportSend: {
          data: {},
          isLoading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
