const formatCustomerObject = (customerObj) => {
    const { id, customerName, customerLatitude, customerLongitude } = customerObj;
    return {
        id,
        name: customerName,
        location: {
            lat: customerLatitude,
            lng: customerLongitude
        }
    }
};

export const formatCustomersList = (customerObjList) => {
    return customerObjList.map(customerObj => formatCustomerObject(customerObj));
}