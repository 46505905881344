export const vehicleOwnerShipArray = [
  { value: 'OWN', displayValue: 'Own' },
  { value: 'COMPANY', displayValue: 'Company' },
  { value: 'RENTED', displayValue: 'Rented' },
];
const fuelRateProperties = {
  messages: {
    error: {
      loadData: 'Could not load fuel rate data please try again!',
      saveData: 'Could not save fuel rate data please try again!',
      deleteFuelRate: 'Could not deactivate fuel rate please try again!',
      invalidRulesData: 'Data entered for rules are invalid!',
      invalidSetupData: 'Invalid Data!',
    },
    success: {
      saveData: 'Fuel Rate details saved successfully!',
      deleteFuelRate: 'Fuel Rate deactivated successfully!',
    },
    normal: {
      deleteFuelRate: 'Do you want to deactivate this fuel rate?',
    },
  },
  FuelRatesForm: {
    name: {
      elType: 'input',
      label: 'Fuel Rate Name',
      inputProps: {
        type: 'text',
        placeholder: 'Fuel Rate Name',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    vehicleType: {
      elType: 'select',
      label: 'Vehicle Type',
      inputProps: {
        options: [],
        width: '100%',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
    },
    ownershipType: {
      elType: 'select',
      label: 'Vehicle Ownership',
      inputProps: {
        options: vehicleOwnerShipArray,
        width: '100%',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
    },
    rate: {
      elType: 'input',
      label: 'Rate',
      inputProps: {
        type: 'number',
        placeholder: 'Rate',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: true,
      valid: false,
      touched: false,
      labelProps: {
        required: true,
        error: false,
        errormessage: '',
      },
    },
    is_active: {
      elType: 'switch',
      label: 'Active',
      inputProps: {
        type: 'switch',
        placeholder: 'Active',
        value: true,
        initvalue: true,
      },
      formControlProps: {
        fullWidth: false,
      },
      validations: {
        required: false,
      },
      isFullWidth: true,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
        style: { display: 'flex', alignItems: 'center' },
      },
      gridStyle: { display: 'flex' },
    },
  },
  actionText: {
    view: 'View',
    edit: 'Edit',
    add: 'Add',
  },
  actionType: {
    view: 'view',
    edit: 'edit',
    add: 'add',
  },
};
export default fuelRateProperties;
