import urlProperties from 'Util/Property/UrlProperties';
import axios from 'axios/axios-default';
import { statusProperties } from 'Util/Property/StatusProperties';

export async function getStatusByFormAndSRCategory(
  form,
  serviceRequestCategoryId
) {
  try {
    return axios.get(
      urlProperties.status.getStatusByFormAndSRCategory +
        '?form=' +
        form +
        '&serviceRequestCategoryId=' +
        serviceRequestCategoryId
    );
  } catch (error) {
    console.log(statusProperties.messages.error.loadAll);
  }
}

export async function getStatusByForm(form) {
  try {
    return axios.get(urlProperties.status.getStatusByForm + '?form=' + form);
  } catch (error) {
    console.log(statusProperties.messages.error.loadAll);
  }
}

export async function getStatusByFormAndSubCategory(form, subCategory) {
  // if(typeof subCategory === 'undefined'){
  //     return axios.get("getWorkflowStatusByTypeAndSubType?type="+form);
  // }else{
  //     return axios.get("getWorkflowStatusByTypeAndSubType?type="+form+"&subtype="+subCategory);
  // }
  try {
    return axios.get('getWorkflowStatusByType?type=' + form);
  } catch (error) {
    console.log(statusProperties.messages.error.loadAll);
  }
}
