import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import { FaSearch } from 'react-icons/fa';
import { connect } from 'react-redux';
import * as actions from 'store/actions/index';
import Avatar from '@material-ui/core/Avatar';
import Spinner from 'components/Spinner/Spinner.js';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import urlProperties from 'Util/Property/UrlProperties';
import { makeStyles } from '@material-ui/core/styles';
import classes from './SearchDialog.module.css';
import { Link } from 'react-router-dom';

const searchTypes = [
  {
    value: 'SERVICE_REQUEST',
    displayValue: 'Service Request',
    placeholder: 'Search by Service Request Id...',
    filterObj: {
      name: '',
    },
  },
  {
    value: 'SERVICE_AGREEMENT',
    displayValue: 'Service Agreement',
    placeholder: 'Search by Service Agreement Id...',
    filterObj: {
      id: '',
    },
  },
  {
    value: 'ESTIMATION',
    displayValue: 'Estimation',
    placeholder: 'Search by Estimation Id...',
    filterObj: {
      id: '',
    },
  },
  {
    value: 'USER',
    displayValue: 'User',
    placeholder: 'Search by User Name...',
    filterObj: {
      user_name: '',
    },
  },
  {
    value: 'CUSTOMER',
    displayValue: 'Customer',
    placeholder: 'Search by Customer Name...',
    filterObj: {
      name: '',
    },
  },
  {
    value: 'CLAIMS',
    displayValue: 'Claims',
    placeholder: 'Search by Employee Name...',
    filterObj: {
      employee_name: '',
    },
  },
  // {
  //   value: 'LEAVE',
  //   displayValue: 'Leave',
  //   placeholder: 'Search by Employee Name...',
  //   filterObj: {
  //     id: '',
  //   },
  // },
  {
    value: 'PRODUCT',
    displayValue: 'Product',
    placeholder: 'Search by Serial No...',
    filterObj: {
      serial: '',
    },
  },
];

const seachtItemsList = [
  {
    value: 'Ceylon Business Appliances',
    category: 'User',
    id: 23,
    url: 'users/user?id=50',
  },
  {
    value: 'Bank of Ceylon',
    category: 'Customer',
    id: 23,
    url: 'customers/customer?id=23',
  },
  {
    value: 'Bank of Ceylon',
    category: 'Service Requests',
    id: 165,
    url: 'serviceRequests/serviceRequest?id=165',
  },
  {
    value: 'Bank of Ceylon',
    category: 'Product Service Agreements',
    id: 6,
    url: 'productServiceAgreements/serviceAgreement?id=6',
  },
  {
    value: 'Ceylon Business Appliances',
    category: 'User',
    id: 23,
    url: 'users/user?id=50',
  },
  {
    value: 'Bank of Ceylon',
    category: 'Customer',
    id: 23,
    url: 'customers/customer?id=23',
  },
  {
    value: 'Bank of Ceylon',
    category: 'Product Service Agreements',
    id: 6,
    url: 'productServiceAgreements/serviceAgreement?id=6',
  },
];

const SearchDialog = (props) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const history = useHistory();

  const perPage = 6;

  const searchSubmitHanlder = (selectedPage) => {
    if (searchKey.length > 0 && searchCategory != null) {
      const searchCategoryClone = { ...searchCategory };
      if (searchCategoryClone.value === 'SERVICE_REQUEST') {
        searchCategoryClone.filterObj.name = searchKey;
        searchCategoryClone.filterObj['user_role_id'] = parseInt(
          localStorage.getItem('role')
        );
      }
      if (searchCategoryClone.value === 'SERVICE_AGREEMENT') {
        searchCategoryClone.filterObj.id = searchKey;
      }
      if (searchCategoryClone.value === 'ESTIMATION') {
        searchCategoryClone.filterObj.id = searchKey;
      }
      if (searchCategoryClone.value === 'USER') {
        searchCategoryClone.filterObj.user_name = searchKey;
      }
      if (searchCategoryClone.value === 'CUSTOMER') {
        searchCategoryClone.filterObj.name = searchKey;
      }
      if (searchCategoryClone.value === 'CLAIMS') {
        searchCategoryClone.filterObj.employee_name = searchKey;
      }
      if (searchCategoryClone.value === 'PRODUCT') {
        searchCategoryClone.filterObj.serial = searchKey;
      }

      setSearchCategory(searchCategoryClone);
      const reqBody = {};
      reqBody.searchCategory = searchCategoryClone.value;
      reqBody.searchObj = searchCategoryClone.filterObj;
      setIsLoading(true);

      try {
        let url = urlProperties.baseUrl + urlProperties.dashboard.search + '?';
        url += 'perPage=' + perPage;
        url += '&page=' + selectedPage;

        fetch(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(reqBody),
        })
          .then((response) => response.json())
          .then((result) => {
            setSearchResults(result.data);
            setPageCount(result.total_pages);
            setTotalResults(result.total);
            setIsLoading(false);
          });
      } catch (error) {
        console.log('Error -------> ', error);
        setIsLoading(false);
      }
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    searchSubmitHanlder(selectedPage);
  };

  const handleSearchClose = (e) => {
    props.closeSearch();
    resetSearchPaginations();
  };

  const resetSearchPaginations = () => {
    setSearchKey('');
    setSearchCategory('');
    setSearchResults([]);
    setPageCount(0);
    setTotalResults(0);
  };

  const searchItem = (item) => {
    return (
      <GridItem xs={12} sm={12} md={4}>
        <div
          className={classes['search-item-container']}
          onClick={() => {
            const urlPath =
              item.category === 'Service Request'
                ? `serviceRequests/serviceRequest?isEdit=false&customerId=0&id=${item.id}`
                : item.category === 'Service Agreement'
                ? `productServiceAgreements/serviceAgreement?isEdit=false&id=${item.id}`
                : item.category === 'Estimation'
                ? `estimations/estimation?isEdit=false&id=${item.id}`
                : item.category === 'User'
                ? `users/user?isEdit=false&isCustomerUser=false&id=${item.id}`
                : item.category === 'Customer'
                ? `customers/customerDetails?isEdit=false&id=${item.id}`
                : item.category === 'Claim Report'
                ? `claims/${item.id}/edit`
                : item.category === 'Product'
                ? `customers/customerDetails/products/productDetails?id=${item.id}&isEdit=false&customerId=${item.customerId}`
                : '';

            const url =
              window.location.protocol +
              '//' +
              window.location.host +
              '/trackable/' +
              urlPath;

            window.location.href = url;
            handleSearchClose();
          }}
        >
          <span className={classes['search-item-title']}>{item.value}</span>
          <span
            style={{
              fontWeight: '500',
              fontSize: '16px',
            }}
          >
            {item.category}
          </span>
          <span
            style={{
              fontWeight: '500',
              fontSize: '13px',
            }}
          >
            {item.category === 'Service Request'
              ? `SR Id: ${item.id}`
              : item.category === 'Service Agreement'
              ? `PSA Id: ${item.id}`
              : item.category === 'Estimation'
              ? `Est. Id: ${item.id}`
              : // : item.category === 'User'
              // ? 'User Id'
              // item.category === 'Customer'
              // ? `Cust. Id: ${item.id}`
              item.category === 'Claim Report'
              ? `Claim Id: ${item.id}`
              : // : item.category === 'Product'
                // ? 'Product Id'
                ''}
          </span>

          {item.customer && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Customer: ${item.customer}`}
            </span>
          )}
          {item.serial && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Serial: ${item.serial}`}
            </span>
          )}
          {item.srId && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`SR Id: ${item.srId}`}
            </span>
          )}
          {item.userName && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`User Name: ${item.userName}`}
            </span>
          )}
          {item.department && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Department: ${item.department}`}
            </span>
          )}
          {item.customerName && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Customer: ${item.customerName}`}
            </span>
          )}
          {item.employeeId && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Emp. Id: ${item.employeeId}`}
            </span>
          )}
          {item.name && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
                overflow: 'ellipsis',
              }}
            >
              {item.name}
            </span>
          )}
          {item.productCategory && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Category: ${item.productCategory}`}
            </span>
          )}
          {item.productBrand && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Brand: ${item.productBrand}`}
            </span>
          )}
          {item.productModel && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Model: ${item.productModel}`}
            </span>
          )}
          {item.customerAddress && (
            <span
              style={{
                fontSize: '13px',
                fontWeight: '500',
              }}
            >
              {`Address: ${item.customerAddress}`}
            </span>
          )}
        </div>
      </GridItem>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.isSearchOpen}
      onClose={handleSearchClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ color: '#B22900', height: '20px' }}
        id="alert-dialog-title"
      >
        {'Search'}
      </DialogTitle>
      <DialogContent
      >
        <div className={classes["search-content"]}>
        <LoadingOverlay
          active={isLoading}
          className={classes["search-content"]}
          spinner={<Spinner />}
          text="Loading ..."
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText={'Select your option...'}
                // id={element.id}
                inputProps={{
                  options: searchTypes,
                  value: searchTypes[0],
                }}
                type={'select'}
                formControlProps={{ fullWidth: true }}
                multiple={false}
                changed={(event, value) => {
                  const searchCategory = searchTypes.find(
                    (el) => el.value === event.target.value
                  );
                  if (searchCategory) {
                    setSearchCategory(searchCategory);
                  }
                }}

                // labelProps={{
                //   ...element.config.labelProps,
                //   error: meta.error && meta.touched,
                // }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <CustomInput
                id="search"
                type="input"
                placeholder={
                  searchCategory != null
                    ? searchCategory.placeholder
                    : 'Search here...'
                }
                formControlProps={{ fullWidth: true }}
                multiple={false}
                onKeyDown={(event, value) => {
                  if (
                    event &&
                    (event.key === 'Enter' || event.keyCode === 13)
                  ) {
                    setSearchResults([]);
                    searchSubmitHanlder(0);
                  }
                }}
                changed={(event, value) => {
                  setSearchKey(event.target.value);
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <Button
                color={window.innerWidth > 959 ? 'transparent' : 'white'}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-haspopup="true"
                onClick={() => {
                  setSearchResults([]);
                  searchSubmitHanlder(0);
                }}
                className={classes.buttonLink}
              >
                <Avatar
                  round={true}
                  style={{
                    width: '38px',
                    height: '38px',
                    border: '3px solid #d4cfcf ',
                    marginTop: 0,
                  }}
                >
                  <FaSearch />
                </Avatar>
              </Button>
            </GridItem>
          </GridContainer>
          <br />
          {searchResults.length > 0 && (
            <div>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                {totalResults === 1
                  ? `${totalResults} result found!`
                  : `${totalResults} results found!`}
              </span>
              <div className={classes['search-results-container']}>
                <GridContainer>
                  {searchResults.map((item) => searchItem(item))}
                </GridContainer>
              </div>
              <div className={classes['search-paginate-container']}>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={classes['break-me']}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={classes['pagination']}
                  subContainerClassName={
                    classes['pages'] + ' ' + classes['pagination']
                  }
                  activeClassName={classes['active']}
                />
              </div>
            </div>
          )}
        </LoadingOverlay>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    isSearchOpen: state.search.isOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSearch: () => dispatch(actions.closeSearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchDialog);
