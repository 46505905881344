import React, { useState } from "react";
import { Polyline } from "@react-google-maps/api";
import RouteMarker from "./RouteMarker";
import CustomMarker from "./CustomMarker";
import { regularMarker } from "./SvgIcons";
import PopupBoxHandler from "./PopupBoxHandler";

const CustomDirectionRenderer = ({
  srId,
  origin,
  destination,
  path,
  routeColor,
  srType,
  srName,
}) => {
  const [openPopupBox, setOpenPopupBox] = useState(false);
  return (
    <>
      <Polyline
        path={path}
        options={{
          strokeColor: routeColor,
          strokeWeight: 4,
          zIndex: srId === -1 ? 5 : 10,
        }}
      />
      <MarkerIcon color={routeColor} label="S" position={origin} />
      <MarkerIcon
        color={routeColor}
        label="E"
        position={destination}
        onMouseOver={(_) => {
          if (srId !== -1) setOpenPopupBox(true);
        }}
        onMouseOut={(_) => {
          if (srId !== -1) setOpenPopupBox(false);
        }}
      />
      <RouteMarker
        route={path}
        origin={origin}
        destination={destination}
        markerChangeLatency={5000 / path.length}
        routeColor={routeColor}
      />
      {openPopupBox && (
        <PopupBoxHandler
          postion={destination}
          width={100}
          text={
            <div style={{ color: routeColor }}>
              <p>name: {srName}</p>
              <p>type: {srType}</p>
            </div>
          }
        />
      )}
    </>
  );
};

const MarkerIcon = ({ position, color, label, onMouseOver, onMouseOut }) => {
  const markerIcon = regularMarker(color);
  return (
    <CustomMarker
      labelText={label}
      position={position}
      iconSize={35}
      icon={markerIcon}
      labelColor={color}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};

export default CustomDirectionRenderer;
