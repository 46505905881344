import * as ActionTypes from "../../store/actions/FuelRatesActionTypes";

const initialState = {
  fuelRatesList: {
    data: [],
    isFetching: false,
    error: null
  },
  fuelRateObject: {
    data: {},
    isFetching: false,
    error: null
  },
  createFuelRate: {
    data: {},
    inProgress: false,
    error: null
  },
  deleteFuelRate: {
    success: false,
    inProgress: false,
    error: null
  },
  activeVehicleTypes: {
    data: [],
    inProgress: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FUEL_RATES_LIST_START:
      return {
        ...state,
        fuelRatesList: {
          ...state.fuelRatesList,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_FUEL_RATES_LIST_SUCCESS:
      return {
        ...state,
        fuelRatesList: {
          ...state.fuelRatesList,
          isFetching: false,
          error: null,
          data: action.payload
        }
      };
    case ActionTypes.GET_FUEL_RATES_LIST_FAILED:
      return {
        ...state,
        fuelRatesList: {
          ...state.fuelRatesList,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_FUEL_RATE_BY_ID_START:
      return {
        ...state,
        fuelRateObject: {
          ...state.fuelRateObject,
          isFetching: true,
          error: null
        }
      };
    case ActionTypes.GET_FUEL_RATES_BY_ID_SUCCESS:
      return {
        ...state,
        fuelRateObject: {
          ...state.fuelRateObject,
          isFetching: false,
          error: null,
          data: action.payload
        }
      };
    case ActionTypes.GET_FUEL_RATES_BY_ID_FAILED:
      return {
        ...state,
        fuelRateObject: {
          ...state.fuelRateObject,
          isFetching: false,
          error: action.payload
        }
      };
    case ActionTypes.CREATE_FUEL_RATE_START:
      return {
        ...state,
        createFuelRate: {
          data: {},
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.CREATE_FUEL_RATE_SUCCESS:
      return {
        ...state,
        createFuelRate: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.CREATE_FUEL_RATE_FAILED:
      return {
        ...state,
        createFuelRate: {
          data: {},
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.UPDATE_FUEL_RATE_START:
      return {
        ...state,
        createFuelRate: {
          data: {},
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.UPDATE_FUEL_RATE_SUCCESS:
      return {
        ...state,
        createFuelRate: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.UPDATE_FUEL_RATE_FAILED:
      return {
        ...state,
        createFuelRate: {
          data: {},
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.DELETE_FUEL_RATE_START:
      return {
        ...state,
        deleteFuelRate: {
          success: false,
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.DELETE_FUEL_RATE_SUCCESS:
      return {
        ...state,
        deleteFuelRate: {
          success: true,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.DELETE_FUEL_RATE_FAILED:
      return {
        ...state,
        deleteFuelRate: {
          success: false,
          inProgress: false,
          error: action.payload
        }
      };
    case ActionTypes.GET_ACTIVE_VEHICLE_TYPES_START:
      return {
        ...state,
        activeVehicleTypes: {
          data: [],
          inProgress: true,
          error: null
        }
      };
    case ActionTypes.GET_ACTIVE_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        activeVehicleTypes: {
          data: action.payload,
          inProgress: false,
          error: null
        }
      };
    case ActionTypes.GET_ACTIVE_VEHICLE_TYPES_FAILED:
      return {
        ...state,
        activeVehicleTypes: {
          data: [],
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return {
        ...state
      };
  }
};
