import * as ActionTypes from '../../store/actions/claimsExpenseCategoriesActionTypes';

const initialState = {
    claimExpenseCategoryList: {
        data: [],
        isFetching: false,
        error: null
    },
    claimExpenseCategoryObject:{
        data: {},
        isFetching: false,
        error: null
    },
    createExpenseCategory: {
        data:{},
        inProgress : false,
        error: null
    },
    deleteExpenseCategory: {
        success:false,
        inProgress : false,
        error: null
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_CLAIMS_EXPENSES_LIST_START:
            return {
                ...state,
                claimExpenseCategoryList: {
                    ...state.claimExpenseCategoryList,
                    isFetching: true,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_EXPENSES_LIST_SUCCESS:
            return {
                ...state,
                claimExpenseCategoryList: {
                    ...state.claimExpenseCategoryList,
                    isFetching: false,
                    error: null,
                    data: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_EXPENSES_LIST_FAILED:
            return {
                ...state,
                claimExpenseCategoryList: {
                    ...state.claimExpenseCategoryList,
                    isFetching: false,
                    error: action.payload,
                }
            };
        case ActionTypes.GET_CLAIMS_EXPENSE_BY_ID_START:
            return {
                ...state,
                claimExpenseCategoryObject: {
                    ...state.claimExpenseCategoryObject,
                    isFetching: true,
                    error: null
                }
            };
        case ActionTypes.GET_CLAIMS_EXPENSES_BY_ID_SUCCESS:
            return {
                ...state,
                claimExpenseCategoryObject: {
                    ...state.claimExpenseCategoryObject,
                    isFetching: false,
                    error: null,
                    data: action.payload
                }
            };
        case ActionTypes.GET_CLAIMS_EXPENSES_BY_ID_FAILED:
            return {
                ...state,
                claimExpenseCategoryObject: {
                    ...state.claimExpenseCategoryObject,
                    isFetching: false,
                    error: action.payload,
                }
            };
        case ActionTypes.CREATE_CLAIMS_EXPENSE_START :
            return {
                ...state,
                createExpenseCategory: {
                    data:{},
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.CREATE_CLAIMS_EXPENSE_SUCCESS :
            return {
                ...state,
                createExpenseCategory: {
                    data: action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.CREATE_CLAIMS_EXPENSE_FAILED :
            return {
                ...state,
                createExpenseCategory: {
                    data:{},
                    inProgress : false,
                    error: action.payload
                }
            };
       case ActionTypes.UPDATE_CLAIMS_EXPENSE_START :
            return {
                ...state,
                createExpenseCategory: {
                    data:{},
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.UPDATE_CLAIMS_EXPENSE_SUCCESS :
            return {
                ...state,
                createExpenseCategory: {
                    data: action.payload,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.UPDATE_CLAIMS_EXPENSE_FAILED :
            return {
                ...state,
                createExpenseCategory: {
                    data:{},
                    inProgress : false,
                    error: action.payload
                }
            };
        case ActionTypes.DELETE_CLAIMS_EXPENSE_START :
            return {
                ...state,
                deleteExpenseCategory: {
                    success:false,
                    inProgress : true,
                    error: null
                }
            };
        case ActionTypes.DELETE_CLAIMS_EXPENSE_SUCCESS :
            return {
                ...state,
                deleteExpenseCategory: {
                    success:true,
                    inProgress : false,
                    error: null
                }
            };
        case ActionTypes.DELETE_CLAIMS_EXPENSE_FAILED :
            return {
                ...state,
                deleteExpenseCategory: {
                    success:false,
                    inProgress : false,
                    error: action.payload
                }
            };
        default:
            return {
                ...state
            };
    }
};
