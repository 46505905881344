export const getLeaveForm = () => {
  return {
    // schema_id: {
    //   elType: 'input',
    //   label: 'Schema Id',
    //   inputProps: {
    //     type: 'number',
    //     placeholder: '',
    //     value: '',
    //     initvalue: '',
    //   },
    //   formControlProps: {
    //     fullWidth: true,
    //   },
    //   labelProps: {
    //     required: true,
    //     error: false,
    //   },
    //   isFullWidth: true,
    // },
    schema_name: {
      elType: 'input',
      label: 'Schema Name',
      inputProps: {
        type: 'text',
        placeholder: '',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: false,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 18,
    },
    casual_leave: {
      elType: 'input',
      label: 'Casual Leave',
      inputProps: {
        type: 'number',
        placeholder: '',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: false,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 18,
      hideArrows: true,
    },
    annual_leave: {
      elType: 'input',
      label: 'Annual Leave',
      inputProps: {
        type: 'number',
        placeholder: '',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: false,
      },
      validations: ['validateUsername'],
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 18,
      hideArrows: true,
    },
    medical_leave: {
      elType: 'input',
      label: 'Medical Leave',
      inputProps: {
        type: 'number',
        placeholder: '',
        value: '',
        initvalue: '',
      },
      formControlProps: {
        fullWidth: false,
      },
      labelProps: {
        required: true,
        error: false,
      },
      isFullWidth: false,
      size: 18,
      hideArrows: true,
    },
  };
};

export const leaveSchemeProperties = {
  messages: {
    error: {
      loadData: 'Could not load leave schema data please try again',
      saveData: 'Could not save leave schema data please try again',
      deleteScheme:
        'Could not delete Leave Schema. Leave Scheme is currently in use',
      SchemeNameTaken: 'Leave Schema Name is already taken',
      invalidLeaveDuration: 'Invalid leave duration. Please enter valid value',
    },
    success: {
      saveData: 'Leave Schema saved successfully',
      deleteScheme: 'Leave Schema deleted successfully',
    },
  },
};
