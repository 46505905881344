import React, { useEffect, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import styles from './Settings.module.css';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import PersonIcon from '@material-ui/icons/Person';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CategoryIcon from '@material-ui/icons/Category';
import SpeedIcon from '@material-ui/icons/Speed';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Settings = (props) => {
  const [permissions, setPermissions] = useState({});
  console.log(permissions);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.productAttributeSetting,
      PermissionProperties.viewRoleAndPermissions,
      PermissionProperties.serviceAgreementSetting,
      PermissionProperties.serviceRequestSetting,
      PermissionProperties.claimSetting,
      PermissionProperties.leaveSetting,
      PermissionProperties.workflows,
      PermissionProperties.kpi,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  return (
    <GridContainer>
      {permissions[PermissionProperties.viewRoleAndPermissions] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/roleAndPermissions'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <PersonIcon fontSize="inherit" style={{ fontSize: '2rem' }} />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.userMangement}
                </span>
                <span className={styles.desc_text}>
                  User Roles and Permission, Form Configuration
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.productAttributeSetting] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/productAttributes'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <CardGiftcardIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.productAttributes}
                </span>
                <span className={styles.desc_text}>
                  Category, Brand and Model of Products
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {/* {process.env.REACT_APP_ENVIRONMENT === 'TSD' && */}
      {permissions[PermissionProperties.serviceAgreementSetting] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/serviceAgreements'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <PeopleIcon fontSize="inherit" style={{ fontSize: '2rem' }} />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.serviceAgrements}
                </span>
                <span className={styles.desc_text}>
                  Different Types of Service Agreements
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.serviceRequestSetting] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/serviceRequestsSettings'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <AssignmentTurnedInIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.serviceRequests}
                </span>
                <span className={styles.desc_text}>
                  Category, Checklist Priority Configuration
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.claimSetting] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/claimsSettings'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <AccountBalanceIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.claims}
                </span>
                <span className={styles.desc_text}>
                  Expense Categories, Expense Rules and Fuel Rates
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.workflows] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/workflows'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <CategoryIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.workflows}
                </span>
                <span className={styles.desc_text}>
                  Different Types of Workflow
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.workflows] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/reportsAndDashboards'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <DashboardIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.dashbordAndReports}
                </span>
                <span className={styles.desc_text}>
                  Define Reports and DashBoard Configurations
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.kpi] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/kpi'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <SpeedIcon fontSize="inherit" style={{ fontSize: '2rem' }} />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.kpi}
                </span>
                <span className={styles.desc_text}>
                  Define KPI Configurations
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}
      {permissions[PermissionProperties.leaveSetting] ? (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              <Link
                to={props.match.url + '/leave'}
                className={styles.settingIconWrapper}
              >
                <span className={styles.setting_block}>
                  <TodayIcon fontSize="inherit" style={{ fontSize: '2rem' }} />
                </span>
                <span className={styles.setting_text}>
                  {GeneralProperties.leave}
                </span>
                <span className={styles.desc_text}>
                  Define Leave Module and Configurations
                </span>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      ) : (
        ''
      )}

      {process.env.REACT_APP_ENVIRONMENT === 'DEV' && (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              {/* <Link
              to={props.match.url + '/leave'}
              className={styles.settingIconWrapper}
            > */}
              <div className={styles.settingIconWrapper}>
                <span className={styles.setting_block}>
                  <AccessTimeIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>OT Configuration</span>
                <span className={styles.desc_text}>Yet to be implemented</span>
              </div>
              {/* </Link> */}
            </CardBody>
          </Card>
        </GridItem>
      )}
      {process.env.REACT_APP_ENVIRONMENT === 'DEV' && (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              {/* <Link
              to={props.match.url + '/leave'}
              className={styles.settingIconWrapper}
            > */}
              <div className={styles.settingIconWrapper}>
                <span className={styles.setting_block}>
                  <InsertChartIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>Chart Configuration</span>
                <span className={styles.desc_text}>Yet to be implemented</span>
              </div>
              {/* </Link> */}
            </CardBody>
          </Card>
        </GridItem>
      )}

      {process.env.REACT_APP_ENVIRONMENT === 'DEV' && (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              {/* <Link
              to={props.match.url + '/leave'}
              className={styles.settingIconWrapper}
            > */}
              <div className={styles.settingIconWrapper}>
                <span className={styles.setting_block}>
                  <PhonelinkSetupIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>
                  Training Module Configuration
                </span>
                <span className={styles.desc_text}>Yet to be implemented</span>
              </div>
              {/* </Link> */}
            </CardBody>
          </Card>
        </GridItem>
      )}
      {process.env.REACT_APP_ENVIRONMENT === 'DEV' && (
        <GridItem xs={6} sm={4} md={3} lg={2}>
          <Card style={{ marginTop: '4px', height: '15rem' }}>
            <CardBody>
              {/* <Link
              to={props.match.url + '/leave'}
              className={styles.settingIconWrapper}
            > */}
              <div className={styles.settingIconWrapper}>
                <span className={styles.setting_block}>
                  <HelpOutlineIcon
                    fontSize="inherit"
                    style={{ fontSize: '2rem' }}
                  />
                </span>
                <span className={styles.setting_text}>Helpdesk Support</span>
                <span className={styles.desc_text}>Yet to be implemented</span>
              </div>
              {/* </Link> */}
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default Settings;
