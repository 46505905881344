import * as ActionTypes from './claimsExpenseRulesActionTypes'
import ClaimsExpensesCategoriesService from "../../services/ClaimsSettings/ClaimsExpenseRulesService";

export const getAllExpenseRules = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_RULE_LIST_START });

            const response = await ClaimsExpensesCategoriesService.getAllClaimsRules();
            dispatch({
                type: ActionTypes.GET_CLAIMS_RULE_LIST_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_RULE_LIST_FAILED,
                payload: error
            });
        }
    };
};

export const getAllowanceTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_START });

            const response = await ClaimsExpensesCategoriesService.getAllowanceTypes();
            dispatch({
                type: ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_ALLOWANCE_TYPES_FAILED,
                payload: error
            });
        }
    };
};
export const getMealTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_MEAL_TYPES_START });

            const response = await ClaimsExpensesCategoriesService.getMealTypes();
            dispatch({
                type: ActionTypes.GET_CLAIMS_MEAL_TYPES_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_MEAL_TYPES_FAILED,
                payload: error
            });
        }
    };
};
export const getProductCategories = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_PRODUCT_CATEGORIES_START });

            const response = await ClaimsExpensesCategoriesService.getProductCategories();
            dispatch({
                type: ActionTypes.GET_PRODUCT_CATEGORIES_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_PRODUCT_CATEGORIES_FAILED,
                payload: error
            });
        }
    };
};

export const getServiceRequestTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_SERVICE_REQUEST_TYPES_START });

            const response = await ClaimsExpensesCategoriesService.getSRTypes();
            dispatch({
                type: ActionTypes.GET_SERVICE_REQUEST_TYPES_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_SERVICE_REQUEST_TYPES_FAILED,
                payload: error
            });
        }
    };
};

export const getExpenseRuleById = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.GET_CLAIMS_RULE_BY_ID_START });

            const response = await ClaimsExpensesCategoriesService.getClaimsRuleById(id);
            dispatch({
                type: ActionTypes.GET_CLAIMS_RULE_BY_ID_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.GET_CLAIMS_RULES_BY_ID_FAILED,
                payload: error
            });
        }
    };
};
export const createExpenseRule = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.CREATE_CLAIMS_RULE_START });
            const response = await ClaimsExpensesCategoriesService.createClaimsRule(data);
            dispatch({
                type: ActionTypes.CREATE_CLAIMS_RULE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.CREATE_CLAIMS_RULE_FAILED,
                payload: error
            });
        }
    };
};
export const updateExpenseRule = (data,id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.UPDATE_CLAIMS_RULE_START });
            const response = await ClaimsExpensesCategoriesService.updateClaimsRule(data,id);
            dispatch({
                type: ActionTypes.UPDATE_CLAIMS_RULE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.UPDATE_CLAIMS_RULE_FAILED,
                payload: error
            });
        }
    };
};
export const deleteExpenseRule = (id,data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.DELETE_CLAIMS_RULE_START });
            const response = await ClaimsExpensesCategoriesService.deleteClaimsRuleById(id,data);
            dispatch({
                type: ActionTypes.DELETE_CLAIMS_RULE_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: ActionTypes.DELETE_CLAIMS_RULE_FAILED,
                payload: error
            });
        }
    };
};