import urlProperties from './UrlProperties';

const reportNames = [
  // {
  //   value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
  //   displayValue: 'On Site Field Agent Report',
  // },
  {
    value: 'Efficiency_Summary_Time',
    displayValue: 'Efficiency Summary - Time',
  },
  {
    value: 'Efficiency_Summary_Time_Between_Stages',
    displayValue: 'Efficiency Summary - Time [Between Stages]',
  },
  {
    value: 'Efficiency_Summary_Count',
    displayValue: 'Efficiency Summary - Count',
  },
  {
    value: 'Efficiency_Summary_Count_Current_Status',
    displayValue: 'Efficiency Summary - Count [Current Status]',
  },
  // {
  //   value: 'Average_Field_Cost',
  //   displayValue: 'Average Field Cost',
  // },
  // {
  //   value: 'SERVICE_REQUEST_DETAIL_REPORT',
  //   displayValue: 'Service Request Detail Report',
  // },
  {
    value: 'TECHNICIAN_ROUTE_REPORT',
    displayValue: 'Field Agent Travel Routes Report',
  },
  // {
  //   value: 'Payment_Collection_Report',
  //   displayValue: 'Payment Collection Report',
  // },
  // {
  //   value: 'Stock_Summery_Report',
  //   displayValue: 'Stock Summury Report',
  // },
  // {
  //   value: 'Machine_Population_Report',
  //   displayValue: 'Machine Population Report',
  // },
  {
    value: 'FIELD_AGENT_JOURNEY_REPORT',    
    displayValue: 'Field Agent Journey Report',
  },
];

export const reportFields = [
  {
    value: 'reportName',
    displayValue: 'Report Name',
  },
  {
    value: 'reportType',
    displayValue: 'Report Type',
  },
  {
    value: 'technicianId',
    displayValue: 'Technician Id',
  },
  {
    value: 'srCategoryId',
    displayValue: 'SR Category Id',
  },
  {
    value: 'reportStages',
    displayValue: 'Report Stages',
  },
  {
    value: 'customerId',
    displayValue: 'Customer Id',
  },
  {
    value: 'department',
    displayValue: 'Department',
  },
  {
    value: 'customerLocation',
    displayValue: 'Customer Location',
  },
  {
    value: 'productCategoryId',
    displayValue: 'Product Category Id',
  },
  {
    value: 'dataRangeFrom',
    displayValue: 'Data Range From',
  },
  {
    value: 'dataRangeTo',
    displayValue: 'Data Range To',
  },
  {
    value: 'date',
    displayValue: 'Date',
  },
  {
    value: 'reportDateGroup',
    displayValue: 'Report Date Group',
  },
  {
    value: 'reportDataType',
    displayValue: 'Report Data Type',
  },
  {
    value: 'dataSource',
    displayValue: 'Data Source',
  },
  {
    value: 'reportStagesFrom',
    displayValue: 'SR Stage From',
  },
  {
    value: 'reportStagesTo',
    displayValue: 'SR Stage To',
  },
];

const reportDataSource = [
  {
    value: 'SR_Base',
    displayValue: 'SR Based',
  },
  {
    value: 'Period_Based',
    displayValue: 'Period Based',
  },
];

const reportDatatypes = [
  {
    value: 'SR_TYPE_WISE',
    displayValue: 'SR Type Wise',
  },
  {
    value: 'PRODUCT_WISE',
    displayValue: 'Product Wise',
  },
  {
    value: 'EMPLOYEE_WISE',
    displayValue: 'Employee Wise',
  },
  {
    value: 'DATE_WISE',
    displayValue: 'Date Wise',
  },
];

let arr = [];
arr.push({ id: '-1', name: 'All' });
const srArr = `http://${urlProperties.baseUrl}:8089/api/v1/getServiceRequestCategories`;

let newArr = arr.concat(srArr);
let newArray1 = [];

// export const saCategories = [
//   { value: 1, displayValue: "Warranty", id: 1, name: "WARRANTY" },
//   { value: 2, displayValue: "Labour-only", id: 2, name: "LABOUR-ONLY" },
//   {
//     value: 3,
//     displayValue: "Comprehensive",
//     id: 3,
//     name: "COMPREHENSIVE"
//   }
// ];
export const reportDisabledFields = {
  Machine_Population_Report: [
    'srCategoryId',
    'reportDateGroup',
    'technicianId',
    'reportType',
    'srCategoryId',
    'department',
    // 'productCategoryId',
    // 'customerId',
    // 'customerLocation',
    'dataRangeFrom',
    'dataRangeTo',
    'reportDataType',
  ],
  Stock_Summery_Report: [
    'srCategoryId',
    'reportDateGroup',
    'technicianId',
    'reportType',
    'srCategoryId',
    'department',
    'productCategoryId',
    'customerId',
    'customerLocation',
  ],
  Payment_Collection_Report: [
    //"srCategoryId",
    //"dataRangeFrom",
    //"dataRangeTo",
    //"reportDateGroup"
    'technicianId',
    'reportType',
  ],
  ON_SITE_TECHNICIANS_SUMMARY_REPORT: [
    //"srCategoryId",
    //"dataRangeFrom",
    //"dataRangeTo",
    //"reportDateGroup"
    'technicianId',
    'reportType',
    'customerLocation',
    'srCategoryId',
  ],
  Efficiency_Summary_Time: [
    'srCategoryId',
    'reportDateGroup',
    'technicianId',
    'reportType',
    'srCategoryId',
    'department',
    'productCategoryId',
    'customerId',
    'customerLocation',
    // 'dataRangeFrom',
    // 'dataRangeTo',
    // 'reportDataType',
  ],
  Efficiency_Summary_Count: [
    'reportDateGroup',
    'technicianId',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    // 'srCategoryId',
    // 'reportStages',
    // 'dataRangeFrom',
    // 'dataRangeTo',
    // 'reportDataType',
  ],
  Efficiency_Summary_Count_Current_Status: [
    'reportDateGroup',
    'technicianId',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    // 'srCategoryId',
    // 'reportStages',
    // 'dataRangeFrom',
    // 'dataRangeTo',
    // 'reportDataType',
  ],
  Efficiency_Summary_Time_Between_Stages: [
    'reportDateGroup',
    'technicianId',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    'reportStages',
    // 'reportDataType',
    // 'srCategoryId',
    // 'dataRangeFrom',
    // 'dataRangeTo',
    // 'reportStagesFrom',
    // 'reportStagesTo',
  ],
  Average_Field_Cost: [
    'srCategoryId',
    'reportDateGroup',
    'technicianId',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    'srCategoryId',
    'reportStages',
    'reportDataType',
    // 'dataRangeFrom',
    // 'dataRangeTo',
  ],
  TECHNICIAN_ROUTE_REPORT: [
    'srCategoryId',
    'reportDateGroup',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    'srCategoryId',
    'reportStages',
    'reportDataType',
    'dataRangeFrom',
    'dataRangeTo',
    // 'technicianId',
    // 'date'
  ],
  SERVICE_REQUEST_DETAIL_REPORT: ['date'],
  FIELD_AGENT_JOURNEY_REPORT: [    
    'srCategoryId',
    'reportDateGroup',
    'reportType',
    'department',
    'customerId',
    'customerLocation',
    'productCategoryId',
    'srCategoryId',
    'reportStages',
    'reportDataType',
    'dataRangeFrom',
    'dataRangeTo',
    // 'technicianId',
    'dataSource',
    // 'date',
    'reportStagesFrom',
    'reportStagesTo',
],
};

export const reportHiddenFields = {
  Machine_Population_Report: [
    'date',
    'dataSource',
    'reportStagesFrom',
    'reportStagesTo',
    'reportType',
  ],
  Stock_Summery_Report: [
    'date',
    'dataSource',
    'reportStagesFrom',
    'reportStagesTo',
    'reportType',
  ],
  Payment_Collection_Report: [
    'date',
    'dataSource',
    'reportStagesFrom',
    'reportStagesTo',
    'reportType',
  ],
  ON_SITE_TECHNICIANS_SUMMARY_REPORT: [
    'date',
    'dataSource',
    'reportStagesFrom',
    'reportStagesTo',
    'reportType',
  ],
  Efficiency_Summary_Time: [
    // 'srCategoryId',
    // 'customerId',
    // 'department',
    // 'customerLocation',
    // 'productCategoryId',
    // 'technicianId',
    // 'reportDateGroup',
    // 'reportStages',
    'date',
    'dataSource',
    'reportType',
    'reportStagesFrom',
    'reportStagesTo',
  ],
  Efficiency_Summary_Time_Between_Stages: [
    // 'srCategoryId',
    // 'customerId',
    // 'department',
    // 'customerLocation',
    // 'productCategoryId',
    // 'technicianId',
    // 'reportDateGroup',
    // 'reportStages',
    'date',
    'dataSource',
    'reportType',
    'reportStages',
    'reportDateGroup',
  ],
  Efficiency_Summary_Count: [
    // 'customerId',
    // 'department',
    // 'customerLocation',
    // 'productCategoryId',
    // 'technicianId',
    // 'reportDateGroup',
    'date',
    'reportType',
    'reportStagesFrom',
    'reportStagesTo',
    'dataSource',
  ],
  Efficiency_Summary_Count_Current_Status: [
    // 'customerId',
    // 'department',
    // 'customerLocation',
    // 'productCategoryId',
    // 'technicianId',
    // 'reportDateGroup',
    'date',
    'reportType',
    'reportStagesFrom',
    'reportStagesTo',
    'dataSource',
  ],
  Average_Field_Cost: [
    // 'srCategoryId',
    // 'customerId',
    // 'department',
    // 'customerLocation',
    // 'productCategoryId',
    // 'technicianId',
    // 'reportDateGroup',
    // 'reportDataType',
    // 'reportStages',
    'dataSource',
    'date',
    'reportType',
    'reportStagesFrom',
    'reportStagesTo',
  ],
  TECHNICIAN_ROUTE_REPORT: [
    'srCategoryId',
    'reportDateGroup',
    // 'technicianId',
    'reportType',
    'srCategoryId',
    'department',
    'productCategoryId',
    'customerId',
    'customerLocation',
    'dataRangeFrom',
    'dataRangeTo',
    'reportDataType',
    'dataSource',
    'reportStages',
    // 'date',
    'reportStagesFrom',
    'reportStagesTo',
  ],
  SERVICE_REQUEST_DETAIL_REPORT: ['date', 'dataSource', 'reportType'],
  FIELD_AGENT_JOURNEY_REPORT: [    
  'srCategoryId',
  'reportDateGroup',
  // 'technicianId',
  'reportType',
  'srCategoryId',
  'department',
  'productCategoryId',
  'customerId',
  'customerLocation',
  'dataRangeFrom',
  'dataRangeTo',
  'reportDataType',
  'dataSource',
  'reportStages',
  // 'date',
  'reportStagesFrom',
  'reportStagesTo',
]
};

export const reportTypes = [
  { value: 'All', displayValue: 'All' },
  { value: 'PDF', displayValue: 'PDF' },
  {
    value: 'EXCEL',
    displayValue: 'Excel',
  },
  {
    value: 'HTML',
    displayValue: 'HTML',
  },
];
export const reportDateGroup = [
  {
    value: 'daily',
    displayValue: 'Daily',
  },
  {
    value: 'weekly',
    displayValue: 'Weekly',
  },
  {
    value: 'monthly',
    displayValue: 'Monthly',
  },
];
export const department = [{ value: 'Test1', displayValue: 'Test1' }];
const provinces = [
  {
    value: 'Western',
    displayValue: 'Western',
  },
  {
    value: 'Southern',
    displayValue: 'Southern',
  },
  {
    value: 'Central',
    displayValue: 'Central',
  },
  {
    value: 'Northern',
    displayValue: 'Northern',
  },
  {
    value: 'North western',
    displayValue: 'North Western',
  },
  {
    value: 'North Central',
    displayValue: 'North Central',
  },
  {
    value: 'Sabaragamua',
    displayValue: 'Sabaragamuwa',
  },
  {
    value: 'Eastern',
    displayValue: 'Eastern',
  },
  {
    value: 'Uwa',
    displayValue: 'Uwa',
  },
];
export const reportStages = [
  {
    value: 'Assigned',
    displayValue: 'Assigned',
  },
  {
    value: 'Complete',
    displayValue: 'Complete',
  },
  {
    value: 'Incomplete',
    displayValue: 'Incomplete',
  },
  {
    value: 'Others',
    displayValue: 'Others',
  },
];

const reportsProperties = {
  messages: {
    error: {
      invalidDateRange: 'Invalid Date range selected',
      invalidStageRange:
        "Please select different stages for 'Stage From' and 'Stage To'",
    },
    success: {
      reportGenerated: 'Report downloaded successfully',
    },
    normal: {
      generateClaim: 'Generate claim',
    },
  },
  reportForm: {
    reportName: {
      elType: 'select',
      label: 'Report Name',
      inputProps: {
        options: reportNames,
        width: '100%',
        value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportType: {
      elType: 'select',
      label: 'Report Type',
      inputProps: {
        options: reportTypes,
        width: '100%',
        value: 'All',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },

    srCategoryId: {
      elType: 'select',
      label: 'SR Type',
      inputProps: {
        options: newArr,
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportStages: {
      elType: 'select',
      multiple: true,
      label: 'Stage',
      inputProps: {
        options: newArray1,
        width: '100%',
        value: 'Assigned',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,

      //isDisabled:true,
    },
    reportStagesFrom: {
      elType: 'select',
      label: 'Stage From',
      inputProps: {
        options: [],
        width: '100%',
        value: 'All',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportStagesTo: {
      elType: 'select',
      label: 'Stage To',
      inputProps: {
        options: [],
        width: '100%',
        value: 'All',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    reportDateGroup: {
      elType: 'select',
      label: 'Date Group',
      inputProps: {
        options: reportDateGroup,
        width: '100%',
        //value: "daily"
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    dataRangeFrom: {
      elType: 'date',
      label: '',
      inputProps: {
        type: 'text',
        label: 'From',
        value: null,
        initvalue: '',
        format: 'MM/DD/yyyy',
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    dataRangeTo: {
      elType: 'date',
      label: '',
      inputProps: {
        type: 'text',
        label: 'To',
        value: null,
        initvalue: '',
        format: 'MM/DD/yyyy',
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: true,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    department: {
      elType: 'select',
      label: 'Department',
      inputProps: {
        options: department,
        width: '100%',
        //value: "Test1",
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    technicianId: {
      elType: 'select',
      label: 'Field Agent Name',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    customerId: {
      elType: 'select',
      label: 'Customer',
      inputProps: {
        options: [],
        value: '',
        initvalue: '',
        customSearchable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      size: 6,
      isAsyncDropDown: true,
    },
    customerLocation: {
      elType: 'select',
      label: 'Province',
      inputProps: {
        options: provinces,
        width: '100%',
        value: null,
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    // subCustomerId: {
    //   elType: "select",
    //   label: "Sub Customer",
    //   inputProps: {
    //     options: [],
    //     width: "100%",
    //     enableclearable:true
    //   },
    //   formControlProps: {
    //     fullWidth: true
    //   },
    //   validations: {
    //     required: false
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   touched: false
    // },
    productCategoryId: {
      elType: 'select',
      label: 'Product Category',
      inputProps: {
        options: [],
        width: '100%',
        enableclearable: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },

    // technicianId: {
    //   elType: "select",
    //   label: "Field Agent Name",
    //   inputProps: {
    //     options: [],
    //     width: "100%",
    //     enableclearable:true
    //   },
    //   formControlProps: {
    //     fullWidth: true
    //   },
    //   validations: {
    //     required: false
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   touched: false
    // },
    date: {
      elType: 'date',
      label: '',
      inputProps: {
        format: 'MM/DD/yyyy',
        value: null,
        label: 'Date',
        initvalue: null,
        clearableDate: true,
      },
      formControlProps: {
        fullWidth: true,
      },
      isFullWidth: false,
      valid: true,
      isDisabled: true,
      labelProps: {
        required: false,
        error: false,
        errormessage: '',
      },
    },
    // saCategoeryId: {
    //   elType: "select",
    //   label: "Service Agreement Category",
    //   inputProps: {
    //     options: saCategories,
    //     width: "100%",
    //     enableclearable:true
    //   },
    //   formControlProps: {
    //     fullWidth: true
    //   },
    //   validations: {
    //     required: false
    //   },
    //   isFullWidth: false,
    //   valid: true,
    //   touched: false
    // },

    reportDataType: {
      elType: 'select',
      label: 'Data Type',
      inputProps: {
        options: reportDatatypes,
        width: '100%',
        value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
    dataSource: {
      elType: 'select',
      label: 'Data Source',
      inputProps: {
        options: reportDataSource,
        width: '100%',
        value: 'SR_Base',
      },
      formControlProps: {
        fullWidth: true,
      },
      validations: {
        required: false,
      },
      isFullWidth: false,
      valid: true,
      touched: false,
    },
  },

  actionText: {
    view: 'View',
    edit: 'Edit',
    add: 'Add',
  },
  reportFields: {
    customerId: null,
    customerLocation: null,
    customerName: '',
    dataRangeFrom: null,
    dataRangeTo: null,
    productBrandId: null,
    productBrandName: '',
    productCategoryId: null,
    productCategoryName: '',
    productModelId: null,
    productModelName: '',
    productStatus: true,
    saCategoeryId: null,
    saCategoryName: '',
    serial: null,
    srCategoryId: null,
    srCategoryName: '',
    srPriorityId: null,
    srPriorityName: '',
    //subCustomerId: null,
    subCustomerName: '',
    technicianId: null,
    technicianName: null,
    dataSource: null,
  },
  reqFieldsMap: {
    num: { req: 'num', table: '#' },
    title: { req: 'title', table: 'Title' },
    count: { req: 'count', table: 'SR Count' },
    completedCount: { req: 'completed_count', table: 'Completed Count' },
    completedPercentage: {
      req: 'completed_percentage',
      table: 'Completed Percentage',
    },
    percentage: {
      req: 'percentage',
      table: 'Percentage',
    },
    address: { req: 'address', table: 'Address' },
    kpi: { req: 'kpi', table: 'KPI (Days)' },
    srName: { req: 'sr_name', table: 'SR Name' },
    srCount: { req: 'sr_count', table: 'SR Count' },
    collectedTotalAmount: {
      req: 'collectedTotalAmount',
      table: 'Collected Total Amount',
    },
    assignedUserName: {
      req: 'assigned_user_name',
      table: 'Assigned User Name',
    },
    assignedUserId: { req: 'assigned_user_id', table: 'Assigned User Id' },
    productBrand: { req: 'product_brand', table: 'Product Brand' },
    tId: { req: 'tId', table: 'TID' },
    mId: { req: 'mId', table: 'MID' },
    productCategory: { req: 'product_category', table: 'Product Category' },
    productModel: { req: 'product_model', table: 'Product Model' },
    collectedAmount: { req: 'collected_amount', table: 'Collected Amount' },
    serial: { req: 'serial', table: 'Serial' },
    startedDate: { req: 'started_date', table: 'Started Date' },
    priority: { req: 'priority', table: 'Priority' },
    productName: { req: 'product_name', table: 'Product Name' },
    saStatus: { req: 'sa_status', table: 'SA Status' },
    completedDate: { req: 'completed_date', table: 'Completed Date' },
    srId: { req: 'sr_id', table: 'SR ID' },
    province: { req: 'province', table: 'Province' },
    stage: { req: 'stage', table: 'Current Stage' },
    stageFrom: { req: 'stageFrom', table: 'Stage From' },
    stageTo: { req: 'stageTo', table: 'Stage To' },
    district: { req: 'district', table: 'District' },
    updatedBy: { req: 'updated_by', table: 'Updated By' },
    customerName: { req: 'customer_name', table: 'Customer' },
    updatedDate: { req: 'updated_date', table: 'Last Update' },
    attendedTime: { req: 'attended_time', table: 'Attended Time' },
    attendedBy: { req: 'attended_by', table: 'Attended By' },
    gap: { req: 'gap', table: 'Milestone Gap (Days)' },
    attendedDate: { req: 'attended_date', table: 'Attended Date' },
    partId: { req: 'partId', table: 'Part Id' },
    partName: { req: 'partName', table: 'Part Name' },
    usageCount: { req: 'usageCount', table: 'Usage Count' },

    avg: { req: 'avg', table: 'AVG time to Complete (Days)' },
    avgHrs: { req: 'avgHrs', table: 'AVG Time (Hrs)' },
    age: { req: 'age', table: 'Age (Days)' },
    ageHrs: { req: 'ageHrs', table: 'Age (Hrs)' },

    visits: { req: 'visits', table: 'Total Field Visits Count' },
    fieldJobCost: {
      req: 'field_job_cost',
      table: 'Cost Per Field Job (LKR)',
    },
    totalCost: { req: 'total_cost', table: 'Total Cost (LKR)' },
    travelCost: { req: 'travel_cost', table: 'Travel Cost  (LKR)' },
    idlingCost: {
      req: 'idling_cost',
      table: 'Idling time Cost (LKR)',
    },
    averageCost: { req: 'average_cost', table: 'AVG Cost (LKR)' },
    department: { req: 'department', table: 'Department' },
    employeeId: { req: 'employeeId', table: 'Employee Id' },
    employeeName: { req: 'employeeName', table: 'Employee Name' },
    mobileNo: { req: 'mobileNo', table: 'Mobile Number' },
    mealCost: { req: 'meal_cost', table: 'Meal Cost (LKR)' },
    // from: { req: 'from', table: 'Start Loccation' },
    // to: { req: 'to', table: 'End Loccation' },
    // distance: { req: 'distance', table: 'Total Distance Traveled (KM)' },
    // duration: { req: 'duration', table: 'Total Duration HH:mm' },
    
    // This is the necessary fields for Travel Agent Journey Report
    outlet: {req: "outlet", table: "Outlet"},
    journeyStartTime: {req: "journeyStartTime", table: "Journey Start Time"},
    journeyEndTime: {req: "journeyEndTime", table: "Journey End Time"},
    journeyDuration: {req: "journeyDuration", table: "Journey Duration"},
    distanceFromPreviousJob: {req: "distanceFromPreviousJob", table: "Distance From Previous Job"},
    distanceFromDistributor: {req: "distanceFromDistributor", table: "Distance From Distributor"},
    checkIn: {req: "checkIn", table: "Check In"},
    checkOut: {req: "checkOut", table: "Check Out"},
    jobDuration: {req: "jobDuration", table: "Job Duration"},
    remarks: {req: "remarks", table: "Remarks"},


    bataCost: { req: 'bata_cost', table: 'BA/TA Cost (LKR)' },
    name: { req: 'name', table: 'Employee' },
    fixedCost: { req: 'fixed_cost', table: 'Fixed Cost (LKR)' },
    allowanceCost: {
      req: 'allowance_cost',
      table: 'Allowances Cost (LKR)',
    },
    totalFieldCost: {
      req: 'total_field_cost',
      table: 'Total Field Cost (LKR)',
    },

    date: { req: 'date', table: 'Date' },
    transportMedium: { req: 'transport_medium', table: 'Mode of Transport' },
    latestEntryDate: { req: 'latest_entry_date', table: 'Latest Entry Date' },
  },

  reportDataMap: {
    customerId: 'allCustomers',
    productCategoryId: 'allProdCategories',
    productBrandId: 'allProdBrands',
    productModelId: 'allProdModels',
    srPriorityId: 'allSRPriorities',
    srCategoryId: 'allSRCategories',

    //subCustomerId: "allSubCustomers"
  },
  reportSubFieldMap: {
    customerId: ['subCustomerId', 'srPriorityId'],
    // srCategoryId: ["srPriorityId"],
    productModelId: ['srPriorityId'],
    productBrandId: ['srPriorityId', 'productModelId'],
    productCategoryId: ['srPriorityId', 'productModelId', 'productBrandId'],
  },
};

export const requiredFormData = [
  {
    value: 'ON_SITE_TECHNICIANS_SUMMARY_REPORT',
    // required: [],
    required: ['srCategoryId', 'dataRangeFrom', 'dataRangeTo', 'reportStages'],
  },
  {
    value: 'Efficiency_Summary_Time',
    required: ['dataRangeFrom', 'dataRangeTo', 'reportDataType'],
    tableFields: {
      table1: [
        { id: '2', field: 'title' },
        { id: '3', field: 'count' },
        { id: '4', field: 'avg' },
        { id: '5', field: 'kpi' },
      ],
      table2: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'priority' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'province' },
        { id: '6', field: 'productName' },
        { id: '7', field: 'startedDate' },
        { id: '8', field: 'completedDate' },
        { id: '9', field: 'stage' },
        { id: '10', field: 'updatedDate' },
        { id: '11', field: 'age' },
        { id: '12', field: 'kpi' },
      ],
      table3: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'customerName' },
        { id: '4', field: 'stage' },
        { id: '5', field: 'attendedBy' },
        { id: '6', field: 'attendedDate' },
        { id: '7', field: 'attendedTime' },
        { id: '8', field: 'gap' },
        { id: '9', field: 'kpi' },
      ],
    },
  },
  {
    value: 'Efficiency_Summary_Time_Between_Stages',
    required: [
      'dataRangeFrom',
      'dataRangeTo',
      'reportDataType',
      'srCategoryId',
      'reportStagesFrom',
      'reportStagesTo',
    ],
    tableFields: {
      table1: [
        { id: '2', field: 'title' },
        { id: '3', field: 'count' },
        { id: '4', field: 'avgHrs' },
        { id: '5', field: 'kpi' },
      ],
      table2: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'priority' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'province' },
        { id: '6', field: 'productName' },
        { id: '7', field: 'startedDate' },
        { id: '8', field: 'completedDate' },
        { id: '9', field: 'stage' },
        { id: '10', field: 'stageFrom' },
        { id: '11', field: 'stageTo' },
        { id: '12', field: 'updatedDate' },
        { id: '13', field: 'ageHrs' },
        { id: '14', field: 'kpi' },
      ],
      table3: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'customerName' },
        { id: '4', field: 'stage' },
        { id: '5', field: 'attendedBy' },
        { id: '6', field: 'attendedDate' },
        { id: '7', field: 'attendedTime' },
        { id: '8', field: 'gap' },
        { id: '9', field: 'kpi' },
      ],
    },
  },
  {
    value: 'Efficiency_Summary_Count',
    required: [
      'srCategoryId',
      'dataRangeFrom',
      'dataRangeTo',
      'reportDataType',
      'reportStages',
    ],
    tableFields: {
      table1: [
        { id: '2', field: 'title' },
        { id: '3', field: 'count' },
        { id: '4', field: 'completedCount' },
        { id: '5', field: 'completedPercentage' },
      ],
      table2: [
        { id: '2', field: 'srId' },
        { id: '3', field: 'priority' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'province' },
        { id: '6', field: 'productName' },
        { id: '7', field: 'saStatus' },
        { id: '8', field: 'stage' },
        { id: '9', field: 'updatedBy' },
        { id: '10', field: 'startedDate' },
        { id: '11', field: 'updatedDate' },
        { id: '12', field: 'age' },
        { id: '13', field: 'kpi' },
      ],
      table3: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'customerName' },
        { id: '4', field: 'stage' },
        { id: '5', field: 'attendedBy' },
        { id: '6', field: 'attendedDate' },
        { id: '7', field: 'attendedTime' },
        { id: '8', field: 'gap' },
        { id: '9', field: 'kpi' },
      ],
    },
  },
  {
    value: 'Efficiency_Summary_Count_Current_Status',
    required: [
      'srCategoryId',
      'dataRangeFrom',
      'dataRangeTo',
      'reportDataType',
      'reportStages',
    ],
    tableFields: {
      table1: [
        { id: '2', field: 'title' },
        { id: '3', field: 'count' },
        { id: '4', field: 'completedCount' },
        { id: '5', field: 'completedPercentage' },
      ],
      table2: [
        { id: '2', field: 'stage' },
        { id: '3', field: 'count' },
        { id: '4', field: 'percentage' },
        { id: '5', field: 'latestEntryDate' },
      ],
      table3: [
        { id: '2', field: 'srId' },
        { id: '3', field: 'priority' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'province' },
        { id: '6', field: 'productName' },
        { id: '7', field: 'saStatus' },
        { id: '8', field: 'stage' },
        { id: '9', field: 'updatedBy' },
        { id: '10', field: 'startedDate' },
        { id: '11', field: 'updatedDate' },
        { id: '12', field: 'age' },
        { id: '13', field: 'kpi' },
      ],
      table4: [
        { id: '2', field: 'srName' },
        { id: '3', field: 'customerName' },
        { id: '4', field: 'stage' },
        { id: '5', field: 'attendedBy' },
        { id: '6', field: 'attendedDate' },
        { id: '7', field: 'attendedTime' },
        { id: '8', field: 'gap' },
        { id: '9', field: 'kpi' },
      ],
    },
  },
  {
    value: 'Average_Field_Cost',
    required: ['dataRangeFrom', 'dataRangeTo'],
    tableFields: {
      table1: [
        { id: '2', field: 'department' },
        { id: '3', field: 'visits' },
        { id: '4', field: 'fieldJobCost' },
        { id: '5', field: 'travelCost' },
        { id: '6', field: 'idlingCost' },
        { id: '7', field: 'totalCost' },
        { id: '8', field: 'averageCost' },
      ],
      table2: [
        { id: '2', field: 'name' },
        { id: '3', field: 'visits' },
        { id: '4', field: 'fieldJobCost' },
        { id: '5', field: 'travelCost' },
        { id: '6', field: 'idlingCost' },
        { id: '7', field: 'totalCost' },
        { id: '8', field: 'averageCost' },
        { id: '9', field: 'distance' },
        { id: '10', field: 'bataCost' },
        { id: '11', field: 'mealCost' },
        { id: '12', field: 'allowanceCost' },
        { id: '13', field: 'fixedCost' },
      ],
      table3: [
        { id: '2', field: 'date' },
        { id: '3', field: 'visits' },
        { id: '4', field: 'travelCost' },
        { id: '5', field: 'bataCost' },
        { id: '6', field: 'mealCost' },
        { id: '7', field: 'allowanceCost' },
        { id: '8', field: 'fixedCost' },
        { id: '9', field: 'kpi' },
      ],
      table4: [
        { id: '2', field: 'srId' },
        { id: '3', field: 'priority' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'province' },
        { id: '6', field: 'district' },
        { id: '7', field: 'productName' },
        { id: '8', field: 'transportMedium' },
        { id: '9', field: 'distance' },
        { id: '10', field: 'travelCost' },
        { id: '11', field: 'bataCost' },
        { id: '12', field: 'mealCost' },
        { id: '13', field: 'allowanceCost' },
        { id: '14', field: 'fixedCost' },
      ],
    },
  },
  {
    value: 'SERVICE_REQUEST_DETAIL_REPORT',
    required: [],
  },
  {
    value: 'TECHNICIAN_ROUTE_REPORT',
    required: ['date', 'technicianId'],
  },
  {
    value: 'Payment_Collection_Report',
    required: [
      'srCategoryId',
      'dataRangeFrom',
      'dataRangeTo',
      'reportStages',
      'reportDataType',
    ],
    tableFields: {
      table1: [
        { id: '2', field: 'title' },
        { id: '3', field: 'srCount' },
        { id: '4', field: 'collectedTotalAmount' },
      ],
      table2: [
        { id: '2', field: 'srId' },
        { id: '3', field: 'srName' },
        { id: '4', field: 'customerName' },
        { id: '5', field: 'address' },
        { id: '6', field: 'productBrand' },
        { id: '7', field: 'productCategory' },
        { id: '8', field: 'productModel' },
        { id: '9', field: 'assignedUserId' },
        { id: '10', field: 'assignedUserName' },
        { id: '11', field: 'startedDate' },
        { id: '12', field: 'completedDate' },
        { id: '13', field: 'serial' },
        { id: '14', field: 'collectedAmount' },
      ],
    },
  },
  {
    value: 'Stock_Summery_Report',
    required: ['dataRangeFrom', 'dataRangeTo', 'reportDataType'],
    tableFields: {
      table1: [
        { id: '2', field: 'employeeId' },
        { id: '3', field: 'employeeName' },
        { id: '4', field: 'department' },
        { id: '5', field: 'mobileNo' },
        { id: '6', field: 'srName' },
        { id: '7', field: 'priority' },
        { id: '8', field: 'customerName' },
        { id: '9', field: 'productName' },
        { id: '10', field: 'startedDate' },
        { id: '11', field: 'completedDate' },
        { id: '12', field: 'stage' },
      ],
      table2: [
        { id: '2', field: 'employeeId' },
        { id: '3', field: 'employeeName' },
        { id: '4', field: 'srId' },
        { id: '5', field: 'date' },
        { id: '6', field: 'partId' },
        { id: '7', field: 'partName' },
        { id: '8', field: 'usageCount' },
      ],
    },
  },
  {
    value: 'Machine_Population_Report',
    required: [
      // 'dataRangeFrom',
      // 'dataRangeTo',
      // 'reportDataType',
    ],
    tableFields: {
      table1: [
        { id: '2', field: 'customerName' },
        { id: '3', field: 'productCategory' },
        { id: '4', field: 'productBrand' },
        { id: '5', field: 'productModel' },
        { id: '6', field: 'tId' },
        { id: '7', field: 'mId' },
      ],
    },
  },
{
    value: 'FIELD_AGENT_JOURNEY_REPORT',
    required: ['date', 'technicianId'],
    tableFields: {
      table1: [
        { id: '2', field: 'outlet' },
        { id: '3', field: 'journeyStartTime' },
        { id: '4', field: 'journeyEndTime' },
        { id: '5', field: 'journeyDuration' },
        { id: '6', field: 'distanceFromPreviousJob' },
        { id: '7', field: 'distanceFromDistributor' },
        { id: '8', field: 'checkIn' },
        { id: '9', field: 'checkOut' },
        { id: '10', field: 'jobDuration' },
        { id: '11', field: 'remarks' },
      ],
    }
  }
];

export default reportsProperties;
