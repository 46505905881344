import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import hexToRgba from "hex-to-rgba";
import { HiClock } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FaHourglassStart } from "react-icons/fa";
import { durationCalculator, getFormattedDateTime } from "./utils/journey-utils";
import CustomCheckbox from "./SidebarCheckbox";

const checkBoxTypes = {
  FULL: "FULL",
  PARTIAL: "PARTIAL",
  NONE: "NONE",
};

const SideBar = ({ journeys, onSelect, hasError, selectAll, selectNone }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "310px",
        height: "100vh",
        boxShadow: "0 5px 15px 2px rgba(0,0,0,0.2)",
        overflowY: journeys.length > 0 ? "auto" : "hidden",
        zIndex: 10,
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          gap: "20px",
          backgroundColor: "white",
          padding: "20px",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%", position: "relative" }}>
          <Typography
            style={{
              color: "#f85444",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "50px",
            }}
          >
            TECHNICIAN
            <br />
            ROUTE REPORT
          </Typography>
          <JourneyDetails
            journeys={journeys}
            onSelect={onSelect}
            hasError={hasError}
            selectAllHandler={selectAll}
            selectNoneHandler={selectNone}
          />
        </div>
      </div>
    </div>
  );
};

const JourneyDetails = ({
  journeys,
  onSelect,
  hasError,
  selectNoneHandler,
  selectAllHandler,
}) => {
  const [mainCheckBoxType, setMainCheckBoxType] = useState(checkBoxTypes.NONE);

  useEffect(() => {
    let checkCounts = 0;
    journeys.forEach((journey) => {
      if (journey.isSelected) checkCounts++;
    });

    if (checkCounts === 0) setMainCheckBoxType(checkBoxTypes.NONE);
    else if (checkCounts === journeys.length)
      setMainCheckBoxType(checkBoxTypes.FULL);
    else setMainCheckBoxType(checkBoxTypes.PARTIAL);
  }, [journeys]);

  const mainCheckBoxHandler = () => {
    if (mainCheckBoxType === checkBoxTypes.FULL) selectNoneHandler();
    else selectAllHandler();
  };

  return journeys.length > 0 ? (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "0 10px",
        }}
      >
        <SubTitle
          name="JOURNEYS"
          checkType={mainCheckBoxType}
          onChange={mainCheckBoxHandler}
        />
        {journeys
          // ?.sort((a, b) => a.id - b.id)
          .map((journey, index) => (
            <SideBarElement key={index} journey={journey} onSelect={onSelect} />
          ))}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "310px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
      }}
    >
      {hasError ? (
        <Typography
          style={{
            fontWeight: "500",
            color: "rgba(0,0,0,0.3)",
          }}
        >
          Unable to find any journeys
        </Typography>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

const SubTitle = ({ name, checkType, onChange }) => {
  return (
    <>
      <Box display="flex">
        <CustomCheckbox
          style={{
            color: "rgba(0,0,0,0.8)",
          }}
          checked={checkType === checkBoxTypes.FULL}
          indeterminate={checkType === checkBoxTypes.PARTIAL}
          onChange={onChange}
        />
        <Typography
          style={{ fontSize: "16px", fontWeight: "600", paddingLeft: "10px" }}
        >
          {name}
        </Typography>
      </Box>
      <Seperator />
    </>
  );
};

const Seperator = () => {
  return (
    <div
      style={{
        marginTop: "1px",
        marginBottom: "4px",
        borderBottom: "2px solid #ededed",
      }}
    />
  );
};

const SideBarElement = ({ journey, onSelect }) => {
  const {
    name,
    id,
    isSelected,
    routeColor,
    distance,
    startTime: startTimeStamp,
    endTime: endTimeStamp,
    customerName,
  } = journey;

  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [times, setTimes] = useState({
    startTime: null,
    endTime: null,
  });

  const [timeDifference, setTimeDifference] = useState("");

  const handleChange = (e) => {
    onSelect(id, !isSelected);
  };

  useEffect(() => {
    if (!startTimeStamp || !endTimeStamp) return;

    const {
      date: startDate,
      month: startMonth,
      time: startTime,
    } = getFormattedDateTime(startTimeStamp);

    const {
      date: endDate,
      month: endMonth,
      time: endTime,
    } = getFormattedDateTime(endTimeStamp);

    if (startDate !== endDate)
      setDates({
        startDate: `${startMonth} ${startDate}`,
        endDate: `${endMonth} ${endDate}`,
      });

    setTimes({
      startTime,
      endTime,
    });

   setTimeDifference(durationCalculator(startTimeStamp, endTimeStamp, true));
  }, [startTimeStamp, endTimeStamp]);

  return (
    <div style={{ width: "100%" }}>
      <div
        onClick={handleChange}
        style={{
          // border:"1px solid black",
          padding: "10px",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 2px 5px 0.5px rgba(0,0,0,0.2)",
          height: isSelected ? "200px" : "30px",
          transition: "all 0.25s ease-out",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={customerName?.toUpperCase() || ""}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                overflowX: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "90%",
              }}
            >
              <CustomCheckbox
                checked={isSelected}
                onChange={handleChange}
                style={{ /* color:"rgba(0,0,0,0.5)" */ color: routeColor }}
              />
              <Typography
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontWeight: "600",
                  fontSize: "13px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  lineHeight: "12px",
                }}
              >
                {customerName?.toUpperCase()}
                <br />
                {id !== -1 && (
                  <span style={{ fontSize: "10px" }}>{name?.toUpperCase()}</span>
                )}
              </Typography>
            </div>
          </Tooltip>
          <div
            style={{
              color: "rgba(0,0,0,0.5)",
              fontSize: "24px",
              transform: isSelected ? "rotate(0deg)" : "rotate(-90deg)",
              transition: "all 0.25s ease-out",
            }}
          >
            <MdOutlineKeyboardArrowDown />
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <JourneyCard
            icon={<ImLocation fontSize="20px" color={routeColor} />}
            value={`${distance} KM`}
            color={routeColor}
          />
          {/* Start Time and End Time showed here */}
          <JourneyCard
            icon={<HiClock fontSize="20px" color={routeColor} />}
            value={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "start",
                  alignItems: "end",
                  gap: "5px",
                  lineHeight: "13px",
                }}
              >
                <DateField date={dates.startDate} time={times.startTime} />
                <div>-</div>
                <DateField date={dates.endDate} time={times.endTime} />
              </div>
            }
            color={routeColor}
          />
          <JourneyCard
            icon={<FaHourglassStart fontSize="20px" color={routeColor} />}
            value={timeDifference}
            color={routeColor}
          />
        </div>
      </div>
    </div>
  );
};

const DateField = ({ date, time }) => (
  <div>
    {date && <div style={{ fontSize: "10px" }}>{date}</div>}
    <div>{time}</div>
  </div>
);

const JourneyCard = ({ icon, value, color }) => (
  <div style={{ width: "100%" }}>
    <div
      style={{
        fontSize: "14px",
        fontWeight: "600",
        padding: "10px",
        color: "#555555",
        textTransform: "uppercase",
        backgroundColor: color && hexToRgba(color, 0.1),
        borderRadius: "10px",
        border: "0.5px solid #f0f0f0",
        maxHeight: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {icon}
        <div>{value}</div>
      </div>
    </div>
  </div>
);

export default SideBar;
