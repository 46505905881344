import React, { useEffect, useState, useRef } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import styles from './ReportsAndDashboard.module.css';
import { Link } from 'react-router-dom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import GeneralProperties from 'Util/Property/GeneralProperties';
import axios from 'axios/axios-default';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ServiceAgreementProperties } from 'Util/Property/ServiceAgreementProperties';
import MaterialTable from 'material-table';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { Typography } from '@material-ui/core';
import { buildFilterObject } from '../../../Util/Util';
import { ReportAndDashboardProperties } from 'Util/Property/ReportAndDashboardProperties';
import { getAllConfigs } from 'services/ReportAndDashBoard/ReportAndDashboardServices';
import LoadingOverlay from 'react-loading-overlay';
import { render } from 'react-dom';
import Spinner from 'components/Spinner/Spinner.js';

const ReportsAndDashboards = (props) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [allConfigs, setAllConfigs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.viewServiceAgreement,
      PermissionProperties.addServiceAgreement,
      PermissionProperties.editServiceAgreement,
      PermissionProperties.deleteServiceAgreement,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    getAll();
  }, []);

  const getAll = () => {
    setIsLoading(true);
    getAllConfigs()
      .then((result) => {
        if (result.status === 200) {
          // console.log("ALL KPIS BY GROUPED.",result.data)
          setAllConfigs(result.data);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.loadAll,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.loadAll,
        });
      });
  };

  const onUserEditHandler = (id, isEdit) => {
    props.history.push({
      pathname:
        props.match.url +
        '/' +
        urlProperties.reportsAndDashboards.reportsAndDashboards,
      search: '?id=' + id + '&isEdit=' + isEdit,
    });
  };

  const deleteUserDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };

  const deleteUserDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };

  const deleteConfirmHandler = () => {
    axios
      .delete('deleteHighlightConfigs/' + deleteRowData.id)
      .then((result) => {
        if (result.status == '200') {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: ReportAndDashboardProperties.messages.success.delete,
          });
          // tableRef.current.onQueryChange();
          getAll();
        } else {
          // could not delete succesfully
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.delete,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.delete,
        });
      });

    deleteUserDialogCloseHandler();
  };

  const deleteUserHandler = (rowData) => {
    setDeleteRowData(rowData);
    deleteUserDialogOpenHandler();
  };

  const [allRolesById, setAllRolesById] = useState({});

  // const getDisplayRolesString = (roles) =>{
  //     console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",roles)
  //     let roleString = "";
  //     for(let ind in roles){
  //        roleString += allRolesById[roles[ind].name]+", ";
  //     }
  //     return <div>{roleString}</div>;
  // }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* {
                        permissions[PermissionProperties.addServiceAgreement] ?
                            <div className={styles.serviceAgreementDiv}>
                              
                            </div> : ""
                            
                    } */}

              <CardBody>
                {/* customer delete confirm dialogBox */}

                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteUserDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        ReportAndDashboardProperties.messages.normal
                          .deleteConfirm
                      }
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteUserDialogCloseHandler}
                      color="primary"
                    >
                      {GeneralProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                    >
                      {GeneralProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* customer table content */}
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Dashboard Tiles
                        </Typography>
                        {/* <Typography variant="body1">Manage service agreements</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={[
                      { title: 'Tile Type', field: 'tile_type' },
                      // { title: 'DURATION', field: 'duration', render: rowData => ServiceAgreementProperties.duration_enum[rowData.duration], isNumericFilter: true },
                      // { title: 'Color', field: 'color' },
                      { title: 'Heading', field: 'heading' },
                      { title: 'Roles', field: 'roles.name' },
                      // { title: 'Roles', field: "roles" , render: rowData => getDisplayRolesString(allConfigs[rowData])

                      // },
                      { title: 'Action', field: 'action' },
                      { title: 'First report', field: 'first_report_type' },
                      { title: 'Date Range', field: 'period' },
                      // { title: 'Province', field: 'province'  },period
                      // { title: 'District', field: 'district' },
                      // { title: 'STATUS', field: 'duration', render: rowData => rowData.status?"Active":"Inactive", filterField: 'status_filter'}
                    ]}
                    data={
                      allConfigs
                      // testArray
                    }
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    actions={[
                      {
                        icon: 'read_more',
                        tooltip: 'View More Details',
                        iconProps: { className: 'action-buttons view-button' },
                        onClick: (event, rowData) =>
                          onUserEditHandler(rowData.id, false),
                        hidden: !permissions[
                          PermissionProperties.viewServiceAgreement
                        ],
                      },

                      {
                        icon: 'edit',
                        tooltip: 'Edit Tile',
                        iconProps: { className: 'action-buttons edit-button' },
                        onClick: (event, rowData) =>
                          onUserEditHandler(rowData.id, true),
                        hidden: !permissions[
                          PermissionProperties.editServiceAgreement
                        ],
                      },

                      (rowData) => ({
                        icon: 'delete',
                        tooltip: 'Delete Tile',
                        onClick: (event, rowData) => deleteUserHandler(rowData),
                        iconProps: {
                          className: 'action-buttons delete-button',
                        },
                        hidden: !permissions[
                          PermissionProperties.deleteServiceAgreement
                        ],
                      }),
                      {
                        icon: () => (
                          <Link
                            to={
                              props.match.url +
                              '/' +
                              urlProperties.reportsAndDashboards
                                .reportsAndDashboards
                            }
                          >
                            <div
                              className={
                                'generic-button-class div-button-wrapper'
                              }
                              style={{ borderRadius: '0.5rem' }}
                              variant="contained"
                              color="primary"
                            >
                              Add New Tile
                            </div>
                          </Link>
                        ),
                        tooltip: 'Add New Tile',
                        isFreeAction: true,
                        hidden: !permissions[
                          PermissionProperties.addServiceAgreement
                        ],
                      },
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ReportsAndDashboards;
